<div class="card mb-4">
	<div class="card-header">
		<i class="fa fa-circle"></i> {{tabTitle}}
		<button class="btn btn btn-secondary btn-sm float-end"
				(click)="addSpace()">Add
		</button>
	</div>
	<div class="card-body">

		<div class="card mb-2"
			 *ngFor="let space of spaces">
			<div class="card-body p-2 clearfix">
				<c-row class="align-items-center">
					<c-col>

						<div class="h5 text-primary mb-0 mt-2">{{space.name}}</div>
						<div class="text-secondary text-uppercase font-weight-light font-xs">{{space.id}}</div>
					</c-col>
					<c-col lg="auto">
						<div class="buttons">
							<button class="btn btn-secondary btn-sm d-none"
									(click)="removeSpace(space)">
								Remove <i class="fa fa-trash-o"></i>
							</button>
							<button class="btn btn-secondary btn-sm"
									(click)="openEditDialog(space)">
								Edit <i class="fa fa-pencil"></i>
							</button>
						</div>
					</c-col>
				</c-row>

			</div>
			<div class="card-footer px-3 py-2">
				<a class="font-weight-bold font-xs btn-block text-secondary pointer"
				   routerLink="/spaces/{{propertyId}}/{{space.id}}">View
					appliances ({{ space.appliance_categories.length}})
					<i class="fa fa-angle-right float-end font-lg"></i></a>
			</div>
		</div>

	</div>
</div>
