import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ThingGroupListComponent} from './thing-group-list/thing-group-list.component';
import {ThingGroupsRoutingModule} from './thing-groups-routing.module';
import {ThingGroupPageComponent} from './thing-group-page/thing-group-page.component';
import {ThingGroupAddComponent} from './thing-group-add/thing-group-add.component';
import {FirmwareTriggerModule} from '../../components/firmware-trigger/firmware-trigger.module';
import {MatDialogModule} from '@angular/material/dialog';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {HeroComponentsModule} from '../../components/hero-components.module';
import {FormModule} from '@coreui/angular';

@NgModule({
    declarations: [ThingGroupListComponent, ThingGroupPageComponent, ThingGroupAddComponent],
    imports: [
        CommonModule,
        ThingGroupsRoutingModule,
        MatDialogModule,
        FirmwareTriggerModule,
        FormsModule,
        HeroComponentsModule,
        FormModule,
        ReactiveFormsModule,
        TabsModule.forRoot(),

    ]
})
export class ThingGroupsModule {
}
