import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {LifeCycleEvent, RadioContent, WifiConnectedContent, WifiDisconnectedContent} from '../../_internal/life-cycle-events';
import {LifeCycleEventService} from '../../providers/services/life-cycle-event.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import {FormControl} from '@angular/forms';
import {OptionalHttpParams} from '../../../common/api/CrudAware';
import {EChartsOption} from 'echarts';
import {lineChartDefaultOptions} from '../../../common/chart-defaults';

@Component({
    selector: 'app-connectivity-timeline',
    templateUrl: './connectivity-timeline.component.html',
    styleUrls: ['./connectivity-timeline.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConnectivityTimelineComponent implements OnInit {


    @Input() title = 'Connectivity timeline';
    @Input() hubId: string;
    @Input() thingId: string;
    @Input() sonicConnection: boolean | string;
    @Input() bridgeConnection: boolean;
    @Input() sonicWifi = false;

    public chartsOption: EChartsOption;
    public thingEvents: LifeCycleEvent[];
    public hubEvents: LifeCycleEvent[];

    public fromControl: FormControl;
    public toControl: FormControl;
    public minDate = moment.utc().startOf('day').subtract(1, 'week');
    public maxDate = moment.utc();
    public maxDateLimit = moment.utc().endOf('day');
    public chartLabels = [];
    public responseSizeReachedMax = false;
    public responseMaxAllowedSize = 3000;
    public noDataMessage = false;
    private movePeriod = 7;
    private numberOfDatasets: number;
    private loadedDatasets = 0;
    private echartsInstance: any;
    private maxChartTimeSpanMs = 3600 * 24 * 1000 * 30; // 30 days

    constructor(private lifeCycleEventService: LifeCycleEventService,
                private cdRef: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.fromControl = new FormControl('');
        this.toControl = new FormControl('');
        this.sonicConnection = this.sonicConnection === 'connected' ? true : false;
        this.setUpFormsControlValues();
        this.fetchConnectivityEvents();
    }

    public onChartInit($event: any) {
        this.echartsInstance = $event;
    }

    public mapLabels(probes: LifeCycleEvent[]): number[] {
        if (!probes) {
            return [];
        }
        return probes.map((val) => {
                if (val.content.timestamp) {
                    return val.content.timestamp;
                } else {
                    return moment(val.inserted_at, 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]').valueOf();
                }
            }
        );
    }

    public mapValues(probes: LifeCycleEvent[], offset = 0): any[] {
        return probes.map((val) => {
            let eventDate: string;
            if (val.content.timestamp) {
                eventDate = moment(val.content.timestamp).utc().format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
            } else {
                eventDate = val.inserted_at;
            }
            return {
                name: val.connected ? 'on' : 'off',
                value: [eventDate, val.connected ? 1 + offset : -1 + offset],
                itemStyle: {color: val.connected ? 'green' : 'red'},
                object: val
            };
        });
    }

    public filterEvents(): void {
        this.minDate = this.fromControl.value;
        this.maxDate = this.toControl.value;
        this.fetchConnectivityEvents();
    }

    public resetFilter(): void {
        this.fromControl.setValue('');
        this.toControl.setValue('');

        if (!this.echartsInstance) {
            return;
        }
        this.echartsInstance.dispatchAction({
            type: 'restore'
        });
    }

    public setTimeFrame(days: number): void {
        this.movePeriod = days;
        this.maxDate = moment.utc();
        this.minDate = moment.utc().startOf('day').subtract(days, 'days');
        this.setUpFormsControlValues();
        this.fetchConnectivityEvents();
    }

    public moveTimeFrame(mode: string): void {
        switch (mode) {
            case 'back':
                this.minDate = this.minDate.subtract(this.movePeriod, 'days');
                this.maxDate = this.maxDate.subtract(this.movePeriod, 'days');
                break;
            case 'future':
                if (this.maxDateLimit.diff(this.maxDate, 'days') >= this.movePeriod) {
                    this.minDate = this.minDate.add(this.movePeriod, 'days');
                    this.maxDate = this.maxDate.add(this.movePeriod, 'days');
                }
                break;
        }
        this.noDataMessage = false;
        this.setUpFormsControlValues();
        this.fetchConnectivityEvents();
    }

    private setUpFormsControlValues() {
        this.fromControl.setValue(this.minDate);
        this.toControl.setValue(this.maxDate);
    }

    private fetchConnectivityEvents(): void {
        const baseParams = {};

        _.set(baseParams, 'page_size', this.responseMaxAllowedSize);
        this.numberOfDatasets = this.hubId && this.thingId ? 2 : 1;

        if (this.sonicWifi) {
            const params = _.clone(baseParams);
            _.set(params, 'hub_id', this.hubId);
            _.set(params, 'from', this.minDate.format('y-MM-DD'));
            _.set(params, 'to', this.maxDate.format('y-MM-DD'));
            this.getEvents(params, 'hub');
            return;
        }

        if (this.hubId) {
            const params = _.clone(baseParams);
            _.set(params, 'hub_id', this.hubId);
            _.set(params, 'from', this.minDate.format('y-MM-DD'));
            _.set(params, 'to', this.maxDate.format('y-MM-DD'));
            this.getEvents(params, 'hub');
        }

        if (this.thingId) {
            const params = _.clone(baseParams);
            _.set(params, 'thing_id', this.thingId);
            _.set(params, 'from', this.minDate.format('y-MM-DD'));
            _.set(params, 'to', this.maxDate.format('y-MM-DD'));
            this.getEvents(params, 'thing');
        }

    }

    private getEvents(params: OptionalHttpParams, type: string): void {
        this.lifeCycleEventService.getEvents(params)
            .subscribe((response) => {
                this.responseSizeReachedMax = response.total_entries >= this.responseMaxAllowedSize;


                this.loadedDatasets++;
                if (_.size(response.lifecycle_events) > 0) {

                    const lifeCycleEvents = _.reverse(response.lifecycle_events);

                    const currentState = _.cloneDeep(_.last(lifeCycleEvents));
                    this.fakeCurrentState(currentState);
                    lifeCycleEvents.push(currentState);

                    const lastState = _.cloneDeep(_.first(lifeCycleEvents));
                    this.fakeLastState(lastState);
                    lifeCycleEvents.unshift(lastState);

                    this.assignGraphData(type, lifeCycleEvents);

                } else {
                    this.handleGraphWithNoData(type);
                }
                this.updateChart();
                this.cdRef.detectChanges();
            });
    }

    private handleGraphWithNoData(type: string) {
        if (this.maxDateLimit.diff(this.maxDate, 'days') !== 0) {
            // eslint-disable-next-line @typescript-eslint/no-shadow
            const lifeCycleEvents = [];
            this.noDataMessage = true;
            this.assignGraphData(type, lifeCycleEvents);
            return;
        }
        const dummyEvent = {
            connected: true,
            content: {
                eventType: 'fakeType',
                ipAddress: '1',
                sessionIdentifier: '1',
                timestamp: 1,
                versionNumber: 1
            },
            hub_id: '1',
            id: '1',
            inserted_at: '1',
            thing_id: null,
            type: 'wifi'
        };

        dummyEvent.connected = type === 'thing' ? this.sonicConnection as boolean : this.bridgeConnection as boolean;
        const lifeCycleEvents = [];
        const currentState = _.cloneDeep(dummyEvent);
        this.fakeCurrentState(currentState);
        lifeCycleEvents.push(currentState);
        const lastState = _.cloneDeep(dummyEvent);
        this.fakeLastState(lastState, true);
        lifeCycleEvents.unshift(lastState);

        this.assignGraphData(type, lifeCycleEvents);
    }

    private assignGraphData(type: string, data: LifeCycleEvent[]) {
        switch (type) {
            case 'hub':
                this.hubEvents = data;
                break;
            case 'thing':
                this.thingEvents = data;
                break;
        }
    }

    private fakeCurrentState(connectionState: LifeCycleEvent) {
        if (this.maxDate.isSame(new Date(), 'day')) {
            connectionState.inserted_at = this.maxDate.utc().format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
        } else {
            connectionState.inserted_at = this.maxDate.endOf('day').utc().format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
        }

        connectionState.content.timestamp = this.maxDate.utc().valueOf();
        connectionState.id = '';

    }

    private fakeLastState(connectionState: LifeCycleEvent, noDataMode = false) {
        connectionState.inserted_at = this.minDate.startOf('day').utc().format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
        connectionState.content.timestamp = this.minDate.utc().valueOf();
        connectionState.connected = noDataMode ? connectionState.connected : !connectionState.connected;
        connectionState.id = '';

    }

    private formatLabel(event: LifeCycleEvent): string[] {
        let labels = [];
        if (event) {
            switch (event.type) {
                case 'wifi':
                    labels = this.formatWifiLogs(labels, event);
                    break;
                case 'radio':
                    labels = this.formatRadioLogs(labels, event);
                    break;
            }
        }
        return labels;
    }

    private formatWifiLogs(labels: string[], event: LifeCycleEvent): string[] {
        if (event.connected) {
            const content = event.content as WifiConnectedContent;
            if (content.ipAddress) {
                labels.push(`ipAddress: ${content.ipAddress}`);
            }
            if (content.eventType) {
                labels.push(`eventType: ${content.eventType}`);
            }
            if (content.versionNumber) {
                labels.push(`versionNumber: ${content.versionNumber}`);
            }
            labels.push(`timestamp: ${moment.utc(content.timestamp).format('YYYY-MM-DD HH:mm:ss')}`);
        } else {
            const content = event.content as WifiDisconnectedContent;
            if (content.clientInitiatedDisconnect) {
                labels.push(`clientInitiatedDisconnect: ${content.clientInitiatedDisconnect}`);
            }
            if (content.disconnectReason) {
                labels.push(`disconnectReason: ${content.disconnectReason}`);
            }

            if (content.eventType) {
                labels.push(`eventType: ${content.eventType}`);
            }
            if (content.versionNumber) {
                labels.push(`versionNumber: ${content.versionNumber}`);
            }
            labels.push(`timestamp: ${moment.utc(content.timestamp).format('YYYY-MM-DD HH:mm:ss')}`);
        }
        return labels;
    }

    private formatRadioLogs(labels: any[], event: LifeCycleEvent): string[] {
        const content = event.content as RadioContent;
        if (content.state) {
            labels.push(`state: ${content.state}`);
        }
        if (content.power_supply) {
            labels.push(`power supply:`);
            labels.push(`-- battery_when_motor_on: ${content.power_supply.battery_when_motor_on}`);
            labels.push(`-- battery: ${content.power_supply.battery}`);
        }
        if (content.firmware_version) {
            labels.push(`firmware_version: ${content.firmware_version}`);
        }
        if (content.version) {
            labels.push(`version: ${content.version}`);
        }
        if (content.debug_data) {
            labels.push(`debug_data: ${content.debug_data}`);
        }
        if (content.connection_state) {
            labels.push(`connection_state: ${content.connection_state}`);
        }
        if (content.health) {
            labels.push(`health:`);
            let healthOk = true;
            for (const healthItem of
                ['water_temp_sensor', 'ultrasound_sensor', 'spi', 'radio', 'pressure_sensor', 'ambient_temp_sensor', 'flash', 'i2c']) {
                const data = _.get(content, `health.${healthItem}`);
                if (data !== 'ok') {
                    labels.push(`-- ${healthItem}: ${data}`);
                    healthOk = false;
                }
            }
            if (healthOk) {
                labels.push(` -- all sensors works fine`);
            }
        }
        return labels;
    }

    private updateChart(): void {
        this.chartsOption = _.cloneDeep(lineChartDefaultOptions);
        const series = [];
        const yLabels = [];
        if (this.thingEvents) {
            yLabels.push('Sonic off');
            yLabels.push('Sonic on');
            yLabels.push('');
            series.push(
                {
                    name: 'Sonic',
                    type: 'line',
                    step: 'end',
                    data: this.mapValues(this.thingEvents),
                    areaStyle: {},
                    lineStyle: {
                        width: 0
                    }
                });
        }
        if (this.hubEvents) {
            yLabels.push('Bridge off');
            yLabels.push('Bridge on');
            series.push(
                {
                    name: 'Bridge',
                    type: 'line',
                    step: 'end',
                    data: this.mapValues(this.hubEvents, this.thingEvents ? 3 : 0),
                    areaStyle: {
                        origin: this.thingEvents ? 3 : 0
                    },
                    lineStyle: {
                        width: 0
                    }
                });
        }


        _.merge(this.chartsOption,
            {
                useUTC: true,
                xAxis: {
                    type: 'time',
                    data: this.mapLabels(this.hubEvents),
                },
                yAxis: [{
                    min: -1,
                    max: this.thingEvents && this.hubEvents ? 4 : 1,
                    minInterval: 1,
                    show: false
                },
                    {
                        data: yLabels,
                        type: 'category',
                        name: 'State',
                        position: 'left',
                    }],
                legend: {
                    data: ['Sonic', 'Bridge']
                },
                tooltip: {
                    position: (pt, params) => [pt[0] - 100, pt[1] - 300],
                    formatter: (params) => {
                        const series0 = params[0];
                        let fake = ' UTC';

                        const data = series0.data.object;
                        if (data.id === '') {
                            fake = ' UTC<br/>faked date';
                        }
                        let lines = '';
                        _.forEach(this.formatLabel(data), (item) => {
                            lines += `<br/>${item}`;
                        });

                        const status = `<span style="color:${data.connected ? 'green' : 'red'}">${data.connected ? 'connected' : 'disconnected'}</span>`;
                        return `<h4>${series0.seriesName}</h4>${status}<br/><strong>${moment.utc(data.inserted_at).format('YYYY-MM-DD HH:mm:ss')}${fake}</strong> ${lines}`;
                    },
                },
                dataZoom: [
                    {
                        filterMode: 'none',
                        startValue: moment().subtract(30, 'days').valueOf(),
                        type: 'inside',
                        maxValueSpan: this.maxChartTimeSpanMs
                    },
                    {
                        dataBackground: {
                            lineStyle: {
                                width: 0
                            },
                            areaStyle: {
                                opacity: 0
                            }
                        },
                        selectedDataBackground: {
                            lineStyle: {
                                width: 0
                            },
                            areaStyle: {
                                opacity: 0
                            }
                        },
                        filterMode: 'none',
                        maxValueSpan: this.maxChartTimeSpanMs
                    }
                ],
                series,
                visualMap: [{
                    type: 'piecewise',
                    pieces: [
                        {
                            gt: -2,
                            lte: 0,
                            color: 'red'
                        },
                        {
                            gt: 0,
                            lte: 2,
                            color: 'green'
                        },
                        {
                            gt: 2,
                            lte: 3,
                            color: 'red'
                        },
                        {
                            gt: 3,
                            lte: 4,
                            color: 'green'
                        },
                    ],


                    // min: 0,
                    // max: 1,
                    // inRange: {
                    //     color: 'green'
                    // },
                    // outOfRange: {
                    //     color: 'red'
                    // },
                    show: false
                }],
            });


    }
}
