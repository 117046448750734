import {EventService} from './../../providers/services/event.service';
import {SonicTelemetryMessage} from './../../_internal/telemetry';
import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {Event} from '../../_internal/event';
import * as moment from 'moment';
import * as _ from 'lodash';
import {ApplianceCategory} from '../../_internal/appliance_category';
import {Router} from '@angular/router';
import {BsModalService} from 'ngx-bootstrap/modal';
import {EventOverrideModalComponent} from '../event-override-modal/event-override-modal.component';
import {JsonDialogComponent} from '../../../common/components/dialogs/json-modal/json-dialog.component';
import {PropertyService} from '../../providers/services/property.service';
import {Appliance} from '../../_internal/appliance';
import {EChartsOption} from 'echarts';
import {lineChartDefaultOptions} from '../../../common/chart-defaults';

@Component({
    selector: 'app-event-details',
    templateUrl: './event-details.component.html',
    styleUrls: ['./event-details.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventDetailsComponent implements OnInit {
    @Input() event: Event;
    @Input() applianceCategories: ApplianceCategory[];
    @Input() appliances: Appliance[];
    @Input() chartStart: string;
    @Input() chartEnd: string;
    public utcStart: string;
    public utcEnd: string;
    public telemetryChartOptions: EChartsOption;
    public pressureChartOptions: EChartsOption;
    public probabilityType: number;
    public eventTooLongThresholdInMs = 86400000; //24 hours
    public probabilitiesTypes: string[];
    isCollapsed = true;

    constructor(private router: Router,
                private propertyService: PropertyService,
                public eventService: EventService,
                private modalService: BsModalService) {
    }

    public getCategory(id): ApplianceCategory {
        return _.find(this.applianceCategories, {id: parseInt(id, 10)});
    }

    public mapLabels(probes: SonicTelemetryMessage[]): number[] {
        return probes.map((val) => +val.probed_at);
    }

    public mapValues(probes: SonicTelemetryMessage[], valueToMap = 'water_flow'): number[] {
        return probes.map((val) => _.get(val, valueToMap));
    }

    public getPredictionProbability(): string {
        const probability = +this.event.predicted_appliance_category_probability[0].toFixed(3);
        return (probability * 100).toFixed(2) + '%';
    }

    public mapProbabilities(): string[] {
        this.probabilitiesTypes = ['bathtub', 'complex', 'dishwasher', 'irrigation', 'other', 'fridge', 'shower', 'tap', 'toilet', 'urinal', 'w-machine'];
        let iteration = 0;
        return _.map(this.event.probabilities, (value) => {
            const floatValue = typeof value === 'string' ? parseFloat(value) : value;
            this.probabilityType = iteration;
            iteration ++;

            return `${this.probabilitiesTypes[this.probabilityType]} ${(parseFloat(floatValue.toFixed(3)) * 100).toFixed(2).toString()} % `;
        });
    }

    public removeOverrides() {
        this.eventService.overrideEvent(this.event.device_id, this.event.event_start, {})
            .subscribe((res) => {
                this.event.overrides = res.overrides;
            });
    }

    public openOverrideModal() {
        this.modalService.show(EventOverrideModalComponent, {
            initialState: {
                event: this.event,
                applianceCategories: this.applianceCategories
            }
        });
    }

    public openRawJsonModal() {
        this.modalService.show(JsonDialogComponent, {
            initialState: {
                title: 'Raw json',
                content: this.event
            },
            class: 'modal-xl'
        });
    }

    public utcTime() {
        this.utcStart = moment.utc(+this.event.event_start / 1000, 'X').format('MMM DD, HH:mm:ss');
        this.utcEnd = moment.utc(+this.event.event_end / 1000, 'X').format('HH:mm:ss');
    }

    public navigateTo(paths) {
        this.router.navigate(paths);
    }


    ngOnInit() {
        this.utcTime();
        this.initTelemetryChart();
        this.initPressureChart();
    }


    public getAppliance(id: string): { name: string; icon_url: string } {
        const appliance = _.find(this.appliances, cat => cat.id === id);
        if (!appliance) {
            return null;
        }
        return {
            name: `${appliance.appliance_category_name} - ${appliance.space_name}`,
            icon_url: appliance.appliance_category_icon
        };
    }

    private initTelemetryChart(): void {
        const xAxisData = this.mapLabels(this.event.telemetry);
        const data = this.mapValues(this.event.telemetry);
        const minValue = Math.min(...data);
        this.telemetryChartOptions = _.cloneDeep(lineChartDefaultOptions);
        _.merge(this.telemetryChartOptions,
            {
                title: {
                    text: 'Telemetry'
                },
                tooltip: {
                    formatter: (params) => {
                        const series0 = params[0];
                        if(series0.value >= 1000) {
                            series0.value = series0.value.toFixed();
                        } else {
                            series0.value = series0.value.toFixed(1);
                        }
                        return `<strong>${series0.seriesName}</strong><br/>timestamp - ${series0.name}<br/>date - ${this.formatTime(series0.name)}<br/>flow - ${series0.value} ml/min`;
                    },
                },
                yAxis: {
                    min: minValue - Math.min(minValue,200),
                },
                xAxis: {
                    data: xAxisData,
                    axisLabel: {
                        formatter: (value, index) => this.formatTime(value)
                    }
                },
                series: [
                    {
                        name: 'Telemetry',
                        type: 'line',
                        data,
                        areaStyle: {},
                    }
                ],
            });
    }

    private formatTime(time: number): string {
        return moment.utc(+time / 1000, 'X').format('HH:mm:ss');
    }

    private initPressureChart(): void {
        const xAxisData = this.mapLabels(this.event.telemetry);
        const data = this.mapValues(this.event.telemetry, 'pressure');
        const minValue = Math.min(...data);

        this.pressureChartOptions = _.cloneDeep(lineChartDefaultOptions);
        _.merge(this.pressureChartOptions,
            {
                title: {
                    text: 'Pressure'
                },
                tooltip: {
                    formatter: (params) => {
                        const series0 = params[0];
                        return `<strong>${series0.seriesName}</strong><br/>timestamp - ${series0.name} <br/> date - ${this.formatTime(series0.name)}<br/>pressure - ${series0.value}`;
                    },
                },
                yAxis: {
                    min: minValue - Math.min(minValue,200),
                },
                xAxis: {
                    data: xAxisData,
                    axisLabel: {
                        formatter: (value, index) => this.formatTime(value)
                    }
                },
                series: [
                    {
                        name: 'Pressure',
                        type: 'line',
                        data,
                        areaStyle: {},
                    }
                ],
            });
    }

}
