<div class="card-header">
    <app-search-form [filterFields]="filterFields"
                     [startValues]="startValues"
                     (search)="onSearch($event)"
                     [pageName]="pageName"></app-search-form>
</div>

<div class="table-wrapper">
    <div class="table-loader"
         *ngIf="isLoading">
        <div class="spinner spinner-border my-5 mx-auto d-block"
             role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    <div class="table-box"
         [ngClass]="{'selection-enabled':selectionEnabled}">
        <ngx-datatable
            #table
            class="material w-100"
            [loadingIndicator]="isLoading"
            [rows]="rows"
            (page)='setPage($event)'
            [headerHeight]="50"
            [rowHeight]="rowHeight || undefined"
            [footerHeight]="50"
            [externalPaging]="true"
            [count]="tableSettings.total_elements"
            [offset]="toNum(tableSettings.page) - 1"
            [limit]="tableSettings.page_size"
            [sorts]="defaultSorts"
            [externalSorting]="externalSorting"
            [selectionType]="selectionEnabled?'single':null"
            (sort)="onSort($event)"
            (select)="onRowSelect($event)"
            [columns]="columns"
            columnMode="force">
        </ngx-datatable>
        <ng-template #serianNumberColumn
                     ngx-datatable-cell-template
                     let-row="row"
                     let-value="value">
            {{ value|uppercase }}
        </ng-template>
        <ng-template #codeColumn
                     ngx-datatable-cell-template
                     let-row="row"
                     let-value="value">
                    {{ value }}
        </ng-template>
        <ng-template #yesNoColumn
                     ngx-datatable-cell-template
                     let-row="row"
                     let-value="value">
            <c-badge [color]="value?'success':'danger'">{{value ? 'Yes' : 'No'}}</c-badge>
        </ng-template>
        <ng-template #dateColumn
                     ngx-datatable-cell-template
                     let-row="row"
                     let-value="value">
            {{value | date: 'yyyy-MM-dd HH:mm:ss' : 'UTC'}}
        </ng-template>
    </div>
</div>
