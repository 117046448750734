import { FavouritesPagesModule } from './favourites/favourites-pages.module';
import { MatIconModule } from '@angular/material/icon';
import { UserPagesModule } from './users/user-pages.module';
import { PropertyPagesModule } from './properties/property-pages.module';
import { IncidentPagesModule } from './incidents/incident-pages.module';
import { FormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { HeroComponentsModule } from '../components/hero-components.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThingPagesModule } from './things/thing-pages.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { HeroDashboardModule } from './hero-dashboard/hero-dashboard.module';
import { OrganisationPagesModule } from './organisations/organisation-pages.module';
import { HubPagesModule } from './hubs/hub-pages.module';
import { SpacesModule } from './spaces/spaces.module';
import { ManufacturingPagesModule } from './manufacturing/manufacturing-pages.module';
import { EventPagesModule } from './events/event-pages.module';
import { JobPagesModule } from './jobs/job-pages.module';
import { ThingGroupsModule } from './thing-groups/thing-groups.module';

@NgModule({
    imports: [
        CommonModule,
        HeroComponentsModule,
        FormsModule,
        IncidentPagesModule,
        PropertyPagesModule,
        ThingPagesModule,
        NgxPaginationModule,
        UserPagesModule,
        ModalModule.forRoot(),
        HeroDashboardModule,
        OrganisationPagesModule,
        HubPagesModule,
        SpacesModule,
        ManufacturingPagesModule,
        EventPagesModule,
        JobPagesModule,
        ThingGroupsModule,
        MatIconModule,
        FavouritesPagesModule,
    ],
    declarations: [],
})
export class HeroPagesModule {
}
