<table class="">
	<tr *ngIf="showUtc">
		<td>{{ displayDate | amFromUtc | amDateFormat : dateFormat}}</td>
	</tr>
	<tr *ngIf="showLocal">
		<td>Local:</td>
		<td>{{ displayDate | amLocal | amDateFormat : dateFormat}}</td>
	</tr>
	<tr *ngIf="showProperty">
		<td>Property:</td>
		<td>{{ getPropertyTime() | amDateFormat : dateFormat}}</td>
	</tr>
</table>
