import {Thing} from './../../_internal/thing';
import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {PropertyResource} from '../../providers/resources/property.resource';
import {Property} from '../../_internal/property';
import {Hub} from '../../_internal/hub';
import {PropertyService} from '../../providers/services/property.service';
import {HubService} from '../../providers/services/hub.service';

@Component({
    selector: 'app-thing-list',
    templateUrl: './thing-list.component.html',
    styleUrls: ['./thing-list.component.scss']
})
export class ThingListComponent implements OnInit {
    @Input() tabTitle: string;
    @Input() things: Thing[];
    @Input() hubs: Hub[];
    @Input() property: Property;
    @Input() hub: Hub;

    constructor(private propertyResource: PropertyResource,
                private propertyService: PropertyService,
                private hubService: HubService,
                private router: Router) {
    }

    public ngOnInit() {
        if (this.property) {
            this.fetchFromProperty();
        } else if (this.hub) {
            this.fetchFromHub();
        }

    }

    public typeIcon(type: string): string {
        switch (type) {
            case 'sonic_pro':
            case 'sonic_wifi':
            case 'smart_valve':
                return 'hl-icon-sonic';
        }
    }

    public goToThing(thingId: string): void {
        this.router.navigate([`things/${thingId}`]);
    }

    private fetchFromProperty() {
        this.hubs = this.propertyService.getAllHubsFromProperty(this.property);
        this.propertyResource.getAssociatedThings(this.property.id)
            .subscribe(response => {
                this.things = response.things;
            });
    }

    private fetchFromHub() {
        this.hubService.getAssociatedThings(this.hub.id)
            .subscribe((things) => this.things = things);
    }
}
