import {ConfirmationDialogComponent} from './../../../../common/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ActivatedRoute, Router} from '@angular/router';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {Thing} from '../../../_internal/thing';
import {PusherService} from '../../../providers/services/pusher.service';
import {BlockUIService} from 'ng-block-ui';
import {finalize, take} from 'rxjs/operators';
import {AvailableFirmwareListComponent} from '../../../components/firmware-trigger/list/available-firmware-list.component';
import {PusherStateChangeMessage} from '../../../_internal/pusher.state.change.message';
import {ThingEditPageComponent} from '../thing-edit/thing-edit-page.component';
import {environment} from '../../../../../environments/environment';
import {Observable, Subscription} from 'rxjs';
import {ThingService} from '../../../providers/services/thing.service';
import {Appliance} from '../../../_internal/appliance';
import * as _ from 'lodash';
import {TabDirective} from 'ngx-bootstrap/tabs';
import {ValueStatusButtonComponent} from '../value-status-button/value-status-button.component';
import {ToastrService} from 'ngx-toastr';
import { UserService } from '../../../providers/services/user.service';
import { ValveStatesService } from '../../../providers/services/valve-states.service';

@Component({
    selector: 'app-thing-page',
    templateUrl: './thing-page.component.html',
    styleUrls: ['./thing-page.component.scss']
})
export class ThingPageComponent implements OnInit, OnDestroy {
    public thingId: string;
    public thing: Thing;
    public modalRef: BsModalRef;
    public loadingState = false;
    public pusherChannel;
    public applianceStream: Observable<Appliance[]>;
    public activeTab = 'events';
    public statusCheck: Subscription;
    public telemetryStatus = true;
    public pusherInitialized: boolean;

    constructor(private thingService: ThingService,
                private modalService: BsModalService,
                private blockUI: BlockUIService,
                private pusher: PusherService,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private toastrService: ToastrService,
                private valveStatesService: ValveStatesService,
                public userService: UserService,) {
    }

    ngOnInit() {
        this.thingId = this.activatedRoute.snapshot.paramMap.get('id');
        this.fetchThing();
    }

    ngOnDestroy() {
        if (this.statusCheck) {
            this.statusCheck.unsubscribe();
        }

        if (this.pusherInitialized) {
            this.closePusher();
        }

    }

    public closePusher(): void {
        this.pusher.unsubscribe(this.thing.property_id);
    }

    public fetchThing(pusher = true): void {
        this.blockUI.start('loader', 'Loading...');
        this.thingService.getThing(this.thingId)
            .pipe(finalize(() => this.blockUI.stop('loader')))
            .subscribe(response => {
                if (response.thing.type === 'sonic_wifi') {
                    this.router.navigate(['things', 'wifi', response.thing.id]);
                    this.pusherInitialized = false;
                    return;
                } else if (response.thing.type === 'siryn') {
                    this.router.navigate(['things', 'tile', response.thing.id]);
                    this.pusherInitialized = false;
                    return;
                }
                this.thing = response.thing;
                if (pusher) {
                    this.initPusher();
                }
            });
    }

    public toggleTelemetryCard(status: boolean): void {
        this.telemetryStatus = status;
    }

    public openEditModal(): void {
        this.modalRef = this.modalService.show(ThingEditPageComponent, {
            initialState: {
                thing: _.cloneDeep(this.thing)
            }
        });
        this.modalService.onHide
            .pipe(take(1))
            .subscribe(() => this.fetchThing(false));
    }

    public openValveStatusModal(): void {
        this.modalRef = this.modalService.show(ValueStatusButtonComponent, {
            initialState: {
                thing: _.cloneDeep(this.thing),
                state: _.clone(this.thing.state)
            }
        });
        this.modalRef.content.onStateChange.subscribe(result => {
            this.loadingState = true;
        });
        this.modalService.onHide
            .pipe(take(1))
            .subscribe(() => this.fetchThing(false));
    }

    public initPusher(): void {
        this.pusherChannel = this.pusher.init(this.thing.property_id);
        this.pusherInitialized = true;
        this.pusherChannel.bind(environment.stateChangePusherEvent, (message: PusherStateChangeMessage) => {
            if (message.message.device_id === this.thingId) {
                this.thing.state = message.message.state;
                this.loadingState = false;
            }
        });
    }

    public openUpdateDialog() {
        this.modalService.show(AvailableFirmwareListComponent, {
            initialState: {listType: 'Firmwares', thing: this.thing},
            class: 'firmware-update-list',
        });
        const subscription = this.modalService.onHide.subscribe((event) => {
            if (event === 'success') {
                this.fetchThing();
                subscription.unsubscribe();
            }
        });
    }

    triggerPressureTest() {
        this.modalService.show(ConfirmationDialogComponent, {
            initialState: {
                message: `Are you sure you want to trigger pressure test?`,
                title: 'Trigger pressure test',
                button: 'Trigger'
            }
        });
        const subscription = this.modalService.onHide.subscribe((reason) => {
            if (reason === 'action-confirmed') {
                this.thingService.triggerPressureTests(this.thing.id).subscribe(
                    res => {
                        this.toastrService.success('Pressure test was triggered.', 'Settings.', environment.toastSettings);
                    },
                    error => {
                        this.toastrService.error('An error has ocured while triggering pressure test. Error code: ' + error.status + 'Message' + error.message, 'Settings.', environment.toastSettings);
                    }
                );
            }
            subscription.unsubscribe();
        });
    }

    public tabSelect($event: TabDirective): void {
        if ($event.id) {
            this.activeTab = $event.id;
        }
    }

    public openChronograf() {
        const domain = environment.production ? 'ai' : 'dev';
        // eslint-disable-next-line max-len
        const url = `https://chronograf.watergate.${domain}/sources/10000/chronograf/data-explorer?query=SELECT%20%2A%20FROM%20%22telegraf-telemetry%22.%22autogen%22.%22mqtt_consumer%22%20WHERE%20time%20%3E%20%3AdashboardTime%3A%20AND%20time%20%3C%20%3AupperDashboardTime%3A%20AND%20%22device_id%22%3D%27${this.thingId}%27%20LIMIT%202000`;
        this.openNewTab(url);
    }


    public openAWS() {
        const url = `https://eu-west-2.console.aws.amazon.com/iot/home?region=eu-west-2#/thing/${this.thingId}`;
        this.openNewTab(url);
    }

    public returnValveState(thing: Thing): string {
        return this.valveStatesService.returnValveState(thing);
    }

    private openNewTab(url: string) {
        window.open(url);
    }
}
