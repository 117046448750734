import {PropertyEditPageComponent} from './../property-edit/property-edit-page.component';
import {Thing} from '../../../_internal/thing';
import {Property} from '../../../_internal/property';
import {PropertyResource} from '../../../providers/resources/property.resource';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {PropertySettings} from '../../../_internal/property.settings';
import {SettingsEditComponent} from '../settings-edit/settings-edit.component';
import {take} from 'rxjs/operators';
import { FakeEventsComponent } from '../../../components/fake-events/fake-events.component';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../../../src/environments/environment';

@Component({
    selector: 'app-property',
    templateUrl: './property-page.component.html',
    styleUrls: ['./property-page.component.scss']
})
export class PropertyPageComponent implements OnInit {
    public property: Property;
    public associatedThings: Thing[] = [];
    public settings: PropertySettings;
    public propertyId: string;
    public modalRef: BsModalRef;

    constructor(private propertyResource: PropertyResource,
                private modalService: BsModalService,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private toastrService: ToastrService) {

    }

    ngOnInit() {
        this.propertyId = this.activatedRoute.snapshot.paramMap.get('id');
        this.fetchProperty();
        this.fetchAssociatedThings();
        this.fetchSettings();
    }

    public fetchProperty(): void {
        this.propertyResource.getProperty(this.propertyId).subscribe(response => {
            this.property = response.property;
        });
    }

    public fetchAssociatedThings(): void {
        this.propertyResource.getAssociatedThings(this.propertyId).subscribe(
            response => {
this.associatedThings = response.things;
},
            err => this.toastrService.error( 'An error has ocured while loading page data. Error code: ' + err.status + 'Error message' + err.message, 'Loading page data.', environment.toastSettings)
        );
    }

    public fetchSettings(): void {
        this.propertyResource.getSettings(this.propertyId).subscribe(
            response => {
this.settings = response;
},
            err => this.toastrService.error( 'An error has ocured while loading page data. Error code: ' + err.status + 'Error message' + err.message, 'Loading page data.', environment.toastSettings)
        );
    }

    public openPropertyEditModal(): void {
        this.modalRef = this.modalService.show(PropertyEditPageComponent, {
            initialState: {
                property: _.cloneDeep(this.property),
                action: 'update'
            }
        });
        this.modalService.onHide
                .pipe(take(1))
                .subscribe(() => this.fetchProperty());
    }

    public openSettingsEditModal(): void {
        this.modalRef = this.modalService.show(SettingsEditComponent, {
            initialState: {
                settings: _.cloneDeep(this.settings),
                propertyId: this.propertyId
            }
        });
        this.modalService.onHide
                .pipe(take(1))
                .subscribe(() => this.fetchSettings());
    }

    public openFakeIncidentstModal(): void {
        this.modalRef = this.modalService.show(FakeEventsComponent, {
            initialState: {
                thing: _.head(this.associatedThings)
            }
        });
    }

    public deleteProperty(): void {
        if (window.confirm(`Are you sure you want to delete ${this.property.name}`)) {
            this.propertyResource.deleteProperty(this.propertyId).subscribe(
                res => {
                    this.toastrService.success('Settings have been saved successfully.', 'Settings.', environment.toastSettings);
                    this.router.navigateByUrl('/properties');
                },
                error => {
                    this.toastrService.error( 'An error has ocured while saving data. Error code: ' + error.status + 'Error message' + error.message, 'Settings.', environment.toastSettings);
                }
            );
        }
    }
}
