import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HourlyDataComponent } from './hourly-data.component';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import { WaterUsageChartComponent } from './charts/water-usage-chart/water-usage-chart.component';
import { PressureChartComponent } from './charts/pressure-chart/pressure-chart.component';
import { TemperatureChartComponent } from './charts/temperature-chart/temperature-chart.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { NgChartsModule } from 'ng2-charts';

const components = [
    HourlyDataComponent,
    WaterUsageChartComponent,
    PressureChartComponent,
    TemperatureChartComponent,
];

@NgModule({
  imports: [
    CommonModule,
    BsDatepickerModule.forRoot(),
    BsDatepickerModule,
    NgChartsModule,
    NgxEchartsModule,

  ],
  exports: components,
  declarations: components,
  entryComponents: components,
  providers: [],
})
export class HourlyDataModule { }
