import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild} from '@angular/core';
import {BatteryLogEvent} from '../../_internal/life-cycle-events';
import {FormControl} from '@angular/forms';
import {BatteryService} from '../../providers/services/battery.service';
import * as moment from 'moment';
import * as _ from 'lodash';
import {BaseChartDirective} from 'ng2-charts';
import {EChartsOption} from 'echarts';
import {lineChartDefaultOptions} from '../../../common/chart-defaults';

@Component({
    selector: 'app-battery-timeline',
    templateUrl: './battery-timeline.component.html',
    styleUrls: ['./battery-timeline.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BatteryTimelineComponent implements OnInit {


    @ViewChild(BaseChartDirective) chart: BaseChartDirective;
    @Input() title = 'Battery timeline';
    @Input() thingId: string;
    public events: BatteryLogEvent[];
    public fromControl: FormControl;
    public toControl: FormControl;
    public minDate: Date;
    public chartLabels = [];
    public maxDate: Date;
    public chartOptions: EChartsOption;
    public mainsVoltage = 32768;
    public voltageCorrection = 26468;
    private echartsInstance: any;
    private maxChartTimeSpanMs = 3600 * 24 * 1000 * 30; // 30 days

    constructor(private lifeCycleEventService: BatteryService,
                private cdRef: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.fetchBatteryEvents();
        this.fromControl = new FormControl('');
        this.toControl = new FormControl('');
        this.minDate = moment.utc().startOf('day').subtract(1, 'months').toDate();
        this.maxDate = moment.utc().toDate();
    }

    public mapValues(probes: BatteryLogEvent[], prop: string): any[] {
        return probes.map((val) => [val.inserted_at, _.get(val, prop)]);
    }

    public mapLabels(probes: BatteryLogEvent[]): string[] {
        return probes.map((val) => val.inserted_at);
    }


    public filterEvents(): void {
        const fromDate = this.fromControl.value;
        let toDate = this.toControl.value;

        if (fromDate && toDate && fromDate > toDate) {
            this.toControl.setValue('');
            toDate = '';
        }

        if (this.echartsInstance) {

            const filterData = {
                type: 'dataZoom'
            };
            if (fromDate) {
                _.set(filterData, 'startValue', moment(fromDate).valueOf());
            }
            if (toDate) {
                _.set(filterData, 'endValue', moment(toDate).endOf('day').valueOf());
            }

            this.echartsInstance.dispatchAction(filterData);
        }

    }

    public setTimeFrame(days: number): void {
        this.resetFilter();
        const toDate = moment();
        const fromDate = toDate.clone().subtract(days, 'days');
        this.fromControl.setValue(fromDate);
        this.toControl.setValue(toDate);
        this.filterEvents();
    }

    public moveTimeFrame(offset: number): void {
        const from = this.fromControl.value;
        const to = this.toControl.value;
        if (from && to) {
            const diff = Math.round((to - from) / (1000 * 60 * 60 * 24));
            let toDate; let fromDate;
            if (offset > 0) {
                fromDate = from.add(diff, 'days');
                toDate = to.add(diff, 'days');
            } else {
                fromDate = from.subtract(diff, 'days');
                toDate = to.subtract(diff, 'days');
            }
            this.fromControl.setValue(fromDate);
            this.toControl.setValue(toDate);
            this.filterEvents();
        }
    }

    public onChartInit($event: any) {
        this.echartsInstance = $event;
    }

    public resetFilter(): void {
        this.fromControl.setValue('');
        this.toControl.setValue('');
        if (!this.echartsInstance) {
            return;
        }
        this.echartsInstance.dispatchAction({
            type: 'restore'
        });
    }

    private fetchBatteryEvents(): void {
        const params = {};

        _.set(params, 'page_size', 999999);

        if (this.thingId) {
            _.set(params, 'thing_id', this.thingId);
        }

        this.lifeCycleEventService.getEvents(params)
            .subscribe((response) => {
                if (_.size(response.battery_logs) > 0) {
                    this.events = _.reverse(response.battery_logs);
                    _.forEach(this.events, (event) => {
                        if(event.battery === this.mainsVoltage) {
                            event.battery = event.battery - this.voltageCorrection;
                        } else if(event.battery >= this.mainsVoltage){
                            event.battery = event.battery - this.mainsVoltage;
                        }
                        if (event.battery_when_motor_on === 65535) {
                            _.unset(event, 'battery_when_motor_on');
                        }
                    });

                    this.updateChart();
                }
                this.cdRef.detectChanges();
            });
    }


    private updateChart(): void {
        this.chartOptions = _.cloneDeep(lineChartDefaultOptions);
        _.merge(this.chartOptions,
            {title: {
                text: 'Battery',
              },
                xAxis: {
                    type: 'time',
                    data: this.mapLabels(this.events),
                },
                tooltip: {
                    position: (pt, params) => [pt[0] - 100, pt[1] - 100]

                },
                dataZoom: [
                    {
                        startValue: moment().subtract(7, 'days').valueOf(),
                        type: 'inside',
                        maxValueSpan: this.maxChartTimeSpanMs
                    },
                    {
                        maxValueSpan: this.maxChartTimeSpanMs
                    }
                ],
                series: [
                    {
                        name: 'When motor on',
                        type: 'line',
                        data: this.mapValues(this.events, 'battery_when_motor_on'),
                        areaStyle: {},
                        color: 'red',
                    },
                    {
                        name: 'Battery',
                        type: 'line',
                        data: this.mapValues(this.events, 'battery'),
                        areaStyle: {},
                        color: '#A0F0AE',
                        markArea: {
                            itemStyle: {
                              color: 'rgba(255, 173, 177, 0.4)'
                            },
                            data: [
                              [
                                {
                                  name: 'External power on',
                                  yAxis: '32760'
                                },
                                {
                                  yAxis: '60000'
                                }
                              ],
                            ]
                          },
                        markLine: {
                            symbol: 'none',
                            data: [{
                              name: '5,5V',
                              label: {
                                normal: {
                                 show: false,
                                }
                              },
                              yAxis: 5500,
                              lineStyle: {
                                normal: {
                                  type:'dashed',
                                  color: 'red',
                                }
                              },
                            },{
                                name: '5,0V',
                                yAxis: 5000,
                                label: {
                                  normal: {
                                   show: false,
                                  }
                                },
                                lineStyle: {
                                  normal: {
                                    type:'dashed',
                                    color: 'purple',
                                  }
                                },
                              },
                            {
                                yAxis: 32760,
                                label: {
                                  normal: {
                                   show: false,
                                  }
                                },
                                lineStyle: {
                                  normal: {
                                    type:'dashed',
                                    color: 'green',
                                  }
                                },
                              }],

                          },

                    },

                ],
            });

    }
}
