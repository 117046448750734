import {Component, OnInit} from '@angular/core';
import {OrganisationResource} from '../../../providers/resources/organisation.resource';
import {Organization} from '../../../_internal/organization';
import {HeroConfig} from '../../../hero-config';
import {Router} from '@angular/router';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {OrganisationEditComponent} from '../organisation-edit/organisation-edit.component';

@Component({
    selector: 'app-organisation-list',
    templateUrl: './organisation-list.component.html',
    styleUrls: ['./organisation-list.component.scss']
})
export class OrganisationListComponent implements OnInit {
    public organisations: Organization[];
    public p = 1;
    public itemsPerPage = HeroConfig.itemsPerPage;
    public modalRef: BsModalRef;

    constructor(private organisationResource: OrganisationResource,
                private modalService: BsModalService,
                private router: Router) {
    }

    ngOnInit() {
        this.fetchOrganisations();
    }

    public fetchOrganisations(): void {
        this.organisationResource.getOrganisationList().subscribe((organisations) => {
            this.organisations = organisations;
        });
    }

    public goToOrganisation(organisationId): void {
        this.router.navigateByUrl(`organisations/${organisationId}`);
    }

    public deleteOrganisation(organisationId): void {
        if (window.confirm('Are you sure you want to delete this organisation?')) {
            this.organisationResource.deleteOrganisation(organisationId).subscribe((response) => {
                this.fetchOrganisations();
            });
        }
    }

    public openCreateModal(): void {
        this.modalRef = this.modalService.show(OrganisationEditComponent);
        this.modalRef.content.organisation = {};
        this.modalRef.content.action = 'create';
        this.modalRef.content.onClose.subscribe(
            (value) => {
                this.fetchOrganisations();
            }
        );
    }
}
