import {NgModule} from '@angular/core';
import {ThingEventListComponent} from './thing-event-list.component';
import {DataTableModule} from '../../../common/components/data-table/data-table.module';
import {CommonModule} from '@angular/common';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {ManageEventCategoryDialogComponent} from './manage-event-category-dialog/manage-event-category-dialog.component';
import {FormsModule} from '@angular/forms';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { AggregatedDailyDataComponent } from './aggregated-daily-data/aggregated-daily-data.component';

const components = [
    ThingEventListComponent,
    ManageEventCategoryDialogComponent,
    AggregatedDailyDataComponent
];

@NgModule({
    imports: [
        CommonModule,
        DataTableModule,
        FormsModule,
        BsDatepickerModule.forRoot(),
        DragDropModule,
    ],
    exports: components,
    declarations: components,
    providers: []
})
export class ThingEventListModule {
}
