import { Injectable } from '@angular/core';
import { MembershipResource } from '../resources/membership.resource';
import { Observable } from 'rxjs';
import { MembershipsResponse, MultiInvitePayload } from '../resources/dto/memberships.response';
@Injectable({
  providedIn: 'root'
})
export class MembershipService {

    public massAddUsersList = [];
    public massAddPropertiesList = [];

    constructor(private membershipResource: MembershipResource, ) { }

    public getPropertyMembers(propertyId: string): Observable<MembershipsResponse> {
        return this.membershipResource.getPropertyMembers(propertyId);
    }

    public addMember(propertyId: string, payload: {email; membership_role}): Observable<MembershipsResponse> {
        return this.membershipResource.addMember(propertyId, payload);
    }

    public multiInvite(payload: MultiInvitePayload[]): Observable<void> {
        return this.membershipResource.multiInvite(payload);
    }

    public expelMember(propertyId: string, membershipId: string): Observable<MembershipsResponse> {
        return this.membershipResource.expelMember(propertyId, membershipId);
    }

    public changeOwner(propertyId: string, membershipId: string): Observable<void> {
        return this.membershipResource.changeOwner(propertyId, membershipId);
    }
}
