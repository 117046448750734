import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ProvisionReportsComponent} from './provision-reports/provision-reports.component';
import {CalibrationResultsComponent} from './calibration-results/calibration-results.component';
import {DeviceComponent} from './device/device.component';
import {DevicesComponent} from './devices/devices.component';
import { PressureTestResultsComponent } from './pressure-test-results/pressure-test-results.component';

const routes: Routes = [
    {
        path: '',
        children: [
            {
                path: 'provision-reports',
                component: ProvisionReportsComponent,
                data: {
                    title: 'Provision reports',
                },
            },
            {
                path: 'devices',
                component: DevicesComponent,
                data: {
                    title: 'Devices',
                },
            },
            {
                path: 'calibration-results',
                component: CalibrationResultsComponent,
                data: {
                    title: 'Calibration results',
                },
            },            {
                path: 'pressure-tests',
                component: PressureTestResultsComponent,
                data: {
                    title: 'Pressure tests',
                },
            },
            {
                path: 'device/:serialNo',
                component: DeviceComponent,
                data: {
                    title: 'Device',
                },
            },
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'provision-reports'
            }
        ]
    },
];

@NgModule({
    declarations: [],
    imports: [RouterModule.forChild(routes)],
    exports: []
})
export class ManufacturingPagesRoutingModule {
}
