<form (search)="doSearch()"
	  *ngIf="filterFields"
	  [formGroup]="tableSearchForm">
	<div class="row align-items-center">


		<div [class]="!filterItem.type?'col-auto':'col'"
			 *ngFor="let filterItem of filterFields">
			<ng-container [ngSwitch]="filterItem.type">
				<div *ngSwitchCase="'text'">
					<mat-form-field class="w-100">
						<mat-label>{{filterItem.label}}</mat-label>
						<input type="text"
							   matInput
							   (keyup)="inputKeyUp($event)"
							   formControlName="{{filterItem.name}}"/>
						<mat-icon matSuffix>search</mat-icon>
					</mat-form-field>
				</div>
				<div *ngSwitchCase="'date'">
					<mat-form-field class="w-100">
						<mat-label>{{filterItem.label}}</mat-label>
						<input matInput
							   [matDatepicker]="picker"
							   (change)="doSearch()"
							   (dateChange)="doSearch()"
							   formControlName="{{filterItem.name}}"/>

						<mat-datepicker #picker></mat-datepicker>
						<button mat-button
								matSuffix
								(click)="picker.open()">Open calendar
							<mat-icon matDatepickerToggleIcon>event
							</mat-icon>
						</button>

					</mat-form-field>
				</div>
				<div *ngSwitchDefault>
					<mat-form-field>
						<mat-label>{{filterItem.label}}</mat-label>
						<mat-select formControlName="{{filterItem.name}}"
									[multiple]="filterItem.multiple"
									(selectionChange)="doSearch()">
							<mat-option [value]="null"
										*ngIf="!filterItem.multiple">-
							</mat-option>
							<mat-option *ngFor="let option of filterItem.options"
										[value]="option.value">{{option.label}}</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
			</ng-container>
		</div>

		<div class="col-auto pl-1 ml-auto">
			<button mat-raised-button
					(click)="resetSearch()"
					type="reset"
					[disabled]="resetDisabled()">
				Reset
			</button>
		</div>
	</div>
</form>
