import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {Event} from '../../../../hero-labs/_internal/event';
import {ProvisionReportResult} from '../../../../hero-labs/_internal/provision-report';
import {PressureReportResult} from '../../../../hero-labs/_internal/pressure-test';
import {ObjectWithUnknownKeys} from '../../../../hero-labs/_internal/object-with-unknown-keys';
import * as _ from 'lodash';
import { BurningTestResult } from '../../../../hero-labs/providers/resources/dto/burning-mode';

@Component({
    selector: 'app-json-dialog',
    templateUrl: './json-dialog.component.html',
    styleUrls: ['./json-dialog.component.scss']
})
export class JsonDialogComponent implements OnInit {

    @ViewChild('exportTable') exportTable: ElementRef;

    public content: Event | ProvisionReportResult | PressureReportResult | BurningTestResult;
    public serial: string;
    public viewType = 'table'; // 'table',  'json'
    public title = '';
    public nestedJson = false;
    public keys: string[];
    public values: ObjectWithUnknownKeys [];

    constructor(private modalService: BsModalService,
                private bsModalRef: BsModalRef) {
    }

    ngOnInit(): void {
        this.keys = Object.keys(this.content);
        this.values = Object.values(this.content);

    }

    public chaneView(newView: string) {
        this.viewType = newView;
    }

    public close() {
        this.bsModalRef.hide();
    }


    public getObjectSize(data: any): number {
        return _.keys(data).length;
    }

    public isArray(data: any): boolean {
        return Array.isArray(data);
    }

    public isArrayOfObjects(data: any): boolean {
        return this.isArray(data) && data.length > 0 && typeof data[0] === 'object';
    }

    public isObject(data: any): boolean {
        return typeof data === 'object' && !Array.isArray(data);
    }

    public getColspanForData(data): number {
        if (this.isObject(data)) {
            return this.getObjectSize(data);
        }
        return 1;
    }

    public getDataSize(index: number): number {
        return _.max([this.getObjectSize(this.values[index]), this.getMinColSpanSize(this.keys[index])]);
    }

    public getMinColSpanSize(key: string): number {
        switch (key) {
            case 'externalSupplyTest':
                return 5;
            case 'batteryLevelTest':
                return 4;
            case 'temperatureTest':
                return 7;
            case 'pressureTest':
                return 4;
            case 'ultrasoundTest':
                return 8;
            case 'motorVoltageTest':
                return 9;
            case 'ledVoltageTest':
                return 12;
            case 'switchTest':
                return 3;
            case 'buzzerTest':
                return 4;
            case 'pairingTest':
                return 5;
        }
        return 1;
    }

    public getDataClass(data: any): string {
        switch (data) {
            case 'ok':
            case 'true':
                return 'text-success';
        }
        if (typeof data === 'string' && data.indexOf('failed') > -1) {
            return 'text-danger';
        }
        return '';
    }


    public copyTable() {
        this.selectNode(this.exportTable.nativeElement);
        document.execCommand('copy');
    }

    private selectNode(node) {
        const range = document.createRange();
        range.selectNodeContents(node);
        const select = window.getSelection();
        select.removeAllRanges();
        select.addRange(range);
    }

}
