import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Device, DeviceData, DevicesResponse, DevicesStatistics} from '../../_internal/device';
import {CalibrationResponse} from '../resources/dto/calibration.response';
import {DevicePressureTestResponse, DevicePressureTestResult} from '../resources/dto/pressure-test-response';
import {ProvisionReportResponse} from '../resources/dto/provision-report.response';
import {ManufacturingResource} from '../resources/manufacturing.resource';
import {ProvisionReport} from '../../_internal/provision-report';
import {CalibrationResult} from '../../_internal/calibration-result';

@Injectable({
    providedIn: 'root'
})
export class ManufacturingService {

    constructor(private manufacturingResource: ManufacturingResource) {
    }

    public getProvisionReports(params = {}): Observable<ProvisionReportResponse> {

        return this.manufacturingResource.getProvisionReports(params);
    }

    public getCalibrationResults(params = {}): Observable<CalibrationResponse> {
        return this.manufacturingResource.getCalibrationResults(params);
    }

    public getCalibrationResultsBySerial(serialNo: string, params = {}): Observable<CalibrationResponse> {
        return this.manufacturingResource.getCalibrationResultsBySerial(serialNo, params);
    }

    public getProvisionReportsBySerial(serialNo: string, params = {}): Observable<ProvisionReportResponse> {
        return this.manufacturingResource.getProvisionReportsBySerial(serialNo, params);
    }

    public getDevice(serialNo: string): Observable<Device> {
        return this.manufacturingResource.getDevice(serialNo);
    }

    public getDeviceManufacturingReport(serialNo: string): Observable<Device> {
        return this.manufacturingResource.getDeviceManufacturingReport(serialNo);
    }

    public changeDeviceManufacturingReport(serialNo: string, params = {}): Observable<Device> {
        return this.manufacturingResource.changeDeviceManufacturingReport(serialNo, params);
    }

    public getDevices(params = {}): Observable<DevicesResponse> {
        return this.manufacturingResource.getDevices(params);
    }

    public getCounts(path = 'provision_reports', date): Observable<{ counts: { string: number } }> {
        return this.manufacturingResource.getCounts(path, date);
    }

    public getStats(params = {}): Observable<DevicesStatistics> {
        return this.manufacturingResource.getStats(params);
    }

    public updateDevice(serialNo: string, params = {}): Observable<Device> {
        return this.manufacturingResource.updateDevice(serialNo, params);
    }

    public updateDeviceAdmin(serialNo: string, params = {}): Observable<Device> {
        // set hardware revision to the string "default" to reset it to the default value
        if (params['hardware_revision'] == null) {
            params['hardware_revision'] = 'default';
        }
        return this.manufacturingResource.updateDeviceAdmin(serialNo, params);
    }

    public getPressureTestReportsBySerial(serialNo: string, params = {}): Observable<DevicePressureTestResponse> {
        return this.manufacturingResource.getPressureTestReportsBySerial(serialNo, params);
    }

    public getPressureTestReports(params = {}): Observable<DevicePressureTestResponse> {
        return this.manufacturingResource.getPressureTestReports(params);
    }

    public updateProvisionReport(serialNo: string, id: string, params = {}): Observable<ProvisionReport> {
        return this.manufacturingResource.updateProvisionReport(serialNo, id, params);
    }

    public updateCalibrationResult(serialNo: string, id: string, params = {}): Observable<CalibrationResult> {
        return this.manufacturingResource.updateCalibrationResult(serialNo, id, params);
    }

    public updatePressureTest(serialNo: string, id: string, params = {}): Observable<DevicePressureTestResult> {
        return this.manufacturingResource.updatePressureTest(serialNo, id, params);
    }

    public postDeviceData( payload: File): Observable<File>{
        return this.manufacturingResource.postDeviceData(payload);
    }

    public getDeviceData(deviceId: string): Observable<DeviceData>{
        return this.manufacturingResource.getDeviceData(deviceId);
    }

    public transferDevice(deviceId: string): Observable<DeviceData>{
        return this.manufacturingResource.transferDevice(deviceId);
    }
}
