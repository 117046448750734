export class StorageParser {

    public static storageGet<T>(key: string): T {
        try {
            return JSON.parse(localStorage.getItem(key));
        } catch {
            return null;
        }
    }
}
