import { EventsResponse, EventResponse } from './dto/event.response';
import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class EventResource {
    private readonly URL = `${environment.apiGatewayUrl}/iot-core/admin/events`;
    constructor(private http: HttpClient) {
    }

    public getEvents(date: string, event_start: string, event_end: string, not_labelled_at: string): Observable<EventsResponse> {
        return this.http.get<EventsResponse>(this.URL + `/${date}`, { params: { event_start, event_end, not_labelled_at } });
    }

    public getEventsByDeviceId(date, event_start, event_end, not_labelled_at, device_id): Observable<EventsResponse> {
        return this.http.get<EventsResponse>(this.URL + `/${date}`, { params: { event_start, event_end, not_labelled_at, device_id } });
    }

    public getEvent(deviceId: string, eventStart: string): Observable<EventResponse> {
        return this.http.get<EventResponse>(this.URL + `/${deviceId}/${eventStart}`);
    }

    public overrideEvent(deviceId: string, eventStart: string, overrides): Observable<EventResponse> {
        return this.http.put<EventResponse>(this.URL + `/${deviceId}/${eventStart}/override`, { overrides });
    }
}
