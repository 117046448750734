<div class="btn-toolbar text-center well float-end" *ngIf="thing.type !=='siryn'; else Tile">
    <a class="btn btn-secondary btn-sm me-2"
    [routerLink]="['/properties',thing.property_id]">
        Go to Property
    </a>
    <a class="btn btn-secondary btn-sm me-2"
        *ngIf="thing.type !== 'sonic_wifi'"
    [routerLink]="['/hubs',thing.hub_id]">
        Go to Hub
    </a>
    <button class="btn btn-secondary btn-sm me-2"
            (click)="triggerPressureTest()">Trigger pressure test
    </button>

    <button class="btn btn-secondary btn-sm me-2 "
            (click)="openValveStatusModal()">Change valve state
    </button>
    <app-chronograf-button [thing]="thing"></app-chronograf-button>
    <button class="btn btn-secondary btn-sm me-2"
        (click)="openAWS()">AWS Thing
    </button>
    <a class="btn btn-secondary btn-sm me-2"
       [routerLink]="['/updates',thing.property_id]"
       *ngIf="this.userService.isFirmwareMaster() || this.userService.isFirmwareManager() || this.userService.isRoot()">
        Go to Updates
    </a>
    <app-trigger-reset-button *ngIf="thing && !isSonicWifi; else sonicWifi"
    [thingId]="thingId">
    </app-trigger-reset-button>
    <ng-template #sonicWifi>
        <app-trigger-reset-button class="reboot-button"
        [hubId]="thing.hub_id"></app-trigger-reset-button>
    </ng-template>
</div>
<ng-template #Tile>
    <a class="btn btn-secondary btn-sm me-2"
    [routerLink]="['/properties',thing.property_id]">
        Go to Property
    </a>
    <a class="btn btn-secondary btn-sm me-2"
        *ngIf="thing.type !== 'sonic_wifi'"
    [routerLink]="['/hubs',thing.hub_id]">
        Go to Hub
    </a>
    <button class="btn btn-secondary btn-sm me-2"
        (click)="openAWS()">AWS Thing
    </button>
    <app-chronograf-button [thing]="thing"></app-chronograf-button>
    <app-chronograf-button [thing]="thing" mode="battery"></app-chronograf-button>
</ng-template>
