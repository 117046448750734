import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../../src/environments/environment';
import { ManufacturingService } from '../../providers/services/manufacturing.service';
@Component({
  selector: 'app-device-modal',
  templateUrl: './device-modal.component.html',
  styleUrls: ['./device-modal.component.scss']
})
export class DeviceModalComponent {

  @Input() statusValue: boolean;
  @Input() statusType: string;
  @Input() serialNo: string;
  public statuses = [true, false];

  constructor(
    private _bsModalRef: BsModalRef,
    private manufacturingService: ManufacturingService,
    private toastrService: ToastrService) { }

  public onCancel(): void{
    this._bsModalRef.hide();

  }

  public onConfirm(statusType: string): void{
    const params = {};
    params[statusType] = !this.statusValue;
    this.manufacturingService.changeDeviceManufacturingReport(this.serialNo, params).subscribe(
        () => {
          this.toastrService.success('Settings have been saved successfully.', 'Settings.', environment.toastSettings);
          this._bsModalRef.hide();
        },
        error => {
          this.toastrService.error( 'An error has ocured while saving data. Error code: '+ error.status + 'Error message' + error.message, 'Settings.', environment.toastSettings);
      }
      );
  }
}
