import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {UserLogService} from '../../providers/services/user-log.service';
import {UserLogsParams, UserLogsResponse} from '../../providers/resources/dto/user-logs.response';
import {Router} from '@angular/router';
import {UserLog} from '../../_internal/user.log';
import * as _ from 'lodash';
import { InformationDialogComponent } from '../../../common/components/dialogs/information-dialog/information-dialog.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import * as moment from 'moment';

@Component({
    selector: 'app-logs-list',
    templateUrl: './logs-list.component.html',
    styleUrls: ['./logs-list.component.scss']
})
export class LogsListComponent implements OnInit, OnDestroy {
    @Input() userId: string;
    @Input() resourceId: string;
    @Input() tabTitle = 'Logs';
    public modalRef: BsModalRef;

    public currentPage = 1;
    public itemsPerPage = 6;
    public totalItems = 0;
    public totalPages = 0;

    userLogsResponse: UserLogsResponse;
    userLogsSubscription: Subscription;
    stringify = JSON.stringify;
    navigatableResources = ['Property', 'Event', 'Thing', 'Hub'];

    constructor(private userLogService: UserLogService,
                private router: Router,
                private modalService: BsModalService) {
    }

    ngOnInit() {
        this.fetchLogs();
    }

    ngOnDestroy() {
        this.userLogsSubscription.unsubscribe();
    }
    public getPage(event: number) {
        this.currentPage = event;
        this.fetchLogs();
    }

    public goTo(resourcePath, id) {
        this.router.navigate([resourcePath, id]);
    }

    public goToResource(log: UserLog) {
        this.modalRef = this.modalService.show(InformationDialogComponent, {
            initialState: {
                modalTitle: `${log.user_full_name} - ${log.user_id}`,
                modalContent: `Action performed on ${log.resource_type}. Action - ${log.action_name}, done at ${moment.utc(log.inserted_at).format('YYYY-MM-DD H:m:s')}`,
            }
        });

        this.router.navigate(this.getResourceNavigationArr(log));
    }

    public getResourceNavigationArr(log: UserLog) {
        switch (log.resource_type) {
            case 'Property':
                return ['properties', log.resource_id];
            case 'Thing':
                return ['things', log.resource_id];
            case 'Hub':
                return ['hubs', log.resource_id];
            case 'Event':
                return ['things', log.resource_id];
        }
    }

    public getAlertColor(action: string): string {
        switch (action) {
            case 'update':
                return 'info';
            case 'delete':
            case 'expel':
                return 'danger';
            case 'create':
                return 'success';
            default:
                return 'secondary';
        }
    }

    private fetchLogs() {
        this.userLogsSubscription = this.userLogService.getLogs(this.craftParams())
                .subscribe((data: UserLogsResponse) => {
                    this.userLogsResponse = data;
                    this.totalPages = data.total_pages;
                    this.totalItems = data.total_entries;
                });
    }

    private craftParams(): UserLogsParams {
        const filters: { user_id?: string; resource_id?: string } = {};

        if (this.userId) {
            filters.user_id = this.userId;
        }

        if (this.resourceId) {
            filters.resource_id = this.resourceId;
        }

        return _.merge(filters, {page_size: this.itemsPerPage, page: this.currentPage});
    }
}
