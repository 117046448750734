import {Observable} from 'rxjs';
import {environment} from './../../../../environments/environment';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Role} from '../../_internal/role';

@Injectable({
    providedIn: 'root'
})
export class RoleResource {
    private readonly url: string = `${environment.apiGatewayUrl}/user-service/admin/roles`;

    constructor(private http: HttpClient) {
    }

    public getRoleList(): Observable<Role[]> {
        return this.http.get<Role[]>(this.url);
    }
}
