import {CoreUINav} from './_core_ui_template_nav';
import {HeroConfig} from './hero-labs/hero-config';

interface NavAttributes {
    [propName: string]: any;
}

interface NavWrapper {
    attributes: NavAttributes;
    element: string;
}

interface NavBadge {
    text: string;
    variant: string;
}

interface NavLabel {
    class?: string;
    variant: string;
}

export interface NavData {
    name?: string;
    url?: string;
    icon?: string;
    badge?: NavBadge;
    title?: boolean;
    children?: NavData[];
    role?: string;
    variant?: string;
    attributes?: NavAttributes;
    divider?: boolean;
    class?: string;
    label?: NavLabel;
    wrapper?: NavWrapper;
}

export const navItems: NavData[] = [
    {
        name: 'Dashboard',
        url: '/dashboard',
        icon: 'icon-speedometer',
    },
    {
        name: 'Favourites',
        url: '/favourites',
        icon: 'icon-heart',
    },
    {
        name: 'Monitoring',
        url: '/monitoring',
        icon: 'icon-globe',
    },
    {
        name: 'Messages',
        url: '/messages',
        icon: 'icon-paper-plane',
    },
    {
        name: 'Properties',
        url: '/properties',
        icon: 'icon-home',
    },
    {
        name: 'Users',
        url: '/users',
        icon: 'icon-user',
    },
    {
        name: 'Incidents',
        url: '/incidents',
        icon: 'icon-wrench',
    },
    {
        name: 'Things',
        url: '/things',
        icon: 'icon-shield',
    },
    {
        name: 'Thing Groups',
        url: '/thing-groups',
        icon: 'icon-shield',
        role: 'root',
    },
    {
        name: 'Jobs',
        url: '/jobs',
        icon: 'icon-shield',
    },
    {
        name: 'Event Labelling UI',
        url: '/events',
        icon: 'icon-list',
    },
    {
        name: 'Organisations',
        url: '/organisations',
        icon: 'icon-tag',
    },
    {
        name: 'Hubs',
        url: '/hubs',
        icon: 'icon-shield',
    },
    {
        name: 'Firmware',
        url: '/firmware',
        children: [
            {
                name: 'Firmware list',
                url: '/firmware/list',
                icon: 'icon-list'
            },
            {
                name: 'Firmware channels',
                url: '/firmware/channels',
                icon: 'icon-list'
            }
        ],
        icon: 'icon-puzzle',
    },
    {
        name: 'Appliance categories',
        url: '/appliance/category',
        icon: 'icon-briefcase',
    },
    {
        name: 'Manufacturing',
        url: '/manufacturing',
        children: [
            {
                name: 'Devices',
                url: '/manufacturing/devices',
                icon: 'icon-list',
            },
            {
                name: 'Provision Reports',
                url: '/manufacturing/provision-reports',
                icon: 'icon-list',
            },
            {
                name: 'Calibration Results',
                url: '/manufacturing/calibration-results',
                icon: 'icon-list',
            },
            {
                name: 'Pressure tests',
                url: '/manufacturing/pressure-tests',
                icon: 'icon-list',
            }
        ],
        icon: 'icon-briefcase',
        divider: false
    },
    ...(() => {
        if (HeroConfig.coreUINav) {
            return CoreUINav;
        } else {
            return [];
        }
    })()
];


