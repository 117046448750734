import {Component, Input, OnInit} from '@angular/core';
import {PropertyMembership} from '../../_internal/property-membership';
import {MembershipRequest} from '../../_internal/membership.request';
import * as moment from 'moment';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {AddMemberComponent} from './add-member/add-member.component';
import {ChangeOwnerComponent} from './change-owner/change-owner.component';
import {MatDialog} from '@angular/material/dialog';
import {MembershipService} from '../../providers/services/membership.service';

@Component({
    selector: 'app-membership-list',
    templateUrl: './membership-list.component.html',
    styleUrls: ['./membership-list.component.scss']
})
export class MembershipListComponent implements OnInit {
    @Input() propertyId: string;
    public members: PropertyMembership[];
    public requests: MembershipRequest[];
    public modalRef: BsModalRef;

    constructor(private modalService: BsModalService,
                private membershipService: MembershipService,
                private matDialog: MatDialog, ) {
    }

    ngOnInit() {
        this.fetchMembers();
    }

    public addMember(): void {
        this.modalRef = this.modalService.show(AddMemberComponent);
        this.modalRef.content.members = this.members;
        this.modalRef.content.requests = this.requests;
        this.modalRef.content.propertyId = this.propertyId;
        this.modalRef.content.onClose.subscribe(result => {
            this.fetchMembers();
        });
    }

    public fetchMembers(): void {
        this.membershipService.getPropertyMembers(this.propertyId).subscribe(response => {
            this.members = response.members;
            this.requests = response.requests;
        });
    }

    public expelMember(membershipId: string): void {
        if (window.confirm('Are you sure you want to expel this member?')) {
            this.membershipService.expelMember(this.propertyId, membershipId).subscribe(
                (response) => {
                    this.fetchMembers();
                }
            );
        }
    }

    public changeOwner(): void {
        const ref = this.matDialog.open(ChangeOwnerComponent, {
            maxWidth: '45%',
            autoFocus: false,
            panelClass: 'mx-auto',
            data: {
                members: this.members,
                propertyId: this.propertyId
            }
        });

    }

    public dateAgo(date): string {
        return moment.utc(date, 'YYYY-MM-DD hh:mm:ss').fromNow();
    }

}
