import {RoleGuardService} from '../providers/services/role-guard.service';
import {Routes} from '@angular/router';

export const HeroPagesRoutes: Routes = [
    {
        path: 'properties',
        loadChildren: () => import('./properties/property-pages.module').then(m => m.PropertyPagesModule),
        data: {
            title: 'Properties',
        },
    },
    {
        path: 'users',
        loadChildren: () => import('./users/user-pages.module').then(m => m.UserPagesModule),
        data: {
            title: 'Users',
        },
    },
    {
        path: 'updates',
        loadChildren: () => import('./updates/updates.module').then(m => m.UpdatesModule),
        canActivate: [RoleGuardService],
        data: {
            role: ['firmware_master', 'firmware_manager', 'root'],
            title: 'Updates',
        },
    },
    {
        path: 'things',
        loadChildren: () => import('./things/thing-pages.module').then(m => m.ThingPagesModule),
        data: {
            title: 'Things',
        },
    },
    {
        path: 'thing-groups',
        loadChildren: () => import('./thing-groups/thing-groups.module').then(m => m.ThingGroupsModule),
        canActivate: [RoleGuardService],
        data: {
            role: ['root'],
            title: 'Thing groups'
        }
    },
    {
        path: 'jobs',
        loadChildren: () => import('./jobs/job-pages.module').then(m => m.JobPagesModule),
        data: {
            title: 'Jobs',
        },
    },
    {
        path: 'incidents',
        loadChildren: () => import('./incidents/incident-pages.module').then(m => m.IncidentPagesModule),
        data: {
            title: 'Incidents',
        },
    },
    {
        path: 'dashboard',
        loadChildren: () => import('./hero-dashboard/hero-dashboard.module').then(m => m.HeroDashboardModule),
        data: {
            title: 'Dashboard',
        },
    },
    {
        path: 'favourites',
        loadChildren: () => import('./favourites/favourites-pages.module').then(m => m.FavouritesPagesModule),
        data: {
            title: 'Favourites',
        },
    },
    {
        path: 'messages',
        loadChildren: () => import('./messages/messages.module').then(m => m.MessagesModule),
        data: {
            title: 'Messages',
        },
    },
    {
        path: 'monitoring',
        loadChildren: () => import('./monitoring/monitoring-pages.module').then(m => m.MonitoringPagesModule),
        data: {
            title: 'Monitoring',
        },
    },
    {
        path: 'organisations',
        loadChildren: () => import('./organisations/organisation-pages.module').then(m => m.OrganisationPagesModule),
        data: {
            title: 'Organisations',
        },
    },
    {
        path: 'firmware',
        loadChildren: () => import('./firmware/firmware.module').then(m => m.FirmwareModule),
        canActivate: [RoleGuardService],
        data: {
            role: ['firmware_master', 'firmware_manager', 'root'],
            title: 'Firmware',
        },
    },
    {
        path: 'appliance',
        loadChildren: () => import('./appliance/appliance.routing.module').then(m => m.ApplianceRoutingModule),
        data: {
            title: 'Appliance',
        },
    },
    {
        path: 'hubs',
        loadChildren: () => import('./hubs/hub-pages.module').then(m => m.HubPagesModule),
        data: {
            title: 'Hubs',
        },
    },
    {
        path: 'spaces',
        loadChildren: () => import('./spaces/spaces.module').then(m => m.SpacesModule),
        data: {
            title: 'Spaces',
        },
    },
    {
        path: 'manufacturing',
        loadChildren: () => import('./manufacturing/manufacturing-pages.module').then(m => m.ManufacturingPagesModule),
        data: {
            title: 'Manufacturing',
        },
    },
    {
        path: 'events',
        loadChildren: () => import('./events/event-pages.module').then(m => m.EventPagesModule),
        data: {
            title: 'Events',
        },
    },
];
