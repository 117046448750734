<div class="modal-content">
    <div class="modal-header">
        <h4 class="modal-title">Longest and biggest events.</h4>
        <button type="button"
            aria-label="Close"
            mat-icon-button
            (click)="close()">
            close
</button>
    </div>
    <div class="modal-body" *ngIf="duration_events.length; else noEvents">
        <div class="data-column">
            <div class="data-header" *ngIf="showUtc">Longest events UTC time</div>
            <div class="data-table" *ngIf="showUtc && returnsDataStream(duration_events)| async">

                <app-data-table [dataStream]="returnsDataStream(duration_events)"

                                [displayedColumns]="displayedColumns">
                    <ng-template let-event>
                    </ng-template>
                </app-data-table>

            </div>

            <div class="data-header" *ngIf="!showUtc">Longest events local time ({{timezone}})</div>
            <div class="data-table" *ngIf="!showUtc &&  returnsDataStream(duration_events_local) | async">

                <app-data-table [dataStream]="returnsDataStream(duration_events_local)"

                                [displayedColumns]="displayedColumns">
                    <ng-template let-event>
                    </ng-template>
                </app-data-table>

            </div>

            <div class="data-header" *ngIf="showUtc">Water usage events UTC time</div>
            <div class="data-table"*ngIf="showUtc && returnsDataStream(water_consumed_events) | async">

                <app-data-table [dataStream]="returnsDataStream(water_consumed_events)"

                                [displayedColumns]="displayedColumns">
                    <ng-template let-event>
                    </ng-template>
                </app-data-table>

            </div>

            <div class="data-header" *ngIf="!showUtc">Water usage local time ({{timezone}})</div>
            <div class="data-table" *ngIf="!showUtc && returnsDataStream(water_consumed_events_local) | async">

                <app-data-table [dataStream]="returnsDataStream(water_consumed_events_local)"

                                [displayedColumns]="displayedColumns">
                    <ng-template let-event>
                    </ng-template>
                </app-data-table>

            </div>
        </div>
    </div>
    <ng-template #noEvents>
        <div class="data-column">
            <div class="data-header">No data</div>
        </div>
    </ng-template>
</div>
