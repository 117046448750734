import {MatIconModule} from '@angular/material/icon';
import {FavouritePageComponent} from './favourite-page/favourite-page.component';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FavouritesPagesRoutingModule} from './favourites-pages-routing.module';
import {CardModule, GridModule, TableModule, TooltipModule} from '@coreui/angular';
import {MatButtonModule} from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';

@NgModule({
    declarations: [FavouritePageComponent],
    imports: [
        CommonModule,
        FavouritesPagesRoutingModule,
        MatIconModule,
        GridModule,
        CardModule,
        TableModule,
        TooltipModule,
        MatButtonModule,
        MatTooltipModule,
    ],
    exports: [
        FavouritePageComponent,
    ],
})
export class FavouritesPagesModule {
}
