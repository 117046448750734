import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {JobListComponent} from './job-list/job-list.component';
import {JobPagesRoutingModule} from './job-pages-routing.module';
import {JobPageComponent} from './job-page/job-page.component';
import {JobExecutionComponent} from './job-execution/job-execution.component';
import {MatDialogModule} from '@angular/material/dialog';
import {DataTableModule} from '../../../common/components/data-table/data-table.module';
import {BadgeModule, ButtonModule} from '@coreui/angular';

@NgModule({
    declarations: [JobListComponent, JobPageComponent, JobExecutionComponent],
    exports: [
        JobExecutionComponent
    ],
    imports: [
        CommonModule,
        JobPagesRoutingModule,
        DataTableModule,
        MatDialogModule,
        BadgeModule,
        ButtonModule,
    ]
})
export class JobPagesModule {
}
