<div class="card mb-4">
    <div class="card-header">
        <i class="fa fa-align-justify"></i> Devices statistics
    </div>
    <div class="=card-body">
        <div class="row m-2">
            <div class="col-lg-4 position-relative">
                <div class="btn-toolbar text-center well">
                    <button class="btn btn btn-secondary btn-sm ml-2" (click)="daterangepicker.toggle()" [attr.aria-expanded]="daterangepicker.isOpen" type="button">Date Range Picker</button>
                    <span>
                        <button type="button"
                        class="btn btn-secondary btn-sm ml-2"
                        (click)="setTimeFrame(30)">30 days
                </button>
                <button type="button"
                        class="btn btn-secondary btn-sm ml-2"
                        (click)="setTimeFrame(7)">7 days
                </button>
                <button type="button"
                        class="btn btn-secondary btn-sm ml-2"
                        (click)="setTimeFrame(1)">1 days
                </button>
            </span>
                </div>
                <form [formGroup]="dateFormGroup" >
                    <input type="text"
                        class="form-control my-2"
                        formControlName="dateRange"
                        #daterangepicker="bsDaterangepicker"
                        bsDaterangepicker
                        placeholder="Date Range" (bsValueChange)="dateRangeCreated($event)"
                        [bsConfig]="{ containerClass: 'theme-blue', rangeInputFormat : 'MMMM DD YYYY', dateInputFormat: 'MMMM Do YYYY'}">
                </form>
            </div>
            <div class="col-lg-8 position-relative">
                <div class="flex-container" *ngIf="statisticsData | async as stats">
                    <div>Calibrated devices: {{stats.calibrated_devices_count}}</div>
                    <div>Calibrated results: {{stats.calibration_results_count}}</div>
                    <div>Tested deviced: {{stats.pressure_tested_devices_count}}</div>
                    <div>Provision reports: {{stats.provision_reports_count}}</div>
                    <div>Provisioned devices: {{stats.provisioned_devices_count}}</div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="card mb-4">
    <div class="card-header">
        <i class="fa fa-align-justify"></i> Devices
        <span class="btn-toolbar text-center well float-end">
            <button
                class="btn btn-secondary btn-sm"
                (click)="postDeviceData()">Import device</button>
            <button
                class="btn btn-secondary btn-sm"
                (click)="changeDevicesStatus()">Change devices state</button>
            <button
                class="btn btn-secondary btn-sm"
                (click)="changeDevicesBlockedStatus()">Change blocked state</button>
        </span>
    </div>
    <div class="card-body">
        <div class="card-header">
            <app-search-form [filterFields]="deviceFilter"
                             [startValues]="null"
                             (search)="fetchDevice($event)"
                             [pageName]="null"></app-search-form>
        </div>
        <app-table [rows]="rows"
                   [filterFields]="filterFields"
                   [columns]="columns"
                   [selectionEnabled]="false"
                   [tableSettings]="tableSettings"
                   (fetchPage)="fetchPage($event)">
        </app-table>
        <ng-template #actionsColumn ngx-datatable-cell-template let-row="row" let-value="value">
            <div class="btn-group">

                <a routerLink="/manufacturing/device/{{row.serial_no}}"
                   class="btn btn-sm btn-secondary">
                    Device
                </a>
            </div>
        </ng-template>
    </div>
</div>
