import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CircleOfTrust} from '../../../_internal/circle-of-trust';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {EmergencyContactFactory} from './emergencyContactFactory';
import {EmergencyContactsCreate} from '../../../_internal/emergency-contacts-create';
import {CircleOfTrustService} from '../../../providers/services/circle-of-trust.service';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../../../src/environments/environment';

@Component({
    selector: 'app-add-contact',
    templateUrl: './add-contact-modal.component.html',
    styleUrls: ['./add-contact-modal.component.scss']
})
export class AddContactModalComponent implements OnInit {

    @Input() propertyId: string;
    @Input() contact: CircleOfTrust;
    @Input() contacts: CircleOfTrust[];
    public formGroup: FormGroup;
    public incorrectInputMsg = '';
    public occupiedEmails: string[];
    public errors: string[];

    constructor(private _bsModalRef: BsModalRef,
                private circleOfTrustService: CircleOfTrustService,
                private fb: FormBuilder,
                private toastrService: ToastrService) {

    }

    ngOnInit() {
        this.initContact();
        this.initForm();
        this.occupiedEmails = _.map(this.contacts, member => member.email);
    }

    public onCancel(): void {
        this._bsModalRef.hide();
    }

    public onConfirm(): void {
        if (this.formIsValid()) {
            const contact = EmergencyContactFactory.build(this.contact, this.formGroup.value);
            if (this.contact.id) {
                this.updateContact(contact);
            } else {
                this.addNewContact(contact);
            }
        }

    }

    public isEmailDuplicated(): boolean {
        const newEmail = this.formGroup.controls.email.value;
        const duplicate = _.find(this.occupiedEmails, email => newEmail !== this.contact.email && newEmail === email);
        return Boolean(duplicate);

        return _.find(this.occupiedEmails, (email) => newEmail !== this.contact.email && newEmail === email) !== undefined;
    }

    private formIsValid(): boolean {
        let formValid = true;
        this.incorrectInputMsg = '';
        if (!this.formGroup.controls.email.valid) {
            this.incorrectInputMsg = 'Provided email is incorrect';
            formValid = false;
        } else if (!this.formGroup.valid) {
            this.incorrectInputMsg = 'Please fill all fields';
            formValid = false;
        } else if (this.isEmailDuplicated()) {
            this.incorrectInputMsg = 'Provided email is already on the list';
            formValid = false;
        }
        return formValid;
    }

    private updateContact(contact: EmergencyContactsCreate): void {
        this.circleOfTrustService.updateEmergencyContact(this.propertyId, contact)
            .subscribe(() => {
                this.toastrService.success('Settings have been saved successfully.', 'Settings.', environment.toastSettings);
                this._bsModalRef.hide();
            }, (error) => {
                this.toastrService.error( 'An error has ocured. Error code: ' + error.status + 'Error message' + error.message, 'Loading page data.', environment.toastSettings);
                this.extractErrors(error);
            });
    }

    private addNewContact(contact: EmergencyContactsCreate): void {
        this.circleOfTrustService.createEmergencyContact(this.propertyId, contact)
            .subscribe(() => {
                this.toastrService.success('Settings have been saved successfully.', 'Settings.', { progressBar: true});
                this._bsModalRef.hide();
            }, (error) => {
                this.toastrService.error( 'An error has ocured. Error code: ' + error.status + 'Error message' + error.message, 'Loading page data.', environment.toastSettings);
                this.extractErrors(error);
            });
    }

    private initForm(): void {

        this.formGroup = this.fb.group({
            email: [this.contact.email, [Validators.required, Validators.email]],
            firstName: [this.contact.first_name, [Validators.required]],
            lastName: [this.contact.last_name, [Validators.required]],
            phone: [this.contact.phone, [Validators.required]],
        });
    }

    private extractErrors(error: any): void {
        this.errors = _.map(_.get(error, 'error.errors'), item => item[0]);

    }

    private initContact(): void {
        if (!this.contact) {
            this.contact = {
                propertyId: this.propertyId,
                contact_type: 'circle_of_trust',
                email: '',
                first_name: '',
                last_name: '',
                phone: '',
            };
        }
        this.contact.propertyId = this.propertyId;
    }
}
