import { Injectable } from '@angular/core';
 import { IncidentResource } from '../resources/incident.resource';
 import {OptionalHttpParams} from '../../../common/api/CrudAware';
 import { IncidentsResponse } from '../resources/dto/incidents.response';
 import { Observable } from 'rxjs';
 import { IncidentDetailsResponse } from '../resources/dto/incidents.response';
 import { fakeIncidentsPayload } from '../resources/dto/incidents.response';
 import { IncidentLogReponse } from '../resources/dto/IncidentLogReponse';
import { CustomMessage } from '../resources/dto/custom-message';
 @Injectable({
   providedIn: 'root'
 })
 export class IncidentService {

   constructor(private resource: IncidentResource) { }

   public getIncidentsList(params: OptionalHttpParams = {}): Observable<IncidentsResponse> {
     return this.resource.getIncidentsList(params);
   }

   public getIncident(id: string): Observable<IncidentDetailsResponse> {
     return this.resource.getIncident(id);
   }

   public findById(incidentId: string): Observable<IncidentLogReponse> {
     return this.resource.findById(incidentId);
   }

   public sendFakeIncident(params: fakeIncidentsPayload): Observable<IncidentsResponse> {
     return this.resource.sendFakeIncident(params);
   }

   public sendCustomSMS(params: CustomMessage) {
    return this.resource.sendCustomSMS(params);
   }

   public sendCustomPush(params: CustomMessage) {
    return this.resource.sendCustomPush(params);
   }
 }
