import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
    name: 'dataTableFilter',
    pure: false
})
export class DataTableFilterPipe implements PipeTransform {
    transform(items: any[], filter: DataTableFilter): any {
        let filteredItems = items;
        if (!items || !filter) {
            return filteredItems;
        }

        switch (filter.comparisionType) {
            case '=':
                filteredItems = _.filter(items, (item) => item[filter.key] === filter.value);
                break;
            case '>':
                filteredItems = _.filter(items, (item) => item[filter.key] > filter.value);
                break;
            case '<':
                filteredItems = _.filter(items, (item) => item[filter.key] < filter.value);
                break;
            case 'LIKE':
                filteredItems = _.filter(items, (item) => item[filter.key].indexOf(filter.value) !== -1);
                break;
        }
        return filteredItems;
    }
}

export interface DataTableFilter {
    key: string;
    value: any;
    comparisionType: DataTableFilterComparision;
}

export declare type DataTableFilterComparision = '=' | '>' | '<' | 'LIKE';
