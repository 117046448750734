<div class="modal-content">
	<div class="modal-header">
		<h4 class="modal-title"> Edit property </h4>
		<button type="button"
				class="close"
				aria-label="Close"
				mat-icon-button
				(click)="hideModal()">
			<mat-icon>close</mat-icon>
		</button>
	</div>
	<div class="modal-body"
		 *ngIf="notificationSettings">

		<div class="form-group mb-2"
			 *ngFor="let prop of boolProperties">
			<label>
				{{prop.name}}
				<input type="checkbox"
					   [(ngModel)]="notificationSettings[prop.key]"/>
			</label>
		</div>
		<div class="form-group mb-2">
			<label>
				High volume threshold [liters]
				<input [(ngModel)]="notificationSettings.high_volume_threshold_litres"
					   class="form-control">
			</label>
		</div>

		<div class="form-group mb-2">
			<label>
				Long usage threshold [min]
				<input [(ngModel)]="notificationSettings.long_flow_notification_delay_mins"
					   class="form-control">
			</label>
		</div>

	</div>
	<div class="modal-footer">
		<button type="button"
				class="btn btn-secondary"
				(click)="hideModal()">Close
		</button>
		<button type="button"
				class="btn btn-primary"
				(click)="onConfirm()">Save changes
		</button>
	</div>
</div>
