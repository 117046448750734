import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import {BaseChartDirective} from 'ng2-charts';
import {EChartsOption} from 'echarts';
import {lineChartDefaultOptions} from '../../../common/chart-defaults';
import {DeviceShadowLog} from '../../_internal/shadow';

@Component({
  selector: 'app-connectivity-strength',
  templateUrl: './connectivity-strength.component.html',
  styleUrls: ['./connectivity-strength.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class ConnectivityStrengthComponent implements OnChanges {

    @ViewChild(BaseChartDirective) chart: BaseChartDirective;
    @Input() events: DeviceShadowLog[];
    @Input() parameterName: string;

    public chartOptions: EChartsOption;

    constructor() { }

    ngOnChanges(changes: SimpleChanges): void {
        this.updateChart();
    }

    public mapValues(probes: DeviceShadowLog[], prop: string): any[] {
        return probes.map((val) => [val.received_at, _.get(val, prop)]);
    }

    public mapLabels(probes: DeviceShadowLog[]): string[] {
        return probes.map((val) => val.received_at);
    }

    private updateChart(): void {
        this.chartOptions = _.cloneDeep(lineChartDefaultOptions);
        _.merge(this.chartOptions,
            {title: {
                text: 'Connection strength',
                },
                useUTC: true,
                xAxis: {
                    type: 'time',
                    data: this.mapLabels(this.events),
                },
                tooltip: {
                    position: (pt, params) => [pt[0] - 100, pt[1] - 100]

                },
                dataZoom: [
                    {
                        startValue: moment().subtract(30, 'days').valueOf(),
                        type: 'inside',
                    },
                    {
                    }
                ],
                series: [

                    {
                        name: 'Rssi',
                        type: 'line',
                        data: this.mapValues(this.events, `original_message.${this.parameterName}`),
                        areaStyle: {},
                        color: '#A0F0AE',
                        markArea: {
                            itemStyle: {
                              color: 'rgba(255, 173, 177, 0.4)'
                            },
                          },

                    },

                ],
            });

    }
}

