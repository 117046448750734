import {PropertyPageComponent} from './property/property-page.component';
import {PropertyEditPageComponent} from './property-edit/property-edit-page.component';
import {HeroComponentsModule} from '../../components/hero-components.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ModalModule} from 'ngx-bootstrap/modal';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PropertyListPageComponent} from './property-list/property-list-page.component';
import {PropertyPagesRoutingModule} from './property-pages-routing.module';
import {SettingsEditComponent} from './settings-edit/settings-edit.component';
import {NgxPaginationModule} from 'ngx-pagination';
import {AlertModule} from 'ngx-bootstrap/alert';
import {FiltersModule} from '../../../common/components/filters/filters.module';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
// import {MomentTimezonePickerModule} from 'moment-timezone-picker';
import {MomentModule} from 'ngx-moment';
import {MatButtonModule} from '@angular/material/button';
import {DirectivesModule} from '../../../directives/directives.module';
import {MatIconModule} from '@angular/material/icon';
import {BadgeModule, ButtonGroupModule, ButtonModule} from '@coreui/angular';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { UserListComponent } from './multi-invite/user-list/user-list.component';
import { StepperMenuComponent } from './multi-invite/stepper-menu/stepper-menu.component';
import { InvitePropertiesListComponent } from './multi-invite/properties-list/properties-list.component';
import {MatStepperModule} from '@angular/material/stepper';
import {TabsModule} from 'ngx-bootstrap/tabs';

@NgModule({
    declarations: [
        PropertyListPageComponent,
        PropertyEditPageComponent,
        PropertyPageComponent,
        InvitePropertiesListComponent,
        SettingsEditComponent,
        UserListComponent,
        StepperMenuComponent,
    ],
    imports: [
        CommonModule,
        HeroComponentsModule,
        FormsModule,
        ReactiveFormsModule,
        ModalModule.forRoot(),
        AlertModule.forRoot(),
        TabsModule.forRoot(),
        MatFormFieldModule,
        MatInputModule,
        PropertyPagesRoutingModule,
        NgxPaginationModule,
        FiltersModule,
        NgxDatatableModule,
        // MomentTimezonePickerModule,
        MomentModule,
        MatButtonModule,
        DirectivesModule,
        MatIconModule,
        ButtonGroupModule,
        ButtonModule,
        MatStepperModule,
        BadgeModule,
    ],
    exports: [
        PropertyListPageComponent,
        PropertyPageComponent,
        StepperMenuComponent,
        NgxPaginationModule,
    ]
})
export class PropertyPagesModule {
}
