import {EventResponse, EventsResponse} from './../resources/dto/event.response';
import {EventResource} from './../resources/event.resource';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Event} from '../../_internal/event';
import * as moment from 'moment';
import * as _ from 'lodash';

@Injectable()
export class EventService {
    constructor(private readonly resource: EventResource) {
    }

    public getEvents(date: string, event_start: string, event_end: string, not_labelled_at: string): Observable<EventsResponse> {
        return this.resource.getEvents(date, event_start, event_end, not_labelled_at);
    }

    public getEventsByDeviceId(date, event_start, event_end, not_labelled_at, device_id): Observable<EventsResponse> {
        return this.resource.getEventsByDeviceId(date, event_start, event_end, not_labelled_at, device_id);
    }

    public getEvent(deviceId: string, eventStart: string): Observable<Event> {
        return this.resource.getEvent(deviceId, eventStart)
                .pipe(map((res: EventResponse) => res.event));
    }

    public overrideEvent(deviceId: string, eventStart: string, overrides): Observable<Event> {
        return this.resource.overrideEvent(deviceId, eventStart, overrides)
                .pipe(map((res: EventResponse) => res.event));
    }

    public getWaterUsage(event: Event): string {
        return (event.water_consumed / 1000).toFixed(2).toString() + ' L';
    }

    public getDuration(event: Event): string {
        const time = moment().format('YYYY-MM-DD');
        return moment(time).add(event.event_duration, 'ms').format('HH:mm:ss');
    }

    public getPredictionProbability(event: Event): string {
        return this.hasRightPredictionProbability(event) ?
                parseFloat(_.first(event.predicted_appliance_category_probability).toFixed(2)) * 100 + '%' : 'No prediction';
    }

    private hasRightPredictionProbability(event: Event): boolean {
        const tmpElement = _.first(event.predicted_appliance_category_probability);
        return event.predicted_appliance_category_probability !== null && tmpElement.toString() !== '' && !_.isNaN(tmpElement);
    }
}
