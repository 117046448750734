import {Injectable} from '@angular/core';
import {CrudAware, OptionalHttpParams} from '../../../common/api/CrudAware';
import {FirmwareChannel, FirmwareChannelIndex} from '../resources/dto/firmware-channel.response';
import {HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {FirmwareChannelResource} from '../resources/firmware-channel.resource';

@Injectable()
export class FirmwareChannelService implements CrudAware<FirmwareChannel> {

    constructor(private readonly resource: FirmwareChannelResource) {
    }

    getMany(params?: HttpParams | OptionalHttpParams): Observable<FirmwareChannelIndex> {
        const headers = new HttpHeaders().set('Accept', 'application/vnd.wtg.firmware-channel-index.v1+json');
        return this.resource.getMany(params, headers);
    }

    delete(id: string | number, params?: HttpParams | OptionalHttpParams): Observable<void> {
        return this.resource.delete(id, params);
    }

    create(payload: FirmwareChannel, params?: HttpParams | OptionalHttpParams): Observable<FirmwareChannel> {
        const headers = new HttpHeaders().set('Content-Type', 'application/vnd.wtg.firmware-channel-create.v1+json')
                    .set('Accept', 'application/vnd.wtg.firmware-channel.v1+json');
        return this.resource.create(payload, params, headers);
    }

    update(id: string, payload: FirmwareChannel, params?: HttpParams | OptionalHttpParams): Observable<FirmwareChannel> {
        const headers = new HttpHeaders().set('Content-Type', 'application/vnd.wtg.firmware-channel-update.v1+json')
                    .set('Accept', 'application/vnd.wtg.firmware-channel.v1+json');
        return this.resource.update(id, payload, params, headers);
    }
}
