import {Component, OnInit} from '@angular/core';
import {DashboardStatsResource} from '../../../../providers/resources/dashboard-stats.resource';
import {DashboardAmounts} from '../../../../_internal/dashboard-stats';

@Component({
    selector: 'app-dashboard-statistics',
    templateUrl: './dashboard-statistics.component.html',
    styleUrls: ['./dashboard-statistics.component.scss']
})
export class DashboardStatisticsComponent implements OnInit {
    public dashboardAmounts: DashboardAmounts;

    constructor(private dashboardStatsResource: DashboardStatsResource) {
    }

    ngOnInit() {
        this.fetchAmounts();
    }

    public fetchAmounts(): void {
        this.dashboardStatsResource.getAmounts().subscribe(amounts => {
            this.dashboardAmounts = amounts;
        });
    }

}
