import {Component, OnInit} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html',
    styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {

    public message = 'Are you sure you want to confirm this action ?';
    public title = 'Confirm your action';
    public button = 'Confirm';
    public cancelButton = 'Cancel';

    constructor(private modalService: BsModalService,
                private bsModalRef: BsModalRef) {
    }

    ngOnInit() {
        this.bsModalRef.setClass('confirmation-dialog');
    }

    public close() {
        this.bsModalRef.hide();
    }

    public confirm() {
        this.modalService.setDismissReason('action-confirmed');
        this.bsModalRef.hide();
    }
}
