import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {OptionalHttpParams} from '../../../common/api/CrudAware';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {BatteryLogResponse} from '../../_internal/life-cycle-events';

@Injectable()
export class BatteryResource {
    private readonly url: string = `${environment.apiGatewayUrl}/iot-core/admin/battery_logs`;

    constructor(private http: HttpClient) {
    }

    public getEvents(params: HttpParams | OptionalHttpParams): Observable<BatteryLogResponse> {
        return this.http.get<BatteryLogResponse>(this.url, {params});
    }
}
