import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {NavData, navItems} from '../../../_nav';
import * as _ from 'lodash';
import {UserService} from './user.service';

@Injectable({
    providedIn: 'root'
})
export class AppSidebarService {
    items$: Observable<NavData[]>;
    navItems = navItems;

    constructor(private userService: UserService) {
        this.items$ = this.getSidebarItems();
    }

    private getSidebarItems(): Observable<NavData []> {
        return of(this.filterItems(this.navItems));
    }

    private filterItems(items: NavData[]): NavData[] {
        const menuItems = [];

        _.forEach(items, (item: NavData) => {
            if (!item.role || this.userService.hasRole(item.role)) {
                menuItems.push(item);
            }
            if (item.children) {
                item.children = this.filterItems(item.children);
            }
        });
        return menuItems;
    }
}
