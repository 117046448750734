import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ThingListPageComponent} from './thing-list/thing-list-page.component';
import {ThingPageComponent} from './thing-page/thing-page.component';
import { SonicWifiPageComponent } from './sonic-wifi-page/sonic-wifi-page.component';
import { TilePageComponent } from './tile-page/tile-page.component';

const routes: Routes = [
    {
        path: '',
        children: [
            {
                path: '',
                component: ThingListPageComponent,
                data: {
                    title: 'Things list',
                },
            },
            {
                path: ':id',
                component: ThingPageComponent,
                data: {
                    title: 'Thing',
                },
            },
            {
                path: 'wifi/:id',
                component: SonicWifiPageComponent,
                data: {
                    title: 'WiFi',
                },
            },
            {
                path: 'tile/:id',
                component: TilePageComponent,
                data: {
                    title: 'Tile',
                },
            },
        ]
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ThingPagesRoutingModule {
}
