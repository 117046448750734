import * as _ from 'lodash';

export const incidentsTypes = {
    water_presence_detected: {
        icon: 'tile-flood.svg',
        label: 'Flood alert'
    },
    high_temperature: {
        icon: 'tile-high-temp.svg',
        label: 'High Temperature alert'
    },
    low_temperature: {
        icon: 'tile-low-temp.svg',
        label: 'Low Temperature alert'
    },
    high_humidity: {
        icon: 'tile-high-humidity.svg',
        label: 'High Humidity alert'
    },
    long_flow_alert: {
        icon: 'long-usage.svg',
        label: 'Long Flow'
    },
    high_volume_alert: {
        icon: 'high-usage.svg',
        label: 'High Volume'
    },
    device_handle_moved: {
        icon: 'moved-handle.svg',
        label: 'Valve position'
    },
    battery_too_low_to_move_handle: {
        icon: 'handle-battery-01.svg',
        label: 'Handle couldn\'t be moved due to low batteries'
    },
    radio_disconnection: {
        icon: 'radio-disconnect.svg',
        label: 'Radio disconnection'
    },
    cloud_disconnection: {
        icon: 'cloud-disconnect.svg',
        label: 'Cloud disconnection'
    },
    pressure_test_failed: {
        icon: 'pressure-failed.svg',
        label: 'Pressure test failed'
    },
    pressure_test_skipped: {
        icon: 'pressure-skipped.svg',
        label: 'Pressure test skipped'
    },
    health_check_failed: {
        icon: 'hardware-check-01.svg',
        label: 'Sonic sensor status'
    },
    low_battery_level: {
        icon: 'low-battery-01.svg',
        label: 'Low Batteries'
    },
    low_water_temperature: {
        icon: 'low-water-temperature.svg',
        label: 'Low temperature'
    },
    legionella_risk: {
        icon: 'legionella.svg',
        label: 'Legionella risk',
    },
    legionella_high_risk: {
        icon: 'legionella.svg',
        label: 'High legionella risk',
    },
    backup_battery_usage: {
        icon: 'backup-battery-usage.svg',
        label: 'Mains Power Disconnected'
    },
    advanced_valve_misalignment: {
        icon: 'moved-handle.svg',
        label: 'Advanced valve alerts'
    },
};
export const incidentColor = {
    high: 'red',
    low: 'yellow',
};


export const filterFields = [
    {
        name: 'status',
        label: 'Status',
        options: [
            {
                value: 'active',
                label: 'Active'
            },
            {
                value: 'closed',
                label: 'Closed'
            }
        ]
    },
    {
        name: 'severity',
        label: 'Severity',
        options: [
            {
                value: 'low',
                label: 'Low'
            },
            {
                value: 'high',
                label: 'High'
            },
        ]
    },
    {
        name: 'state',
        label: 'State',
        options: [
            {
                value: 'suspected',
                label: 'Suspected'
            },
            {
                value: 'contacting',
                label: 'Contacting'
            },
            {
                value: 'resolved',
                label: 'Resolved'
            },
            {
                value: 'escalated',
                label: 'Escalated'
            },
            {
                value: 'waiting_for_user',
                label: 'Waiting for user'
            },
            {
                value: 'snoozed',
                label: 'Snoozed'
            },
            {
                value: 'dismissed',
                label: 'Dismissed'
            }
        ]
    },
    {
        name: 'type',
        label: 'Type',
        options: _.map(incidentsTypes, (item, key) => ({
            value: key,
            label: item.label
        }))
    }
];
