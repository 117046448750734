import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import { SwapResponse } from '../../_internal/swap-data';

@Injectable({
  providedIn: 'root'
})
export class SwapResource {

  private readonly url: string = `${environment.apiGatewayUrl}/iot-core/admin`;

  constructor(private http: HttpClient) { }

  public thingSwaps(thingID: string): Observable<SwapResponse> {
    const url = `${this.url}/things/${thingID}/swaps`;

    return this.http.get<SwapResponse>(url);
}

public hubSwaps(hubID: string): Observable<SwapResponse> {
    const url = `${this.url}/hubs/${hubID}/swaps`;

    return this.http.get<SwapResponse>(url);
}

}



