<table class="table table-striped {{settings.customClass}}" *ngIf="dataStream | async as dataSet">
    <thead>
    <tr>
        <th *ngFor="let column of displayedColumns">{{column.name}}</th>
        <th *ngIf="settings.hasAction">{{settings.actionName}}</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let data of dataSet | dataTableFilter:filter; let i = index;">
        <td *ngFor="let column of displayedColumns" [innerHTML]="getColumnData(data, column)"></td>
        <td *ngIf="settings.hasAction">
            <ng-container [ngTemplateOutletContext]="{$implicit: data, index: i, dataSet: dataSet}"
                          [ngTemplateOutlet]="actionMenuTemplate">
            </ng-container>
        </td>
    </tr>
    </tbody>
</table>
