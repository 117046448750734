import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Observable } from 'rxjs';
import { ManufacturingFeedback } from '../../../../../app/hero-labs/_internal/manufacturing-response';
import { environment } from '../../../../../../src/environments/environment';
import {ManufacturingService} from '../../../../hero-labs/providers/services/manufacturing.service';
import { mergeMap, switchMap } from 'rxjs/operators';
import * as _ from 'lodash';
import { CalibrationResult } from '../../../../hero-labs/_internal/calibration-result';
import { ProvisionReport } from '../../../../hero-labs/_internal/provision-report';
import { DevicePressureTestResult } from '../../../../hero-labs/providers/resources/dto/pressure-test-response';
import { OptionalHttpParams } from '../../../../common/api/CrudAware';

@Component({
  selector: 'app-manufacturing-blocked-status-change',
  templateUrl: './manufacturing-blocked-status-change.component.html',
  styleUrls: ['./manufacturing-blocked-status-change.component.scss']
})
export class ManufacturingBlockedStatusChangeComponent implements OnInit {


    public formGroup: FormGroup;
    public testTypes = ['Provision', 'Calibration','Pressure'];
    public testStatuses = [true, false];
    public describtion= 'Enter the serial numbers of the sonics. They must be separated by a comma.';


    constructor(private toastrService: ToastrService,
             private bsModalRef: BsModalRef,
             private manufacturingService: ManufacturingService,
             private fb: FormBuilder,) { }

    ngOnInit(): void {
        this.initForm();
    }

    public close(): void {
        this.bsModalRef.hide();
    }

    public confirm(): void {
        let queryParams: OptionalHttpParams;
        const observableList = [];
        const params = this.getFeedbackParams();
        const deviceList = this.formGroup.controls.serialNumbers.value.split(',');

        switch(this.formGroup.value.testType){
            case 'Provision':
                this.prepareProvisionObservables(deviceList, observableList, params, queryParams);
                break;
            case 'Calibration':
                this.prepareCalibrationObservables(deviceList, observableList, params, queryParams);
                break;
            case 'Pressure':
                this.preparePressureObservables(deviceList, observableList, params, queryParams);
                break;
        }

        forkJoin({
            ...observableList
        }).subscribe(
            () => {
                this.toastrService.success('Settings have been saved successfully.', 'Test value change.', environment.toastSettings);
                this.bsModalRef.hide();
            },
            error => {
                const serialNumberFailed = error.url.split('/')[6];
                this.toastrService.error( `An error has ocured while saving data. Failed to update: ${serialNumberFailed}`, 'Test value change.', environment.toastSettings);
                this.bsModalRef.hide();
          }
        );
    }

    private prepareProvisionObservables(deviceList: string[], observableList: object[] ,params: { manufacturing_feedback: ManufacturingFeedback }, queryParams?: OptionalHttpParams) {
        for (const serialNumber of deviceList) {
            const serialNo = serialNumber.replace(/\s/g,'');
            observableList.push(
                this.manufacturingService.getProvisionReportsBySerial(serialNo, queryParams)
                .pipe(mergeMap(data => {
                    if (data.total_entries === 0) {
                        return;
                    }
                    const lastProvisionReport: ProvisionReport = _.head(data.data);
                    return this.manufacturingService.updateProvisionReport(serialNo, lastProvisionReport.id, params);
                }))
            );
        };
    }

    private prepareCalibrationObservables(deviceList: string[], observableList: object[] ,params: { manufacturing_feedback: ManufacturingFeedback }, queryParams?: OptionalHttpParams) {
        for (const serialNumber of deviceList) {
            const serialNo = serialNumber.replace(/\s/g,'');
            observableList.push(
                this.manufacturingService.getCalibrationResultsBySerial(serialNo, queryParams)
                    .pipe(mergeMap(data => {
                        if (data.total_entries === 0) {
                            return;
                        }
                        const lastCalibrationResult: CalibrationResult = _.head(data.data);
                        return this.manufacturingService.updateCalibrationResult(serialNo, lastCalibrationResult.id, params);
                    }))
            );
        }
    }

    private preparePressureObservables(deviceList: string[], observableList: object[] ,params: { manufacturing_feedback: ManufacturingFeedback }, queryParams?: OptionalHttpParams) {
        for (const serialNumber of deviceList) {
            const serialNo = serialNumber.replace(/\s/g,'');
            observableList.push(
                this.manufacturingService.getPressureTestReportsBySerial(serialNo, queryParams)
                    .pipe(mergeMap(data => {
                        if (data.total_entries === 0) {
                            return;
                        }
                        const lastPressureTest: DevicePressureTestResult = _.head(data.data);
                        return this.manufacturingService.updatePressureTest(serialNo, lastPressureTest.id, params);
                    }))
            );
        }
    }

    private initForm(): void {
        this.formGroup = this.fb.group({
            testType: ['', [Validators.required]],
            serialNumbers: ['', [Validators.required]],
        });
    }

    private getFeedbackParams(): { manufacturing_feedback: ManufacturingFeedback } {
        return {manufacturing_feedback: {description: 'Generic unblock from admin'}};
    }

}
