import {Component, ContentChild, Input, TemplateRef} from '@angular/core';
import {TableDisplayedColumn} from './table-displayed-column';
import {Observable} from 'rxjs';
import * as _ from 'lodash';
import {DataTableFilter} from './data-table-filter.pipe';
import {DatePipe, JsonPipe} from '@angular/common';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
    selector: 'app-data-table',
    templateUrl: './data-table.component.html',
    styleUrls: ['./data-table.component.scss']
})
export class DataTableComponentComponent {
    @ContentChild(TemplateRef) actionMenuTemplate: TemplateRef<any>;
    @Input() dataStream: Observable<any[]>;
    @Input() displayedColumns: TableDisplayedColumn[];
    @Input() settings: DataTableSettings = {
        hasAction: false,
        actionName: 'Action',
        customClass: 'custom'
    };
    @Input() filter: DataTableFilter;

    constructor(public jsonPipe: JsonPipe,
                private sanitized: DomSanitizer,
                public datePipe: DatePipe) {
    }

    public getColumnData(rowItem: any, column: TableDisplayedColumn) {
        const value = _.get(rowItem, column.key);
        if (typeof column.autoFormat !== 'undefined') {
            switch (column.autoFormat) {
                case 'code':
                    return `<pre>${value}</pre>`;
                case 'json':
                    return this.jsonPipe.transform(value);
                case 'dateUTC':
                    return this.datePipe.transform(value, 'medium');
                case 'date':
                    return this.datePipe.transform(value, 'medium', 'UTC');
            }
        } else if (column.formatter) {
            return this.sanitized.bypassSecurityTrustHtml(column.formatter(value, rowItem));
        }

        return value;
    }

}

interface DataTableSettings {
    hasAction?: boolean;
    actionName?: string;
    customClass?: string;
}
