<div class="modal-content">
  <div class="modal-header">
	  <h4 class="modal-title"> Appliance </h4>
	  <button type="button"
			  class="close"
			  aria-label="Close"
			  mat-icon-button
			  (click)="close()">
		  <mat-icon>close</mat-icon>
	  </button>
  </div>
  <div class="modal-body" *ngIf="appliance">
    <div class="row">
      <div class="col-lg-6">

        <div class="form-group">
          <label>Name(optional)</label>
          <input type="text" class="form-control" [(ngModel)]="appliance.name">
        </div>

        <div class="form-group">
			<label>Brand(optional)</label>
          <input type="text" class="form-control" [(ngModel)]="appliance.brand">
        </div>

        <div class="form-group">
			<label>Space:</label>
          <strong>{{appliance.space_name || appliance.space_id}}</strong>
        </div>

      </div>
      <div class="col-lg-6">

		  <div class="form-group">
			  <label>Model(optional)</label>
			  <input type="text"
					 class="form-control"
					 [(ngModel)]="appliance.model"/>
		  </div>

        <div class="form-group">
			<label>Category:</label>
          <select class="form-control" [(ngModel)]="appliance.appliance_category_id">
            <option *ngFor="let cat of applianceCategories" [value]="cat.id">
              {{cat.name}}
            </option>
          </select>
        </div>

      </div>

    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="close()">Close</button>
    <button type="button" class="btn btn-primary" (click)="onConfirm()">Save changes</button>
  </div>
</div>
