import {Injectable} from '@angular/core';
import {ApplianceResource} from '../resources/appliance.resource';
import {Observable} from 'rxjs';
import {Appliance} from '../../_internal/appliance';
import {map} from 'rxjs/operators';

@Injectable()
export class ApplianceService {

    constructor(private applianceResource: ApplianceResource) {
    }

    public getList(params = {}): Observable<Appliance[]> {
        return this.applianceResource.getList(params)
            .pipe(map((res) => res.appliances));
    }

    public updateAppliance(appliance): Observable<Appliance> {
        return this.applianceResource.updateAppliance(appliance)
            .pipe(map((res) => res.appliance));
    }

    public deleteAppliance(appliance): Observable<Appliance> {
        return this.applianceResource.deleteAppliance(appliance)
            .pipe(map((res) => res.appliance));
    }

    public createAppliance(appliance): Observable<Appliance> {
        return this.applianceResource.createAppliance(appliance)
            .pipe(map((res) => res.appliance));
    }
}
