import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {JobService} from '../../../providers/services/job.service';
import {JobExecutionDetails} from '../../../_internal/job';
import * as _ from 'lodash';
import {Router} from '@angular/router';
import { interval, Subscription } from 'rxjs';

@Component({
    selector: 'app-job-execution',
    templateUrl: './job-execution.component.html',
    styleUrls: ['./job-execution.component.scss'],
})
export class JobExecutionComponent implements OnInit ,OnDestroy{
    public jobExecutionDetails: JobExecutionDetails;
    public statusCheck: Subscription;
    constructor(@Inject(MAT_DIALOG_DATA) public data: JobExecutionComponentData,
                private dialogRef: MatDialogRef<JobExecutionComponent>,
                private jobsService: JobService,
                private router: Router) {
    }

    ngOnDestroy(): void {
        if (this.statusCheck){
            this.statusCheck.unsubscribe();
        }
    }

    ngOnInit() {
        this.fetchJobExecution();
        this.refreshDataIfNotComplete();
    }

    public getDetailsMap(): [string, string][] {
        return _.toPairs(this.jobExecutionDetails.statusDetails.detailsMap);
    }

    public getThingId(): string {
        return _.split(this.jobExecutionDetails.thingArn, '/')[1];
    }

    public onJobId(): void {
        this.dialogRef.close();
    }

    public onThingId(thingId: string): void {
        this.dialogRef.close();
        this.router.navigate(['things', thingId]);
    }

    public refreshDataIfNotComplete() {
        this.statusCheck =  interval(10000)
            .subscribe((data) => {
                if (this.jobExecutionDetails.status !== 'SUCCEEDED' && this.jobExecutionDetails.status !== 'FAILED') {
                    this.fetchJobExecution();
                    return;
                }
                this.statusCheck.unsubscribe();
            });
    }
    
    private fetchJobExecution(): void {
        this.jobsService.getJobsExecution(this.data.jobId, {thing_id: this.data.target_id}).subscribe((response) => {
            this.jobExecutionDetails = response;
        });
    }
}

class JobExecutionComponentData {
    jobId: string;
    target: string;
    target_id: string;
}
