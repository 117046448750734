import {IncidentPagesRoutingModule} from './incident-pages-routing.module';
import {HeroComponentsModule} from '../../components/hero-components.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ModalModule} from 'ngx-bootstrap/modal';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IncidentListPageComponent} from './incident-list/incident-list-page.component';
import {NgxPaginationModule} from 'ngx-pagination';
import {IncidentDetailsComponent} from './incident-details/incident-details.component';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {FiltersModule} from '../../../common/components/filters/filters.module';
import {PipesModule} from '../../pipes/pipes.module';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {MomentModule} from 'ngx-moment';
import {BadgeModule} from '@coreui/angular';

@NgModule({
    declarations: [
        IncidentListPageComponent,
        IncidentDetailsComponent
    ],
    imports: [
        IncidentPagesRoutingModule,
        CommonModule,
        HeroComponentsModule,
        FormsModule,
        ModalModule.forRoot(),
        NgxPaginationModule,
        TooltipModule,
        FiltersModule,
        PipesModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSelectModule,
        NgxDatatableModule,
        MomentModule,
        BadgeModule
    ],
    exports: [
        IncidentListPageComponent,
        NgxPaginationModule
    ]
})
export class IncidentPagesModule {
}
