import {Subject} from 'rxjs';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Component, Input, OnInit} from '@angular/core';
import {Device} from '../../../_internal/device';
import {ToastrService} from 'ngx-toastr';
import {environment} from '../../../../../environments/environment';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ManufacturingService} from '../../../providers/services/manufacturing.service';

@Component({
    selector: 'app-device-edit',
    templateUrl: './device-edit-page.component.html',
    styleUrls: ['./device-edit-page.component.scss']
})
export class DeviceEditPageComponent implements OnInit {
    @Input() device: Device;

    public onStateChange: Subject<boolean>;
    public updateAvailability = [true, false];
    public formGroup: FormGroup;

    constructor(private _bsModalRef: BsModalRef,
        private manufacturingService: ManufacturingService,
        private toastrService: ToastrService,
        private fb: FormBuilder) {
    }

    ngOnInit(): void {
        this.initForm();
    }

    public onConfirm(): void {
        this.manufacturingService.updateDeviceAdmin(this.device.serial_no, this.formGroup.value).subscribe(
            response => {
                this.toastrService.success('Device has been updated successfully.', 'Settings.', environment.toastSettings);
                this._bsModalRef.hide();
            },
            error => {
                this.toastrService.error('An error has occurred while saving data. Error code: ' + error.status + 'Error message' + error.message, 'Settings.', environment.toastSettings);
            }
        );
    }

    public onCancel(): void {
        this._bsModalRef.hide();
    }

    private initForm() {
        this.formGroup = this.fb.group({
            hardware_revision: [this.device.hardware_revision],
            is_golden_sample: [this.device.is_golden_sample]
        });
    }
}
