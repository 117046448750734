import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
    selector: 'app-provision-reports',
    templateUrl: './provision-reports.component.html',
    styleUrls: ['./provision-reports.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProvisionReportsComponent {

    constructor() {
    }
}
