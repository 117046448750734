import {PropertyPageComponent} from './property/property-page.component';
import {PropertyListPageComponent} from './property-list/property-list-page.component';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import { StepperMenuComponent } from './multi-invite/stepper-menu/stepper-menu.component';


const routes: Routes = [
    {
        path: '',
        children: [
            {
                path: '',
                component: PropertyListPageComponent,
                data: {
                    title: 'Properties list',
                },
            },
            {
                path: 'invite',
                component: StepperMenuComponent,
                data: {
                    title: 'Mass invite',
                },
            },
            {
                path: ':id',
                component: PropertyPageComponent,
                data: {
                    title: 'Property',
                },
            }
        ]
    },

];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PropertyPagesRoutingModule {
}
