
<mat-stepper [linear]="true" #stepper class="stepper">





    <mat-step >
        <ng-template matStepLabel>Select users</ng-template>
        <div class="text-conteiner ">
            <h6>Select users. </h6>
            <p> Select the users you want to add to the selected properties.</p>
            <p> You can choose multiple users.</p>
            <p> Number of selected users: {{numberOfSelectedUsers()}}</p>

            <div class="mat-buttons flex-buttons">
                    <button
                        mat-raised-button
                        (click)="resetList('users')">
                            Reset users list
                    </button>
                    <button
                        mat-raised-button
                        (click)="selectAll('users')">
                            Select whole page
                    </button>
                    <button
                        [disabled]="!numberOfSelectedUsers()"
                        (click)="changeTable('properties')"
                        mat-raised-button color="primary"
                        matStepperNext>
                            Next
                    </button>
            </div>
        </div>
    </mat-step>




    <mat-step>
        <ng-template matStepLabel>Select properties</ng-template>
        <div class="text-conteiner ">
            <h6>Select properties. </h6>
            <p> Select the properties to which you want to add the previously selected users.</p>
            <p> You can choose multiple properties.</p>
            <p> Number of selected properties: {{numberOfSelectedProperties()}}</p>

            <div class="mat-buttons flex-buttons">
                <button
                    mat-raised-button
                    (click)="resetList('properties')">
                        Reset properties list
                </button>
                <button
                    mat-raised-button
                    (click)="selectAll('properties')">
                        Select whole page
                </button>
                <button
                    (click)="changeTable('users')"
                    mat-raised-button matStepperPrevious>
                        Back
                </button>
                <button
                    [disabled]="!numberOfSelectedProperties()"
                    (click)="tableToggle(false)"
                    mat-raised-button color="primary"
                    matStepperNext>
                        Next
                </button>
            </div>
        </div>
    </mat-step>


    <mat-step >
        <ng-template matStepLabel>Add users</ng-template>
            <div
            class="summary-conteiner"
        >
                <div class="summary-column">
                    <h6>Summary. </h6>
                    <p>The following number of users will be added to the indicated properties without sending an invitation.</p>
                    <p>Selected users: {{numberOfSelectedUsers()}}</p>
                    <p>Selected properties: {{numberOfSelectedProperties()}}</p>

                </div>
                <div class="text-conteiner ">
                    <div class="mat-buttons flex-buttons">
                        <button (click)="tableToggle(true)" mat-raised-button matStepperPrevious>Back</button>
                        <button [disabled]="sendButtonBlocked" (click)="preparePayload()" mat-raised-button color="primary">Add users to properties</button>
                    </div>
                </div>
        </div>
    </mat-step>
</mat-stepper>

<div class="card mb-4" *ngIf="showTable">

                    <app-user-list *ngIf="activeTab==='users'" #userList></app-user-list>

                    <app-multi-invite *ngIf="activeTab==='properties'" #propertiesList></app-multi-invite>


</div>

