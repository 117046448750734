import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {EChartsOption} from 'echarts';
import {lineChartDefaultOptions} from '../../../../../common/chart-defaults';
import * as moment from 'moment';
import * as _ from 'lodash';
import { HourlyStatistics } from '../../../../providers/resources/dto/hourly-statistics';

@Component({
  selector: 'app-water-usage-chart',
  templateUrl: './water-usage-chart.component.html',
  styleUrls: ['./water-usage-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class WaterUsageChartComponent implements OnInit, OnChanges {

    @Input() deviceStatistics: HourlyStatistics[];
    @Input() dateFormat: string;
    public chartOptions: EChartsOption;

    constructor() { }

    ngOnInit(): void {
        this.updateChart();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.updateChart();
    }

    private updateChart(): void {
        this.chartOptions = _.cloneDeep(lineChartDefaultOptions);

        _.merge(this.chartOptions,
            {
                title: {
                  text: 'Water usage [l]'
                },
                useUTC: true,
                tooltip: {
                  trigger: 'axis',
                },
                legend: {
                  data: 'Water usage in litres'
                },
                xAxis: {
                  type: 'category',
                  boundaryGap: true,
                  data: this.deviceStatistics.map(a => moment.unix(a.agr_date).format(this.dateFormat)),
                },
                yAxis: {
                    type: 'value',
                    mame: 'litres',
                    min: 0,
                },
                series: [
                  {
                    name: 'Water usage',
                    type: 'bar',
                    data: this.deviceStatistics.map(a => _.round(a.water_usage/1000, 2)),
                  },
                ],
                itemStyle: {
                    borderRadius : [50, 50, 0, 0], // Specify the border radius
                    borderType: 'solid',
                    borderColor: '#73c0de',
                    shadowColor: '#5470c6',
                    shadowBlur: 8,
                  },
              }
        );
        }
}
