<div class="modal-content">
    <div class="modal-header">
        {{modalTitle}}
    </div>
    <div class="chart-container">
        <div echarts [options]="getChartSettings(data)"></div>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="close()">Close</button>

    </div>
</div>
