<div class="card mb-4">
    <div class="card-header">
        <i class="fa fa-align-justify"></i> Provision Reports
    </div>
    <div class="card-body">
        <app-table [rows]="rows"
                   [filterFields]="filterFields"
                   [columns]="columns"
                   [selectionEnabled]="false"
                   [tableSettings]="tableSettings"
                   (fetchPage)="fetchPage($event)">
        </app-table>
        <ng-template #dateColumn
                     ngx-datatable-cell-template
                     let-row="row"
                     let-value="value">
            {{value | date: 'yyyy-MM-dd HH:mm:ss' : 'UTC'}}
        </ng-template>
        <ng-template #resultsFailedColumn ngx-datatable-cell-template let-row="row" let-value="value">
            <b>{{ this.failedUnits(value).join(', ') }}</b>
        </ng-template>
        <ng-template #resultsPassedColumn
                     ngx-datatable-cell-template
                     let-row="row"
                     let-value="value">
            <c-badge color="{{reportPassed(value) ? 'success' : 'danger'}}">
                <i class="fa fa-{{reportPassed(value) ? 'check' : 'times-circle'}}"></i>
            </c-badge>
        </ng-template>
        <ng-template #actionsColumn
                     ngx-datatable-cell-template
                     let-row="row"
                     let-value="value">
            <div class="btn-toolbar text-center well">
                <button type="button"
                    [disabled] = "!row.manufacturing_feedback"
                    class="btn btn-sm btn-secondary"
                    (click)="manufacturingFeedbackModal(row.manufacturing_feedback.description)">
                Feadback
                </button>
                <ng-container></ng-container>
                
                <button type="button"
                        class="btn btn-sm btn-secondary"
                        (click)="openFullReportModal(row)">
                    Report
                </button>
                <button type="button"
                        class="btn btn-sm btn-secondary"
                        *ngIf="row.test_details"
                        (click)="openTestDetailsModal(row)">
                    Details
                </button>
                <a routerLink="/manufacturing/device/{{row.serial_no}}"
                   *ngIf="showDeviceLink"
                   class="btn btn-sm btn-secondary">
                    Device
                </a>
            </div>
        </ng-template>
    </div>

</div>
