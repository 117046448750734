import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HasRolesDirective} from './has-roles.directive';

@NgModule({
    declarations: [HasRolesDirective],
    imports: [
        CommonModule
    ],
    exports: [HasRolesDirective]
})
export class DirectivesModule {
}
