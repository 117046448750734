import {Injectable} from '@angular/core';
import {EMPTY, Observable} from 'rxjs';
import {expand, reduce} from 'rxjs/operators';

@Injectable()
export class CommonResource {

    constructor() {
    }

    public getListRecursively<ResponseType>(
        getItems: (token?: string) => Observable<ResponseType>,
        getToken: (ResponseType) => string,
        reducer: (acc: ResponseType, val: ResponseType) => ResponseType
    ): Observable<ResponseType> {
        return getItems()
            .pipe(
                expand((prevResponse) => {
                    const token = getToken(prevResponse);
                    return token ? getItems(token) : EMPTY;
                }),
                reduce(reducer)
            );
    }
}
