import {environment} from './../../../../environments/environment';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {MembershipsResponse, MultiInvitePayload} from './dto/memberships.response';

@Injectable({
    providedIn: 'root'
})
export class MembershipResource {
    private readonly url: string = `${environment.apiGatewayUrl}/iot-core/admin`;

    constructor(private http: HttpClient) {
    }

    public getPropertyMembers(propertyId: string): Observable<MembershipsResponse> {
        const url = `${this.url}/properties/${propertyId}/memberships`;
        return this.http.get<MembershipsResponse>(url);
    }

    public addMember(propertyId: string, payload: {email; membership_role}): Observable<MembershipsResponse> {
        const url = `${this.url}/properties/${propertyId}/memberships`;
        return this.http.post<MembershipsResponse>(url, {membership: payload});
    }

    public multiInvite(payload: MultiInvitePayload[]): Observable<void> {
        const url = `${this.url}/memberships`;
        return this.http.post<void>(url, {memberships: payload});
    }

    public expelMember(propertyId: string, membershipId: string): Observable<MembershipsResponse> {
        const url = `${this.url}/properties/${propertyId}/memberships/${membershipId}/expel`;
        return this.http.delete<MembershipsResponse>(url);
    }

    public changeOwner(propertyId: string, membershipId: string): Observable<void> {
        const url = `${this.url}/properties/${propertyId}/memberships/${membershipId}/takeover`;
        return this.http.put<void>(url, []);
    }
}
