import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {PasswordResource} from '../../hero-labs/providers/resources/password.resource';
import {Router} from '@angular/router';
import * as _ from 'lodash';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit{
    public password_reset = false;
    public error = 'Provided input is incorrect';
    public passwordReset: FormGroup;
    public email: string;

    constructor(private passwordResource: PasswordResource,
                private router: Router) {
    }
    ngOnInit(): void {
        this.passwordReset = new FormGroup(
            {
                email: new FormControl(null, [Validators.required, Validators.email]),
            }
        );
    }

    public onSubmit(): void {
        if (this.passwordReset.valid) {
            this.passwordResource.requestPasswordReset(this.passwordReset.value.email).subscribe(
                    (res: any) => {
                        this.password_reset = true;
                    },
                    (err) => {
                        this.error = _.get(err, 'error.message') || err || this.error;
                        console.log(err);
                    }
            );
        }
    }

    public goToLogin(): void {
        this.router.navigate(['login']);
    }


}
