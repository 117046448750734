import {EventService} from './../../providers/services/event.service';
import {Component, Input} from '@angular/core';
import {Event} from '../../_internal/event';

@Component({
    selector: 'app-event-widgets',
    templateUrl: './event-widgets.component.html',
    styleUrls: ['./event-widgets.component.scss']
})
export class EventWidgetsComponent {
    @Input() event: Event;

    constructor(private eventService: EventService) {
    }

    public getDuration(): string {
        return this.eventService.getDuration(this.event);
    }

    public getWaterUsage(): string {
        return this.eventService.getWaterUsage(this.event);
    }

    public getPredictionProbability(): string {
        return this.eventService.getPredictionProbability(this.event);
    }

}
