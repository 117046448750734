<div class="card mb-4">
	<div class="card-header">
		<i class="fa fa-align-justify"></i> Jobs
	</div>
	<div class="card-body">
		<div class="row">
			<div class="col-lg-12"
				 *ngIf="getJobStream() | async;">

				<app-data-table [dataStream]="getJobStream()"
								[displayedColumns]="displayedColumns"
								[settings]="{hasAction: true, customClass: 'text-center'}">
					<ng-template let-job
								 let-index="index"
								 let-dataSet="dataSet">
						<a routerLink="/jobs/{{job.job_id}}"
						   class="btn btn-secondary px-5 ml-auto">Open</a>
					</ng-template>
				</app-data-table>


				<div class="text-center">
					<button *ngIf="jobsParameters.nextToken && jobsParameters.nextToken.length"
							(click)="updateJobStream()"
							class="btn btn-sm btn-primary"> Load more
					</button>
				</div>
			</div>
		</div>
	</div>
</div>
