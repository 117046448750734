<div class="modal-content">
	<div class="modal-header">
		<h4 class="modal-title"> Add member </h4>
		<button type="button"
				class="close"
				aria-label="Close"
				mat-icon-button
				(click)="onCancel()">
			<mat-icon>close</mat-icon>
		</button>
	</div>
	<div class="modal-body">
		<div class="row">
			<div class="col-lg-12">

				<div class="form-group">
					<label>
						Email
						<c-badge color="danger" *ngIf="incorrectInputMsg !== ''">{{incorrectInputMsg}}</c-badge>
					</label>
					<input type="email" [formControl]="emailFormControl" value="''" class="form-control">
				</div>
				<div class="form-group">
					<label>Membership Role</label>
					<select class="form-control" [(ngModel)]="selectedRole">
						<option *ngFor="let role of membershipRoles">
							{{role}}
						</option>
					</select>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-secondary" (click)="onCancel()">Close</button>
		<button type="button" class="btn btn-primary" (click)="onConfirm()">Add</button>
	</div>
</div>
