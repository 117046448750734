export const PressureTestStatuses = {
    pending: 'Check-Up is being prepared...',
    okay: 'Check-Up has been completed successfully',
    generic_error: 'Check-Up failed due unknown reason',
    abandon_valve_timeout: 'Check-Up failed to close the valve',
    abandon_water_run: 'Check-Up test has been abandoned due to flowing water',
    abandon_handle_movement: 'Check-Up has been abandoned due to handle movement',
    skipped_water_running: 'Check-Up has been skipped due to flowing water',
    skipped_device_offline: 'Check-Up has been skipped due to device being offline',
    closed_due_to_inactivity: 'Check-Up was not completed because no response was received from Sonic.',
    state_changed_error: 'Check-Up has been abandoned due to state change request',
    timeout: 'Check-Up has been abandoned due to valve position timeout ',
    pressure_failed: 'Check-Up failed',
    canceled: 'Check-Up has been canceled',
    drag_out: 'Check-Up Drag out issue',
    low_pressure: 'Check-Up has been abandoned due to low pressure.',
    pressure_drop: 'Check-Up has been abandoned due to pressure drop.',
    skipped_valve_not_opened: 'Check-Up was omitted due to the valve not opening',
};
