import {environment} from './../../../../environments/environment';
import {Observable} from 'rxjs';
import {CircleOfTrustResponse} from './dto/circle-of-trust.response';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CircleOfTrust} from '../../_internal/circle-of-trust';
import {EmergencyContactsCreate} from '../../_internal/emergency-contacts-create';

@Injectable({
    providedIn: 'root'
})
export class CircleOfTrustResource {

    private readonly URL = `${environment.apiGatewayUrl}/user-service/admin/emergency_contacts`;

    constructor(private http: HttpClient) {
    }

    public getPropertyCircleOfTrust(propertyId: string): Observable<CircleOfTrustResponse> {
        const url = this.URL;
        return this.http.get<CircleOfTrustResponse>(url, {params: {by_property_id: propertyId}});
    }

    createEmergencyContact(propertyId: string, contactData: EmergencyContactsCreate) {
        const url = `${this.URL}`;
        return this.http.post<CircleOfTrust>(url, {emergency_contact: contactData});
    }

    deleteEmergencyContact(propertyId: string, contactId: string) {
        const url = `${this.URL}/${contactId}`;
        return this.http.delete<CircleOfTrust>(url);
    }

    updateEmergencyContact(propertyId: string, contactData: EmergencyContactsCreate) {
        const url = `${this.URL}/${contactData.id}`;
        return this.http.put<CircleOfTrust>(url, {emergency_contact: contactData});
    }
}
