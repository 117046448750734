import {NgModule} from '@angular/core';
import {LitersPipe} from './consumption/LitersPipe';
import {DecimalPipe} from '@angular/common';


const pipes = [
    LitersPipe,
];

@NgModule({
    imports: [],
    exports: [...pipes],
    declarations: [
        pipes],
    providers: [DecimalPipe],
})
export class PipesModule {
}
