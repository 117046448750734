import {Injectable} from '@angular/core';
import {ThingGroupResource} from '../resources/thing-group.resource';
import {Observable} from 'rxjs';
import {AddOrRemoveDeviceFromThingGroupParams, ThingGroupDevicesResponse, ThingGroupsResponse} from '../resources/dto/thing-group.response';
import {OptionalHttpParams} from '../../../common/api/CrudAware';

@Injectable({
    providedIn: 'root'
})

export class ThingGroupService {
    constructor(private thingGroupResource: ThingGroupResource) {
    }

    public getList(params: OptionalHttpParams = {}): Observable<ThingGroupsResponse> {
        return this.thingGroupResource.getList(params);
    }

    public getThingsInsideGroup(groupName: string, params = {}): Observable<ThingGroupDevicesResponse> {
        return this.thingGroupResource.getThingsInsideGroup(groupName, params);
    }

    public addThingToGroup(params: AddOrRemoveDeviceFromThingGroupParams): Observable<string> {
        return this.thingGroupResource.addThingToGroup(params);
    }

    public removeThingFromGroup(params: AddOrRemoveDeviceFromThingGroupParams): Observable<string> {
        return this.thingGroupResource.removeThingFromGroup(params);
    }
}
