import {BsModalRef} from 'ngx-bootstrap/modal';
import {Component, Input, OnInit} from '@angular/core';
import {PropertyService} from '../../../providers/services/property.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HttpErrorResponse} from '@angular/common/http';
import * as _ from 'lodash';
import {Property} from '../../../_internal/property';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../../../src/environments/environment';

@Component({
    selector: 'app-property-edit-page',
    templateUrl: './property-edit-page.component.html',
    styleUrls: ['./property-edit-page.component.scss']
})
export class PropertyEditPageComponent implements OnInit {
    @Input() property: Property;
    @Input() action: string;
    public propertyForm: FormGroup;
    public apiError: string;
    public apiErrors: [];

    constructor(private fb: FormBuilder,
                private _bsModalRef: BsModalRef,
                private propertyService: PropertyService,
                private toastrService: ToastrService) {
    }

    public ngOnInit(): void {
        this.initFormGroup();
    }

    public initFormGroup(): void {
        this.propertyForm = this.fb.group({
            name: [_.get(this.property, 'name'), Validators.compose([Validators.required])],
            uprn: [_.get(this.property, 'uprn')],
            country: [_.get(this.property, 'country')],
            city: [_.get(this.property, 'city')],
            address_1: [_.get(this.property, 'address_1')],
            address_2: [_.get(this.property, 'address_2')],
            address_3: [_.get(this.property, 'address_3')],
            postcode: [_.get(this.property, 'postcode')],
        });

        this.propertyForm.valueChanges.subscribe((values) => {
            if (!this.property) {
                this.property = {
                    name: values.name,
                    uprn: values.uprn,
                    country: values.country,
                    city: values.city,
                    address_1: values.address_1,
                    address_2: values.address_2,
                    address_3: values.address_3,
                    postcode: values.postcode,
                };
            } else {
                this.property.name = values.name;
                this.property.uprn = values.uprn;
                this.property.country = values.country;
                this.property.city = values.city;
                this.property.address_1 = values.address_1;
                this.property.address_2 = values.address_2;
                this.property.address_3 = values.address_3;
                this.property.postcode = values.postcode;
            }
        });
    }

    public hasErrors(controlName: string): boolean {
        return this.apiErrors && _.get(this.apiErrors, controlName);
    }

    public onConfirm(): void {
        this.resetErrors();
        switch (this.action) {
            case 'create':
                this.propertyService.createProperty(this.property)
                        .subscribe((response) => {
                            this._bsModalRef.hide();
                            this.toastrService.success('Settings have been saved successfully.', 'Settings.', environment.toastSettings);
                        }, error => {
                            this.toastrService.error( 'An error has ocured while saving data. Error code: ' + error.status + 'Error message' + error.message, 'Settings.', environment.toastSettings);
                            this.handleError(error);
});
                break;
            case 'update':
                this.propertyService.updateProperty(this.property.id, {property: this.property})
                        .subscribe(() => {
                            this._bsModalRef.hide();
                            this.toastrService.success('Settings have been saved successfully.', 'Settings edit.', environment.toastSettings);
                        }, error => {
                            this.toastrService.error( 'An error has ocured while saving data. Error code: ' + error.status + 'Error message' + error.message, 'Settings.', environment.toastSettings);
                            this.handleError(error);
});
                break;
        }
    }

    public onCancel(): void {
        this._bsModalRef.hide();
    }


    private handleError(error: HttpErrorResponse): void {
        this.apiError = _.get(error, 'error.error');
        this.apiErrors = _.get(error, 'error.errors');
    }

    private resetErrors(): void {
        this.apiErrors = [];
        this.apiError = '';
    }

}
