<div class="modal-content">
    <div class="modal-header">
        <h4 class="modal-title">Trigger update</h4>
        <button type="button" class="close" aria-label="Close" (click)="close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p [innerHTML]="message"></p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="close()">Cancel</button>
        <button type="button" class="btn btn-warning" (click)="confirm('ota')">Update as OTA</button>
        <button type="button" class="btn btn-danger" (click)="confirm('factory')">Update as Factory</button>
    </div>
</div>
