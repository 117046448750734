import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {CrudResource} from '../../../common/api/CrudResource';
import {FirmwareChannel} from './dto/firmware-channel.response';

@Injectable()
export class FirmwareChannelResource extends CrudResource<FirmwareChannel> {
    constructor(httpClient: HttpClient) {
        super(`${environment.apiGatewayUrl}/firmware/firmware-channels`, httpClient);
    }
}
