<div class="row"
	 *ngIf="user">
	<div class="col-lg-6">

		<div class="card mb-4">
			<div class="card-header">
				<i class="fa fa-align-justify"></i> {{user.first_name}}
				<div class="float-end buttons-row">
                    <app-favourites-button
							location="users"
							[rowID]="user.id"
							[rowName]="user.first_name +' '+ user.last_name"
							[rowAddress]="user.email">
                    </app-favourites-button>
					<span class="btn-toolbar text-center well float-end">
					<button class="btn btn-secondary btn-sm"
						(click)="openSubscriptionModal()">
						Subscription <i class="fa icons icon-pencil"></i>
					</button>
					<button class="btn btn-secondary btn-sm"
							(click)="openEditModal()">
						Edit user<i class="fa icons icon-pencil"></i>
					</button>
					<button class="btn btn-danger btn-sm"
							(click)="deleteUser()">
						Delete <i class="fa icons fa-trash-o"></i>
					</button>

				</span>
			</div>
			</div>
			<div class="card-body">
				<dl class="dl-horizontal">

					<dt>Id:</dt>
					<dd>
						{{user.id}}
					</dd>

					<dt>Email:</dt>
					<dd>
						{{user.email}}
					</dd>

					<dt>Names:</dt>
					<dd>
						{{user.full_name}}
					</dd>

					<dt>Phone:</dt>
					<dd>
						{{user.phone}}
					</dd>
					<dt>Active:</dt>
					<dd>
						<c-badge color="success"
								 *ngIf="user.active">Active
						</c-badge>
						<c-badge color="danger"
								 *ngIf="!user.active">Inactive
						</c-badge>

					</dd>
                    <dt>Subscription:</dt>

                    <ng-container *ngIf="subscriptionData === undefined; else subData">
                        <dd>
                            <c-badge color="danger">
                                Inactive
                            </c-badge>
                        </dd>
                    </ng-container>
                    <ng-template #subData>
                        <dd>
                            <c-badge color="success"
                                     *ngIf="subscriptionData.is_active">Active
                            </c-badge>
                            <c-badge color="danger"
                                     *ngIf="!subscriptionData.is_active">Inactive
                            </c-badge>
                        </dd>
                    </ng-template>

					<dt>Roles:</dt>
					<dd>
						<c-badge *ngFor="let role of user.roles"
								 class="me-1"
								 [color]="role.name === 'admin'?'warning': 'secondary'">{{role.name}}</c-badge>
					</dd>
					<dt>Organisations:</dt>
					<dd>
						<c-badge *ngFor="let orgnisation of user.organizations"
								 class="me-1"
								 color="primary">{{orgnisation.name}}</c-badge>
					</dd>

				</dl>
			</div>
		</div>

		<app-property-list [properties]="properties"
						   *ngIf="properties"
						   [tabTitle]="'Subscribed & owned properties'">
		</app-property-list>

	</div>

	<div class="col-lg-6">
		<app-logs-list [userId]="userId"
					   [tabTitle]="'Activity'"></app-logs-list>



	</div>
</div>
