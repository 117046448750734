import {environment} from './../../../../environments/environment';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {CalibrationResponse} from './dto/calibration.response';
import {ProvisionReportResponse} from './dto/provision-report.response';
import {Device, DeviceData, DevicesResponse, DevicesStatistics} from '../../_internal/device';
import {DevicePressureTestResponse, DevicePressureTestResult} from './dto/pressure-test-response';
import {ProvisionReport} from '../../_internal/provision-report';
import {CalibrationResult} from '../../_internal/calibration-result';

@Injectable({
    providedIn: 'root'
})
export class ManufacturingResource {
    private readonly url: string = `${environment.apiGatewayUrl}/device-provisioner`;
    private readonly urlNew: string = `${environment.apiGatewayUrl}/new-device-provisioner/v1`;

    constructor(private http: HttpClient) {
    }

    public getProvisionReports(params = {}): Observable<ProvisionReportResponse> {
        const url = `${this.urlNew}/provision_reports`;

        return this.http.get<ProvisionReportResponse>(url, {params});
    }

    public getCalibrationResults(params = {}): Observable<CalibrationResponse> {
        const url = `${this.urlNew}/calibration_results`;
        return this.http.get<CalibrationResponse>(url, {params});
    }

    public getCalibrationResultsBySerial(serialNo: string, params = {}): Observable<CalibrationResponse> {
        const url = `${this.urlNew}/devices/${serialNo}/calibration_results`;
        return this.http.get<CalibrationResponse>(url, {params});
    }

    public getProvisionReportsBySerial(serialNo: string, params = {}): Observable<ProvisionReportResponse> {
        const url = `${this.urlNew}/devices/${serialNo}/provision_reports`;
        return this.http.get<ProvisionReportResponse>(url, {params});
    }

    public getDevice(serialNo: string): Observable<Device> {
        const url = `${this.urlNew}/devices/${serialNo}`;
        return this.http.get<Device>(url);
    }

    public getDeviceManufacturingReport(serialNo: string): Observable<Device> {
        const url = `${this.urlNew}/devices/${serialNo}/manufacturing_report`;
        return this.http.get<Device>(url);
    }

    public changeDeviceManufacturingReport(serialNo: string, params = {}): Observable<Device> {
        const url = `${this.urlNew}/devices/${serialNo}/manufacturing_report`;
        return this.http.put<Device>(url, params);
    }

    public getDevices(params = {}): Observable<DevicesResponse> {
        const url = `${this.urlNew}/devices/`;
        return this.http.get<DevicesResponse>(url, {params});
    }

    public getCounts(path = 'provision_reports', date): Observable<{ counts: { string: number } }> {
        const url = `${this.url}/${path}/summaries`;
        return this.http.get<{ counts: { string: number } }>(url, {params: {by_date: date}});
    }

    public getStats(params = {}): Observable<DevicesStatistics> {
        const url = `${this.urlNew}/stats/`;
        return this.http.get<DevicesStatistics>(url, {params});
    }

    public updateDevice(serialNo: string, params = {}): Observable<Device> {
        const url = `${this.urlNew}/devices/${serialNo}`;
        return this.http.patch<Device>(url, params);
    }

    public updateDeviceAdmin(serialNo: string, params = {}): Observable<Device> {
        const url = `${this.urlNew}/admin/devices/${serialNo}`;
        return this.http.put<Device>(url, params);
    }

    public getPressureTestReportsBySerial(serialNo: string, params = {}): Observable<DevicePressureTestResponse> {
        const url = `${this.urlNew}/devices/${serialNo}/pressure_tests`;
        return this.http.get<DevicePressureTestResponse>(url, {params});
    }

    public getPressureTestReports(params = {}): Observable<DevicePressureTestResponse> {
        const url = `${this.urlNew}/pressure_tests`;
        return this.http.get<DevicePressureTestResponse>(url, {params});
    }

    public updateProvisionReport(serialNo: string, id: string, params = {}): Observable<ProvisionReport> {
        const url = `${this.urlNew}/devices/${serialNo}/provision_reports/${id}`;
        return this.http.put<ProvisionReport>(url, params);
    }

    public updateCalibrationResult(serialNo: string, id: string, params = {}): Observable<CalibrationResult> {
        const url = `${this.urlNew}/devices/${serialNo}/calibration_results/${id}`;
        return this.http.put<CalibrationResult>(url, params);
    }

    public updatePressureTest(serialNo: string, id: string, params = {}): Observable<DevicePressureTestResult> {
        const url = `${this.urlNew}/devices/${serialNo}/pressure_tests/${id}`;
        return this.http.put<DevicePressureTestResult>(url, params);
    }

    public postDeviceData( payload: File): Observable<File>{
        const url = `${this.urlNew}/admin/devices/import`;
        return this.http.post<File>(url, payload);
    }

    public getDeviceData(deviceId: string): Observable<DeviceData>{
        const url = `${this.urlNew}/admin/devices/${deviceId}/export`;
        return this.http.get<DeviceData>(url);
    }

    public transferDevice(deviceId: string): Observable<DeviceData>{
        const url = `${this.urlNew}/admin/devices/${deviceId}/transfer`;
        return this.http.post<DeviceData>(url, null);
    }
}
