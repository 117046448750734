<div class="card mb-4">
	<div class="card-header">
		<i class="fa fa-exclamation-triangle"></i> {{tabTitle}}
		<c-badge color="danger"
				 *ngIf="activeIncidentsCount">Active incidents {{ activeIncidentsCount }}        </c-badge>
	</div>
	<div class="card-body">

		<tabset>
			<tab (selectTab)="fetchIncidents('active')">
				<ng-template tabHeading><i class="fa fa-tint"></i> Active
				</ng-template>
				<div>

					<div class="card my-2 pointer {{determineBgClass(incident.level)}}"
						 (click)="goToLogs(incident.id)"
						 *ngFor="let incident of incidents  | paginate: { itemsPerPage: itemsPerPage, currentPage: pageActive, totalItems: totalItems, id:'active-pagination' }">
						<app-incident-list-item [incident]="incident"></app-incident-list-item>
					</div>

				</div>
				<pagination-controls class="pt-3"
									 *ngIf="totalItems > itemsPerPage"
									 id="active-pagination"
									 (pageChange)="fetchIncidents('active',$event)"></pagination-controls>
				<div *ngIf="!incidents.length">
					<p class="mb-0"> There are no active incidents</p>
				</div>
			</tab>
			<tab (selectTab)="fetchIncidents('closed')">

				<mat-form-field *ngIf="mostRecentClosedIncidents">
					<mat-label>Incident type</mat-label>
					<mat-select [(ngModel)]="filteredType"
								(selectionChange)="fetchIncidents('closed',1,filteredType)">
						<mat-option [value]="null">-</mat-option>
						<mat-option *ngFor="let option of mostRecentClosedIncidents"
									[value]="option.type">{{recentTypes[option.type].label}}
							<small *ngIf="option.incident"> - Last: {{timeAgo(option.incident.detected_at)}}</small>
						</mat-option>
					</mat-select>
				</mat-form-field>

				<ng-template tabHeading>
					<span (click)="incidents = []"><i class="fa fa-check-square-o"></i> Closed
						&nbsp; </span></ng-template>


				<ng-container *ngIf="incidents.length>0; else noIncidents">
					<div class="closed-box">
						<div class="incidents-list">
							<div class="card my-2 pointer {{determineBgClass(incident.level)}}"
								 (click)="goToLogs(incident.id)"
								 *ngFor="let incident of incidents | paginate: { itemsPerPage: itemsPerPage, currentPage: pageResolved, totalItems: totalItems, id:'resolved-pagination' }">
								<app-incident-list-item [incident]="incident"></app-incident-list-item>

							</div>

						</div>
						<pagination-controls class="pt-3"
											 *ngIf="totalItems > itemsPerPage"
											 id="resolved-pagination"
											 (pageChange)="fetchIncidents('closed',$event,filteredType)"></pagination-controls>
						<div *ngIf="!incidents.length">
							<p class="mb-0"> There are no closed incidents</p>
						</div>
					</div>
				</ng-container>
				<ng-template #noIncidents>
					<div class="text-center p-5">
						No incidents
					</div>
				</ng-template>


			</tab>
		</tabset>

	</div>
</div>
