<div class="card mb-4">
    <div class="card-header">
        <i class="fa fa-align-justify"></i> Things
        <div class="float-end buttons-row buttons-gap">
            <button
                mat-raised-button
                type="reset"
                (click)="triggerDevicesReportDownload()">
                    Download devices report
        </button>
        </div>
    </div>

    <div class="card-body">
        <app-table [rows]="rows"
                   [filterFields]="filterFields"
                   [columns]="columns"
                   [selectionEnabled]="false"
                   [tableSettings]="tableSettings"
                   (fetchPage)="fetchPage($event)"
                   pageName="things"
                   #mainTable>
        </app-table>
        <ng-template #cloudConnectionColumn
                     ngx-datatable-cell-template
                     let-row="row"
                     let-value="value">


            <div [ngSwitch]="row.cloud_connection">
                <c-badge *ngSwitchCase="true"
                         color="success">Connected
                </c-badge>
                <c-badge *ngSwitchCase="false"
                         color="danger">Disconnected
                </c-badge>
                <c-badge *ngSwitchDefault
                         color="secondary">{{row.cloud_connection}}
                </c-badge>
            </div>
        </ng-template>
        <ng-template #radioConnectionColumn
                     ngx-datatable-cell-template
                     let-row="row"
                     let-value="value">

            <div *ngIf="row.radio_connection" [ngSwitch]="row.radio_connection">
                <c-badge *ngSwitchCase="'connected'"
                         color="success">Connected
                </c-badge>
                <c-badge *ngSwitchCase="'disconnected'"
                         color="danger">Disconnected
                </c-badge>
                <c-badge *ngSwitchDefault
                         color="secondary">{{row.radio_connection}}
                </c-badge>
            </div>

        </ng-template>
        <ng-template #stateColumn
                     ngx-datatable-cell-template
                     let-row="row"
                     let-value="value">
            <div *ngIf="row.state" [ngSwitch]="true">
                <c-badge *ngSwitchCase="isStateSucces(row.state)"
                         color="success">{{returnValveStateBadge(row)}}
                </c-badge>
                <c-badge *ngSwitchCase="isStateDanger(row.state)"
                         color="danger">{{returnValveStateBadge(row)}}
                </c-badge>
                <c-badge
                *ngSwitchCase="isStateSecondary(row.state)"
                color="secondary">{{returnValveStateBadge(row)}}
                </c-badge>
            </div>
        </ng-template>
        <ng-template #batteryColumn
                     ngx-datatable-cell-template
                     let-row="row"
                     let-value="value">
            <ng-container [ngSwitch]="value">
                <c-badge color="success"
                         *ngSwitchCase="'high'"><i class="fa fa-battery-4"></i></c-badge>
                <c-badge color="warning"
                         *ngSwitchCase="'mid'"><i class="fa fa-battery-1"></i></c-badge>
                <c-badge color="danger"
                         *ngSwitchCase="'low'"><i class="fa fa-battery-0"></i></c-badge>
                <c-badge color="info"
                         *ngSwitchCase="'external_power_supply'"><i class="fa fa-plug"></i></c-badge>
            </ng-container>
        </ng-template>
        <ng-template #actionsColumn
                     ngx-datatable-cell-template
                     let-row="row"
                     let-value="value">
               <a
               [routerLink]="getRouterUrl(row)"
               class="btn btn-secondary btn-md px-5 ml-auto">Open</a>
            <app-favourites-button location="things"
                                   [rowID]="row.id"
                                   [rowName]="row.name"
                                   [rowAddress]="row.city"></app-favourites-button>
        </ng-template>
    </div>
</div>
