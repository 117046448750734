import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ThingService } from '../../providers/services/thing.service';
import * as moment from 'moment';
import * as _ from 'lodash';
import { HourlyStatistics } from '../../providers/resources/dto/hourly-statistics';

@Component({
  selector: 'app-hourly-data',
  templateUrl: './hourly-data.component.html',
  styleUrls: ['./hourly-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HourlyDataComponent implements OnInit {

    @Input() thingId: string;
    @Input() title = 'Hourly data';
    public datepickerInitialValue: Date[];
    public startDate: number;
    public endDate: number;
    public currentTab: string;
    public deviceStatistics: HourlyStatistics[];
    public dateFormat: string;
    public chartMode: 'hours'| 'day' | 'week' | 'month' | string;
    constructor(private thingService: ThingService,
                private cdRef: ChangeDetectorRef) { }

    ngOnInit(): void {
        this.datepickerInitialValue = [new Date(), new Date()];
    }

    public setCurrentTab(tabName: string): void {
        this.currentTab = tabName;
    }

    public  dateRangeCreated($event: object): void {
        this.startDate = moment($event[0]).utcOffset(0, true).startOf('day').unix();
        this.endDate = moment($event[1]).utcOffset(0, true).endOf('day').unix();

        this.chartMode = this.returnChartMode(moment($event[1]).diff($event[0], 'd'));
        this.updateHourlyStatisticsData();
    }

    private aggregateData(inputData: HourlyStatistics[], aggregatePeriod: 'hours'| 'day' | 'week' | 'month') {
        if(aggregatePeriod !== 'hours') {
            const result = [];
            _.forEach(inputData, (item) => {
                const newKey = moment.unix(item.agr_date).startOf(aggregatePeriod).valueOf() / 1000;
                const foundItem = _.find(result, (resultItem) => resultItem.agr_date === newKey);
                item.agr_date = newKey;
                if (foundItem) {
                    this.mergeStatItem(foundItem, item);
                } else {
                    result.push(item);
                }
            });
            return result;
        }
        return inputData;
    }

    private mergeStatItem(item1: HourlyStatistics, item2: HourlyStatistics): HourlyStatistics {
        item1.temp_min = _.min([item1.temp_min, item2.temp_min]);
        item1.temp_max = _.max([item1.temp_max, item2.temp_max]);
        item1.temp_average = _.round(_.mean([item1.temp_average, item2.temp_average]), 1);

        item1.pressure_min = _.min([item1.pressure_min, item2.pressure_min]);
        item1.pressure_max = _.max([item1.pressure_max, item2.pressure_max]);
        item1.pressure_average = _.round(_.mean([item1.pressure_average, item2.pressure_average]), 2);
        item1.water_usage = _.sum([item1.water_usage, item2.water_usage]);

        return item1;
    }

    private returnChartMode(daysNumber: number): string{
        switch (true) {
            case (daysNumber < 1):
                this.dateFormat = 'YYYY-MM-DD hh';
                return 'hours';
            case (daysNumber >= 1 && daysNumber <= 30):
                this.dateFormat = 'YYYY-MM-DD';
                return 'day';
            case (daysNumber >= 31):
                this.dateFormat = 'MMM';
                return 'month';
        }
    }
    private async updateHourlyStatisticsData() {
        const payload = {
            from: this.startDate,
            to: this.endDate,
        };
        this.thingService.getHourlyStatistics(this.thingId, payload).subscribe(data => {
            this.deviceStatistics = this.aggregateData(data, this.chartMode as 'hours'| 'day' | 'week' | 'month');
            if (this.currentTab === undefined) {
                this.currentTab = 'pressure';
            }
            this.cdRef.detectChanges();
        });

    }
}
