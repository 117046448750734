import {Component, Input} from '@angular/core';
import {ApplianceCategory} from '../../_internal/appliance_category';
import {Event} from '../../_internal/event';
import * as _ from 'lodash';

@Component({
    selector: 'app-appliance-usage-log-list',
    templateUrl: './appliance-usage-log-list.component.html',
    styleUrls: ['./appliance-usage-log-list.component.scss']
})
export class ApplianceUsageLogListComponent {
    @Input() event: Event;
    @Input() applianceCategories: ApplianceCategory[];

    constructor() {
    }

    public getCategory(id): ApplianceCategory {
        return _.find(this.applianceCategories, {id: parseInt(id, 10)});
    }
}
