import {Injectable} from '@angular/core';
import {CircleOfTrustResource} from '../resources/circle-of-trust.resource';
import {EmergencyContactsCreate} from '../../_internal/emergency-contacts-create';
import {Observable} from 'rxjs';
import {CircleOfTrust} from '../../_internal/circle-of-trust';

@Injectable({
    providedIn: 'root'
})
export class CircleOfTrustService {

    constructor(private circleOfTrustResource: CircleOfTrustResource) {
    }

    public getPropertyCircleOfTrust(propertyId: string) {
        return this.circleOfTrustResource.getPropertyCircleOfTrust(propertyId);
    }

    public createEmergencyContact(propertyId: string, contactData: EmergencyContactsCreate): Observable<CircleOfTrust> {
        return this.circleOfTrustResource.createEmergencyContact(propertyId, contactData);
    }

    public deleteEmergencyContact(propertyId: string, contactId: string): Observable<CircleOfTrust> {
        return this.circleOfTrustResource.deleteEmergencyContact(propertyId, contactId);
    }

    public updateEmergencyContact(propertyId: string, contactData: EmergencyContactsCreate): Observable<CircleOfTrust> {
        return this.circleOfTrustResource.updateEmergencyContact(propertyId, contactData);
    }
}
