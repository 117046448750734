import {Injectable} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {OptionalHttpParams} from '../../../common/api/CrudAware';
import {Observable} from 'rxjs';
import {LifeCycleEventsResponse} from '../../_internal/life-cycle-events';
import {LifeCycleEventResource} from '../resources/life-cycle-event.resource';

@Injectable()
export class LifeCycleEventService {

    constructor(private resource: LifeCycleEventResource) {
    }

    public getEvents(params?: HttpParams | OptionalHttpParams): Observable<LifeCycleEventsResponse> {
        return this.resource.getEvents(params);
    }
}
