<div class="card mb-4">
	<div class="card-header"><i class="fa fa-exclamation-triangle"></i>{{tabTitle}}</div>

	<div class="card-body"
		 *ngIf="userLogsResponse">
		<c-alert *ngFor="let log of userLogsResponse.user_logs | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalItems }"
				 color="{{getAlertColor(log.action)}}">
			<c-row>
				<c-col lg="auto">
					<div class="icons float-left me-2">
						<ng-template #popTemplate>
							<div [innerHtml]="stringify(log.payload)"></div>
						</ng-template>

						<i class="fa fa-info-circle test"
						[tooltip]="popTemplate"
						placement="bottom"
						container="body"></i>
					</div>
				</c-col>
				<c-col>
					<a class="link cursor-pointer"
					   (click)="goTo('users', log.user_id)"><b>{{log.user_full_name}}</b></a>
					{{log.action_name}}
					<small class="text-muted">{{log.inserted_at | date:'MMM d, y, H:mm:ss' : 'UTC'}}</small>
				</c-col>
				<c-col lg="auto">
					<div class="icons float-end">
						<a class="pointer"
						   *ngIf="navigatableResources.includes(log.resource_type)"
						   (click)="goToResource(log)">
							<i class="fa fa-arrow-right"></i>
						</a>
					</div>
				</c-col>
			</c-row>
		</c-alert>
		<pagination-controls (pageChange)="getPage($event)"></pagination-controls>

	</div>
</div>
