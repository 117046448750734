import { Observable } from 'rxjs';
import { environment } from './../../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserLogsResponse } from './dto/user-logs.response';

@Injectable({
    providedIn: 'root'
})
export class UserLogResource {
    private readonly url: string = `${environment.apiGatewayUrl}/iot-core/admin/user_logs`;

    constructor(private http: HttpClient) { }

    public getLogs(params = {}): Observable<UserLogsResponse> {
        return this.http.get<UserLogsResponse>(this.url, { params });
    }
}
