import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {AddOrRemoveDeviceFromThingGroupParams, ThingGroupDevicesResponse, ThingGroupsResponse} from './dto/thing-group.response';
import * as _ from 'lodash';
import {Observable} from 'rxjs';
import {OptionalHttpParams} from '../../../common/api/CrudAware';
import {CommonResource} from './common.resource';

@Injectable({
    providedIn: 'root'
})
export class ThingGroupResource {

    private readonly url: string = `${environment.apiGatewayUrl}/iot-core/admin/groups`;

    constructor(private http: HttpClient,
                private commonResource: CommonResource) {
    }

    public getList(initialParams: OptionalHttpParams): Observable<ThingGroupsResponse> {
        return this.commonResource.getListRecursively<ThingGroupsResponse>(
            nextToken => {
                const params = initialParams;
                if (nextToken) {
                    _.set(params, 'nextToken', window.btoa(nextToken));
                }
                return this.http.get<ThingGroupsResponse>(
                    this.url, {params}
                );
            },
            response => response.next_token,
            (accumulator, response): ThingGroupsResponse => ({
                groups: [...accumulator.groups, ...response.groups],
                next_token: null
            })
        );
    }

    public getThingsInsideGroup(groupName: string, params): Observable<ThingGroupDevicesResponse> {
        return this.http.get<ThingGroupDevicesResponse>(`${this.url}/${groupName}/things`, {params});
    }

    public addThingToGroup(params: AddOrRemoveDeviceFromThingGroupParams): Observable<string> {
        return this.http.put<string>(`${this.url}/${params.group_id}/add_thing_to_group`, {thing_name: params.thing_name});
    }

    public removeThingFromGroup(params: AddOrRemoveDeviceFromThingGroupParams): Observable<string> {
        const opts = {thing_name: params.thing_name};
        return this.http.put<string>(`${this.url}/${params.group_id}/remove_thing_from_group`, opts);
    }

}
