import {CrudAware, OptionalHttpParams} from './CrudAware';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';

export class CrudResource<T> implements CrudAware<T> {

    constructor(protected URL: string,
                protected readonly httpClient: HttpClient) {
    }

    public create<K>(payload: K, params?: HttpParams | OptionalHttpParams, headers?: HttpHeaders): Observable<T> {
        return this.httpClient.post<T>(this.URL, payload, {params, headers});
    }

    public delete(id: string | number, params?: HttpParams | OptionalHttpParams): Observable<void> {
        return this.httpClient.delete<void>(this.URL + '/' + id, {params});
    }

    public get(id: string | number, params?: HttpParams | OptionalHttpParams, headers?: HttpHeaders): Observable<T> {
        return this.httpClient.get<T>(`${this.URL}/${id}`, {params, headers});
    }

    public getMany(params?: HttpParams | OptionalHttpParams, headers?: HttpHeaders): Observable<T[] | any> {
        return this.httpClient.get<T[]>(`${this.URL}`, {params, headers});
    }

    public update<K>(id: string | number, payload: K | any, params?: HttpParams | OptionalHttpParams, headers?: HttpHeaders): Observable<T> {
        return this.httpClient.put<T>(this.URL + '/' + id, payload, {params, headers});
    }
}
