import {
    cibCcAmex,
    cibCcApplePay,
    cibCcMastercard,
    cibCcPaypal,
    cibCcStripe,
    cibCcVisa,
    cibFacebook,
    cibGoogle,
    cibLinkedin,
    cibSkype,
    cibTwitter,
    cifBr,
    cifEs,
    cifFr,
    cifIn,
    cifPl,
    cifUs,
    cilAlignCenter,
    cilAlignLeft,
    cilAlignRight,
    cilApplicationsSettings,
    cilArrowBottom,
    cilArrowRight,
    cilArrowTop,
    cilBasket,
    cilBell,
    cilBold,
    cilBookmark,
    cilCalculator,
    cilCalendar,
    cilChart,
    cilChartPie,
    cilChevronLeft,
    cilChevronRight,
    cilCloudDownload,
    cilCode,
    cilCommentSquare,
    cilCreditCard,
    cilCursor,
    cilDollar,
    cilDrop,
    cilEnvelopeClosed,
    cilEnvelopeOpen,
    cilFile,
    cilHome,
    cilInbox,
    cilIndentDecrease,
    cilIndentIncrease,
    cilItalic,
    cilJustifyCenter,
    cilLayers,
    cilList,
    cilListNumbered,
    cilLocationPin,
    cilLockLocked,
    cilMagnifyingGlass,
    cilMap,
    cilMediaPlay,
    cilMenu,
    cilMoon,
    cilNotes,
    cilOptions,
    cilPaperclip,
    cilPaperPlane,
    cilPen,
    cilPencil,
    cilPeople,
    cilPrint,
    cilPuzzle,
    cilReportSlash,
    cilSave,
    cilSettings,
    cilShare,
    cilShareAll,
    cilShareBoxed,
    cilSpeech,
    cilSpeedometer,
    cilSpreadsheet,
    cilStar,
    cilSun,
    cilTags,
    cilTask,
    cilTrash,
    cilUnderline,
    cilUser,
    cilUserFemale,
    cilUserFollow,
    cilUserUnfollow,
} from '@coreui/icons';

export const iconSubset = {
    cibCcAmex,
    cibCcApplePay,
    cibCcMastercard,
    cibCcPaypal,
    cibCcStripe,
    cibCcVisa,
    cibFacebook,
    cibGoogle,
    cibLinkedin,
    cibSkype,
    cibTwitter,
    cifBr,
    cifEs,
    cifFr,
    cifIn,
    cifPl,
    cifUs,
    cilAlignCenter,
    cilAlignLeft,
    cilAlignRight,
    cilApplicationsSettings,
    cilArrowBottom,
    cilArrowRight,
    cilArrowTop,
    cilBasket,
    cilBell,
    cilBold,
    cilBookmark,
    cilCalculator,
    cilCalendar,
    cilChart,
    cilChartPie,
    cilChevronLeft,
    cilChevronRight,
    cilCloudDownload,
    cilCode,
    cilCommentSquare,
    cilCreditCard,
    cilCursor,
    cilDollar,
    cilDrop,
    cilEnvelopeClosed,
    cilEnvelopeOpen,
    cilFile,
    cilHome,
    cilInbox,
    cilIndentDecrease,
    cilIndentIncrease,
    cilItalic,
    cilJustifyCenter,
    cilLayers,
    cilList,
    cilListNumbered,
    cilLocationPin,
    cilLockLocked,
    cilMagnifyingGlass,
    cilMap,
    cilMediaPlay,
    cilMenu,
    cilMoon,
    cilNotes,
    cilOptions,
    cilPaperclip,
    cilPaperPlane,
    cilPen,
    cilPencil,
    cilPeople,
    cilPrint,
    cilPuzzle,
    cilReportSlash,
    cilSave,
    cilSettings,
    cilShare,
    cilShareAll,
    cilShareBoxed,
    cilSpeech,
    cilSpeedometer,
    cilSpreadsheet,
    cilStar,
    cilSun,
    cilTags,
    cilTask,
    cilTrash,
    cilUnderline,
    cilUser,
    cilUserFemale,
    cilUserFollow,
    cilUserUnfollow,
};
