import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeroDashboardPageComponent} from './dashboard-page/dashboard-page.component';
import {HeroDashboardRoutingModule} from './hero-dashboard-routing.module';
import {PropertiesMapComponent} from './components/properties-map/properties-map.component';
import {MapSidebarComponent} from './components/map-sidebar/map-sidebar.component';
import {DashboardStatisticsComponent} from './components/dashboard-statistics/dashboard-statistics.component';
import {HeroComponentsModule} from '../../components/hero-components.module';
import {MatIconModule} from '@angular/material/icon';
import {CardModule, DropdownModule, GridModule, ProgressModule, WidgetModule} from '@coreui/angular';

@NgModule({
    declarations: [
        HeroDashboardPageComponent,
        PropertiesMapComponent,
        MapSidebarComponent,
        DashboardStatisticsComponent,
    ],
    imports: [
        CommonModule,
        HeroComponentsModule,
        HeroDashboardRoutingModule,
        MatIconModule,
        GridModule,
        WidgetModule,
        DropdownModule,
        CardModule,
        ProgressModule,
    ],
    exports: [
        HeroDashboardPageComponent,
        PropertiesMapComponent,
        MapSidebarComponent,
        DashboardStatisticsComponent,
    ],
    providers: []
})
export class HeroDashboardModule {
}
