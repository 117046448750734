<div class="modal-content modal-xl">
  <div class="modal-header">
    <h4 class="modal-title"> Label event </h4>
    <button type="button" (click)="close()" class="close" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row" cdkDropListGroup>
        <div class="col-lg-6">
          <h5>Available appliances</h5>
          <div class="dragdrop-container">
            <div class="dragdrop-list" [id]="'availableAppliances'" cdkDropList [cdkDropListData]="appliances">
              <div class="dragdrop-box cdk-drag" *ngFor="let appliance of appliances" cdkDrag>
                {{appliance.name ? appliance.name : appliance.appliance_category_name}}({{appliance.space_name}})
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <h5>Event appliances</h5>
          <div class="dragdrop-container">
            <div class="dragdrop-list" cdkDropList [id]="'eventAppliances'" [cdkDropListData]="eventAppliances" (cdkDropListDropped)="onDragEnd($event)">
              <div class="dragdrop-box cdk-drag" *ngFor="let appliance of eventAppliances; let i=index" cdkDrag>
                {{appliance.name ? appliance.name : appliance.appliance_category_name}}({{appliance.space_name}})
                <button type="button" (click)="removeApplianceFromEvent(appliance, i)" class="close" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="saveChanges()">Save changes</button>
    <button type="button" class="btn btn-secondary" (click)="close()">Close</button>
  </div>
</div>
