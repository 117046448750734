import {Component, EventEmitter, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ThingGroupService} from '../../../providers/services/thing-group.service';
import {ThingGroupDevice} from '../../../providers/resources/dto/thing-group.response';
import {Router} from '@angular/router';
import * as _ from 'lodash';
import {MatDialog} from '@angular/material/dialog';
import {ThingGroupAddComponent} from '../thing-group-add/thing-group-add.component';
import {AvailableFirmwareListComponent} from '../../../components/firmware-trigger/list/available-firmware-list.component';
import {BsModalService} from 'ngx-bootstrap/modal';
import {UserService} from '../../../providers/services/user.service';

@Component({
    selector: 'app-thing-group-page',
    templateUrl: './thing-group-page.component.html',
    styleUrls: ['./thing-group-page.component.scss']
})
export class ThingGroupPageComponent implements OnInit, OnDestroy {
    @Input() thingGroupSwitch: EventEmitter<string>;
    public group: string;
    public switchSubscription: Subscription;
    public thingListSubscription: Subscription;
    public groupDeviceList: ThingGroupDevice[];
    public showFirmwares = false;
    public nextToken: string;

    constructor(private thingGroupService: ThingGroupService,
                private router: Router,
                private modalService: BsModalService,
                private dialog: MatDialog,
                public userService: UserService) {
        this.groupDeviceList = [];
    }

    ngOnInit() {
        this.switchSubscription = this.thingGroupSwitch.subscribe((data) => {
            this.group = data;
            this.groupDeviceList = [];
            this.showFirmwares = false;
            this.nextToken = null;
            this.fetchThingList();
        });
    }

    ngOnDestroy() {
        if (this.switchSubscription) {
            this.switchSubscription.unsubscribe();
        }
        if (this.thingListSubscription) {
            this.thingListSubscription.unsubscribe();
        }
    }

    goToDevice(thingGroupDevice: ThingGroupDevice) {
        this.router.navigate([thingGroupDevice.device === 'hub' ? 'hubs' : 'things', thingGroupDevice.id]);
    }

    openUpdateDialog() {
        const firmwareTypes = [];
        if (this.groupDeviceList.length > 0) {
            switch (this.groupDeviceList[0].type) {
                case 'smart_valve':
                    firmwareTypes.push('END_DEVICE');
                    break;
                default:
                    firmwareTypes.push('HUB_ESP');
                    firmwareTypes.push('MODEM_EZR');
                    break;
            }

            this.modalService.show(AvailableFirmwareListComponent, {
                initialState: {listType: 'Firmwares', group: this.group, firmwareTypes},
                class: 'firmware-update-list modal-xl',
            });
            const subscription = this.modalService.onHide.subscribe((event) => {
                if (event === 'success') {
                    this.showFirmwares = false;
                    this.fetchThingList();
                }
                subscription.unsubscribe();
            });
        }
    }

    addDeviceToTheGroup(): void {
        const dialogRef = this.dialog.open(ThingGroupAddComponent, {
            width: '900px',
            height: '600px',
            data: {
                group: this.group,
                groupDeviceList: this.groupDeviceList
            }
        });
        dialogRef.afterClosed().subscribe(() => {
            setTimeout(() => {
                this.fetchThingList();
            }, 500);
        });
    }

    removeDeviceFromTheGroup(device: ThingGroupDevice) {
        if (window.confirm(`Are you sure you want to remove ${device.device}#${device.id} from ${this.group}`)) {
            this.thingGroupService.removeThingFromGroup({
                group_id: this.group,
                thing_name: device.id
            }).subscribe((res) => {
                _.remove(this.groupDeviceList, (elem) => elem.id === device.id);
            });
        }
    }

    public fetchThingList(): void {
        const params = {};
        if (this.nextToken) {
            _.set(params, 'nextToken', this.nextToken);
        }
        this.thingListSubscription = this.thingGroupService.getThingsInsideGroup(this.group, params)
                .subscribe((data) => {
                    this.nextToken = data.next_token ? btoa(data.next_token) : null;
                    this.groupDeviceList.push(...data.things);
                    this.showFirmwares = true;
                });
    }
}
