import {Injectable} from '@angular/core';
import {SpaceResponse} from './dto/space.response';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Space} from '../../_internal/space';

@Injectable({
    providedIn: 'root'
})
export class SpaceResource {

    private readonly url: string = `${environment.apiGatewayUrl}/iot-core/admin/spaces`;

    constructor(private http: HttpClient) {
    }

    public getSpace(spaceId: string): Observable<SpaceResponse> {
        const url = `${this.url}/${spaceId}`;
        return this.http.get<SpaceResponse>(url);
    }

    public createSpace(propertyId: string, spaceName: string): Observable<SpaceResponse> {
        const url = `${this.url}/`;

        return this.http.post<SpaceResponse>(url, {space: {property_id: propertyId, name: spaceName}});
    }

    public deleteSpace(spaceId: string): Observable<SpaceResponse> {
        const url = `${this.url}/${spaceId}`;
        return this.http.delete<SpaceResponse>(url);
    }

    public updateSpace(space: Space): Observable<SpaceResponse> {
        const url = `${this.url}/${space.id}`;

        return this.http.put<SpaceResponse>(url, {space});
    }
}
