import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {UserResource} from '../resources/user.resource';
import {UserService} from './user.service';
import {LoginResponse} from '../resources/dto/login.response';
import {Router} from '@angular/router';
import {RefreshTokenResponse, ValidateTokenResponse} from '../resources/dto/token.response';
import {User} from '../../_internal/user';
import {skipWhile, tap} from 'rxjs/operators';
import * as _ from 'lodash';
import {StorageParser} from '../../../common/storage/StorageParser';

@Injectable()
export class AuthenticationService {
    public redirectUrl: string;
    private readonly TOKEN_KEY = 'auth-token';
    private readonly USER_KEY = 'user';
    private authenticationState = new BehaviorSubject<boolean>(null);

    constructor(private userResource: UserResource,
                private router: Router,
                private userService: UserService) {
    }

    public checkToken() {
        const user = StorageParser.storageGet<User>(this.USER_KEY);
        const token = localStorage.getItem(this.TOKEN_KEY);
        if (user !== null && token) {
            this.userService.user = user;
            this.validateToken().subscribe();
        } else {
            this.authenticationState.next(false);
        }
    }

    public validateToken(): Observable<ValidateTokenResponse> {
        return this.userResource.validateUserToken()
            .pipe(
                tap((response: ValidateTokenResponse) => {
                    this.processLogin(response.token_details, response.user_details);
                }, (error) => {
                    this.authenticationState.next(false);
                })
            );
    }

    public signIn(email: string, password: string): Observable<LoginResponse> {
        return this.userResource.signInUser(email, password).pipe(
            tap((response) => {
                this.processLogin(response.token_details, response.user_details);
            })
        );
    }

    public getToken(): string {
        return localStorage.getItem(this.TOKEN_KEY);
    }

    public processLogin(token: string, user_details: User) {
        if (_.find(user_details.roles, (r) => r.name === 'admin')) {
            this.userService.token = token;
            this.userService.user = user_details;
            this.userService.userId = this.userService.user.id;
            localStorage.setItem(this.USER_KEY, JSON.stringify(this.userService.user));
            localStorage.setItem(this.TOKEN_KEY, this.userService.token);
            this.authenticationState.next(true);
        } else {
            throw Error('You have not sufficient permission');
        }
    }

    public signOut() {
        this.destroySession();
    }

    public destroySession() {
        this.userResource.signOutUser().subscribe(
            (res) => {
                this.clearSessionData();
                this.router.navigate(['login']);
            },
            (err) => {
                console.log(`Couldn't destroy the session ${err}`);
            }
        );
    }

    public isAuthenticated() {
        return this.authenticationState.value;
    }

    public clearSessionData() {
        localStorage.removeItem(this.USER_KEY);
        localStorage.removeItem(this.TOKEN_KEY);
        this.authenticationState.next(false);
    }

    public getAuthStateStream(): Observable<boolean> {
        return this.authenticationState.asObservable()
            .pipe(
                skipWhile((val) => val === null)
            );
    }

    /**
     * @deprecated switched to validate_token
     */
    public refreshToken(): Observable<RefreshTokenResponse> {
        return this.userResource.refreshUserToken()
            .pipe(
                tap((response: RefreshTokenResponse) => {
                    localStorage.setItem(this.TOKEN_KEY, response.token_details);
                    this.processLogin(response.token_details, this.userService.user);
                }, (error) => {
                    this.authenticationState.next(false);
                })
            );
    }
}
