<div class="row">
	<div class="col-lg-4">
		<div class="card mb-4"
			 *ngIf="organisation">
			<div class="card-header">
				<i class="fa fa-align-justify"></i> Organisation
				<span class="float-end">
          <i class="fa icons icon-pencil"
			 [ngStyle]="{'cursor': 'pointer'}"
			 (click)="openEditModal()"></i>
        </span>

			</div>
			<div class="card-body">
				<dl class="dl-horizontal">
					<dt>Id:</dt>
					<dd>{{organisation.id}}</dd>

					<dt>Name:</dt>
					<dd>
						{{organisation.name}}
					</dd>

					<dt>Active:</dt>
					<dd>
						{{organisation.active}}
					</dd>
				</dl>
			</div>
		</div>
	</div>

	<div class="col-lg-8">
		<div class="card mb-4">
			<div class="card-header">
				<i class="fa fa-users"></i> Assigned users
			</div>
			<div class="card-body">
				<div class="card mb-2"
					 *ngFor="let user of associatedUsers">
					<div class="card-body p-1 clearfix">
						<i class="icons icon-user bg-light p-3 font-xl me-3 float-left"></i>

						<div class="h6 text-primary mb-0 mt-2">{{user.full_name}}</div>
						<div class="text-muted font-xs">{{user.email}}</div>
					</div>
					<div class="card-footer px-3 py-2">
						<a class="font-weight-bold font-xs btn-block text-muted pointer"
						   (click)="goToUser(user.id)">View
							More <i
									class="fa fa-angle-right float-end font-lg"></i></a>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
