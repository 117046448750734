import {IncidentResource} from './../../../providers/resources/incident.resource';
import {Incident} from './../../../_internal/incident';
import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import * as moment from 'moment';
import * as momentDurationFormatSetup from 'moment-duration-format';
import * as _ from 'lodash';
import {IncidentsResponse} from '../../../providers/resources/dto/incidents.response';
import {filterFields, incidentColor, incidentsTypes} from '../config';
import {OptionalHttpParams} from '../../../../common/api/CrudAware';
import {TableComponent} from '../../../components/table/table.component';
import {FilterOption, tableSettings} from '../../../_internal/table-settings';

@Component({
    selector: 'app-incident-list-page',
    templateUrl: './incident-list-page.component.html',
    styleUrls: ['./incident-list-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class IncidentListPageComponent implements OnInit, AfterViewInit {
    @ViewChild(TableComponent) table: TableComponent;
    @ViewChild('titleColumn') titleColumn: TemplateRef<any>;
    @ViewChild('statusColumn') statusColumn: TemplateRef<any>;
    @ViewChild('severityColumn') severityColumn: TemplateRef<any>;
    @ViewChild('additionalColumn') additionalColumn: TemplateRef<any>;
    @ViewChild('actionsColumn') actionsColumn: TemplateRef<any>;
    public incidentsTypes = incidentsTypes;
    public incidentColor = incidentColor;
    public filterFields = filterFields;
    public startValues: FilterOption[] = [];

    public tableSettings = _.cloneDeep(tableSettings);
    public rows: Incident[];
    public columns = [];

    private startStatus: string;
    private startState: string;


    constructor(private incidentResource: IncidentResource,
                private cdRef: ChangeDetectorRef,
                private activatedRoute: ActivatedRoute) {
    }

    ngOnInit() {
        momentDurationFormatSetup(moment);

        this.startStatus = this.activatedRoute.snapshot.paramMap.get('status');
        this.startState = this.activatedRoute.snapshot.paramMap.get('state');
        if (this.startState) {
            this.startValues.push({label: 'state', value: this.startState});
        }
        if (this.startStatus) {
            this.startValues.push({label: 'status', value: this.startStatus});
        }
    }

    public ngAfterViewInit(): void {

        this.columns = [
            {prop: 'id', name: 'Id', flexGrow: 1, sortable: false, cellTemplate: this.table.codeColumn},
            {cellTemplate: this.titleColumn, prop: 'title', name: 'Title', flexGrow: 1, sortable: false},
            {cellTemplate: this.statusColumn, prop: 'status', name: 'Status', flexGrow: 0.3, sortable: false},
            {
                cellTemplate: this.severityColumn,
                prop: 'severity',
                name: 'Severity score',
                flexGrow: 0.5,
                sortable: false
            },
            {prop: 'state', name: 'state', flexGrow: 0.3, sortable: false},
            {cellTemplate: this.additionalColumn, name: 'Additional data', flexGrow: 0.8, sortable: false},
            {
                cellTemplate: this.table.dateColumn,
                prop: 'detected_at',
                name: 'Detected at',
                flexGrow: 0.8,
                sortable: false
            },
            {
                cellTemplate: this.actionsColumn,
                name: '',
                flexGrow: 1,
                sortable: false
            },
        ];
    }


    public formatDuration(duration: number): string {
        return (moment.duration(duration, 'millisecond') as momentDurationFormatSetup).format('m');
    }


    public fetchPage(params?: OptionalHttpParams): void {
        _.unset(params, 'order_by');
        _.unset(params, 'order_strategy');
        this.incidentResource.getIncidentsList(params)
                .subscribe((response: IncidentsResponse) => {
                    this.table.setPageParams(response);
                    this.rows = response.content;
                    this.table.isLoading = false;
                    this.cdRef.detectChanges();
                });

    }

}
