import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {UserResource} from '../../hero-labs/providers/resources/user.resource';
import {AuthenticationService} from '../../hero-labs/providers/services/authenthication.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {RegexMatcher} from '../../common/regex/RegexMatcher';
import * as data from '../../../../package.json';

@Component({
    selector: 'app-dashboard',
    templateUrl: 'login.component.html',
    styleUrls: ['./login.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent implements OnInit {
    @BlockUI() blockUI: NgBlockUI;

    public version = '';
    public error = '';
    public loginForm: FormGroup;
    private EMAIL_REGEXP: RegExp = RegexMatcher.EMAIL_REGEXP;

    constructor(private userResource: UserResource,
                private formBuilder: FormBuilder,
                private router: Router,
                private cdRef: ChangeDetectorRef,
                private authService: AuthenticationService) {
    }

    ngOnInit(): void {
        this.preBuildForm();
        const appInfo = data;
        this.version = appInfo.version;
    }

    public onSubmit(): void {
        this.error = null;
        if (this.loginForm.valid) {
            this.signInUser();
        } else {
            this.error = 'Provided inputs are incorrect';
        }
    }

    public goToReset(): void {
        this.router.navigate(['reset-password']);
    }

    private signInUser() {
        this.authService.signIn(this.loginForm.value.email, this.loginForm.value.password)
            .subscribe(
                () => {
                    if (this.authService.redirectUrl) {
                        this.router.navigateByUrl(this.authService.redirectUrl);
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        this.authService.redirectUrl = null!;
                    } else {
                        this.router.navigate(['dashboard']);
                    }
                },
                (err) => {
                    switch (err.status) {
                        case 404:
                            this.error = 'Error 404 - Not found';
                            break;
                        case 500:
                            this.error = 'Error 500 - Internal Server Error';
                            break;
                        case 502:
                            this.error = 'Error 502 - Bad gateway';
                            break;
                        default:
                            this.error = err.message || err || this.error;
                    }
                    this.cdRef.detectChanges();
                }
            );
    }

    private preBuildForm() {
        this.blockUI.start('Loading data');

        if (this.authService.isAuthenticated()) {
            this.router.navigate(['dashboard']);
        } else {
            this.loginForm = this.formBuilder.group({
                email: ['', Validators.compose([Validators.required, Validators.pattern(this.EMAIL_REGEXP)])],
                password: ['', Validators.compose([Validators.minLength(6), Validators.required])]
            });
        }
        this.blockUI.stop();
    }
}
