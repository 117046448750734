import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {OptionalHttpParams} from '../../../common/api/CrudAware';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import { SubscriptionData } from './dto/subscription.response';

@Injectable({
    providedIn: 'root'
})
export class SubscriptionResource {

    private readonly url: string = environment.apiGatewayUrl + '/subscriptions/subscriptions';

    constructor(private http: HttpClient) {
    }

    public getSubscriptionData(id: string): Observable<SubscriptionData> {
        const url = `${this.url}?user_id=${id}`;
        return this.http.get<any>(url);
    }

    public createSubscription(params: HttpParams | OptionalHttpParams): Observable<SubscriptionData> {
        const url = `${this.url}`;

        return this.http.post<any>(url, params);
    }

    public updateSubscription(id: string, params: HttpParams | OptionalHttpParams): Observable<SubscriptionData> {
        const url = `${this.url}/${id}`;

        return this.http.patch<any>(url, params);
    }
}
