import {environment} from './../../../../environments/environment';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {HubResponse} from './dto/hub.response';
import {CrudResource} from '../../../common/api/CrudResource';
import {Observable} from 'rxjs';
import {ThingsResponse} from './dto/thing.response';
import { HubUpdateData } from '../../_internal/hub';
import {FirmwareSettings} from './dto/firmware-device-settings.response';

@Injectable({
    providedIn: 'root'
})
export class HubResource extends CrudResource<HubResponse> {
    private readonly firmwareUrl: string = `${environment.apiGatewayUrl}/firmware/devices`;

    constructor(private http: HttpClient) {
        super(`${environment.apiGatewayUrl}/iot-core/admin/hubs`, http);
    }

    public getAssociatedThings(hubId: string): Observable<ThingsResponse> {
        return this.http.get<ThingsResponse>(this.URL + `/${hubId}/things`);
    }

    public deleteHub(hubId: string): Observable<HubResponse> {
        return this.http.delete<HubResponse>(this.URL + `/${hubId}`);
    }

    public updateHub(hubId: string, payload: HubUpdateData): Observable<HubResponse> {
        return this.http.put<HubResponse>(`${this.URL}/${hubId}`, {hub: payload});
    }

    public getFirmwareSettings(thingId: string, headers: {Accept: string}): Observable<FirmwareSettings> {
        return this.http.get<FirmwareSettings>(`${this.firmwareUrl}/${thingId}`, {headers});
    }

    public updateFirmwareSettings(thingId: string, payload: FirmwareSettings, headers: {'Content-Type': string; Accept: string}): Observable<FirmwareSettings> {
        return this.http.put<FirmwareSettings>(`${this.firmwareUrl}/${thingId}`, payload, {headers});
    }
}
