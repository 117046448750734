import { Favourite } from './../../providers/resources/dto/favourit-data';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import * as _ from 'lodash';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Subscription, timer} from 'rxjs';
import {OptionalHttpParams} from '../../../common/api/CrudAware';
import {FilterField, FilterOption} from '../../_internal/table-settings';
import { Router } from '@angular/router';

@Component({
    selector: 'app-search-form',
    templateUrl: './search-form.component.html',
    styleUrls: ['./search-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchFormComponent implements AfterViewInit, OnDestroy, OnInit {

    @Input() filterFields: FilterField[];
    @Input() startValues: FilterOption[];
    @Input() pageName: string;
    @Output() search: EventEmitter<OptionalHttpParams> = new EventEmitter<OptionalHttpParams>();
    public tableSearchForm: FormGroup;
    public favouritesArray = [];
    public searchPhrase = new FormControl('', Validators.compose([Validators.minLength(1)]));
    private searchInputDebounceTime = 400;
    private searchInputSubscription: Subscription;

    constructor(private fb: FormBuilder,
                private router: Router,
                private cdRef: ChangeDetectorRef) {
    }

    public ngOnInit(): void {
        if (this.filterFields) {
            this.buildForm();
        }
        if (_.size(this.startValues) > 0) {
            this.doSearch();
        }
        this.favouritesArray = this.getFavourites();
    }

    public ngAfterViewInit(): void {
        this.cdRef.detectChanges();
    }

    public ngOnDestroy(): void {
        this.cleanSearchSubscription();
    }


    public resetSearch(): void {
        this.cleanSearchSubscription();

        const formData = {
            search: '',
        };
        for (const filterItem of this.filterFields) {
            formData[filterItem.name] = null;
        }
        this.tableSearchForm.reset(formData);
        this.search.emit(this.parameters());
    }

    public resetDisabled(): boolean {
        let isDisabled = true;
        for (const filterItem of this.filterFields) {
            isDisabled = _.isNull(this.tableSearchForm.get(filterItem.name).value);
            if (!isDisabled) {
                break;
            }
        }
        return isDisabled;
    }

      public getFavourites(): Favourite[] {
        const localStorageItem = JSON.parse(localStorage.getItem(this.pageName));
        return localStorageItem === null ? [] : localStorageItem.data;
      }


    public doSearch(): void {
        this.cleanSearchSubscription();
        this.search.emit(this.parameters());
    }

    public inputKeyUp(event): void {
        this.cleanSearchSubscription();
        if (event.key !== 'Enter') {
            this.searchInputSubscription = timer(this.searchInputDebounceTime)
                    .subscribe(() => {
                        this.doSearch();
                    });
        }
    }

    private cleanSearchSubscription(): void {
        if (this.searchInputSubscription) {
            this.searchInputSubscription.unsubscribe();
        }
    }

    private buildForm(): void {
        const formData = {};
        for (const filterItem of this.filterFields) {
            const startValue = _.find(this.startValues, (item) => item.label === filterItem.name);
            formData[filterItem.name] = [startValue ? startValue.value : null];
        }
        this.tableSearchForm = this.fb.group(formData);
    }

    private parameters(): OptionalHttpParams {
        const params = {};
        for (const filterItem of this.filterFields) {
            const value = this.tableSearchForm.get(filterItem.name).value;
            if (!_.isNull(value) && value !== '') {
                if(filterItem.type === 'date') {
                    _.set(params, filterItem.name, value.format('YYYY-MM-DD'));
                } else if(Array.isArray(value)) {
                    _.set(params, filterItem.name, value);
                } else {
                    _.set(params, filterItem.name, value.toString().toLowerCase());
                }
            }
        }
        return params;
    }
}
