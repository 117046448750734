<div class="card">
    <div class="card-header">
        <i class="fa fa-align-justify"></i> {{title}}
    </div>
    <div class="card-body">
        <div class="row">
            <div class="col">
                <div class="btn-toolbar text-center well group"
                    role="group">
                    <div class="datatpicker-flex-conteiner">
                        <div class="calendar-box">
                            <input type="text"
                            class="form-control"
                            formControlName="dateRange"
                            #daterangepicker="bsDaterangepicker"
                            bsDaterangepicker
                            [bsValue]="datepickerInitialValue"
                            id="DateOfBirth" placeholder="Date Range" (bsValueChange)="dateRangeCreated($event)"
                            [bsConfig]="{ containerClass: 'theme-blue', rangeInputFormat : 'MMMM Do YYYY', dateInputFormat: 'MMMM Do YYYY'  }">
                        </div>
                        <button
                            class="btn btn-secondary btn-sm"
                            (click)="daterangepicker.toggle()"
                            [attr.aria-expanded]="daterangepicker.isOpen"
                            type="button">
                            <i class="icon-calendar icons"></i>
                        </button>
                    </div>
                        <div>
                            <button
                                class="btn btn-secondary btn-sm"
                                (click)="setCurrentTab('pressure')"
                            >
                                Pressure
                            </button>
                            <button
                                class="btn btn-secondary btn-sm"
                                (click)="setCurrentTab('temperature')"
                            >
                                Temperature
                            </button>
                            <button
                                class="btn btn-secondary btn-sm"
                                (click)="setCurrentTab('usage')"
                            >
                                Water usage
                            </button>
                        </div>
                    </div>
                </div>

        </div>
        <div class="charts">
            <div *ngIf="currentTab === 'pressure'">
                <app-pressure-chart [deviceStatistics]="deviceStatistics" [dateFormat]="dateFormat"></app-pressure-chart>
            </div>
            <div *ngIf="currentTab === 'temperature'">
                <app-temperature-chart [deviceStatistics]="deviceStatistics" [dateFormat]="dateFormat"></app-temperature-chart>
            </div>
            <div *ngIf="currentTab === 'usage'">
                <app-water-usage-chart [deviceStatistics]="deviceStatistics" [dateFormat]="dateFormat"></app-water-usage-chart>
            </div>
        </div>
    </div>
</div>
