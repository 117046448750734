<div class="card mb-4">
	<div class="card-header">
		<i class="fa fa-align-justify"></i> Thing groups
	</div>
	<div class="card-body">
		<div class="row">
			<div class="col-lg-3">

				<table class="table table-striped"
					   *ngIf="thingGroupList">
					<thead>
					<tr>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let thingGroup of thingGroupList" class="pointer"
              (click)="focusThingGroup(thingGroup.group_name)">
              <td>{{thingGroup.group_name}}</td>
            </tr>
          </tbody>
        </table>

      </div>

      <div class="col-lg-9">
        <app-thing-group-page [thingGroupSwitch]="switchThingGroupEvent"></app-thing-group-page>
      </div>
    </div>
  </div>
</div>
