import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {IconSetService} from '@coreui/icons-angular';
import {iconSubset} from './icons/icon-subset';

@Component({
    selector: 'app-root',
    template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit {
    public year = new Date().getFullYear();

    constructor(private router: Router,
                private iconSetService: IconSetService) {
        iconSetService.icons = {...iconSubset};
    }

    ngOnInit() {
        this.scrollTop();
    }

    private scrollTop() {
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
    }
}
