import {NgModule} from '@angular/core';
import {TableFilterComponent} from './table/table.filter.component';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';

const components = [
    TableFilterComponent,
];


@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
    ],
    exports: components,
    declarations: components,
})
export class FiltersModule {
}
