<div class="card text-white bg-info">
  <div class="card-header">
    Appliance usage logs
  </div>
  <div class="card-body">
    <div class="appliance-usage-logs">
      <div *ngFor="let log of event.appliance_usage_logs">
        <span class="me-2"
			  tooltip="{{getCategory(log.appliance_category_id).name}}">
          <i class="hl-icon hl-icon-{{getCategory(log.appliance_category_id).icon_url}}"> </i>
        </span>
        {{getCategory(log.appliance_category_id).name}}
        <b>{{log.log_start | date:'HH:mm:ss' : 'UTC'}} - {{log.log_end | date:'HH:mm:ss' : 'UTC'}}</b>
      </div>
    </div>
  </div>
</div>
