<div class="modal-content">
	<div class="modal-header">
		<h4 class="modal-title"> Edit property </h4>
		<button type="button"
				class="close"
				aria-label="Close"
				mat-icon-button
				(click)="onCancel()">
			<mat-icon>close</mat-icon>
		</button>
	</div>
	<div class="modal-body"
		 *ngIf="action === 'create' || property">
		<form [formGroup]="propertyForm">
			<div class="row">
				<div class="col-lg-6">

					<div class="form-group">
						<label for="name">Name</label>
						<input type="text"
							   id="name"
							   [ngClass]="{'is-invalid': hasErrors('name')}"
							   formControlName="name"
							   class="form-control"
						/>
						<div class="errors" *ngIf="apiErrors">
							<p *ngFor="let error of apiErrors['name']" class="alert alert-danger">
								{{ error }}
							</p>
						</div>
					</div>

					<div class="form-group">
						<label for="address_1">Address 1</label>
						<input type="text"
							   id="address_1"
							   class="form-control"
							   [ngClass]="{'is-invalid': hasErrors('address_1')}"
							   formControlName="address_1"
						/>
						<div class="errors" *ngIf="apiErrors">
							<p *ngFor="let error of apiErrors['address_1']" class="alert alert-danger">
								{{ error }}
							</p>
						</div>
					</div>

					<div class="form-group">
						<label for="address_2">Address 2</label>
						<input type="text"
							   class="form-control"
							   id="address_2"
							   [ngClass]="{'is-invalid': hasErrors('address_2')}"
							   formControlName="address_2"
						/>
						<div class="errors" *ngIf="apiErrors">
							<p *ngFor="let error of apiErrors['address_2']" class="alert alert-danger">
								{{ error }}
							</p>
						</div>
					</div>

					<div class="form-group">
						<label for="address_3">Address 3</label>
						<input type="text"
							   [ngClass]="{'is-invalid': hasErrors('address_3')}"
							   id="address_3" class="form-control"
							   formControlName="address_3"/>
						<div class="errors" *ngIf="apiErrors">
							<p *ngFor="let error of apiErrors['address_3']" class="alert alert-danger">
								{{ error }}
							</p>
						</div>
					</div>

				</div>
				<div class="col-lg-6">

					<div class="form-group">
						<label for="country">Country</label>
						<input type="text"
							   id="country"
							   [ngClass]="{'is-invalid': hasErrors('country')}"
							   class="form-control"
							   formControlName="country"
						/>
						<div class="errors" *ngIf="apiErrors">
							<p *ngFor="let error of apiErrors['country']" class="alert alert-danger">
								{{ error }}
							</p>
						</div>
					</div>

					<div class="form-group">
						<label for="city">City</label>
						<input type="text"
							   id="city"
							   class="form-control"
							   [ngClass]="{'is-invalid': hasErrors('city')}"
							   formControlName="city"
						/>
						<div class="errors" *ngIf="apiErrors">
							<p *ngFor="let error of apiErrors['city']" class="alert alert-danger">
								{{ error }}
							</p>
						</div>
					</div>

					<div class="form-group">
						<label for="postcode">Postcode</label>
						<input type="text"
							   id="postcode"
							   class="form-control"
							   [ngClass]="{'is-invalid': hasErrors('postcode')}"
							   formControlName="postcode"
						/>
						<div class="errors" *ngIf="apiErrors">
							<p *ngFor="let error of apiErrors['postcode']" class="alert alert-danger">
								{{ error }}
							</p>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-secondary" (click)="onCancel()">Close</button>
		<button type="button" class="btn btn-primary" (click)="onConfirm()">Save changes</button>
	</div>
</div>
