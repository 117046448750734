import {Favourite} from './../../providers/resources/dto/favourit-data';
import {size, uniq} from 'lodash';
import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
    selector: 'app-favourites-button',
    templateUrl: './favourites-button.component.html',
    styleUrls: ['./favourites-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FavouritesButtonComponent {
    @Input() rowID;
    @Input() rowName;
    @Input() rowAddress;
    @Input() location: string;
    public IDs = [];

    constructor() {
    }

    public getButtonStatus(): boolean {
        this.getIFavouritesIDs(this.getFavourites());
        if (this.IDs.includes(this.rowID)) {
            return false;
        } else {
            return true;
        }
    }

    public getIFavouritesIDs(data: object): void {
      for (let i = 0; i < size(data); i++) {
          this.IDs.push(data[i]['id']);
      }
      this.IDs = uniq(this.IDs);
    }

    public saveFavourites(id: string, name: string, address: string): void {
        const favourite = {id, name, address};
    const favourites = this.getFavourites();
    favourites.push(favourite);
    this.setLocalStorageFavourites(favourites);
  }

  public getFavourites(): Favourite[] {
    const localStorageItem = JSON.parse(localStorage.getItem(this.location));
    return localStorageItem === null ? [] : localStorageItem.data;
  }
  public removeFavourite(id: string): void {
    let favourites = this.getFavourites();
    favourites = favourites.filter((item) => item.id !== id);
    this.setLocalStorageFavourites(favourites);
    this.IDs.splice(this.IDs.indexOf(id), 1);
  }

  private setLocalStorageFavourites(favourites: Favourite[]): void {
    localStorage.setItem(this.location, JSON.stringify({ data: favourites }));
  }
}
