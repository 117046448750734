import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {CrudResource} from '../../../common/api/CrudResource';
import {ApplianceCategory} from '../../_internal/appliance_category';

@Injectable()
export class ApplianceCategoryResource extends CrudResource<ApplianceCategory> {
    constructor(httpClient: HttpClient) {
        super(`${environment.apiGatewayUrl}/iot-core/admin/appliance_categories`, httpClient);
    }
}
