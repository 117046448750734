import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { environment } from '../../../../../../src/environments/environment';
import {ManufacturingService} from '../../../../hero-labs/providers/services/manufacturing.service';

@Component({
  selector: 'app-manufacturing-states-change',
  templateUrl: './manufacturing-states-change.component.html',
  styleUrls: ['./manufacturing-states-change.component.scss']
})
export class ManufacturingStatesChangeComponent implements OnInit{

    public formGroup: FormGroup;
    public testTypes = ['Provision', 'Calibration','Pressure'];
    public testStatuses = [true, false];
    public describtion= 'Enter the serial numbers of the sonics. They must be separated by a comma.';
    private testValues = {
        Provision: 'provisioned',
        Calibration: 'calibrated',
        Pressure: 'pressure_tested',
    };

    constructor(private toastrService: ToastrService,
             private bsModalRef: BsModalRef,
             private manufacturingService: ManufacturingService,
             private fb: FormBuilder,) { }

    ngOnInit(): void {
        this.initForm();
    }

    public close(): void {
        this.bsModalRef.hide();
    }

    public confirm(): void {
        const observableList = [];
        const params = {};
        params[this.testValues[this.formGroup.controls.testType.value]] = this.formGroup.controls.manufacturingTestStatus.value;
        const deviceList = this.formGroup.controls.serialNumbers.value.split(',');

        for (const serialNumber of deviceList) {
            const serialNo = serialNumber.replace(/\s/g,'');
            observableList.push(this.manufacturingService.changeDeviceManufacturingReport(serialNo, params));
        }

        forkJoin({
            ...observableList
        }).subscribe(
            () => {
                this.toastrService.success('Settings have been saved successfully.', 'Test value change.', environment.toastSettings);
            },
            error => {
                const serialNumberFailed = error.url.split('/')[6];
                this.toastrService.error( `An error has ocured while saving data. Failed to update: ${serialNumberFailed}`, 'Test value change.', environment.toastSettings);

          }
        );
        this.bsModalRef.hide();
    }

    private initForm(): void {
        this.formGroup = this.fb.group({
            testType: ['', [Validators.required]],
            manufacturingTestStatus: ['', [Validators.required]],
            serialNumbers: ['', [Validators.required]],
        });
    }

}

