<div class="card mb-4">
	<div class="card-header">
		<i class="fa fa-align-justify"></i> Hubs
        <div class="float-end buttons-row buttons-gap">
            <button
                mat-raised-button
                type="reset"
                (click)="triggerDevicesReportDownload()">
                    Download devices report
        </button>
        </div>
	</div>

	<div class="card-body">
		<app-table [rows]="rows"
				   [filterFields]="filterFields"
				   [columns]="columns"
				   [tableSettings]="tableSettings"
				   [selectionEnabled]="false"
				   (fetchPage)="fetchPage($event)"
				   pageName="hubs"
                   #mainTable>
		</app-table>
		<ng-template #actionsColumn
					 ngx-datatable-cell-template
					 let-hub="row"
					 let-value="value">
			<div class="pull-right d-flex align-items-center">
            <a  [routerLink]="getRouterUrl(hub)"
                class="btn btn-secondary btn-md px-5 ml-auto">Open</a>
			</div>
			<app-favourites-button location="hubs"
								   [rowID]="hub.id"
								   [rowName]="hub.name"
								   [rowAddress]="hub.city"></app-favourites-button>
		</ng-template>
		<ng-template #onlineColumn
					 ngx-datatable-cell-template
					 let-row="row"
					 let-value="value">
			<c-badge *ngIf="value"
					 color="success">Connected
			</c-badge>
			<c-badge *ngIf="!value"
					 color="danger">Disconnected
			</c-badge>
		</ng-template>
	</div>
</div>
