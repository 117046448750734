import {ThingEvent} from './../../_internal/thing.event';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {TableDisplayedColumn} from '../../../common/components/data-table/table-displayed-column';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {ThingService} from '../../providers/services/thing.service';
import {finalize, map} from 'rxjs/operators';
import * as moment from 'moment';
import * as _ from 'lodash';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ManageEventCategoryDialogComponent} from './manage-event-category-dialog/manage-event-category-dialog.component';
import {DataTableFilter} from '../../../common/components/data-table/data-table-filter.pipe';
import {Thing} from '../../_internal/thing';
import {Appliance} from '../../_internal/appliance';
import {ApplianceCategoryService} from '../../providers/services/appliance-category.service';
import {ApplianceCategory} from '../../_internal/appliance_category';
import {PropertyService} from '../../providers/services/property.service';
import {LastEvaluatedKey} from '../../providers/resources/dto/thing-event.response';
import {Router} from '@angular/router';
import {TotalUsage} from '../../_internal/total.usage';
import {Moment} from 'moment-timezone';
import {EventListComponent} from '../../pages/events/event-list/event-list.component';
import {Event} from '../../_internal/event';
import { AggregatedEvent } from '../../providers/resources/dto/aggregated-daily-data';
import {AggregatedDailyDataComponent} from './aggregated-daily-data/aggregated-daily-data.component';

@Component({
    selector: 'app-thing-event-list',
    templateUrl: './thing-event-list.component.html',
    styleUrls: ['./thing-event-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ThingEventListComponent implements OnInit, OnDestroy {
    @Input() thing: Thing;
    @Input() title = 'Events List';
    @Input() applianceStream: Observable<Appliance[]>;
    public datepickerInitialValue: Date;
    public modalRef: BsModalRef;
    public event_start: string;
    public event_end: string;
    readonly pageSize = '999';

    public isLoading = false;
    public totalUsageToday: TotalUsage;
    public appliances: Appliance[];
    public applianceCategories: ApplianceCategory[];
    public eventsFilter: DataTableFilter = {key: 'water_consumed', comparisionType: '>', value: 40};
    public lastEvaluatedKey: LastEvaluatedKey;

    public duration_events: AggregatedEvent[];
    public duration_events_local: AggregatedEvent[];
    public water_consumed_events: AggregatedEvent[];
    public water_consumed_events_local: AggregatedEvent[];
    public showTodaysAggregateData = false;

    public displayedColumns: TableDisplayedColumn[] = [

        {
            key: 'event_start', name: 'Date', formatter: (dateTime) => `UTC: ${moment.utc(dateTime).format('MMM DD')} <br/>Property:  ${moment(dateTime).tz(this.timezone).format('MMM DD')}`
        },
        {
            key: 'event_start', name: 'Started At', formatter: (dateTime) => `${moment.utc(dateTime).format('HH:mm:ss')} <br/> ${moment(dateTime).tz(this.timezone).format('HH:mm:ss')}`
        },
        {
            key: 'event_end', name: 'Stopped At', formatter: (dateTime, rowItem) => {
                const eventStart = moment.utc(rowItem.event_start);
                const eventEnd = moment.utc(rowItem.event_end);
                const eventStartLocal = moment(rowItem.event_start).tz(this.timezone);
                const eventEndLocal = moment(rowItem.event_end).tz(this.timezone);
                let dayNotice = '';
                if (!eventStart.isSame(eventEnd, 'day')) {
                    dayNotice = `<span class="badge bg-warning">${eventEnd.format('MMM DD')}</span>`;
                }
                let dayLocalNotice = '';
                if (!eventStartLocal.isSame(eventEndLocal, 'day')) {
                    dayLocalNotice = `<span class="badge bg-warning">${eventEndLocal.format('MMM DD')}</span>`;
                }
                return `${moment.utc(dateTime).format('HH:mm:ss')} ${dayNotice}<br/> ${moment(dateTime).tz(this.timezone).format('HH:mm:ss')} ${dayLocalNotice}`;
            }
        },

        {
            key: 'event_duration',
            name: 'Duration',
            formatter: (duration) => {
                const time = moment(duration).utc();

                const days = moment.duration(duration).days();


                return `${days > 0 ? days.toString() + ' days<br/>' : ''}${time.format('HH:mm:ss')}`;
            }
        },
        {
            key: 'water_consumed',
            name: 'Water consumed',
            formatter: (consumed) => (consumed / 1000).toFixed(2).toString() + ' L'
        },
        {
            key: 'appliance_ids',
            name: 'Appliances',
            formatter: (applianceIds) => {
                const categories = _.map(applianceIds, (appId) => {
                    const appliance = _.find(this.appliances, {id: appId});
                    return (_.get(appliance, 'name') || _.get(appliance, 'appliance_category_name'))
                        + `(${_.get(appliance, 'space_name')})`;
                });
                return categories.toString();
            }
        },
        {
            key: 'appliance_category_ids',
            name: 'Categories',
            formatter: (applianceCategoryIds) => {
                const categories = _.map(applianceCategoryIds, (appId) => _.get(_.find(this.applianceCategories, {id: appId}), 'name'));
                return categories.toString();
            }
        },
    ];

    private readonly maxEventLengthInMs = EventListComponent.maxEventLengthInMs;
    private eventStream = new BehaviorSubject<ThingEvent[]>([]);
    private eventSubscription: Subscription;
    private timezone = 'Europe/London';

    constructor(private readonly thingService: ThingService,
                private propertyService: PropertyService,
                private router: Router,
                private cdRef: ChangeDetectorRef,
                private applianceCategoryService: ApplianceCategoryService,
                private modalService: BsModalService) {
    }

    public onDatePick(dateString): void {
        const propertyDate = moment(dateString).utcOffset(0, true);
        this.event_start = propertyDate.clone().startOf('day').valueOf().toString();
        this.event_end = propertyDate.clone().endOf('day').valueOf().toString();
        this.fetchTotalUsageToday(propertyDate.clone().startOf('day'), propertyDate.clone().endOf('day'));
        this.aggregatedStatsEvents(propertyDate.clone().startOf('day'), propertyDate.clone().endOf('day'));
        this.updateEventsStream(this.event_start, this.event_end);
    }

    public getEventStream(): Observable<ThingEvent[]> {
        return this.eventStream.asObservable().pipe(
            map((data) => _.orderBy(data,['event_start'],['desc']))
        );
    }

    ngOnInit() {
        this.getTimezone();
        this.fetchApplianceCategories();
        this.fetchAppliances();
    }

    ngOnDestroy() {
        this.eventSubscription.unsubscribe();
    }

    public canViewEventDetails(event: Event): boolean {
        return (moment(event.event_end).valueOf() - moment(event.event_start).valueOf()) < EventListComponent.maxEventLengthInMs;
    }

    public loadMore(): void {
        this.updateEventsStream(this.lastEvaluatedKey.event_start, this.event_end);
    }

    public goToEvent(eventStart, deviceId, event) {
        const url = this.router.createUrlTree(['events', moment(eventStart).valueOf().toString(), deviceId]);
        window.open(url.toString(), '_blank');
    }


    public manageApplianceCategories(event): void {
        this.modalRef = this.modalService.show(ManageEventCategoryDialogComponent);
        this.modalRef.content.event = event;
        this.modalRef.content.actionPerformed = false;
        this.modalRef.content.appliances = this.appliances;
        this.modalRef.content.eventAppliances = _.map(event.appliance_ids, (appId) => _.find(this.appliances, {id: appId}));
        const subscription = this.modalService.onHide.subscribe(($event) => {
            if (this.modalRef.content.actionPerformed) {
                this.updateEventsStream(this.event_start, this.event_end);
                subscription.unsubscribe();
            }
        });
    }

    public fetchTotalUsageToday(start: Moment, end: Moment): void {
        this.thingService.totalUsage(this.thing.id, start, end)
            .subscribe((response: TotalUsage) => {
                    this.totalUsageToday = response;
                    this.cdRef.detectChanges();
                }
            );
    }

    public aggregatedStatsEvents(start: Moment, end: Moment): void {
        const utcDate = moment(start).utc();
        const utcStart = utcDate.clone().startOf('day').valueOf().toString();
        const utcEnd = utcDate.clone().endOf('day').valueOf().toString();

        this.thingService.getDailyUsage(this.thing.id, utcStart, utcEnd)
        .subscribe(response => {
            if (response.aggregates[0]) {
                this.duration_events = response.aggregates[0].duration_events;
                this.duration_events_local = response.aggregates[0].duration_events_local;
                this.water_consumed_events = response.aggregates[0].water_consumed_events;
                this.water_consumed_events_local = response.aggregates[0].water_consumed_events_local;
                return;
            }
            this.duration_events = [];
            this.duration_events_local = [];
            this.water_consumed_events = [];
            this.water_consumed_events_local = [];
            }
        );
    }

    public showAggregatedData(mode: boolean) {
        this.modalService.show(AggregatedDailyDataComponent, {
            initialState: {
                duration_events: this.duration_events,
                duration_events_local: this.duration_events_local,
                water_consumed_events: this.water_consumed_events,
                water_consumed_events_local: this.water_consumed_events_local,
                appliances: this.appliances,
                timezone: this.timezone,
                applianceCategories: this.applianceCategories,
                showUtc: mode,
            },
            class: 'modal-xl'
        });
    }

    public fetchApplianceCategories(): void {
        this.applianceCategoryService.getMany({page_size: this.pageSize})
            .subscribe(res => this.applianceCategories = res);
    }

    public fetchAppliances(): void {
        this.propertyService.getAssociatedAppliances(this.thing.property_id)
            .subscribe(res => this.appliances = res);
    }

    public sumUsage(events: ThingEvent[]): number {
        return _.sumBy(events, (event) => event.water_consumed);
    }

    public sumDuration(events: ThingEvent[]): string {
        const time = moment(_.sumBy(events, (event) => event.event_duration)).utc();
        return time.format('HH:mm:ss');
    }

    private getTimezone(): void {
        this.propertyService.getSettings(this.thing.property_id).subscribe(response => {
            if (response.timezone) {
                this.timezone = response.timezone;
            }
            this.datepickerInitialValue = new Date();
            this.cdRef.detectChanges();
        });
    }

    private updateEventsStream(start, end) {
        this.isLoading = true;
        this.eventSubscription = this.thingService.getThingEvents(this.thing.id, start, end)
            .pipe(finalize(() => this.isLoading = false))
            .subscribe(res => {
                this.eventStream.next(this.event_start !== start ? this.eventStream.value.concat(res.events) : res.events);
                this.lastEvaluatedKey = res.last_evaluated_key;
            });
    }
}
