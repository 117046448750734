import {Observable} from 'rxjs';
import {environment} from './../../../../environments/environment';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApplianceResponse, AppliancesResponse} from './dto/appliance.response';
import {Appliance} from '../../_internal/appliance';

@Injectable({
    providedIn: 'root'
})
export class ApplianceResource {
    private readonly url: string = `${environment.apiGatewayUrl}/iot-core/admin/appliances`;

    constructor(private http: HttpClient) {}

    public getList(params): Observable<AppliancesResponse> {
        return this.http.get<AppliancesResponse>(this.url, {params});
    }

    public updateAppliance(appliance: Appliance): Observable<ApplianceResponse> {
        return this.http.put<ApplianceResponse>(`${this.url}/${appliance.id}`, {appliance});
    }

    public deleteAppliance(appliance: Appliance): Observable<ApplianceResponse> {
        return this.http.delete<ApplianceResponse>(`${this.url}/${appliance.id}`);
    }

    public createAppliance(appliance: Appliance): Observable<ApplianceResponse> {
        return this.http.post<ApplianceResponse>(this.url, {appliance});
    }
}
