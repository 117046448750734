import {Pipe, PipeTransform} from '@angular/core';
import {DecimalPipe} from '@angular/common';

@Pipe({
    name: 'liters',
})
export class LitersPipe implements PipeTransform {

    constructor(private numberPipe: DecimalPipe) {

    }

    transform(value: number, args: { hasMax: boolean; digits?: string } = {hasMax: false, digits: '1.2-2'}) {
        const liters = args.digits ? this.numberPipe.transform(value / 1000, args.digits) : (value / 1000).toFixed(1);
        return args.hasMax && liters.length > 5 ? parseInt(liters, 10).toFixed(1) : liters;
    }
}
