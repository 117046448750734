import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../../../src/environments/environment';
import {ManufacturingService} from '../../../../hero-labs/providers/services/manufacturing.service';

@Component({
   selector: 'app-export-device',
   templateUrl: './export-device.component.html',
   styleUrls: ['./export-device.component.scss']
})
export class ExportDeviceComponent implements OnInit {

    public serialNo: string;
    public fileName= '';
    // eslint-disable-next-line max-len
    public message = 'You can export device data by clicking <strong>Export data</strong> button or export device and transfer certificates from current enviroment by clicking <strong>Export data and transfer certificates</strong> button.';
    public file: File;

    constructor(private toastrService: ToastrService,
                private bsModalRef: BsModalRef,
                private modalService: BsModalService,
                private manufacturingService: ManufacturingService) { }

    ngOnInit(): void {
     }

    public close(): void {
        this.bsModalRef.hide();
     }

     public getDeviceData(buttonType: string): void{
        this.manufacturingService.getDeviceData(this.serialNo).subscribe(
            data => {
                const sJson = JSON.stringify(data);
                const link = document.createElement('a');
                link.download = `device_${this.serialNo}.json`;
                link.href = 'data:text/json;charset=UTF-8,' + encodeURIComponent(sJson);
                link.click();
                link.remove();
                this.successToast('Export.', 'Json with data exported');
             },
            error => this.errorToast('Export.',  error)
        );

        if(buttonType === 'export'){
            this.bsModalRef.hide();
        } else {
            this.manufacturingService.transferDevice(this.serialNo).subscribe(
                    next =>  {
                        this.successToast('Certificates transfer.', 'Certificates transfer to another environment');
                        this.modalService.setDismissReason('certificates-transfer');
                    },
                    error => {
                        if(error.status === 500){
                            this.toastrService.warning( 'Recived error 500. The certificates are probably already in another environment.', 'Certificates transfer', {  timeOut: 8000, progressBar: true});
                        }
                        this.errorToast('Certificates transfer.', error);
                    }
                 );
            this.bsModalRef.hide();
        }
     }

     private successToast(title: string, message: string) {
        this.toastrService.success( message , title, environment.toastSettings);
     }

     private errorToast(title: string, error: HttpErrorResponse) {
        this.toastrService.error(  'An error has ocured. Error code: ' + error.status + 'Error message: ' + error.error , title, environment.toastSettings);

    }
}
