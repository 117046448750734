import {Component, Input} from '@angular/core';
import {Space} from '../../_internal/space';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {AddSpaceComponent} from './add-space/add-space.component';
import {ConfirmationDialogComponent} from '../../../common/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import {Subject} from 'rxjs';
import * as _ from 'lodash';
import {SpaceService} from '../../providers/services/space.service';
import {first} from 'rxjs/operators';

@Component({
    selector: 'app-spaces-list',
    templateUrl: './spaces-list.component.html',
    styleUrls: ['./spaces-list.component.scss']
})
export class SpacesListComponent {

    @Input() spaces: Space[];
    @Input() tabTitle: string;
    @Input() propertyId: string;
    public modalRef: BsModalRef;
    private onClose: Subject<Space>;

    constructor(private modalService: BsModalService,
                private spaceService: SpaceService) {
    }


    public addSpace(): void {
        this.onClose = new Subject<Space>();
        this.modalRef = this.modalService.show(AddSpaceComponent, {
            initialState: {
                propertyId: this.propertyId,
                closeCallback: this.onClose
            }
        });
        this.onClose.subscribe((response) => {
            this.spaces.push(response);
        });
    }

    public removeSpace(item: Space): void {
        this.onClose = new Subject<Space>();
        this.modalService.show(ConfirmationDialogComponent, {
            initialState: {
                message: `Are you sure you want to delete space <strong>${item.name}</strong> ?`,
                title: 'Confirm Circle of trust Delete',
                button: 'Delete'
            }
        });
        this.modalService.onHide
            .pipe(first())
            .subscribe(() => this.onClose);
        this.modalService.onHide
            .pipe(first())
            .subscribe((reason) => {
            if (reason === 'action-confirmed') {
                this.spaceService.deleteSpace(item.id)
                        .subscribe(() => {
                            this.spaces = _.remove(this.spaces, (s) => s.id === item.id);
                        });
            }
        });
    }

    public openEditDialog(space: Space): void {
        this.modalRef = this.modalService.show(AddSpaceComponent, {
            initialState: {
                space,
                propertyId: this.propertyId
            }
        });
    }
}
