export const thingsFilterFields = [
    {
        type: 'text',
        name: 'search',
        label: 'Search'
    },
    {
        type: 'text',
        name: 'version_from',
        label: 'Thing version from, inclusive'
    },
    {
        type: 'text',
        name: 'version_to',
        label: 'Thing version to, exclusive'
    },
];
