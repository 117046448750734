<div class="card mb-4">
	<div class="card-header">
		<i class="fa fa-users"></i> Memberships
		<div class="btn-toolbar text-center well float-end">

				<button class="btn btn-secondary btn-sm"
						(click)="addMember()">Invite
				</button>
				<button class="btn btn-secondary btn-sm"
						(click)="changeOwner()">Change owner
				</button>

		</div>
	</div>
	<div class="card-body">

		<tabset>
			<tab>
				<ng-template tabHeading><i class="fa fa-user"></i> Members</ng-template>
				<ng-container *ngIf="members">
					<div class="card mb-2"
						 *ngFor="let member of members">
						<div
								class="card-body p-1 clearfix pointer">
							<c-badge color="danger"
									 class="float-end"
									 placement="bottom"
									 tooltip="Expel member"
									 (click)="expelMember(member.id)"
									 *ngIf="!member.ownership">

								<i class="icons icon-user-unfollow pointer"></i>
							</c-badge>


							<div routerLink="/users/{{member.user_id}}"
								 mat-ripple>
								<c-row>
									<c-col lg="auto">
										<i class="icons icon-user bg-light p-3 font-xl me-3 float-start"></i>
									</c-col>
									<c-col>
										<div class="h6 text-primary mb-0 mt-2">{{member.first_name}} {{member.last_name}}
											<c-badge color="{{member.ownership ? 'success' : 'warning'}}">

												{{member.role | titlecase}}
											</c-badge>
										</div>
										<div class="text-secondary font-xs">{{member.email}}</div>
									</c-col>
								</c-row>

							</div>
						</div>
					</div>
				</ng-container>
			</tab>
			<tab>
				<ng-template tabHeading><i class="fa fa-user-plus"></i> Pending</ng-template>
				<div class="card mb-2"
					 *ngFor="let request of requests">
					<div class="card-body p-2 clearfix">
						<div class="h6 text-primary mb-0 mt-2">{{request.email}}</div>
						<div class="text-secondary font-xs">Added by {{request.added_by}}
							, {{dateAgo(request.added_at)}}</div>
					</div>
				</div>
			</tab>
		</tabset>

	</div>
</div>
