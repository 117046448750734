export const hubsFilterFields = [
    {
        name: 'type',
        label: 'Type',
        options: [
            {
                label: 'Hub',
                value: 'hub'
            },
            {
                label: 'Sonic',
                value: 'smart_valve'
            }
        ]
    },
    {
        type: 'text',
        name: 'serial_no',
        label: 'Serial No'
    },
    {
        type: 'text',
        name: 'status',
        label: 'Status'
    },
    {
        type: 'text',
        name: 'version_from',
        label: 'Hub version from, inclusive'
    },
    {
        type: 'text',
        name: 'version_to',
        label: 'Hub version to, exclusive'
    },
    {
        type: 'text',
        name: 'modem_version_from',
        label: 'Modem version from, inclusive'
    },
    {
        type: 'text',
        name: 'modem_version_to',
        label: 'Modem version to, exclusive'
    },
];
