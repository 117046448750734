import {Component, Inject, OnDestroy} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {navItems} from '../../_nav';
import {AppSidebarService} from '../../hero-labs/providers/services/app-sidebar.service';
import { UserService } from '../../hero-labs/providers/services/user.service';


@Component({
    selector: 'app-dashboard',
    templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent implements OnDestroy {
    public navItems = [];
    public sidebarMinimized = true;
    public sidebarMinimizer = false;
    public element: HTMLElement;
    public perfectScrollbarConfig = {
        suppressScrollX: true,
    };
    private changes: MutationObserver;

    constructor(public sidebarService: AppSidebarService, private userService: UserService, @Inject(DOCUMENT) _document?: any) {
        this.navItems = navItems.filter(i => {
            if(i.url === '/firmware' ) {
                return (this.userService.hasRole('firmware_master') || this.userService.hasRole('firmware_manager') || this.userService.isRoot());
            }
            return true;
        });

        this.changes = new MutationObserver((mutations) => {
            this.sidebarMinimized = _document.body.classList.contains('sidebar-minimized');
        });
        this.element = _document.body;
        this.changes.observe(this.element as Element, {
            attributes: true,
            attributeFilter: ['class']
        });
    }

    ngOnDestroy(): void {
        this.changes.disconnect();
    }
}
