<div class="card mb-4">
    <div class="card-header">
        <i class="fa fa-align-justify"></i> Provision Reports
    </div>
    <div class="card-body">
        <app-table [rows]="rows"
                   [scrollToTop]="false"
                   [columns]="columns"
                   [selectionEnabled]="false"
                   [tableSettings]="tableSettings"
                   (fetchPage)="fetchPressureTests($event)">
        </app-table>
        <ng-template #state
            ngx-datatable-cell-template
            let-row="row"
            let-value="value">
            <span class="badge bg-{{this.determineStateClassName(value)}}">{{value}}</span>
        </ng-template>
        <ng-template #status
            ngx-datatable-cell-template
            let-row="row"
            let-value="value">
            {{returnStatus(value)}}
        </ng-template>
        <ng-template #average
                     ngx-datatable-cell-template
                     let-row="row"
                     let-value="value">
                    {{this.roundNumber(value, 0)}}
        </ng-template>
        <ng-template #resultsPassedColumn
            ngx-datatable-cell-template
            let-row="row"
            let-value="value">
                <c-badge color="{{value ? 'success' : 'danger'}}">
                <i class="fa fa-{{value ? 'check' : 'times-circle'}}"></i>
            </c-badge>
        </ng-template>
        <ng-template #tendency
            ngx-datatable-cell-template
            let-row="row"
            let-value="value">
            <span *ngIf="tendencyValue(value); else Unknown" class="badge bg-{{this.tendencyData(value)}}" >{{this.roundNumber(value, 1)}} %</span>
            <ng-template #Unknown>
                <span class="badge bg-danger" >Unknown</span>
            </ng-template>
        </ng-template>
        <ng-template #created_at
            ngx-datatable-cell-template
            let-row="row"
            let-value="value">
            {{value | date: 'yyyy-MM-dd HH:mm:ss' : 'UTC'}}
        </ng-template>
        <ng-template #started_at
            ngx-datatable-cell-template
            let-row="row"
            let-value="value">
            {{value | date: 'yyyy-MM-dd HH:mm:ss' : 'UTC'}}
        </ng-template>
        <ng-template #finished_at
            ngx-datatable-cell-template
            let-row="row"
            let-value="value">
            {{value | date: 'yyyy-MM-dd HH:mm:ss' : 'UTC'}}
        </ng-template>
        <ng-template #actionsColumn
                     ngx-datatable-cell-template
                     let-row="row"
                     let-value="value">
            <div class="btn-toolbar text-center well">
                <button *ngIf="row.measurements.length" (click)="openModal(row)"
                    class="btn btn-secondary btn-sm mb-2 float-end toggle-bt ">
                    Show
                </button>
            </div>
        </ng-template>
    </div>
</div>
