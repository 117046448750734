import {Component, Input} from '@angular/core';
import {Property} from '../../_internal/property';
import {Router} from '@angular/router';

@Component({
    selector: 'app-property-list',
    templateUrl: './property-list.component.html',
    styleUrls: ['./property-list.component.scss']
})
export class PropertyListComponent {
    @Input() properties: Property[];
    @Input() tabTitle: string;

    constructor(private router: Router) {
    }

    public goToProperty(proprtyId): void {
        this.router.navigate([`properties/${proprtyId}`]);
    }
}
