<div class="card mb-4">
	<div class="card-header">
		<i class="fa fa-align-justify"></i> {{title}}
	</div>
	<div class="card-body">
		<div class="row">
			<div class="col-lg-4 position-relative">
				<div class="calendar-box">
					<div class="btn-toolbar text-center well">
                        <label>Minimum value for events eligibility (in millilitres)</label><br/>
                            <button type="button" class="btn btn-secondary" (click)="eventsFilter.value = 0">0</button>
                            <button type="button" class="btn btn-secondary" (click)="eventsFilter.value = 40">40
                            </button>
                            <button type="button" class="btn btn-secondary" (click)="eventsFilter.value = 100">100
                            </button>
                        <input type="number" class="form-control" [(ngModel)]="eventsFilter.value">
                    </div>
                    <bs-datepicker-inline [bsValue]="datepickerInitialValue"
                                          (bsValueChange)="onDatePick($event)"
                                          [bsConfig]="{containerClass: 'theme-blue'}"></bs-datepicker-inline>
                    <div *ngIf="getEventStream() | async as events;" class="py-3">
                        Number of events: <strong>{{ events.length}}</strong><br/>
                        Total usage from events: <strong>{{ sumUsage(events) / 1000 | number : '1.2-2' }}
                        L</strong><br/>
                        <div *ngIf="totalUsageToday">
                            Total usage aggregated: {{ totalUsageToday.total_usage / 1000 | number : '1.2-2'}} L<br/>
                            Total usage aggregated
                            local: {{ totalUsageToday.total_usage_local / 1000 | number : '1.2-2'}} L<br/>
                        </div>
                        Total duration from events: <strong>{{ sumDuration(events) }} L</strong><br/>
                    </div>
                    <div class="btn-toolbar text-center well">
                        <button type="button" class="btn btn-secondary" (click)="showAggregatedData(true)">Show biggest UTC events</button>
                        <button type="button" class="btn btn-secondary" (click)="showAggregatedData(false)">Show biggest local events</button>
                    </div>
                </div>
            </div>
            <div class="col-lg-8" *ngIf="getEventStream() | async; else noEvents">

                <app-data-table [dataStream]="getEventStream()"
                                [displayedColumns]="displayedColumns"
                                [filter]="eventsFilter"
                                [settings]="{hasAction: true}">
                    <ng-template let-event>
                        <button type="button"
                                (click)="manageApplianceCategories(event)"
                                class="btn btn-sm btn-primary m-1"
                                tooltip="Add appliance category">

                            <i class="fa fa-pencil"
                               aria-hidden="true"></i>
                        </button>
                        <button type="button"
                                *ngIf="canViewEventDetails(event)"
                                (click)="goToEvent(event.event_start, event.device_id,event)"
                                class="btn btn-sm btn-primary m-1">
                            <i class="fa fa-arrow-right"></i>
                        </button>
                    </ng-template>
                </app-data-table>

                <div class="col-12 d-flex justify-content-center" *ngIf="isLoading">
                    <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>

                <div class="text-center">
                    <button *ngIf="lastEvaluatedKey"
                            (click)="loadMore()"
                            class="btn btn-sm btn-primary"> Load more
                    </button>
                </div>

            </div>
        </div>
    </div>

    <ng-template #noEvents>
        <div class="card-body">
            <div class="col-12 d-flex justify-content-center">
                <h5>No Events yet</h5>
            </div>
        </div>
    </ng-template>
</div>
