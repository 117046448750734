<div class="modal-content">
    <div class="modal-header">
        <h4 class="modal-title">
            Subscription
        </h4>
    </div>
        <div class="modal-body">
            <form [formGroup]="subscriptionForm">
                <div class="row">
                    <div class="col-lg-6">

                        <div class="form-group">
                            <label>Name</label>
                            <input type="text"
                                   class="form-control"
                                   formControlName="name">
                        </div>
                        <div class="form-group">
                            <label>Subscription role</label>
                            <ng-select
                                    [items]="subsctiptionTiers"
                                    bindLabel="key"
                                    formControlName="key">
                                </ng-select>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Is active</label>
                            <ng-select
                                    [items]="status"
                                    bindLabel="is_active"
                                    formControlName="is_active">
                            </ng-select>
                        </div>
                        <div class="form-group">
                            <label>Billing interval</label>
                            <ng-select
                                    [items]="billingInterval"
                                    bindLabel="billing_interval"
                                    formControlName="billing_interval">
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div  class="form-group">
                            <label>Subscription end date</label>
                            <mat-form-field class="w-100">
                                <mat-label>Choose a date</mat-label>
                                <input matInput
                                        [min]="minDate"
                                        [matDatepicker]="toPicker"
                                        (click)="toPicker.open()"
                                        bindLabel="end_date"
                                        formControlName="end_date"
                                        (dateChange)="dataChange()"
                                        >
                                        <mat-datepicker #toPicker></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>

                </div>
            </form>
        </div>
        <div class="modal-footer">
            <button type="button"
                    class="btn btn-secondary"
                    (click)="onCancel()">Close
            </button>
            <button type="button"
                    class="btn btn-primary"
                    (click)="onConfirm()">Save changes
            </button>
        </div>
</div>
