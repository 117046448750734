import {Observable} from 'rxjs';
import {environment} from './../../../../environments/environment';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DashboardAmounts} from '../../_internal/dashboard-stats';

@Injectable({
    providedIn: 'root'
})
export class DashboardStatsResource {
    private readonly url: string = `${environment.apiGatewayUrl}/iot-core/admin`;

    constructor(private http: HttpClient) {
    }


    public getAmounts(): Observable<DashboardAmounts> {
        const url = `${this.url}/amounts`;
        return this.http.get<DashboardAmounts>(url);
    }
}
