<div class="card mb-4">
    <div class="card-header">
        <i class="fa fa-align-justify"></i> Burn-in reports
    </div>
    <div class="card-body">
        <app-table [rows]="rows"
                   [columns]="columns"
                   [selectionEnabled]="false"
                   [tableSettings]="tableSettings"
                   (fetchPage)="fetchPage($event)">
        </app-table>
        <ng-template #dateColumn
                     ngx-datatable-cell-template
                     let-row="row"
                     let-value="value">
            {{value | date: 'yyyy-MM-dd HH:mm:ss' : 'UTC'}}
        </ng-template>
        <ng-template #resultsPassedColumn
                     ngx-datatable-cell-template
                     let-row="row"
                     let-value="value">
            <c-badge color="{{reportPassed(value) ? 'success' : 'danger'}}">
                <i class="fa fa-{{reportPassed(value) ? 'check' : 'times-circle'}}"></i>
            </c-badge>
        </ng-template>
        <ng-template #actionsColumn ngx-datatable-cell-template let-row="row" let-value="value">
            <div class="btn-toolbar text-center well">
                <button type="button"
                    class="btn btn-sm btn-secondary"
                    (click)="openFullReportModal(row)">
                        Report
                </button>
            </div>
        </ng-template>
    </div>
</div>
