<div class="card mb-4">
    <div class="card-header">
        <i class="fa fa-align-justify"></i> Mass invite

    </div>
    <div class="card-body">
        <app-table [rows]="rows"
                   [filterFields]="filterFields"
                   [columns]="columns"
                   [selectionEnabled]="false"
                   [tableSettings]="tableSettings"
                   (fetchPage)="fetchPage($event)"
                   #mainTable>
        </app-table>
		<ng-template #addressColumn
					 ngx-datatable-cell-template
					 let-row="row"
					 let-value="value">
			{{row.address_1}} {{row.address_2}} {{row.address_3}}
		</ng-template>

        <ng-template #actionsColumn
                     ngx-datatable-cell-template
                     let-row="row"
                     let-value="value">

                     <button class="btn btn-sm" (click)="selectProperty(row)" *ngIf="getButtonStatus(row) ; else  Selected">
                        <mat-icon >check_box_outline_blank {{getButtonStatus(row)}}</mat-icon>
                    </button>
                    <ng-template #Selected>
                        <button class="btn btn-sm" (click)="removeDevice(row)">
                            <mat-icon >check_box {{getButtonStatus(row)}}</mat-icon>
                        </button>
                    </ng-template>
        </ng-template>
    </div>
</div>
