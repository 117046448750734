import {Injectable} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {OptionalHttpParams} from '../../../common/api/CrudAware';
import {Observable} from 'rxjs';
import {BatteryLogResponse} from '../../_internal/life-cycle-events';
import {BatteryResource} from '../resources/battery.resource';

@Injectable()
export class BatteryService {

    constructor(private resource: BatteryResource) {
    }

    public getEvents(params?: HttpParams | OptionalHttpParams): Observable<BatteryLogResponse> {
        return this.resource.getEvents(params);
    }
}
