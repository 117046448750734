import {Component, OnInit} from '@angular/core';
import {FooterComponent} from '@coreui/angular';
import * as data from '../../../../package.json';

@Component({
    selector: 'app-default-footer',
    templateUrl: './default-footer.component.html',
    styleUrls: ['./default-footer.component.scss'],
})
export class DefaultFooterComponent extends FooterComponent implements OnInit {
    public year = new Date().getFullYear();
    public version = '';

    constructor() {
        super();
    }

    ngOnInit(): void {
        const appInfo = data;
        this.version = appInfo.version;
    }
}
