<app-table [rows]="rows"
		   [columns]="columns"
		   rowHeight="auto"
		   [selectionEnabled]="false"
		   [tableSettings]="tableSettings"
		   (fetchPage)="fetchPage($event)">
</app-table>
<ng-template #dateColumn
			 ngx-datatable-cell-template
			 let-row="row"
			 let-value="value">
	{{value | date: 'yyyy-MM-dd HH:mm:ss'}}
</ng-template>