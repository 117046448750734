import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ObjectWithUnknownKeys } from '../../../../hero-labs/_internal/object-with-unknown-keys';

@Component({
  selector: 'app-csv-dialog',
  templateUrl: './csv-dialog.component.html',
  styleUrls: ['./csv-dialog.component.scss']
})
export class CsvDialogComponent implements OnInit {

  @Input() csvData: string;
  @Input() title: string;
  public rounded = true;
  public records: ObjectWithUnknownKeys[] = [];
  public headersRow: string[];
  public csvRecord: ObjectWithUnknownKeys;

  constructor(private bsModalRef: BsModalRef) {
  }

  ngOnInit(): void {
    this.getCSVData();
  }

  public getCSVData(): void {
    const csvRecordsArray = this.csvData.split(/\r\n|\n/);
    this.headersRow = this.getHeaderArray(csvRecordsArray);
    this.records = this.getDataRecordsArrayFromCSV(csvRecordsArray, this.headersRow.length);
  }

  public getDataRecordsArrayFromCSV<T>(csvRecordsArray: string[], headerLength: number): ObjectWithUnknownKeys[] {
    const csvArr = [];
    for (let i = 1; i < csvRecordsArray.length; i++) {
      const curruntRecord = (csvRecordsArray[i]).split(',');
      if (curruntRecord.length === headerLength) {
        this.csvRecord = {};
        for (let step = 0; step < curruntRecord.length; step++){
          this.csvRecord[this.headersRow[step]] = curruntRecord[step].trim();
        }
        csvArr.push(Object.values(this.csvRecord));
      }
    }
    return csvArr;
  }

  public getHeaderArray(csvRecordsArr: any): string[] {
    const headers = (csvRecordsArr[0]).split(',');
    const headerArray = [];
    for ( const data of headers ){
        headerArray.push(data);
    }
    return headerArray;
  }

  public roundToggle() {
    this.rounded = !this.rounded;
  }

  public close() {
    this.bsModalRef.hide();
  }

}
