import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Appliance } from '../../../_internal/appliance';
import { AggregatedEvent } from '../../../providers/resources/dto/aggregated-daily-data';
import { TableDisplayedColumn } from '../../../../common/components/data-table/table-displayed-column';
import { ApplianceCategory } from '../../../_internal/appliance_category';
import { Observable, of } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-aggregated-daily-data',
  templateUrl: './aggregated-daily-data.component.html',
  styleUrls: ['./aggregated-daily-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class AggregatedDailyDataComponent  {

    @Input() duration_events: AggregatedEvent[];
    @Input() duration_events_local: AggregatedEvent[];
    @Input() water_consumed_events: AggregatedEvent[];
    @Input() water_consumed_events_local: AggregatedEvent[];
    @Input() timezone: string;
    @Input() appliances: Appliance[];
    @Input() applianceCategories: ApplianceCategory[];
    @Input() showUtc = true;

    public displayedColumns: TableDisplayedColumn[] = [
        {
            key: 'event_start', name: 'Date', formatter: (dateTime) => `UTC: ${moment.unix(dateTime/1000).utc().format('MMM DD')} <br/>Property:  ${moment.unix(dateTime/1000).tz(this.timezone).format('MMM DD')}`
        },
        {
            key: 'event_start', name: 'Started At', formatter: (dateTime) => `${moment.unix(dateTime/1000).utc().format('HH:mm:ss')} <br/> ${moment.unix(dateTime/1000).tz(this.timezone).format('HH:mm:ss')}`
        },
        {
            key: 'event_end', name: 'Stopped At', formatter: (dateTime, rowItem) => {
                const eventStart = moment.unix(rowItem.event_start/1000).utc();
                const eventEnd = moment.unix(rowItem.event_end/1000).utc();
                const eventStartLocal = moment.unix(rowItem.event_start/1000).tz(this.timezone);
                const eventEndLocal = moment.unix(rowItem.event_end/1000).tz(this.timezone);
                let dayNotice = '';
                if (!eventStart.isSame(eventEnd, 'day')) {
                    dayNotice = `<span class="badge bg-warning">${eventEnd.format('MMM DD')}</span>`;
                }
                let dayLocalNotice = '';
                if (!eventStartLocal.isSame(eventEndLocal, 'day')) {
                    dayLocalNotice = `<span class="badge bg-warning">${eventEndLocal.format('MMM DD')}</span>`;
                }
                return `${moment.unix(dateTime/1000).utc().format('HH:mm:ss')} ${dayNotice}<br/> ${moment.unix(dateTime/1000).tz(this.timezone).format('HH:mm:ss')} ${dayLocalNotice}`;
            }
        },

        {
            key: 'duration',
            name: 'Duration',
            formatter: (duration) => {
                const time = moment(duration).utc();

                const days = moment.duration(duration).days();


                return `${days > 0 ? days.toString() + ' days<br/>' : ''}${time.format('HH:mm:ss')}`;
            }
        },
        {
            key: 'water_consumed',
            name: 'Water consumed',
            formatter: (consumed) => (consumed / 1000).toFixed(2).toString() + ' L'
        },

        {
            key: 'category',
            name: 'Categories',
            formatter: (applianceCategoryIds) => {
                const categories = _.map(applianceCategoryIds, (appId) => {
                    const applianceId = +appId;
                    return _.get(_.find(this.applianceCategories, {id: applianceId}), 'name');
                });

                return categories.toString();
            }
        },
    ];
  constructor(private bsModalRef: BsModalRef) { }

  public returnsDataStream(dataCollection: AggregatedEvent[]): Observable<AggregatedEvent[]>{
    return of(dataCollection);
  }

  public close(): void {
    this.bsModalRef.hide();
}
}
