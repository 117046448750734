import {Directive, ElementRef, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {UserService} from '../hero-labs/providers/services/user.service';
import * as _ from 'lodash';

@Directive({
    selector: '[appHasRoles]'
})
export class HasRolesDirective implements OnInit {

    private currentUser;
    private roles = [];

    constructor(private element: ElementRef,
                private templateRef: TemplateRef<any>,
                private viewContainer: ViewContainerRef,
                private userService: UserService) {
    }

    @Input()
    set appHasRoles(val) {
        this.roles = val;
        this.updateView();
    }

    ngOnInit() {
        this.currentUser = this.userService.user;
        this.updateView();
    }

    private updateView() {
        if (this.checkPermission()) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
    }

    private checkPermission() {
        if (this.currentUser && this.currentUser.roles) {
            return _.find(this.currentUser.roles, (role) => _.includes(this.roles, role.name));
        }
        return false;
    }

}
