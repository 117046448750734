import {Component, Input} from '@angular/core';
import {SonicTelemetryMessage} from '../../_internal/telemetry';

@Component({
    selector: 'app-thing-cached-telemetry',
    templateUrl: './thing-cached-telemetry.component.html',
    styleUrls: ['./thing-cached-telemetry.component.scss']
})
export class ThingCachedTelemetryComponent {
    @Input() telemetry: SonicTelemetryMessage;
    @Input() propertyId: string;

    constructor() {
    }

}
