<div class="modal-content">
	<div class="modal-header">
		<h4 class="modal-title"> Send fake incident </h4>
		<button type="button"
				class="close"
				aria-label="Close"
				mat-icon-button
				(click)="onCancel()">
			<mat-icon>close</mat-icon>
		</button>
	</div>
    <div class="modal-body">
        <div class="row mb-3">
            <div class="col-lg-6">
                <label>Severinity level</label>
                    <div class="form-group">
                        <select class="form-control"
                            cFormControl
                            type="text"
                            [(ngModel)]="severityLevel">
                            <option *ngFor="let status of severityLevels"
                                    [value]="status">{{status}}</option>
                        </select>
                    </div>
            </div>
            <div class="col-lg-6">
                <label>Incident type</label>
                    <div class="form-group">
                        <select class="form-control"
                            cFormControl
                            type="text"
                            [(ngModel)]="incidentType">
                            <option *ngFor="let incident of incidentsTypes;let i=index"
                                    [value]="IncidentsValues[i]">{{incidentsTypes[i]}}</option>
                        </select>
                    </div>
            </div>
        </div>
    </div>
	<div class="modal-footer">
		<button type="button"
				class="btn btn-secondary"
				(click)="onCancel()">Close
		</button>
		<button type="button"
				class="btn btn-primary"
                [disabled]="!(incidentType && severityLevel)"
				(click)="onConfirm()">Send
		</button>
	</div>
</div>
