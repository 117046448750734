import {PropertyResource} from './../../providers/resources/property.resource';
import {Incident, MostRecentIncident} from './../../_internal/incident';
import {Component, Input, OnChanges} from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import {HeroConfig} from '../../hero-config';
import {Router} from '@angular/router';
import {incidentsTypes} from '../../pages/incidents/config';
import {map} from 'rxjs/operators';

@Component({
    selector: 'app-incident-list',
    templateUrl: './incident-list.component.html',
    styleUrls: ['./incident-list.component.scss']
})
export class IncidentListComponent implements OnChanges {

    @Input() tabTitle: string;
    @Input() propertyId: string;

    public incidents: Incident[];
    public pageResolved = 1;
    public filteredType: string;
    public totalItems: number;
    public pageActive = 1;
    public activeIncidentsCount = 0;
    public itemsPerPage = HeroConfig.itemsPerPage;
    public mostRecentClosedIncidents: MostRecentIncident[];
    public recentTypes = incidentsTypes;
    private deprecatedIncidents = ['unusual_usage_alert', 'large_usage_alert', 'high_flow_alert', 'long_usage_alert'];

    constructor(private propertyResource: PropertyResource,
                private router: Router) {
    }

    ngOnChanges(changes) {
        this.fetchIncidents();

    }


    public fetchIncidents(status = 'active', page = 1, type?: string): void {
        this.incidents = [];
        const params = {};
        _.set(params, 'status', status);
        _.set(params, 'page', page);
        _.set(params, 'page_size', this.itemsPerPage);
        if (type) {
            this.filteredType = type;
            _.set(params, 'type', type);
        } else {
            this.filteredType = null;
        }
        this.propertyResource.getIncidentsList(this.propertyId, params)
                .subscribe(response => {
                    if (status === 'active') {
                        this.activeIncidentsCount = response.total_entries;
                    }
                    this.incidents = response.content;
                    this.pageResolved = response.page_number;
                    this.itemsPerPage = response.page_size;
                    this.totalItems = response.total_entries;
                });
    }


    public resolve(incidentId: string): void {
        this.propertyResource.resolvePropertyIncident(this.propertyId, incidentId).subscribe();
    }

    public determineBgClass(level: string): string {
        switch (level) {
            case 'low':
                return 'border-primary';
                break;
            case 'high':
                return 'border-danger';
                break;
        }
    }

    public goToLogs(id): void {
        this.router.navigate(['incidents', id]);
    }

    public timeAgo(date): string {
        return moment.utc(date, 'YYYY-MM-DD hh:mm:ss').fromNow();
    }

    public fetchMostRecent() {
        this.filteredType = null;
        this.incidents = [];
        this.propertyResource.theMostRecentClosedIncidents(this.propertyId)
                .pipe(map((response) => _.filter(response, (item) => !_.includes(this.deprecatedIncidents, item.type))))
                .subscribe((response) => {
                    this.mostRecentClosedIncidents = response;
                });
    }
}
