import {Component, Input} from '@angular/core';
import {PropertySettings} from '../../../_internal/property.settings';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {PropertyResource} from '../../../providers/resources/property.resource';
import * as moment from 'moment-timezone';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../../../src/environments/environment';

// import {TZone} from 'moment-timezone-picker';

@Component({
    selector: 'app-settings-edit',
    templateUrl: './settings-edit.component.html',
    styleUrls: ['./settings-edit.component.scss']
})
export class SettingsEditComponent {
    @Input() settings: PropertySettings;
    @Input() propertyId: string;

    public currencies: { value: string; label: string }[] =
            [{value: 'pounds', label: 'GBP(£)'}, {value: 'zlotowki', label: 'PLN'}, {
                value: 'dollars',
                label: 'USD($)'
            }];

    constructor(private _bsModalRef: BsModalRef,
                private propertyResource: PropertyResource,
                private toastrService: ToastrService) {
    }

    public onConfirm(): void {
        this.propertyResource.updateSettings(this.propertyId, this.settings).subscribe(
                response => {
                    const utcDate = moment.utc(this.settings.pressure_tests_schedule, 'HH:mm:ss').toDate();
                    this.settings.pressure_tests_schedule = moment.utc(utcDate).format('HH:mm');
                    this.toastrService.success('Settings have been saved successfully.', 'Settings.', environment.toastSettings);
                    this._bsModalRef.hide();
                },
                err => this.toastrService.error( 'An error has ocured while loading page data. Error code: ' + err.status + 'Error message' + err.message, 'Loading page data.', environment.toastSettings)
        );
    }

    public onCancel(): void {
        this._bsModalRef.hide();
    }

    // public changeTimezone($event: TZone) {
    //     this.settings.timezone = $event.nameValue;
    // }
}
