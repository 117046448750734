import {Component, OnInit} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {Appliance} from '../../../_internal/appliance';
import {ApplianceService} from '../../../providers/services/appliance.service';
import {ApplianceCategoryService} from '../../../providers/services/appliance-category.service';
import * as _ from 'lodash';
import {ApplianceCategory} from '../../../_internal/appliance_category';

@Component({
    selector: 'app-manage-appliance-dialog',
    templateUrl: './manage-appliance-dialog.component.html',
    styleUrls: ['./manage-appliance-dialog.component.scss']
})
export class ManageApplianceDialogComponent implements OnInit {
    public appliance: Appliance;
    public applianceCategories: ApplianceCategory[];
    private readonly pageSize = '9999';

    constructor(private modalService: BsModalService,
                private applianceService: ApplianceService,
                private applianceCategoryService: ApplianceCategoryService,
                private bsModalRef: BsModalRef) { }

    ngOnInit() {
        this.fetchCategories();
    }

    public fetchCategories(): void {
        this.applianceCategoryService.getMany({page_size: this.pageSize})
            .subscribe(res => this.applianceCategories = res);
    }

    public close() {
        this.bsModalRef.hide();
    }

    public onConfirm() {
        if (_.isNil(this.appliance.id)) {
            this.applianceService.createAppliance(this.appliance).subscribe((res) => {
                this.modalService.setDismissReason('applianceCreated');
                this.close();
            });
        } else {
            this.applianceService.updateAppliance(this.appliance).subscribe(res => this.close());
        }
    }
}
