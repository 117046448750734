
export const provisionFilterFields = [
    {
        type: 'text',
        name: 'serial_no',
        label: 'Enter serial no'
    },
];

export const calibrationFilterFields = [
    {
        type: 'text',
        name: 'serial_no',
        label: 'Enter serial no'
    },
];

export const deviceFilter =
    [{
        type: 'text',
        name: 'serial_no',
        label: 'Exact serial no'
    }];


export const paramsFilterFields =
    [{
        name: 'device_type',
        label: 'Type',
        options: [
            {
                label: 'Sonic wifi',
                value: 'sonic_wifi'
            },
            {
                label: 'Bridge pro',
                value: 'signal_pro'
            },
            {
                label: 'Sonic pro',
                value: 'sonic_pro'
            }
        ]
    },
    {
        name: 'group_type',
        label: 'Groupe type',
        options: [
            {
                label: 'Radio device',
                value: 'radio_device'
            },
            {
                label: 'Cloud device',
                value: 'cloud_device'
            }
        ]
    },
    {
        name: 'is_golden_sample',
        label: 'Golden sample',
        options: [
            {
                label: 'True',
                value: 'true'
            },
            {
                label: 'False',
                value: 'false'
            }
        ]
    }];
