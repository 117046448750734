<div class="modal-content">
    <div class="modal-header">
        <h4 class="modal-title">{{title}}</h4>
        <div class="buttons-position">
            <button *ngIf="serial"
                    type="button"
                    class="btn btn-sm btn-secondary btn-sm"
                    (click)="copyTable()">
                Copy table
            </button>
            <button *ngIf="viewType!=='table'"
                    type="button"
                    class="btn btn-sm btn-secondary btn-sm ml-2"
                    (click)="chaneView('table')">
                Table
            </button>
            <button *ngIf="viewType!=='json'"
                    type="button"
                    class="btn btn-sm btn-secondary btn-sm ml-2"
                    (click)="chaneView('json')">
                Json
            </button>
            <button type="button"
                    aria-label="Close"
                    mat-icon-button
                    (click)="close()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
    <div class="modal-body">
        <div #exportTable class="export-block">
            &nbsp;
            <table class="table table-bordered  table-responsive w-100 d-block">
                <thead class="d-none">
                <tr>
                    <th *ngIf="serial">serial</th>
                    <th *ngFor="let item of keys; let i=index" [colSpan]="getDataSize(i)">{{item}}</th>
                </tr>
                </thead>
                <tbody>
                <tr class="d-none">
                    <td *ngIf="serial">serial</td>
                    <ng-container *ngFor="let value of values ; let i=index">
                        <td *ngFor="let data of value | keyvalue">
                            {{data.key}}
                        </td>
                        <td *ngIf="getMinColSpanSize(keys[i]) > getObjectSize(value)"
                            [colSpan]="(getMinColSpanSize(keys[i]) - getObjectSize(value))">
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <td *ngIf="serial">{{serial}}</td>
                    <ng-container *ngFor="let value of values ; let i=index">
                        <td *ngFor="let data of value | keyvalue" class="value-col">

                            <ng-container
                                *ngTemplateOutlet="displayValue; context: {data: data.value}"></ng-container>

                        </td>

                        <td *ngIf="getMinColSpanSize(keys[i]) > getObjectSize(value)"
                            [colSpan]="(getMinColSpanSize(keys[i]) - getObjectSize(value))">
                        </td>
                    </ng-container>
                </tr>
                </tbody>
            </table>
            &nbsp;
        </div>

        <ng-container [ngSwitch]="viewType">

            <div *ngSwitchCase="'table'">
                <table class="table table-responsive result-table table-bordered"
                       *ngIf="!nestedJson; else nestedJsonTable">
                    <tbody>
                    <tr *ngFor="let data of keys; let i=index">
                        <th>{{data}}</th>
                        <td>
                            <ng-container
                                *ngTemplateOutlet="displayValue; context: {data: values[i]}"></ng-container>

                        </td>
                    </tr>
                    </tbody>
                </table>
                <ng-template #nestedJsonTable>
                    <table class="table table-responsive result-table table-bordered table-striped">
                        <tbody>
                        <ng-container *ngFor="let data of values; let i=index">

                            <tr *ngFor="let item of data | keyvalue; let first=first">
                                <th *ngIf="first" [rowSpan]="getColspanForData(data)" class="bg-none"
                                    scope="row">{{keys[i]}}</th>
                                <td class="row-title">
                                    {{item.key}}
                                </td>
                                <td class="nested-td">
                                    <ng-container
                                        *ngTemplateOutlet="displayValue; context: {data: item.value}"></ng-container>

                                </td>
                            </tr>
                        </ng-container>
                        </tbody>
                    </table>

                </ng-template>
            </div>
            <div *ngSwitchCase="'json'">

            <pre>
            <p> {{content | json}} </p>
            </pre>
            </div>
        </ng-container>

        <ng-template #simpleValue let-data="data">
            <span [ngClass]="getDataClass(data)">
            {{data}}
                </span>
        </ng-template>
        <ng-template #objectValue let-data="data">
            <ng-container *ngFor="let dataItem of data |  keyvalue">
                {{dataItem.key}}: {{dataItem.value}}
            </ng-container>
        </ng-template>
        <ng-template #arrayValue let-data="data">
            <ng-container *ngFor="let objectData of data; let last = last">
                <ng-container *ngTemplateOutlet="displayValue; context: {data: objectData}"></ng-container>
                <span *ngIf="!last">;<br/></span>
            </ng-container>
        </ng-template>
        <ng-template #displayValue let-data="data">
            <ng-container *ngIf="isArrayOfObjects(data); else notArray">
                <ng-container *ngTemplateOutlet="arrayValue; context: {data: data}"></ng-container>
            </ng-container>
            <ng-template #notArray>
                <ng-container [ngTemplateOutlet]="isObject(data)?objectValue:simpleValue"
                              [ngTemplateOutletContext]="{data:data}"></ng-container>

            </ng-template>
        </ng-template>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="close()">Cancel</button>
    </div>
</div>
