import {Component, OnDestroy, OnInit} from '@angular/core';
import {JobService} from '../../../providers/services/job.service';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {TableDisplayedColumn} from '../../../../common/components/data-table/table-displayed-column';
import {Job} from '../../../_internal/job';
import {Router} from '@angular/router';
import {JobsParameters} from '../../../providers/resources/dto/job.response';
import {HeroConfig} from '../../../hero-config';

@Component({
    selector: 'app-job-list',
    templateUrl: './job-list.component.html',
    styleUrls: ['./job-list.component.scss']
})
export class JobListComponent implements OnInit, OnDestroy {

    jobSubscription: Subscription;
    jobStream = new BehaviorSubject([]);
    jobsParameters: JobsParameters = {maxResults: HeroConfig.itemsPerPage};

    public displayedColumns: TableDisplayedColumn[] = [
        {key: 'job_id', name: 'Job ID'},
        {
            key: 'status', name: 'Status', formatter: (status) => `<span class="badge bg-${JobListComponent.determineStatusLabelClass(status)}">${status}</span>`
        },
        {key: 'target_selection', name: 'Target Selection'},
        {key: 'created_at', name: 'Created at', autoFormat: 'date'},
        {key: 'completed_at', name: 'Completed at', autoFormat: 'date'},
        {key: 'last_updated_at', name: 'Last update', autoFormat: 'date'},
        {key: 'thing_group_id', name: 'Thing group'}
    ];

    constructor(private jobService: JobService,
        private router: Router) {
}

    static determineStatusLabelClass(status: string): string {
        switch (status) {
            case 'COMPLETED':
                return 'success';
            case 'CANCELED':
                return 'danger';
            default:
                return 'warning';
        }
    }

    getJobStream(): Observable<Job[]> {
        return this.jobStream.asObservable();
    }

    updateJobStream(): void {
        this.jobSubscription = this.jobService.getJobs(this.jobsParameters)
                .subscribe((res) => {
                    this.jobStream.next(this.jobStream.value.concat(res.jobs));
                    this.jobsParameters.nextToken = res.next_token;
                });
    }

    goToJob(jobId): void {
        this.router.navigate(['jobs', jobId]);
    }

    ngOnInit() {
        this.updateJobStream();
    }

    ngOnDestroy() {
        this.jobSubscription.unsubscribe();
        this.jobStream.complete();
    }
}
