import {ThingService} from './../../../providers/services/thing.service';
import {forkJoin, Subject} from 'rxjs';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Component, Input, OnInit} from '@angular/core';
import {Thing} from '../../../_internal/thing';
import {ToastrService} from 'ngx-toastr';
import {environment} from '../../../../../../src/environments/environment';
import {FormBuilder, FormGroup} from '@angular/forms';
import {FirmwareChannelService} from '../../../providers/services/firmware-channel.service';
import {FirmwareChannel} from '../../../providers/resources/dto/firmware-channel.response';
import * as _ from 'lodash';
import { FirmwareSettings } from '../../../providers/resources/dto/firmware-device-settings.response';

@Component({
    selector: 'app-thing-edit',
    templateUrl: './thing-edit-page.component.html',
    styleUrls: ['./thing-edit-page.component.scss']
})
export class ThingEditPageComponent implements OnInit {
    @Input() thing: Thing;
    public thingStatuses: string[] = ['active', 'inactive'];
    public onStateChange: Subject<boolean>;
    public updateAvailability = [true, false];
    public formGroup: FormGroup;
    public firmwareChannels: FirmwareChannel[];
    public firmwareSettings: FirmwareSettings;
    constructor(private _bsModalRef: BsModalRef,
                private thingService: ThingService,
                private firmwareChannelService: FirmwareChannelService,
                private toastrService: ToastrService,
                private fb: FormBuilder) {
    }

    ngOnInit(): void {
        this.getFirmwareChannels();
        this.thingService.getFirmwareSettings(this.thing.id).subscribe(data =>{
            this.firmwareSettings = data;
            this.initForm();
        });
    }


    public onConfirm(): void {
        const activeChannel = _.find(this.firmwareChannels, ['id', this.formGroup.value.firmware_channel_id]);
        forkJoin({
            1: this.thingService.updateFirmwareSettings(this.thing.type === 'sonic_wifi' ? this.thing.hub_id:this.thing.id, {mass_upgrade_blocked: this.formGroup.value.mass_firmware_upgrade_disabled, firmware_channel: activeChannel.name}),
            2: this.thingService.updateThing(this.thing.id, {name: this.formGroup.value.name, status: this.formGroup.value.status})}
        )
        .subscribe(
            response => {
                this.toastrService.success('Settings have been saved successfully.', 'Settings.', environment.toastSettings);
                this._bsModalRef.hide();
            },
            error => {
                this.toastrService.error('An error has ocured while saving data. Error code: ' + error.status + 'Error message' + error.message, 'Settings.', environment.toastSettings);
            }
        );
    }

    public onCancel(): void {
        this._bsModalRef.hide();
    }

    private initForm() {
        this.formGroup = this.fb.group({
            name: [this.thing.name],
            firmware_channel_id: [this.firmwareSettings.firmware_channel_id],
            status: [this.thing.status],
            mass_firmware_upgrade_disabled: [this.firmwareSettings.mass_upgrade_blocked]
        });
    }

    private getFirmwareChannels() {
        this.firmwareChannelService.getMany()
            .subscribe(response => this.firmwareChannels = response.firmware_channels
        );
    }
}
