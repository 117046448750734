<c-row>
	<c-col lg="6"
		   class="mb-4">
		<c-card>
			<c-card-header>
				<strong>Hubs</strong>
			</c-card-header>
			<c-card-body>
				<table cTable>
					<thead>
					<tr>
						<th>Id</th>
						<th>Address</th>
						<th class="actions-column"></th>
					</tr>
					</thead>
					<tbody>
					<tr *ngFor="let item of getFavourites('hubs')">
						<td>
							<a mat-button
							   routerLink="/hubs/{{item.id}}">{{item.id}}</a>
						</td>
						<td>{{item.address}}</td>
						<td>
							<button mat-button
									(click)="removeFavourite(item.id, 'hubs')">
								<mat-icon>delete</mat-icon>
							</button>
						</td>
					</tr>
					</tbody>
				</table>
			</c-card-body>
		</c-card>
	</c-col>
	<c-col lg="6"
		   class="mb-4">
		<c-card>
			<c-card-header>
				<strong>Properties</strong>
			</c-card-header>
			<c-card-body>
				<table cTable>
					<thead>
					<tr>
						<th>Name</th>
						<th>Address</th>
						<th class="actions-column"></th>
					</tr>
					</thead>
					<tbody>
					<tr *ngFor="let item of getFavourites('properties')">
						<td>
							<a mat-button
							   routerLink="/properties/{{item.id}}">{{item.name}}</a></td>
						<td>{{item.address}}</td>
						<td>
							<button mat-button
									(click)="removeFavourite(item.id, 'properties')">
								<mat-icon>delete
								</mat-icon>
							</button>
						</td>
					</tr>
					</tbody>
				</table>
			</c-card-body>
		</c-card>
	</c-col>
	<c-col lg="6"
		   class="mb-4">
		<c-card>
			<c-card-header>
				<strong>Users</strong>
			</c-card-header>
			<c-card-body>
				<table cTable>
					<thead>
					<tr>
						<th>Name</th>
						<th>Address</th>
						<th class="actions-column"></th>
					</tr>
					</thead>
					<tbody>
					<tr *ngFor="let item of getFavourites('users')">
						<td><a mat-button
							   routerLink="/users/{{item.id}}">{{item.name}}</a></td>
						<td>{{item.address}}</td>
						<td>
							<button mat-button
									(click)="removeFavourite(item.id, 'users')">
								<mat-icon>delete
								</mat-icon>
							</button>
						</td>
					</tr>
					</tbody>
				</table>
			</c-card-body>
		</c-card>
	</c-col>
	<c-col lg="6"
		   class="mb-4">
		<c-card>
			<c-card-header>
				<strong>Sonics</strong>
			</c-card-header>
			<c-card-body>
				<table cTable>
					<thead>
					<tr>
						<th>Name</th>
						<th>Address</th>
						<th class="actions-column"></th>
					</tr>
					</thead>
					<tbody>
					<tr *ngFor="let item of getFavourites('things')">
						<td><a mat-button
							   routerLink="/things/{{item.id}}"
						>{{item.name}}</a></td>
						<td>{{item.address}}</td>
						<td>
							<button mat-button
									(click)="removeFavourite(item.id, 'things')">
								<mat-icon>delete
								</mat-icon>
							</button>
						</td>
					</tr>
					</tbody>
				</table>
			</c-card-body>
		</c-card>
	</c-col>
</c-row>

