<div class="card mb-4">
	<div class="card-header">
		<i class="fa fa-align-justify"></i> Users

	</div>
	<div class="card-body">
		<app-table [rows]="rows"
                    *ngIf="showTable"
                    [filterFields]="filterFields"
                    [columns]="columns"
                    [selectionEnabled]="false"
                    [tableSettings]="tableSettings"
				   [defaultSorts]="[{prop:'created_at', dir:'desc'}]"
                   (fetchPage)="fetchPage($event)"
                   >
		</app-table>
        <ng-template #rolesColumn
                    ngx-datatable-cell-template
                    let-row="row"
                    let-value="value">
                    <c-badge [color]="role.name==='admin'?'warning':'secondary'"
                            class="me-2"
                            *ngFor="let role of value">
                            {{role.name}}
                    </c-badge>
        </ng-template>
		<ng-template #activeColumn
					 ngx-datatable-cell-template
					 let-row="row"
					 let-value="value">
			<c-badge color="success"
					 *ngIf="value">Active
			</c-badge>
			<c-badge color="danger"
					 *ngIf="!value">Inactive
			</c-badge>

		</ng-template>
		<ng-template #actionsColumn
					 ngx-datatable-cell-template
					 let-row="row"
					 let-value="value">
                     <button class="btn btn-sm" (click)="selectUser(row)" *ngIf="getButtonStatus(row) ; else  Selected">
                        <mat-icon >check_box_outline_blank</mat-icon>
                    </button>
                    <ng-template #Selected>
                        <button class="btn btn-sm" (click)="removeUser(row)">
                            <mat-icon >check_box</mat-icon>
                        </button>
                    </ng-template>
		</ng-template>
	</div>
</div>
