<div class="card mb-4"
	 *ngIf="event">
	<div class="card-header">
		Override event
	</div>
	<div class="card-body">
		<form class="ng-untouched ng-pristine ng-valid">

			<div class="form-group mb-3">
				<label>Complex
					<input type="checkbox"
						   name="complexity"
						   value="event.overrides.complex"
						   [(ngModel)]="overrides.complex">
				</label>
			</div>

			<div class="form-group mb-3">
				<label>Children count
					<input class="form-control"
						   type="number"
						   name="childrenCount"
						   [(ngModel)]="overrides.children_count">
				</label>
			</div>

			<div class="form-group mb-3">
				<label>Appliance Categories</label>
				<ng-select [items]="applianceCategories"
						   name="categories"
						   [(ngModel)]="overrides.appliance_category_ids"
						   [multiple]="true"
						   [closeOnSelect]="false"
						   bindLabel="name"
						   placeholder="Select categories"
						   bindValue="id">
				</ng-select>
			</div>

			<div class="form-group mb-3">
				<label>
					Confidence level <b>(required)</b>
					<label class="bg-red">{{errorMsg}}</label>
				</label>
				<rating [(ngModel)]="overrides.confidence"
						name="confidence"
						[max]="5"
						[readonly]="false"></rating>
			</div>

			<div class="form-group mb-3">
				<button class="btn btn-primary"
						(click)="onSave()">Save
				</button>
				<button class="btn btn-danger"
						(click)="onClose()">Cancel
				</button>
			</div>


    </form>
  </div>
</div>
