<div class="card mb-4">
	<div class="card-header">
		<i class="fa fa-align-justify"></i> Appliances

		<div class="btn-group float-end"
			 *ngIf="spaceId">
			<button class="btn btn-sm btn-secondary"
					(click)="openCreateDialog()">Create appliance
			</button>
		</div>

	</div>
  <div class="card-body" *ngIf="(applianceStream | async); else noData">
    <app-data-table [dataStream]="applianceStream"
                    [displayedColumns]="displayedColumns"
                    [settings]="{hasAction: true}">
      <ng-template let-appliance>
        <div class="btn-toolbar text-center well">
        <button type="button" (click)="openUpdateDialog(appliance)" class="btn btn-sm btn-secondary"
                container="body"
                tooltip="Edit appliance">
          <i class="fa fa-pencil" aria-hidden="true"></i>
        </button>
        <button type="button" (click)="removeAppliance(appliance)" class="btn btn-sm btn-danger"
                container="body"
                tooltip="Remove appliance">
          <i class="fa fa-trash" aria-hidden="true"></i>
        </button>
      </div>
      </ng-template>
    </app-data-table>

  </div>
  <ng-template #noData>
    <div class="card-body">
      <div class="col-12 d-flex justify-content-center">
        <h5>No Appliances</h5>
      </div>
    </div>
  </ng-template>
</div>
