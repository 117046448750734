<div class="modal-content">
	<div class="modal-header">
		<h4 class="modal-title"> Add Circle of trust </h4>
		<button type="button"
				class="close"
				aria-label="Close"
				(click)="onCancel()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<form [formGroup]="formGroup">
			<div class="row">
				<div class="col-lg-6">
					<div class="form-group">
						<label for="email">Email</label>
						<input id="email"
							   type="email"
							   class="form-control"
							   formControlName="email"/>
					</div>
				</div>
				<div class="col-lg-6">
					<div class="form-group">
						<label for="phone">Phone</label>
						<input id="phone"
							   type="tel"
							   class="form-control"
							   formControlName="phone"/>
					</div>
				</div>
				<div class="col-lg-6">
					<div class="form-group">
						<label for="first-name">First name</label>
						<input id="first-name"
							   type="text"
							   class="form-control"
							   formControlName="firstName"/>
					</div>
				</div>
				<div class="col-lg-6">
					<div class="form-group">
						<label for="last-name">Last name</label>
						<input id="last-name"
							   type="text"
							   class="form-control"
							   formControlName="lastName"/>
					</div>
				</div>
				<div class="col-12">
					<p *ngIf="incorrectInputMsg">
						{{incorrectInputMsg}}
					</p>
					<p *ngFor="let error of errors">
						{{error}}
					</p>
				</div>
			</div>
		</form>
	</div>
	<div class="modal-footer">
		<button type="button"
				class="btn btn-secondary"
				(click)="onCancel()">Close
		</button>
		<button type="button"
				class="btn btn-primary"
				(click)="onConfirm()">
			<span *ngIf="!contact.id">Add</span>
			<span *ngIf="contact.id">Update</span>
		</button>
	</div>
</div>
