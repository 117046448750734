import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SpacePageComponent} from './space/space-page.component';
import {SpacesRoutingModule} from './spaces.routing.module';
import {HeroComponentsModule} from '../../components/hero-components.module';

@NgModule({
    imports: [
        CommonModule,
        SpacesRoutingModule,
        CommonModule,
        HeroComponentsModule
    ],
    declarations: [
        SpacePageComponent,
    ],
    exports: [
        SpacePageComponent
    ]
})
export class SpacesModule {
}
