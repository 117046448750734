<div class="modal-content" *ngIf="!isRequestedList; else card">
    <div class="modal-header">
        <h4 class="modal-title">{{listType}}</h4>


        <button type="button"
                class="close"
                aria-label="Close"
                mat-icon-button
                (click)="close()">
            <mat-icon>close</mat-icon>
        </button>

    </div>
    <div class="modal-body">
        <app-data-table [dataStream]="firmwaresStream" [displayedColumns]="displayedColumnsFirmwares"
                        [settings]="{hasAction: !isRequestedList}">
            <ng-template let-firmware>
                <button type="button" (click)="update(firmware)" class="btn btn-sm btn-primary"
                        container="body"
                        placement="bottom"
                        tooltip="Update Firmware">
                    <i class="fa fa-download" aria-hidden="true"></i>
                </button>
            </ng-template>
        </app-data-table>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="close()">Close</button>
    </div>
</div>

<ng-template #card>
    <div class="card mb-4">
        <div class="card-header">
            <i class="fa fa-align-justify"></i> {{listType}}
        </div>
        <div class="card-body"
             *ngIf="(firmwaresStream | async); else noData">
            <app-data-table [dataStream]="firmwaresStream"
                            [displayedColumns]="displayedRequestColumns"
                            [settings]="{hasAction: true}">
                <ng-template let-firmware>
                    <button type="button"
                            *ngIf="group"
                            (click)="goToJob(firmware.job_id)"
                            class="btn btn-sm btn-primary"
                            tooltip="Go To Job">
                        Show details
                    </button>

                    <button
                        (click)="redirectToJOb(firmware.job_id)"
                        type="button"
                        class="btn btn-sm btn-secondary"
                    >Redirect to job
                    </button>
                </ng-template>
            </app-data-table>

            <div class="col-12 d-flex justify-content-center" *ngIf="isLoading">
                <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>

        </div>
        <ng-template #noData>
            <div class="card-body">
                <div class="col-12 d-flex justify-content-center">
                    <h5>No Updates</h5>
                </div>
            </div>
        </ng-template>
    </div>
</ng-template>
