<div class="bg-light min-vh-100 d-flex flex-row align-items-center">
	<c-container>
		<div class="row">
			<div class="col-md-8 mx-auto">
				<div class="card-group">

					<div class="card p-4"
						 *ngIf="!password_reset">
						<div class="card-body">
                            <form [formGroup]="passwordReset">
								<h1>Password reset</h1>
								<p class="text-muted">Provide your email</p>
								<div role="alert"
									 class="alert alert-danger"
									 *ngIf="!passwordReset.get('email').valid && passwordReset.get('email').touched">
									{{error}}
								</div>
								<c-input-group class="mb-3">
									<span cInputGroupText>
										<svg cIcon
											 name="cilUser"></svg>
									</span>
									<input autoComplete="email"
										   cFormControl
										   placeholder="E-mail"
										   type="email"
										   formControlName="email"
										   (keyup.enter)="onSubmit()"
										   required
									/>
								</c-input-group>

								<div class="row">
									<div class="col-6">
										<button type="button"
												class="btn btn-primary px-4"
												(click)="onSubmit()">
											Reset
										</button>
									</div>
								</div>
							</form>
						</div>
					</div>
					<div class="card p-4"

						 *ngIf="password_reset">
						<div class="card-body">
							<h1>Password reset</h1>
							<div role="alert"
								 class="alert alert-success">
								Password reset instruction has been sent to your mailbox
							</div>
							<div class="row">
								<div class="col-12">
									<button type="button"
											class="btn btn-primary px-4"
											(click)="goToLogin()">
										Login using your new password
									</button>
								</div>
							</div>
						</div>
					</div>

					<div class="card text-white py-5 d-md-down-none logo">
						<div class="card-body text-center logo-position">

							<div>
								<img src="assets/img/brand/logo-black.svg"
									 alt="">
							</div>

						</div>
					</div>
				</div>
			</div>
		</div>
	</c-container>
</div>
