import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ManufacturingPagesRoutingModule} from './manufacturing-pages-routing.module';
import {ProvisionReportsComponent} from './provision-reports/provision-reports.component';
import {CalibrationResultsComponent} from './calibration-results/calibration-results.component';
import {DataTableModule} from '../../../common/components/data-table/data-table.module';
import {ModalModule} from 'ngx-bootstrap/modal';
import {FiltersModule} from '../../../common/components/filters/filters.module';
import {HeroComponentsModule} from '../../components/hero-components.module';
import {BadgeModule} from '@coreui/angular';
import {DeviceComponent} from './device/device.component';
import {DevicesComponent} from './devices/devices.component';
import {RouterModule} from '@angular/router';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import { PressureTestResultsComponent } from './pressure-test-results/pressure-test-results.component';
import { ReactiveFormsModule } from '@angular/forms';
import {DeviceEditPageComponent} from './device-edit/device-edit-page.component';

@NgModule({
    declarations: [
        ProvisionReportsComponent,
        CalibrationResultsComponent,
        DeviceComponent,
        DeviceEditPageComponent,
        DevicesComponent,
        PressureTestResultsComponent,
    ],
    imports: [
        CommonModule,
        ManufacturingPagesRoutingModule,
        DataTableModule,
        ModalModule.forRoot(),
        FiltersModule,
        HeroComponentsModule,
        BadgeModule,
        RouterModule,
        BsDatepickerModule.forRoot(),
        ReactiveFormsModule

    ],
    exports: []
})
export class ManufacturingPagesModule {
}
