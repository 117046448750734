import {AverageConsumption} from './../../_internal/average.consumption';
import {HistoricRange} from './../../_internal/historic.range';
import {Injectable} from '@angular/core';
import * as moment from 'moment';
import {Moment} from 'moment';
import * as _ from 'lodash';

@Injectable()
export class HistoricDataService {
    readonly currencies = {pounds: '£', pound: '£', GBP: '£'};

    constructor() {
    }

    public setRange(range: string = 'day'): HistoricRange {
        let dateFrom: Moment = moment();
        let dateTo: Moment;
        let interval = 'hour';

        switch (range) {
            case 'day':
                interval = 'hour';
                dateFrom = moment().startOf('day');
                dateTo = moment().startOf('hour');
                break;
            case 'week':
                interval = 'day';
                dateTo = moment();
                dateFrom = moment().subtract(7, 'days');
                break;
            case 'month':
                interval = 'week';
                dateTo = moment().subtract(1, 'months');
                break;
            case 'year':
                interval = 'month';
                dateTo = moment().subtract(1, 'years');
                break;
        }
        return {dateFrom, dateTo, interval};
    }

    public totalConsumption(averageConsumption: AverageConsumption): number {
        return _.sumBy(averageConsumption.data, 'total_usage_local');
    }

    public totalCost(averageConsumption: AverageConsumption): number {
        return _.sumBy(averageConsumption.data, 'estimated_cost');
    }

    public convertWaterFlowToConsumption(waterFlow: number): number {
        return waterFlow / 60000;
    }

    public convertSecondsToMinutes(seconds: number): string {
        const minutes: number = Math.floor(seconds / 60);
        const hours: number = Math.floor(minutes / 60);
        if (hours > 0) {
            return hours + 'h ' + (minutes - hours * 60) + 'm';
        } else {
            return minutes + 'm ' + (seconds - minutes * 60) + 's';
        }

    }

    public currency(averageConsumption: AverageConsumption): string {
        const currency = _.get(averageConsumption, 'data[0].currency');
        return this.getSymbolFromCurrency(currency);
    }

    private getSymbolFromCurrency(currency: string) {
        return this.currencies[currency] || '$';
    }
}
