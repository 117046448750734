import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {ThingGroupListComponent} from './thing-group-list/thing-group-list.component';

const routes: Routes = [
    {
        path: '',
        children: [
            {
                path: '',
                component: ThingGroupListComponent,
                data: {
                    title: 'Thing groups list',
                },
            }
        ]
    }
];


@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ThingGroupsRoutingModule {
}
