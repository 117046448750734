import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import * as moment from 'moment';
import {Incident} from '../../_internal/incident';
import * as momentDurationFormatSetup from 'moment-duration-format';
import {incidentsTypes} from '../../pages/incidents/config';
import * as _ from 'lodash';

@Component({
    selector: 'app-incident-list-item',
    templateUrl: './incident-list-item.component.html',
    styleUrls: ['./incident-list-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class IncidentListItemComponent implements OnInit {

    @Input() incident: Incident;
    public incidentColor = {
        high: 'red',
        mid: 'orange',
        low: 'yellow',
    };
    public recentTypes = incidentsTypes;

    constructor() {
    }

    ngOnInit() {
        momentDurationFormatSetup(moment);
    }

    public timeAgo(date): string {
        return moment.utc(date, 'YYYY-MM-DD hh:mm:ss').fromNow();
    }


    public formatDuration(incident: Incident): string {
        const duration = _.get(incident, 'payload.duration');
        return (moment.duration(duration, 'millisecond') as momentDurationFormatSetup).format('m');
    }

    public getIncidentVolume(incident: Incident): number {
        return _.get(incident, 'payload.volume');
    }
}
