import {Observable} from 'rxjs';
import {environment} from './../../../../environments/environment';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { LegionellaCheckResonse } from './dto/legionella.response';

@Injectable({
  providedIn: 'root'
})
export class LegionellaResource {
    /**
     * page_size is equal to 120 so we can have legionella checks from 2 months (2 checks per day for 60 days)
     */
    public pageSize = 120;
    private readonly url: string = `${environment.apiGatewayUrl}/iot-core/admin`;

  constructor(private http: HttpClient) {
  }

  public legionellaTemperatureChecks(thingID: string): Observable<LegionellaCheckResonse> {
      const url = `${this.url}/legionella_temp_checks?thing_id=${thingID}&page_size=${this.pageSize}`;
      return this.http.get<LegionellaCheckResonse>(url);
  }

  public legionellaStagnationChecks(thingID: string): Observable<LegionellaCheckResonse> {
    const url = `${this.url}/legionella_stagnation_checks?thing_id=${thingID}&page_size=${this.pageSize}`;
    return this.http.get<LegionellaCheckResonse>(url);
}
}


