import {RouterModule, Routes} from '@angular/router';
import {SpacePageComponent} from './space/space-page.component';
import {NgModule} from '@angular/core';

const routes: Routes = [
    {
        path: '',
        children: [
            {
                path: ':propertyId/:spaceId',
                component: SpacePageComponent,
                data: {
                    title: 'Space',
                },
            }
        ]
    }
];


@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class SpacesRoutingModule {
}
