import {Component, Input} from '@angular/core';
import {TileTelemetryMessage} from '../../_internal/telemetry';
import * as moment from 'moment';

@Component({
    selector: 'app-tile-telemetry',
    templateUrl: './tile-telemetry.component.html',
})
export class TileTelemetryComponent {

    @Input() telemetry: TileTelemetryMessage;
    @Input() propertyId: string;

    public timeAgo(date: number): string {
        return moment.utc(date).fromNow();
    }

}
