import {PusherTelemetryMessage} from '../../_internal/pusher.telemetry.message';
import {HistoricDataService} from '../../providers/services/historic-data.service';
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ThingService} from '../../providers/services/thing.service';
import * as moment from 'moment-timezone';
import {TotalUsage} from '../../_internal/total.usage';
import {PusherService} from '../../providers/services/pusher.service';
import {environment} from '../../../../environments/environment';
import {PropertyService} from '../../providers/services/property.service';
import {Subscription, timer} from 'rxjs';
import {SonicTelemetryMessage} from '../../_internal/telemetry';

@Component({
    selector: 'app-thing-telemetry',
    templateUrl: './thing-telemetry.component.html',
    styleUrls: ['./thing-telemetry.component.scss']
})
export class ThingTelemetryComponent implements OnInit, OnDestroy {
    @Input() thingId: string;
    @Input() propertyId: string;
    @Output() telemetryStatus: EventEmitter<boolean> = new EventEmitter();
    public statusCheck: Subscription;
    public totalUsageToday: TotalUsage;
    public telemetryTimeout = 6000;
    public pusherChannel;


    public waterCostPerLitre = 0.03;
    public telemetry: SonicTelemetryMessage;
    private timezone = 'Europe/London';

    constructor(private historicDataService: HistoricDataService,
                private pusher: PusherService,
                private propertyService: PropertyService,
                private thingService: ThingService) {
    }

    ngOnInit() {
        this.propertyService.getSettings(this.propertyId)
            .subscribe(settings => {
                this.timezone = settings.timezone;
                this.fetchTotalUsageToday();

            });
        this.initPusher();
    }

    ngOnDestroy() {
        this.closePusher();
    }

    public closePusher(): void {
        this.pusher.unsubscribe(this.propertyId);
    }
    public initPusher(): void {
        this.pusherChannel = this.pusher.init(this.propertyId);
        this.pusherChannel.bind(environment.telemetryPusherEvent, (message: PusherTelemetryMessage) => {
            this._handleTelemetryMessage(message.message as SonicTelemetryMessage);
        });
    }

    public fetchTotalUsageToday(): void {
        this.thingService.totalUsage(this.thingId,
            moment.tz(this.timezone).startOf('day'),
            moment.tz(this.timezone).endOf('day')).subscribe(
            (response: TotalUsage) => {
                this.totalUsageToday = response;
                this.waterCostPerLitre = response.water_cost_per_litre;
            }
        );
    }

    private updateTotalUsageToday(message: SonicTelemetryMessage): void {
        const litres = this.historicDataService.convertWaterFlowToConsumption(message.water_flow);
        this.totalUsageToday.total_usage += litres;
        this.totalUsageToday.estimated_cost += this.waterCostPerLitre * litres;
    }

    private _handleTelemetryMessage(message: SonicTelemetryMessage): void {
        if (this.statusCheck) {
            this.statusCheck.unsubscribe();
        }
        ;
        if (message.device_id === this.thingId) {
            this.telemetryStatus.emit(false);
            this.statusCheck = timer(this.telemetryTimeout)
                .subscribe(() => {
                    this.telemetryStatus.emit(true);
                });
            this.telemetry = message;
            this.updateTotalUsageToday(message);
        }
    }
}
