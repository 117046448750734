<div class="card p-0" *ngIf="jobExecutionDetails">
    <div class="card-header">
        Job Execution Details
    </div>
    <div class="card-body row">
        <div class="col-lg-12">
            <div><strong>Job id:</strong> <span class="btn btn-link p-0 b-0"
                                                (click)="onJobId()">{{jobExecutionDetails.jobId}}</span></div>
            <div><strong>Status:</strong> {{jobExecutionDetails.status}}</div>
            <div>
                <div class="m-0"><strong>Details:</strong></div>
                <div class="ml-2" *ngFor="let detail of getDetailsMap()"><strong>{{detail[0]}}</strong>: {{detail[1]}}
                </div>
            </div>
            <div><strong>Last updated at: </strong> {{jobExecutionDetails.lastUpdatedAt * 1000 | date: 'yyyy-MM-dd HH:mm:ss' : 'UTC'}}</div>
            <div><strong>Queued at: </strong> {{jobExecutionDetails.queuedAt * 1000 | date: 'yyyy-MM-dd HH:mm:ss' : 'UTC'}}</div>
            <div><strong>Started at: </strong> {{jobExecutionDetails.startedAt * 1000 | date: 'yyyy-MM-dd HH:mm:ss' : 'UTC'}}</div>
            <div><strong>Execution number: </strong> {{jobExecutionDetails.executionNumber}}</div>
            <div><strong>Version number: </strong> {{jobExecutionDetails.versionNumber}}</div>
            <div><strong>Thing arn: </strong><span class="btn btn-link p-0 b-0"
                                                   (click)="onThingId(getThingId())">{{getThingId()}}</span></div>
        </div>
    </div>
</div>
