import { Component, OnInit } from '@angular/core';
import { MonitoringService } from '../../providers/services/monitoring-service';
import * as moment from 'moment';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../../src/environments/environment';

@Component({
  selector: 'app-devices-report',
  templateUrl: './devices-report.component.html',
  styleUrls: ['./devices-report.component.scss']
})
export class DevicesReportComponent implements OnInit {

    public message = 'This process can take a long time. Closing this popup will not stop the download. <b>You can continue using the admin dashboard - as long as you do not close the tab, the download will not be interrupted.</b>';
    public showSpinner = true;
    private messageAfterSuccessfulDownload = 'Download completed.';
    private messageAfterFailedDownload = 'Download failed. ';

    constructor(private monitoringService: MonitoringService,
                private bsModalRef: BsModalRef,
                private toastrService: ToastrService) { }

    ngOnInit(): void {
        this.downloadReport();
    }

    public onClose(): void {
        this.bsModalRef.hide();
    }

    public downloadReport(): void {
        this.monitoringService.getDevicesData().subscribe(
            data => {

                const blob = new Blob([data], {type: 'csv;charset=utf-8'});
                const downloadLink = document.createElement('a');
                downloadLink.href = window.URL.createObjectURL(blob);
                downloadLink.download = (`${moment().format('YYYY-MM-DD')}.csv`);
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);

                this.message = this.messageAfterSuccessfulDownload;
                this.showSpinner = false;
                this.toastrService.success('Download completed.', 'Download.', environment.toastSettings);
            },
            error => {
                this.message = this.messageAfterFailedDownload + error.statusText;
                this.showSpinner = false;
                this.toastrService.error( `An error has ocured while downloading data.`, 'Error.', environment.toastSettings);
            }
        );
    }

}
