<div class="modal-content">
	<div class="modal-header">
		<h4 class="modal-title"> Edit status </h4>
		<button type="button"
				class="close"
				aria-label="Close"
				mat-icon-button
				(click)="onCancel()">
			<mat-icon>close</mat-icon>
		</button>
	</div>
	<div class="modal-body">
		<div class="row mb-3">
			<div>Do you want to change {{statusType}} status to {{!statusValue}}?</div>
		</div>
	</div>
	<div class="modal-footer">
		<button type="button"
				class="btn btn-secondary"
				(click)="onCancel()">Close
		</button>
		<button type="button"
				class="btn btn-primary"
				(click)="onConfirm(statusType)">Save changes
		</button>
	</div>
</div>
