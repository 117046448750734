import {Component, OnInit} from '@angular/core';
import {IncidentResource} from '../../../providers/resources/incident.resource';
import {ActivatedRoute} from '@angular/router';
import {first, map, switchMap, tap} from 'rxjs/operators';
import {BehaviorSubject, Observable} from 'rxjs';
import {IncidentLog} from '../../../providers/resources/dto/IncidentLogReponse';
import {IncidentDetails} from '../../../_internal/incident';
import {incidentColor, incidentsTypes} from '../config';
import * as moment from 'moment';
import * as momentDurationFormatSetup from 'moment-duration-format';
import {PropertyService} from '../../../providers/services/property.service';
import * as _ from 'lodash';
import {BsModalService} from 'ngx-bootstrap/modal';
import {ConfirmationDialogComponent} from '../../../../common/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import {ToastrService} from 'ngx-toastr';
import {environment} from '../../../../../../src/environments/environment';

@Component({
    selector: 'app-incident-details',
    styleUrls: ['./incident-details.component.scss'],
    templateUrl: 'incident-details.component.html'
})

export class IncidentDetailsComponent implements OnInit {
    public incidentId: number;
    public incidentStream: Observable<IncidentDetails>;
    public incidentsTypes = incidentsTypes;
    public incidentColor = incidentColor;
    private incidentLogsSubject = new BehaviorSubject<IncidentLog[]>(null);

    constructor(private incidentResource: IncidentResource,
                private propertyService: PropertyService,
                private route: ActivatedRoute,
                private modalService: BsModalService,
                private toastrService: ToastrService) {
    }

    ngOnInit() {
        momentDurationFormatSetup(moment);
        this.fetchPage();
    }

    public resolveIncident() {
        this.modalService.show(ConfirmationDialogComponent, {
            initialState: {
                message: `Are you sure you want to resolve this incident?`,
                title: 'Incident',
                button: 'Resolve incident'
            }
        });
        this.modalService.onHide
            .pipe(first())
            .subscribe((reason) => {
                if (reason !== 'action-confirmed') {
                    return;
                }
                this.propertyService.resolveIncident(this.incidentId).subscribe(
                    response => {
                        this.fetchPage();
                        this.toastrService.success('The incident has been resolved', 'Incident.', environment.toastSettings);
                    },
                    err => this.toastrService.error('An error has ocured. The incident has not been resolved. Error code: ' + err.status, 'Incident.', environment.toastSettings)
                );
            });
    }

    public fetchPage() {
        this.route.params.pipe(
            tap(params => {
                this.incidentStream = this.incidentResource.getIncident(params.id)
                    .pipe(
                        map((incidentResponse) => incidentResponse.incident));
            }),
            switchMap((params) => {
                this.incidentId = params.id;
                return this.incidentResource.findById(params.id);
            })
        )
            .subscribe((res) => this.incidentLogsSubject.next(res.incident_logs));
    }

    public getIncidentLogs(): Observable<IncidentLog[]> {
        return this.incidentLogsSubject.asObservable().pipe(
            map(res => _.orderBy(res, ['inserted_at'], ['asc'])),
        );
    }

    public formatDuration(duration: number): string {
        return (moment.duration(duration, 'millisecond') as momentDurationFormatSetup).format('m');
    }

    hasPayload(incident: IncidentDetails): boolean {
        return !_.isEmpty(incident.payload);
    }
}
