import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-information-dialog',
  templateUrl: './information-dialog.component.html',
  styleUrls: ['./information-dialog.component.scss']
})
export class InformationDialogComponent {

  @Input() modalTitle: string;
  @Input() modalContent: string;

  constructor(private _bsModalRef: BsModalRef) { }

  public onCancel(): void{
    this._bsModalRef.hide();

  }

}
