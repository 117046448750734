import {Injectable} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {OptionalHttpParams} from '../../../common/api/CrudAware';
import {Observable} from 'rxjs';
import {ShadowLogResponse} from '../../_internal/shadow';
import {ShadowResource} from '../resources/shadow.resource';

@Injectable()
export class ShadowService {

    constructor(private resource: ShadowResource) {
    }

    public getLogs(params: HttpParams | OptionalHttpParams): Observable<ShadowLogResponse> {
        return this.resource.getLogs(params);
    }
}
