<app-dashboard-statistics></app-dashboard-statistics>
<c-row>
	<c-col lg="6">

		<app-logs-list [tabTitle]="'Latest activity'"></app-logs-list>
	</c-col>
	<c-col lg="6">
		<c-card>
			<c-card-header>
				<i class="fa fa-map"></i> Properties
			</c-card-header>
			<c-card-body>
				<div class="properties-map">
					<c-row>
						<c-col *ngIf="properties">
							<app-properties-map [properties]="properties">
							</app-properties-map>

						</c-col>
					</c-row>
					<!-- <div class="col-lg-5 map-sidebar-nav">
						<app-map-sidebar [properties]="properties" (hasFocus)="focusElement($event)"></app-map-sidebar>						</div> -->
				</div>
			</c-card-body>
		</c-card>
	</c-col>
</c-row>
