import {Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {BehaviorSubject, forkJoin, fromEvent, Observable, Subscription} from 'rxjs';
import {ThingResource} from '../../../providers/resources/thing.resource';
import {HubResource} from '../../../providers/resources/hub.resource';
import {Thing} from '../../../_internal/thing';
import {Hub} from '../../../_internal/hub';
import * as _ from 'lodash';
import {ThingGroupService} from '../../../providers/services/thing-group.service';
import {ThingGroupDevice} from '../../../providers/resources/dto/thing-group.response';
import {FormControl} from '@angular/forms';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';

@Component({
    selector: 'app-thing-group-add',
    templateUrl: './thing-group-add.component.html',
    styleUrls: ['./thing-group-add.component.scss']
})
export class ThingGroupAddComponent implements OnInit, OnDestroy {
    @ViewChild('devicesSearchInput') devicesSearchInput: ElementRef;
    public devicesSearchControl = new FormControl('');
    private things: Thing[];
    private hubs: Hub[];
    private hubsBehaviorSubject: BehaviorSubject<Hub[]> = new BehaviorSubject([]);
    private thingsBehaviorSubject: BehaviorSubject<Thing[]> = new BehaviorSubject([]);
    private page = 1;
    private perPage = 999999;
    private inProgress = false;
    private devicesInputDebounceTime = 400;
    private devicesTimeoutSubscription: Subscription;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                private dialogRef: MatDialogRef<ThingGroupAddComponent>,
                private thingResource: ThingResource,
                private hubResource: HubResource,
                private thingGroupService: ThingGroupService) {
    }

    ngOnInit() {
        this.fetchHubsAndThings();
        this.setUpSearch();
    }

    ngOnDestroy() {
        this.devicesTimeoutSubscription.unsubscribe();
        this.hubsBehaviorSubject.complete();
        this.thingsBehaviorSubject.complete();
    }

    public getThings(): Observable<Thing[]> {
        return this.thingsBehaviorSubject.asObservable();
    }

    public getHubs(): Observable<Hub[]> {
        return this.hubsBehaviorSubject.asObservable();
    }

    public onAdd(id: string): void {
        if (this.inProgress) {
            return;
        }
        this.inProgress = true;
        this.thingGroupService.addThingToGroup({
            group_id: this.data.group,
            thing_name: id,
        }).subscribe(() => {
            this.dialogRef.close();
        });
    }

    public resetSearch(): void {
        this.devicesSearchControl.reset('');
        this.search();
    }

    public setUpSearch(): void {
        this.devicesTimeoutSubscription = fromEvent(this.devicesSearchInput.nativeElement, 'keyup')
            .pipe(
                debounceTime(this.devicesInputDebounceTime),
                distinctUntilChanged()
            )
            .subscribe(() => {
                this.search();
            });
    }

    public search(): void {
        let device_type = null;
        if (this.data.groupDeviceList.length > 0) {
            device_type = this.data.groupDeviceList[0].type;
        }
        if (_.isNull(device_type) || device_type === 'smart_valve') {
            this.thingsBehaviorSubject.next(_.filter(this.things, (thing) => {
                if (_.includes(thing.id, this.devicesSearchControl.value) ||
                    _.includes(thing.type, this.devicesSearchControl.value) ||
                    _.includes(thing.name, this.devicesSearchControl.value) ||
                    _.includes(thing.internal_name, this.devicesSearchControl.value)) {
                    return thing;
                }
            }) as Thing[]);
        }
        if (_.isNull(device_type) || device_type === 'hub') {
            this.hubsBehaviorSubject.next(_.filter(this.hubs, (hub) => {
                if (_.includes(hub.id, this.devicesSearchControl.value) ||
                    _.includes(hub.type, this.devicesSearchControl.value) ||
                    _.includes(hub.name, this.devicesSearchControl.value)) {
                    return hub;
                }
            }) as Hub[]);
        }
    }

    private fetchHubsAndThings(): void {
        forkJoin([this.thingResource.getThingsList(this.parameters()), this.hubResource.getMany(this.parameters())])
            .subscribe(([thingsResponse, hubsResponse]) => {
                this.hubs = _.differenceWith(hubsResponse.hubs, this.data.groupDeviceList, (arrVal: Hub, othVal: ThingGroupDevice) => arrVal.id === othVal.id);
                this.things = _.differenceWith(thingsResponse.things, this.data.groupDeviceList,
                    (arrVal: Thing, othVal: ThingGroupDevice) => arrVal.id === othVal.id);
                this.search();
            });
    }

    private parameters(): { [param: string]: string | string[] } {
        const params: { [param: string]: string | string[] } = {};
        _.set(params, 'page', this.page);
        _.set(params, 'page_size', this.perPage);
        return params;
    }
}
