import { Component, Input, OnInit } from '@angular/core';
import {Thing} from '../../_internal/thing';
import {BsModalRef} from 'ngx-bootstrap/modal';
import { IncidentService } from '../../providers/services/incident.service';
import { fakeIncidentsPayload } from '../../providers/resources/dto/incidents.response';
import { filterFields, incidentsTypes } from '../../pages/incidents/config';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { environment } from '../../../../../src/environments/environment';

@Component({
  selector: 'app-fake-events',
  templateUrl: './fake-events.component.html',
  styleUrls: ['./fake-events.component.scss']
})
export class FakeEventsComponent implements OnInit{

  @Input() thing: Thing;
  public incidentType: string;
  public severityLevel: string;
  public incidentMessage: string;
  public severityLevels = ['low', 'high'];
  public incidentsTypes: string[];
  public IncidentsValues: string[];

  constructor(private incidentService: IncidentService,
              private _bsModalRef: BsModalRef,
              private toastr: ToastrService) { }

  ngOnInit(): void {
    this.incidentsTypes = _.map(incidentsTypes, (exp) => exp.label);
    this.IncidentsValues = Object.keys(incidentsTypes);
  }

  public onCancel(): void {
    this._bsModalRef.hide();
  }

  public onConfirm(): void {
    const apiCallPayload: fakeIncidentsPayload = {
      severity: this.severityLevel,
      thing_id: this.thing.id,
      type: this.incidentType,
    };

    if (this.incidentMessage){
      apiCallPayload['payload'] = {text: 'This is fake incident, and it was created in admin dashboard.'};
    };

    this.incidentService.sendFakeIncident(apiCallPayload).subscribe(
      response => this.toastr.success('A fake incident has been sent.', 'Fake incident.', environment.toastSettings),
      err => this.toastr.error( 'An error has ocured. The fake incidents haven\'t been created. Error code: ' + err.status , 'Fake incident.', environment.toastSettings)
    );

    this._bsModalRef.hide();
  }
}
