import {User} from '../../../_internal/user';
import {UsersResponse} from '../../../providers/resources/dto/users.response';
import {UserResource} from '../../../providers/resources/user.resource';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {UserEditComponent} from '../user-edit/user-edit.component';
import {HeroConfig} from '../../../hero-config';
import {FormBuilder} from '@angular/forms';
import * as _ from 'lodash';
import {HttpParams} from '@angular/common/http';
import {RoleResource} from '../../../providers/resources/role.resource';
import {Role} from '../../../_internal/role';
import {tableSettings} from 'app/hero-labs/_internal/table-settings';
import {OptionalHttpParams} from '../../../../common/api/CrudAware';
import {TableComponent} from '../../../components/table/table.component';
import {usersFilterFields} from './filter-fields';
import {first} from 'rxjs/operators';

@Component({
    selector: 'app-users-list',
    templateUrl: './user-list-page.component.html',
    styleUrls: ['./user-list-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserListPageComponent implements OnInit {
    @ViewChild(TableComponent) table: TableComponent;
    @ViewChild('rolesColumn') rolesColumn: TemplateRef<any>;
    @ViewChild('activeColumn') activeColumn: TemplateRef<any>;
    @ViewChild('actionsColumn') actionsColumn: TemplateRef<any>;
    public users: User[];
    public modalRef: BsModalRef;
    public page = 1;
    public itemsPerPage = HeroConfig.itemsPerPage;
    public roles: Role[];
    public filterFields = usersFilterFields;
    public rows: User[];
    public columns = [];n;
    public showTable = false;
    public tableSettings = _.cloneDeep(tableSettings);

    constructor(private userResource: UserResource,
                private roleResource: RoleResource,
                private modalService: BsModalService,
                private fb: FormBuilder,
                private cdRef: ChangeDetectorRef) {
    }

    ngOnInit() {
        _.set(this.tableSettings, 'order_by', 'created_at');
        this.fetchRoles();
    }


    public fetchPage(params?: OptionalHttpParams): void {
        let newParams = new HttpParams();
        _.forIn(params, (value, key) => {
            if (Array.isArray(value)) {
                _.forEach(value, (arrItem) => {
                    newParams = newParams.append(`${key}[]`, arrItem);
                });
            } else {
                newParams = newParams.append(key, value.toString());
            }
        });

        this.userResource.getUsersList(newParams)
                .subscribe((response: UsersResponse) => {
                    this.table.setPageParams(response);
                    this.rows = response.users;
                    this.table.isLoading = false;
                    this.cdRef.detectChanges();
                });
    }

    public deleteUser(user: User): void {
        if (window.confirm(`Are you sure you want to delete ${user.full_name}?`)) {
            this.userResource.deleteUser(user.id).subscribe(
                    () => {
                        this.table.refresh();
                    }
            );
        }
    }

    public openCreateModal(): void {
        this.modalRef = this.modalService.show(UserEditComponent, {
            initialState: {
                user: null,
                action: 'create'
            }
        });
        this.modalRef.onHide
            .pipe(first())
            .subscribe(
                (value) => {
                    this.table.refresh();
                }
        );
    }

    private fetchRoles(): void {
        this.roleResource.getRoleList()
                .subscribe((roles) => {
                    if (!_.find(this.filterFields, (item) => item.name === 'by_roles')) {
                        const roleOptions = [];
                        _.forEach(roles, (role) => {
                            roleOptions.push({
                                label: role.name,
                                value: role.id
                            });
                        });

                        this.filterFields.push({
                            multiple: true,
                            name: 'by_roles',
                            label: 'Roles',
                            options: roleOptions
                        });
                    }
                    this.showTable = true;
                    this.cdRef.detectChanges();
                    this.columns = [
                        {prop: 'email', name: 'Email', flexGrow: 0.5},
                        {prop: 'first_name', name: 'First name', flexGrow: 0.5},
                        {prop: 'last_name', name: 'Last name', flexGrow: 0.5},
                        {prop: 'phone', name: 'Phone', flexGrow: 0.5},
                        {prop: 'active', name: 'Active', flexGrow: 0.3, cellTemplate: this.activeColumn},
                        {prop: 'roles', name: 'Roles', flexGrow: 0.7, cellTemplate: this.rolesColumn, sortable: false},
                        {prop: 'created_at', name: 'Created at', flexGrow: 0.5, cellTemplate: this.table.dateColumn},
                        {name: '', flexGrow: 0.8, cellTemplate: this.actionsColumn}
                    ];
                });
    }
}
