import {NgModule} from '@angular/core';
import {AvailableFirmwareListComponent} from './list/available-firmware-list.component';
import {CommonModule} from '@angular/common';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {CommonDialogsModule} from '../../../common/components/dialogs/common-dialogs.module';
import {DataTableModule} from '../../../common/components/data-table/data-table.module';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';


@NgModule({
    imports: [
        CommonModule,
        CommonDialogsModule,
        TooltipModule,
        DataTableModule,
        MatIconModule,
        MatButtonModule,
    ],
    exports: [
        AvailableFirmwareListComponent
    ],
    declarations: [
        AvailableFirmwareListComponent
    ]
})
export class FirmwareTriggerModule {
}
