<div class="row">
	<div class="col-12">
		<div class="btn-group mb-4" *ngIf="spaceId">
			<button class="btn btn-sm btn-primary" routerLink="/properties/{{propertyId}}">Go back to property</button>
		</div>
	</div>
	<div class="col-lg-4">
		<div class="card mb-4"
			 *ngIf="space">
			<div class="card-header">
				<i class="fa fa-align-justify"></i> {{space.name}}
				<span class="float-end">
          <i class="fa icons icon-pencil"
			 [ngStyle]="{'cursor': 'pointer'}"
			 (click)="openEditDialog(space)"></i>
        </span>

			</div>
			<div class="card-body">
				<dl class="dl-horizontal">

					<dt>ID:</dt>
					<dd>
						{{space.id}}
					</dd>
					<dt>Is default?:</dt>
					<dd>
						{{space.default ? 'Yes' : 'No'}}
					</dd>


				</dl>
			</div>
		</div>
	</div>
	<div class="col-lg-8">


		<app-appliance-list [spaceId]="spaceId"
							[pageSize]="pageSize"></app-appliance-list>
	</div>
</div>
