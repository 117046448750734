import { AfterViewInit, ChangeDetectorRef, Component, Input, TemplateRef, ViewChild } from '@angular/core';
import {OptionalHttpParams} from '../../../common/api/CrudAware';
import {TableComponent} from '../table/table.component';
import {BehaviorSubject, Observable} from 'rxjs';
import * as _ from 'lodash';
import {tableSettings} from '../../_internal/table-settings';
import { DevicePressureTestResult, DevicePressureTestResponse } from '../../providers/resources/dto/pressure-test-response';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { InformationDialogComponent } from '../../../common/components/dialogs/information-dialog/information-dialog.component';
import { ManufacturingService } from '../..//providers/services/manufacturing.service';
import { HeroConfig } from '../../hero-config';
import { deviceFilter} from '../../pages/manufacturing/manufacturing-config';
import {JsonDialogComponent} from '../../../common/components/dialogs/json-modal/json-dialog.component';
import { PressureReport } from '../../_internal/pressure-test';

@Component({
  selector: 'app-pressure-test-table',
  templateUrl: './pressure-test-table.component.html',
  styleUrls: ['./pressure-test-table.component.scss']
})
export class PressureTestTableComponent implements AfterViewInit {

    @Input() showSearchBar = true;
    @Input() showDeviceLink = true;
    @ViewChild(TableComponent) table: TableComponent;
    @ViewChild('dateColumn') dateColumn: TemplateRef<any>;
    @ViewChild('actionsColumn') actionsColumn: TemplateRef<any>;
    @ViewChild('resultsPassedColumn') resultsPassedColumn: TemplateRef<any>;

    public columns = [];
    public rows: DevicePressureTestResult[];
    public tableSettings = _.cloneDeep(tableSettings);
    public modalRef: BsModalRef;
    public deviceFilter = deviceFilter;

    constructor(private cdRef: ChangeDetectorRef,
                private manufacturingService: ManufacturingService,
                private modalService: BsModalService) { }

    @Input()
    public getData: (params?: OptionalHttpParams) => Observable<DevicePressureTestResponse> = (params?: OptionalHttpParams) => this.manufacturingService.getPressureTestReports(params);
    public ngAfterViewInit(): void {
    this.columns = [
        {prop: 'serial_no', name: 'Serial no', flexGrow: 1, sortable: false, cellTemplate: this.table.codeColumn},
        {cellTemplate: this.dateColumn, prop: 'created_at', name: 'Created at', flexGrow: 1, sortable: false},
        {prop: 'result.socket_no', name: 'Socket number', flexGrow: 1, sortable: false},
        {
          cellTemplate: this.resultsPassedColumn,
          prop: 'result.result',
          name: 'Test passed?',
          flexGrow: 1,
          sortable: false
      },
        {
            cellTemplate: this.actionsColumn, name: 'Actions', flexGrow: 1, sortable: false
        }
    ];
  }

  public fetchDevice(params: OptionalHttpParams) {
    const serialNo = _.get(params, 'serial_no');
    if (!serialNo) {
        this.table.refresh();
        return;
    }
    this.manufacturingService.getPressureTestReportsBySerial(serialNo.toString())
        .subscribe((device) => {
          this.table.setPageParams({
            page_number: 1,
            page_size: HeroConfig.itemsPerPage,
            total_entries: device.total_entries,
            total_pages: device.total_pages
        });
            this.rows = [... device.data];
            this.table.isLoading = false;
            this.cdRef.detectChanges();
        }, () => {
            this.table.setPageParams({
                page_number: 1,
                page_size: HeroConfig.itemsPerPage,
                total_entries: 0,
                total_pages: 1
            });
          },
      );
  }

  public fetchPage(params?: OptionalHttpParams): void {
    _.unset(params, 'order_by');
    _.unset(params, 'order_strategy');
    this.getData(params)
    .subscribe((response: DevicePressureTestResponse) => {
            this.table.setPageParams(response);
            this.rows = response.data;
            this.table.isLoading = false;
        });
  }

  public manufacturingFeedbackModal( feedback: string) {
    this.modalRef = this.modalService.show(InformationDialogComponent, {
        initialState: {
            modalTitle: 'Feedback',
            modalContent: feedback,
        }
    });
  }

  public openFullReportModal(report: PressureReport) {
    this.modalService.show(JsonDialogComponent, {
        initialState: {
            content: report.result,
            title: `Generated at: ${report.created_at} Serial no: ${report.serial_no}, Socket number: ${report.result.socket_no}`,
        },
        class: 'modal-xl'
    });
}

  public reportPassed(result): boolean {
    return result === 'passed';
  }

}
