import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    TemplateRef,
    ViewChild
} from '@angular/core';
import {Hub} from '../../../_internal/hub';
import {FilterInput} from '../../../../common/components/filters/filter-fields.interface';
import {HubService} from '../../../providers/services/hub.service';
import * as _ from 'lodash';
import {OptionalHttpParams} from '../../../../common/api/CrudAware';
import {HubResource} from '../../../providers/resources/hub.resource';
import {HubsResponse} from '../../../providers/resources/dto/hub.response';
import {TableComponent} from '../../../components/table/table.component';
import {tableSettings} from '../../../_internal/table-settings';
import {hubsFilterFields} from './filter-config';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../../environments/environment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DevicesReportComponent } from '../../../components/devices-report/devices-report.component';
@Component({
    selector: 'app-hub-list',
    templateUrl: './hub-list.component.html',
    styleUrls: ['./hub-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HubListComponent implements AfterViewInit {
    @ViewChild(TableComponent) table: TableComponent;
    @ViewChild('onlineColumn') onlineColumn: TemplateRef<any>;
    @ViewChild('actionsColumn') actionsColumn: TemplateRef<any>;
    @ViewChild('mainTable', {static: true}) mainTable: TableComponent;
    public filterFields = hubsFilterFields;
    public tableSettings = _.cloneDeep(tableSettings);
    public rows: Hub[];
    public columns = [];
    public filters: FilterInput[] = [
        {name: 'Type', value: 'type'},
        {name: 'Serial number', value: 'serial_no'},
        {name: 'Status', value: 'status'},
    ];

    constructor(private hubService: HubService,
                private router: Router,
                private toastrService: ToastrService,
                private hubResource: HubResource,
                private cdRef: ChangeDetectorRef,
                private modalService: BsModalService) {
    }

    public ngAfterViewInit(): void {
        this.columns = [
            {prop: 'id', name: 'ID', flexGrow: 1, sortable: false, cellTemplate: this.table.codeColumn},
            {prop: 'name', name: 'Name', flexGrow: 1, sortable: false},
            {prop: 'type', name: 'Type', flexGrow: 0.5, sortable: false},
            {prop: 'serial_no', name: 'Serial No', flexGrow: 0.7, sortable: false, cellTemplate: this.table.codeColumn},
            {prop: 'space_id', name: 'Space id', flexGrow: 1, sortable: false, cellTemplate: this.table.codeColumn},
            {prop: 'online', name: 'Status', flexGrow: 0.5, sortable: false, cellTemplate: this.onlineColumn},
            {
                cellTemplate: this.actionsColumn, name: 'Actions', flexGrow: 1, sortable: false
            }
        ];

    }

    public fetchPage(params?: OptionalHttpParams): void {
        params = _.omit(params, ['order_by', 'order_strategy']);
        this.hubResource.getMany(params)
                .subscribe((response: HubsResponse) => {
                    this.table.setPageParams(response);
                    this.rows = response.hubs;
                    this.table.isLoading = false;
                    this.cdRef.detectChanges();
                });
    }

    public getRouterUrl(hub: Hub): string{
        if(hub.type === 'hub' || hub.type === 'signal_v2'){
            return `/hubs/${hub.id}`;
        }
        return `/things/wifi/${hub.id.slice(0,-1) + 1}`;
    }

    public triggerDevicesReportDownload(): void {
        this.modalService.show(DevicesReportComponent);
    }
}
