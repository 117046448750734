<div class="card mb-4">
    <div class="card-header">
        <i class="fa fa-home"></i> {{ tabTitle }}
    </div>
    <div class="card-body"
         *ngIf="properties">

        <div class="card mb-2"
             *ngFor="let property of properties">
            <div class="card-body p-2 clearfix">
                <c-row class="align-items-center">
                    <c-col lg="auto">
                        <i class="icons icon-home bg-primary p-3 font-2xl me-3 float-left"></i>
                    </c-col>
                    <c-col>
                        <c-badge *ngIf="property.ownership"
                                 class="float-end"
                                 color="success">
                            Ownership
                        </c-badge>
                        <div class="h5 text-primary mb-0 mt-2">{{ property.name }}</div>
                        <div class="text-secondary text-uppercase font-weight-bold font-xs">
                            {{ property.address_1 }} {{ property.address_2 }} {{ property.address_3 }}
                        </div>
                        <div class="text-secondary text-uppercase font-weight-light font-xs">{{ property.postcode }}
                        </div>
                    </c-col>
                </c-row>

            </div>
            <div class="card-footer px-3 py-2">
                <a class="font-weight-bold font-xs btn-block text-secondary pointer"
                   [routerLink]="['/properties',property.id]">View
                    More <i class="fa fa-angle-right float-end font-lg"></i></a>
            </div>
        </div>

    </div>
</div>
