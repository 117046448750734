<div class="card-body">
    <div class="row"
        *ngIf="events; else noEvents">
        <div class="col-12">
            <div class="chart-box">
                <div echarts
                     [options]="chartsOption"></div>
            </div>
        </div>
    </div>
    <ng-template #noEvents>
        <div class="row">
            <div class="col-12">
                No data
            </div>
        </div>
    </ng-template>
</div>
