<div class="card mb-4">
	<div class="card-header">
		<i class="fa fa-align-justify"></i> Parent and simple events
	</div>
	<div class="card-body"
		 *ngIf="applianceCategories">
		<div class="row">
			<div class="col-lg-3">

				<div class="row mb-3"
					 *ngIf="date">

					<div class="col-lg-6">
						<label>From</label>
						<input class="form-control"
							   type="time"
							   [(ngModel)]="startHour"
							   (blur)="onRangeChange()">
					</div>
					<div class="col-lg-6">
						<label>To</label>
						<input class="form-control"
							   type="time"
							   [(ngModel)]="endHour"
							   (blur)="onRangeChange()">
					</div>
					<div class="col-lg-6 mt-2">
						<label>Device Id</label>
						<input class="form-control"
							   type="text"
							   [(ngModel)]="deviceId"
							   (change)="onRangeChange()">
					</div>
					<div class="col-lg-6 mt-2">
						<div class="form-group">
							<label>Water consumed </label>
							<input type="number"
								   class="form-control"
								   [(ngModel)]="eventsFilter.value">
						</div>
					</div>
				</div>

				<bs-datepicker-inline [bsValue]="datepickerInitialValue"
									  (bsValueChange)="onDatePick($event)"
									  [bsConfig]="{containerClass: 'theme-blue'}"></bs-datepicker-inline>

				<div class="card-body">
					<ul class="list-group list-group-flush">
						<li class="list-group-item"
							*ngFor="let category of applianceCategories">
							<i class="hl-icon hl-icon-{{category.icon_url}}"></i> - {{category.name}}
						</li>
					</ul>
				</div>

			</div>
			<div class="col-lg-9"
				 *ngIf="getEventStream() | async;">
				<div class="text-center">
					<div class="btn-group mb-3">
						<button (click)="onFilterLabelChange('everything')"
								class="btn btn-{{notLabelledAt === 'everything' ? '' : 'outline-'}}primary btn-md">
							All
						</button>
						<button (click)="onFilterLabelChange('appliance_category_ids')"
								class="btn btn-{{notLabelledAt === 'appliance_category_ids' ? '' : 'outline-'}}primary btn-md">
							Not labelled by a user
						</button>
						<button (click)="onFilterLabelChange('predicted_appliance_category_ids')"
								class="btn btn-{{notLabelledAt === 'predicted_appliance_category_ids' ?  '' : 'outline-'}}primary btn-md">
							Without prediction
						</button>
						<button (click)="onFilterLabelChange('overrides')"
								class="btn btn-{{notLabelledAt === 'overrides' ?  '' : 'outline-'}}primary btn-md">
							Unoverridden
						</button>
					</div>
				</div>

				<app-data-table [dataStream]="getEventStream()"
								[displayedColumns]="displayedColumns"
								[filter]="eventsFilter"
								[settings]="{hasAction: true, customClass: 'text-center'}">
					<ng-template let-event
								 let-index="index"
								 let-dataSet="dataSet">
						<span class="btn-toolbar text-center well">
							<button type="button"
									cButton
									placement="bottom"
                                    container="body"
									(click)="goTo(['properties', event.property_id])"
									tooltip="Property: {{event.property_id}}"
									class="btn btn-secondary btn-sm">
								<i class="icon icon-home"></i>
							</button>
							<button type="button"
									cButton
									placement="bottom"
									(click)="goTo(['things', event.device_id])"
                                    container="body"
									tooltip="Device: {{event.device_id}}"
									class="btn btn-secondary btn-sm">
								<i class="icon icon-shield"></i>
							</button>
							<button type="button"
                                    cButton
                                    *ngIf="canViewEventDetails(event)"
                                    (click)="goToEvent(event.event_start, event.device_id, index, dataSet)"
                                    class="btn btn-secondary btn-sm">
								<i class="fa fa-arrow-right"></i>
							</button>
						</span>
					</ng-template>
				</app-data-table>

				<div class="text-center">
					<button *ngIf="lastEvaluatedKey"
							(click)="loadMore()"
							class="btn btn-secondary btn-sm"> Load more
					</button>
				</div>

			</div>
		</div>
	</div>
</div>
