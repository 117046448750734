<div class="event-navigation mb-2">
	<div class="row">
		<div class="col-lg-6">
			<button class="btn-link btn"
					(click)="goToEventList()">
				<i class="fa fa-arrow-left me-1"></i>
				Back to event list
			</button>
		</div>
		<div class="col-lg-6 text-right"
			 *ngIf="nextEvents && nextEvents.length">
			<button class="btn btn-link"
					(click)="goToNextEvent()">
				Go to next event({{nextEvents.length}})
				<i class="fa fa-arrow-right ml-1"></i>
			</button>
		</div>
	</div>
</div>

<div class="card mb-4"
	 *ngIf="event && appliances">
	<div class="card-header">
		<div class="float-left">
			<i class="fa fa-align-justify"></i> Water usage event recognised at <b>{{event.property_name}}
			- {{event.property_address}}</b>
		</div>
		<div class="float-end">
			Device: <a class="link"
					   (click)="goToDevice(event.device_id)">{{event.device_id}}</a>
		</div>
	</div>
	<div class="card-body">
		<app-event-widgets [event]="event"></app-event-widgets>
		<app-appliance-usage-log-list [event]="event"
									  *ngIf="event.appliance_usage_logs.length"
									  [applianceCategories]="applianceCategories"></app-appliance-usage-log-list>

		<app-event-details [applianceCategories]="applianceCategories"
						   [appliances]="appliances"
						   [event]="event"></app-event-details>


		<div class="card mt-4"
			 *ngIf="event.child_events.length">
			<div class="card-header">
				Children
			</div>
			<div class="card-body">

				<div class="row">
					<div class="col-lg-12 mb-2"
						 *ngFor="let childEvent of event.child_events">
						<app-event-details [applianceCategories]="applianceCategories"
										   [appliances]="appliances"
										   [event]="childEvent"
                                           [chartStart]="eventStart"
                                           [chartEnd]="eventEnd"></app-event-details>
					</div>
				</div>

			</div>
		</div>

	</div>
</div>
