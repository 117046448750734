<div class="card mb-4">
    <div class="card-header">
        <i class="fa fa-line-chart"></i> {{title}}

    </div>
    <div class="card-body">
        <div class="row"
             *ngIf="thingEvents || hubEvents; else noEvents">
            <div class="col">
                <mat-form-field class="w-100">
                    <mat-label>Date from</mat-label>
                    <input matInput
                           [max]="maxDateLimit"
                           [matDatepicker]="fromPicker"
                           (dateChange)="filterEvents()"
                           [formControl]="fromControl"/>

                    <mat-datepicker #fromPicker></mat-datepicker>
                    <button mat-button
                            matSuffix
                            (click)="fromPicker.open()">Open calendar
                        <mat-icon matDatepickerToggleIcon>event
                        </mat-icon>
                    </button>
                </mat-form-field>
            </div>
            <div class="col">
                <mat-form-field class="w-100">
                    <mat-label>Date to</mat-label>
                    <input matInput
                           [max]="maxDateLimit"
                           [matDatepicker]="toPicker"
                           (dateChange)="filterEvents()"
                           [formControl]="toControl"/>

                    <mat-datepicker #toPicker></mat-datepicker>
                    <button mat-button
                            matSuffix
                            (click)="toPicker.open()">Open calendar
                        <mat-icon matDatepickerToggleIcon>event
                        </mat-icon>
                    </button>
                </mat-form-field>
            </div>
            <div class="col-auto">
                <div class="btn-toolbar text-center well"
                     role="group">
                    <button type="button"
                            class="btn btn-secondary"
                            (click)="setTimeFrame(60)">60 days
                    </button>
                    <button type="button"
                            class="btn btn-secondary"
                            (click)="setTimeFrame(30)">30 days
                    </button>
                    <button type="button"
                            class="btn btn-secondary"
                            (click)="setTimeFrame(7)">7 days
                    </button>
                    <button type="button"
                            class="btn btn-secondary"
                            (click)="setTimeFrame(1)">1 day
                    </button>
                </div>
                <div class="btn-toolbar text-center well"
                     role="group">
                    <button type="button"
                            class="btn btn-secondary"
                            (click)="moveTimeFrame('back')"><i class="fa fa-chevron-left"></i></button>
                    <button type="button"
                            class="btn btn-secondary"
                            (click)="moveTimeFrame('future')"><i class="fa fa-chevron-right"></i></button>
                            <!-- <button mat-button
                            (click)="resetFilter()">
                        Reset
                    </button> -->
                </div>
            </div>
            <div class="message-overload"
                 *ngIf="responseSizeReachedMax">Replay size was limited to {{responseMaxAllowedSize}} entries and has
                reached its limit. There are more possible events.
            </div>
            <div class="message-overload"
                 *ngIf="noDataMessage">No data available for the time period.
            </div>
            <div class="col-12">
                <div class="chart-box">
                    <div echarts
                         [options]="chartsOption" (chartInit)="onChartInit($event)"></div>
                </div>
            </div>
        </div>
        <ng-template #noEvents>
            <div class="row">
                <div class="col-12">
                    No data
                </div>
            </div>
        </ng-template>
    </div>
</div>
