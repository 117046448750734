<div class="card mb-4">
	<div class="card-header">
		<i class="fa fa-align-justify"></i> Organisations

		<div class="float-end">
			<button class="btn btn-secondary btn-sm"
					(click)="openCreateModal()">Create organisation
			</button>
		</div>
	</div>
	<div class="card-body">
		<table class="table table-striped" *ngIf="organisations">
			<thead>
			<tr>
				<th>ID</th>
				<th>Name</th>
				<th>Active</th>
				<th>Action</th>
			</tr>
			</thead>
			<tbody>
			<tr *ngFor="let organisation of organisations  | paginate: { itemsPerPage: itemsPerPage, currentPage: p }"
				class="pointer"
				(click)="goToOrganisation(organisation.id)">
				<td>{{organisation.id}}</td>
				<td>{{organisation.name}}</td>
				<td>{{organisation.active}}</td>
				<td>
					<button class="btn btn-sm btn-danger"
							(click)="deleteOrganisation(organisation.id); $event.stopPropagation();">
						<i class="fa fa-trash-o"></i>
					</button>
				</td>
			</tr>
			</tbody>
		</table>

		<pagination-controls (pageChange)="p = $event"></pagination-controls>
	</div>
</div>
