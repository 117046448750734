import { HttpParams } from '@angular/common/http';
import { ChangeDetectorRef,ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { BsModalRef} from 'ngx-bootstrap/modal';
import { OptionalHttpParams } from '../../../../../common/api/CrudAware';
import { TableComponent } from '../../../../../hero-labs/components/table/table.component';
import { HeroConfig } from '../../../../../hero-labs/hero-config';
import { UsersResponse } from '../../../../../hero-labs/providers/resources/dto/users.response';
import { RoleResource } from '../../../../../hero-labs/providers/resources/role.resource';
import { Role } from '../../../../../hero-labs/_internal/role';
import {tableSettings} from '../../../../_internal/table-settings';
import { User } from '../../../../../hero-labs/_internal/user';
import { usersFilterFields } from '../../../users/user-list/filter-fields';
import {MembershipService} from '../../../../providers/services/membership.service';
import { UserService } from '../../../../../hero-labs/providers/services/user.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
})
export class UserListComponent implements OnInit {
    @ViewChild(TableComponent) table: TableComponent;
    @ViewChild('rolesColumn') rolesColumn: TemplateRef<any>;
    @ViewChild('activeColumn') activeColumn: TemplateRef<any>;
    @ViewChild('actionsColumn') actionsColumn: TemplateRef<any>;
    @Output() selected = new EventEmitter<any>();
    public page = 1;
    public itemsPerPage = HeroConfig.itemsPerPage;
    public roles: Role[];
    public filterFields = usersFilterFields;
    public rows: User[];
    public columns = [];
    public showTable = false;
    public tableSettings = _.cloneDeep(tableSettings);

    constructor(private userService: UserService,
                private roleResource: RoleResource,
                private membershipService: MembershipService,
                private cdRef: ChangeDetectorRef) {
    }
    ngOnInit(): void {
        _.set(this.tableSettings, 'order_by', 'created_at');
        this.fetchRoles();
    }

    public fetchPage(params?: OptionalHttpParams): void {
        let newParams = new HttpParams();
        _.forIn(params, (value, key) => {
            if (Array.isArray(value)) {
                _.forEach(value, (arrItem) => {
                    newParams = newParams.append(`${key}[]`, arrItem);
                });
            } else {
                newParams = newParams.append(key, value.toString());
            }
        });

        this.userService.getUsersList(newParams)
                .subscribe((response: UsersResponse) => {
                    this.table.setPageParams(response);
                    this.rows = response.users;
                    this.table.isLoading = false;
                    this.cdRef.detectChanges();
                });
    }

    public getButtonStatus(row: User): boolean {
        return !this.membershipService.massAddUsersList.includes(row.id);
    }

    public selectUser(row: User): void {
        this.membershipService.massAddUsersList.push(row.id);
    }

    public removeUser(data: User): void {
        this.membershipService.massAddUsersList = _.filter(this.membershipService.massAddUsersList, (item) => item !== data.id);
    }

    private selectPage(): void {
        for(const thing of this.rows){
            this.selectUser(thing);
        }
    }

    private fetchRoles(): void {
        this.roleResource.getRoleList()
                .subscribe((roles) => {
                    if (!_.find(this.filterFields, (item) => item.name === 'by_roles')) {
                        const roleOptions = [];
                        _.forEach(roles, (role) => {
                            roleOptions.push({
                                label: role.name,
                                value: role.id
                            });
                        });

                        this.filterFields.push({
                            multiple: true,
                            name: 'by_roles',
                            label: 'Roles',
                            options: roleOptions
                        });
                    }
                    this.showTable = true;
                    this.columns = [
                        {
                            cellTemplate: this.actionsColumn, name: 'Select', flexGrow: 1.5, sortable: false
                        },
                        {prop: 'email', name: 'Email', flexGrow: 0.5},
                        {prop: 'first_name', name: 'First name', flexGrow: 0.5},
                        {prop: 'last_name', name: 'Last name', flexGrow: 0.5},
                        {prop: 'phone', name: 'Phone', flexGrow: 0.5},
                        {prop: 'active', name: 'Active', flexGrow: 0.3, cellTemplate: this.activeColumn},
                        {prop: 'roles', name: 'Roles', flexGrow: 0.7, cellTemplate: this.rolesColumn, sortable: false},
                    ];
                    this.cdRef.detectChanges();

                });
    }
}
