import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {OptionalHttpParams} from '../../../common/api/CrudAware';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {ShadowLogResponse} from '../../_internal/shadow';

@Injectable()
export class ShadowResource {
    private readonly url: string = `${environment.apiGatewayUrl}/iot-core/admin/device_shadow_logs`;

    constructor(private http: HttpClient) {
    }

    public getLogs(params: HttpParams | OptionalHttpParams): Observable<ShadowLogResponse> {
        return this.http.get<ShadowLogResponse>(this.url, {params});
    }
}
