import { Injectable } from '@angular/core';
import { Thing } from '../../_internal/thing';

@Injectable({
  providedIn: 'root'
})
export class ValveStatesService {

    public success = ['open', 'closed'];
    public danger = ['manual_movement_after_closed', 'manual_movement_after_open', 'failed_to_open', 'failed_to_close','manual_movement'];
    public secondary = ['opening', 'closing', 'requested_open', 'requested_closed'];
  constructor() { }

  public returnValveState(thing: Thing, shorStrings = false): string {
        switch (thing.state) {
            case 'open':
                return 'Open';
            case 'closed':
                return 'Closed';
            case 'requested_open':
                return 'Requested open';
            case 'requested_closed':
                return 'Requested close';
            case 'opening':
                return 'Opening';
            case 'closing':
                return 'Closing';
            case 'manual_movement_after_open':
                if(!shorStrings){
                    return 'Valve not fully close (manual_movement_after_open)';
                }
                return 'Valve not fully close';
            case 'manual_movement_after_closed':
                if(!shorStrings){
                    return 'Valve not fully opened (manual_movement_after_closed)';
                }
                return 'Valve not fully opened';
            case 'failed_to_close':
                if(!shorStrings){
                    if(thing.battery_level === 'low'){
                        return 'Batteries too low to close the valve (failed_to_close)';
                    }
                    return 'Sonic tried to close (failed_to_close)';
                }
                if(thing.battery_level === 'low'){
                    return 'Batteries too low';
                }
                return 'Tried to close';
            case 'failed_to_open':
                if(!shorStrings){
                    if(thing.battery_level === 'low'){
                        return 'Batteries too low to open the valve(failed_to_open)';
                    }
                    return 'Sonic tried to open (failed_to_open)';
                }
                if(thing.battery_level === 'low'){
                    return 'Batteries too low ';
                }
                return 'Tried to open';
            case 'manual_movement':
                return 'Unknown (manual_movement)';
        }
        return thing.state;
    }

    public stateDanger(state: string): boolean {
        return this.danger.includes(state);
    }

    public stateSucces(state: string): boolean {
        return this.success.includes(state);
    }

    public stateSecondary(state: string): boolean {
        return this.secondary.includes(state);
    }
}

