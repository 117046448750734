<div class="card mb-4"
     *ngIf="incidentStream | async as incident">
    <div class="card-header">

        <img src="../../../../assets/img/incidents-icons/{{ incidentsTypes[incident.type].icon }}"
             class="incident-icon"
             alt=""/> Incident - <strong>{{ incident.title }}</strong>
        <button
            *ngIf="incident.status !== 'closed'"
            class="btn btn-secondary btn-sm float-end"
            color="danger"
            (click)="resolveIncident()">
            Resolve incident
            <i class="fa fa-trash-o"></i>
        </button>
    </div>
    <div class="card-body">

        <div class="row">
            <div class="col-lg-6">

                <dl class="dl-horizontal">

                    <dt>Id:</dt>
                    <dd>
                        <span class="text text-small">{{ incident.id }}</span>
                    </dd>
                    <dt>Detected at:</dt>
                    <dd>
                        <app-multi-time [date]="incident.detected_at"
                                        [showLocal]="false"
                                        [showProperty]="false"></app-multi-time>
                    </dd>
                    <dt>Thing id:</dt>
                    <dd>
                        <a routerLink="/things/{{incident.thing_id}}">{{ incident.thing_id }}</a>
                    </dd>
                    <dt>Type:</dt>
                    <dd>
                        {{ incident.type }}
                    </dd>
                    <dt>State:</dt>
                    <dd>
                        <c-badge color="success"
                                 *ngIf="incident.state === 'resolved'">{{ incident.state }}
                        </c-badge>
                        <c-badge color="warning"
                                 *ngIf="incident.state !== 'resolved'">{{ incident.state }}
                        </c-badge>
                    </dd>
                    <dt>Severity:</dt>
                    <dd>
                        <div class="row align-items-center">
                            <div class="col-auto">

                                <div class="state-indicator"
                                     [ngClass]="[incidentColor[incident.severity]]"></div>
                            </div>
                            <div class="col p-0">
                                {{ incident.severity }}
                            </div>
                        </div>
                    </dd>
                    <dt>Status:</dt>
                    <dd>
                        <c-badge color="warning"
                                 *ngIf="incident.status === 'active'">{{ incident.status }}
                        </c-badge>
                        <c-badge color="light"
                                 *ngIf="incident.status === 'closed'">{{ incident.status }}
                        </c-badge>
                    </dd>

                </dl>
            </div>
            <div class="col-lg-6">

                <ng-container *ngIf="hasPayload(incident)">
                    <h4 class="pb-3">Additional data:</h4>
                    <div class="incident-payload pb-4 mb-4"
                         [ngSwitch]="incident.type">
                        <ng-container *ngSwitchCase="'advanced_valve_misalignment'">
                            <ng-container *ngIf="incident.payload.battery_level">
                                <app-battery-feed
                                [batteryLevel]="incident.payload.battery_level"></app-battery-feed>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngSwitchCase="'backup_battery_usage'">
                            <ng-container *ngIf="incident.payload.battery_level">
                                <app-battery-feed
                                [batteryLevel]="incident.payload.battery_level"></app-battery-feed>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngSwitchCase="'low_battery_level'">
                            <ng-container *ngIf="incident.payload.power_supply">
                                <div>
                                    <span class="value">{{ incident.payload.power_supply.battery / 1000 }}</span>
                                    <span class="l-font">V</span><br/>
                                    <span class="label">Battery</span>
                                </div>
                                <div>
									<span
                                        class="value">{{ incident.payload.power_supply.battery_when_motor_on / 1000 }}</span>
                                    <span class="l-font">V</span><br/>
                                    <span class="label">Battery when motor on</span>
                                </div>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngSwitchCase="'low_water_temperature'">
                            <div>
                                                            <span
                                                                class="value">{{ incident.payload.water_temp | number : '1.1-1' }}</span>
                                <span class="l-font">&deg;C</span><br/>
                                <span class="label">Water temperature</span>
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="'health_check_failed'">
                            <div>
                                Components reporting a failure:<br/>
                                <strong><span
                                    *ngFor="let component of incident.payload.damaged_components; let last = last">{{ component }}
                                    <span *ngIf="!last">, </span></span></strong>
                            </div>

                        </ng-container>
                        <ng-container *ngSwitchCase="'long_flow_alert'">
                            <ng-container *ngTemplateOutlet="leakDetails"></ng-container>
                        </ng-container>
                        <ng-container *ngSwitchCase="'high_volume_alert'">
                            <ng-container *ngTemplateOutlet="leakDetails"></ng-container>

                        </ng-container>
                        <ng-container *ngSwitchDefault>

                        </ng-container>
                        <ng-template #leakDetails>
                            <div>
                                <span class="value">{{ incident.payload.volume | liters }}</span>
                                <span class="l-font">l</span><br/>
                                <span class="label">Volume</span>
                            </div>
                            <div>
                                <span class="value">{{ formatDuration(incident.payload.duration) }}</span>
                                <span class="l-font">min</span><br/>
                                <span class="label">Duration</span>
                            </div>
                            <div>
                                <span class="value">{{ incident.payload.max_flow | liters }}</span>
                                <span class="l-font">l/min</span><br/>
                                <span class="label">Max flow</span>
                            </div>
                        </ng-template>
                    </div>
                </ng-container>

                <h4>Description</h4>
                <div [innerHTML]="incident.description"></div>
            </div>
        </div>
    </div>
</div>
<div class="card mb-4">
    <div class="card-header">
        <i class="fa fa-list"></i> Log
    </div>
    <div class="card-body"
         *ngIf="getIncidentLogs() | async as incidents">
        <table class="table table-striped"
               *ngIf="incidents.length; else noData">
            <thead>
            <tr>
                <th>ID</th>
                <th>Inserted At</th>
                <th>Changed Attribute</th>
                <th>Value</th>
                <th>Performed By</th>
                <th>User ID</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let incident of incidents "
                class="pointer">
                <td>
                    {{ incident.incident_id }}
                </td>
                <td>{{ incident.inserted_at | date: 'yyyy-MM-dd HH:mm:ss' : 'UTC' }}</td>
                <td>
                    <c-badge color="success">{{ incident.changed_attribute }}</c-badge>
                </td>
                <td>
                    <c-badge color="secondary" class="text-strike-through"
                             tooltip="Old Value">{{ incident.old_value }}
                    </c-badge>
                    <c-badge color="primary"
                             tooltip="New Value">{{ incident.new_value }}
                    </c-badge>
                </td>
                <td>{{ incident.performed_by }}</td>
                <td>{{ incident.user_id }}</td>
            </tr>
            </tbody>
        </table>
        <ng-template #noData>
            <h4>Sorry there is no log data for given Incident</h4>
        </ng-template>
    </div>
</div>
