import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeroComponentsModule} from '../../components/hero-components.module';
import {NgxPaginationModule} from 'ngx-pagination';
import {FirmwareTriggerModule} from '../../components/firmware-trigger/firmware-trigger.module';
import {HubPagesRoutingModule} from './hub-pages-routing.module';
import {HubListComponent} from './hub-list/hub-list.component';
import {FiltersModule} from '../../../common/components/filters/filters.module';
import {DataTableModule} from '../../../common/components/data-table/data-table.module';
import {HubPageComponent} from './hub-page/hub-page.component';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {DirectivesModule} from '../../../directives/directives.module';
import {ModalModule} from 'ngx-bootstrap/modal';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {BadgeModule, ButtonGroupModule, ButtonModule} from '@coreui/angular';
import { HubEditComponent } from './hub-edit/hub-edit.component';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
    declarations: [
        HubListComponent,
        HubPageComponent,
        HubEditComponent,
    ],
    imports: [
        CommonModule,
        HeroComponentsModule,
        ModalModule.forRoot(),
        TabsModule.forRoot(),
        FirmwareTriggerModule,
        HubPagesRoutingModule,
        DataTableModule,
        FiltersModule,
        NgxDatatableModule,
        DirectivesModule,
        BadgeModule,
        ButtonModule,
        ButtonGroupModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,

    ],
    exports: [
        NgxPaginationModule,
        HubListComponent,
        HubPageComponent,
    ]
})
export class HubPagesModule {
}
