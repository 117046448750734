import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {Space} from '../../../_internal/space';
import * as _ from 'lodash';
import {SpaceService} from '../../../providers/services/space.service';
import {Subject} from 'rxjs';

@Component({
    selector: 'app-add-space',
    templateUrl: './add-space.component.html',
    styleUrls: ['./add-space.component.scss']
})
export class AddSpaceComponent implements OnInit {

    @Input() propertyId: string;
    @Input() space: Space;
    @Input() closeCallback: Subject<Space>;

    public formGroup: FormGroup;
    public incorrectInputMsg = '';
    public errors: string[];

    constructor(private modalService: BsModalService,
                private _bsModalRef: BsModalRef,
                private spaceService: SpaceService,
                private fb: FormBuilder) {
    }

    ngOnInit() {
        this.initSpace();
        this.initForm();
    }

    public onCancel(): void {
        this.modalService.setDismissReason('canceled');
        this._bsModalRef.hide();
    }

    public onConfirm(): void {
        if (this.formIsValid()) {

            if (this.space.id) {
                this.space.name = _.get(this.formGroup, 'value.name');
                this.updateSpace(this.space);
            } else {
                this.addNewSpace();
            }
        }
    }

    private initForm(): void {

        this.formGroup = this.fb.group({
            name: [this.space.name, [Validators.required]],
        });
    }

    private updateSpace(space: Space): void {
        this.spaceService.updateSpace(space)
            .subscribe(() => {
                this.modalService.setDismissReason('space-updated');
                this._bsModalRef.hide();
            }, (error) => {
                this.extractErrors(error);
            });
    }

    private addNewSpace(): void {
        this.spaceService.createSpace(this.propertyId, this.formGroup.value.name)
            .subscribe((response) => {
                this.modalService.setDismissReason('space-created');
                this.closeCallback.next(response.space);
                this._bsModalRef.hide();
            }, (error) => {
                this.extractErrors(error);
            });
    }

    private formIsValid(): boolean {
        let formValid = true;
        this.incorrectInputMsg = '';
        if (!this.formGroup.valid) {
            this.incorrectInputMsg = 'Please fill all fields';
            formValid = false;
        }
        return formValid;
    }

    private initSpace(): void {
        if (!this.space) {
            this.space = {
                name: '',
                default: false
            };
        }
    }

    private extractErrors(error: any): void {
        this.errors = _.map(_.get(error, 'error.errors'), item => item[0]);

    }
}
