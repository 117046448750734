<div class="container-fluid">
	<div class="row align-items-center py-2"
		 *ngIf="incident"
		 [ngClass]="{'border-snoozed': incident.state === 'snoozed'}">
		<div class="col-3 px-0">
			<div class="row">
				<div class="col-auto mx-auto">
					<ng-container *ngIf="incident.state === 'processing'; else notProcessing">
						<div class="spinner-wrapper">
							<div class="spinner spinner-border my-5 mx-auto d-block"
								 role="status">
							</div>
						</div>
					</ng-container>
					<ng-template #notProcessing>
						<img src="../../../../assets/img/incidents-icons/{{ recentTypes[incident.type].icon }}"
							 width="25"
							 alt=""/>
					</ng-template>
				</div>
			</div>
			<span *ngIf="incident.state === 'snoozed'"
				  class="snoozed-tag">Zzz...</span>
		</div>
		<div class="col">
			<p class="incident-name m-0">{{incident.title}}</p>
			<p class="incident-payload m-0" *ngIf="incident.payload">
				<span [ngSwitch]="incident.type" class="font-weight-normal">
					<ng-container *ngSwitchCase="'high_volume_alert'">
						Volume: {{ getIncidentVolume(incident) | liters}}
						<span class="l-font">l</span>
					</ng-container>
					<ng-container *ngSwitchCase="'long_flow_alert'">
						Duration: {{ formatDuration(incident)}} min
					</ng-container>
				</span>
			</p>
			<p class="incident-time m-0">
				{{timeAgo(incident.detected_at)}}</p>
		</div>
		<div class="col-auto">
			<i class="icon-arrow"></i>
		</div>
	</div>
</div>
