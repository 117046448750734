<div class="modal-content">
	<div class="modal-header">
		<h4 class="modal-title"> Add Space </h4>
		<button type="button"
				class="close"
				aria-label="Close"
				mat-icon-button
				(click)="onCancel()">
			<mat-icon>close</mat-icon>
		</button>
	</div>
	<div class="modal-body">
		<form [formGroup]="formGroup">
			<div class="row">
				<div class="col-lg-12">
					<div class="form-group">
						<label for="name">Name</label>
						<input id="name"
							   type="text"
							   class="form-control"
							   formControlName="name"/>
					</div>
				</div>
				<div class="col-12">
					<p *ngIf="incorrectInputMsg">
						{{incorrectInputMsg}}
					</p>
					<p *ngFor="let error of errors">
						{{error}}
					</p>
				</div>
			</div>
		</form>
	</div>
	<div class="modal-footer">
		<button type="button"
				class="btn btn-secondary"
				(click)="onCancel()">Close
		</button>
		<button type="button"
				class="btn btn-primary"
				(click)="onConfirm()">
			<span *ngIf="!space.id">Add</span>
			<span *ngIf="space.id">Update</span>
		</button>
	</div>
</div>
