import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {OrganisationListComponent} from './organisation-list/organisation-list.component';
import {OrganisationPageComponent} from './organisation-page/organisation-page.component';


const routes: Routes = [
    {
        path: '',
        children: [
            {
                path: '',
                component: OrganisationListComponent,
                data: {
                    title: 'Organisations list',
                },
            },
            {
                path: ':id',
                component: OrganisationPageComponent,
                data: {
                    title: 'Organisation',
                },
            }
        ]
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class OrganisationPagesRoutingModule {
}
