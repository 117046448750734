import {IncidentResponse, IncidentsResponse} from './dto/incidents.response';
import {ThingsResponse} from './dto/thing.response';
import {PropertiesResponse, PropertyResponse} from './dto/property.response';
import {environment} from './../../../../environments/environment';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {PropertySettings} from '../../_internal/property.settings';
import {Property} from '../../_internal/property';
import {PropertyCreate} from '../../_internal/property.create';
import {AppliancesResponse} from './dto/appliance.response';
import {OptionalHttpParams} from '../../../common/api/CrudAware';
import {Pageable} from '../../_internal/Pageable';
import {Incident, MostRecentIncident} from '../../_internal/incident';
import {NotificationSettings} from '../../_internal/notification-settings';
import { IncidentDetailsResponse } from './dto/incidents.response';

@Injectable({
    providedIn: 'root'
})
export class PropertyResource {
    private readonly url: string = `${environment.apiGatewayUrl}/iot-core/admin/properties`;

    constructor(private http: HttpClient) {
    }

    public getPropertiesList(params = {}): Observable<PropertiesResponse> {
        return this.http.get<PropertiesResponse>(this.url, {params});
    }

    public getPropertiesByUserId(userId: string): Observable<Property[]> {
        const url = `${environment.apiGatewayUrl}/iot-core/admin/users/${userId}/properties`;
        return this.http.get<Property[]>(url);
    }

    public getProperty(id): Observable<PropertyResponse> {
        return this.http.get<PropertyResponse>(`${this.url}/${id}`);
    }

    public getAssociatedThings(propertyId: string): Observable<ThingsResponse> {
        const url = `${this.url}/${propertyId}/things`;
        return this.http.get<ThingsResponse>(url);
    }

    public getAssociatedAppliances(propertyId: string): Observable<AppliancesResponse> {
        const url = `${this.url}/${propertyId}/appliances`;
        return this.http.get<AppliancesResponse>(url);
    }

    public updateProperty(propertyId: string, propertyPayload): Observable<PropertyResponse> {
        return this.http.put<PropertyResponse>(`${this.url}/${propertyId}`, propertyPayload);
    }

    public deleteProperty(propertyId: string): Observable<PropertyResponse> {
        return this.http.delete<PropertyResponse>(`${this.url}/${propertyId}`);
    }

    public getIncidentsList(propertyId: string, params?: HttpParams | OptionalHttpParams): Observable<Pageable<Incident>> {
        const url = `${this.url}/${propertyId}/incidents`;
        return this.http.get<IncidentsResponse>(url, {params});
    }

    public resolveIncident(incidentId: number): Observable<IncidentDetailsResponse> {
        const url = `${environment.apiGatewayUrl}/iot-core/admin/incidents/${incidentId}/resolve`;
        return this.http.put<IncidentDetailsResponse>(url, null);
    }

    public resolvePropertyIncident(propertyId: string, incidentId: string): Observable<IncidentResponse> {
        const url = `${this.url}/${propertyId}/incidents/${incidentId}/resolve`;
        return this.http.post<IncidentResponse>(url, null);
    }

    public getSettings(propertyId: string): Observable<PropertySettings> {
        const url = `${this.url}/${propertyId}/settings`;
        return this.http.get<PropertySettings>(url);
    }

    public updateSettings(propertyId: string, payload): Observable<PropertySettings> {
        const url = `${this.url}/${propertyId}/update_settings`;
        return this.http.put<PropertySettings>(url, {settings: payload});
    }

    public createProperty(propertyData: PropertyCreate): Observable<PropertyResponse> {
        const url = this.url;
        return this.http.post<PropertyResponse>(url, {property: propertyData});
    }

    public theMostRecentClosedIncidents(propertyId: string): Observable<MostRecentIncident[]> {
        const url = `${this.url}/${propertyId}/the_most_recent_closed_incidents`;
        return this.http.get<MostRecentIncident[]>(url);
    }

    public getPropertiesMap(): Observable<PropertiesResponse> {
        return this.http.get<PropertiesResponse>(`${this.url}/map`, {});
    }

    public getNotificationSettings(propertyId: string): Observable<NotificationSettings> {
        const url = `${this.url}/${propertyId}/notification_settings`;

        return this.http.get<NotificationSettings>(url);
    }

    public updateNotificationSettings(propertyId: string, payload: NotificationSettings): Observable<NotificationSettings> {
        const url = `${this.url}/${propertyId}/update_notification_settings`;
        const data = {
            settings: payload,
        };

        return this.http.put<NotificationSettings>(url, data);
    }

}
