import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Organization} from '../../../_internal/organization';
import {OrganisationResource} from '../../../providers/resources/organisation.resource';
import {UserResource} from '../../../providers/resources/user.resource';
import {UsersResponse} from '../../../providers/resources/dto/users.response';
import {User} from '../../../_internal/user';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {OrganisationEditComponent} from '../organisation-edit/organisation-edit.component';
import {HttpParams} from '@angular/common/http';
import {take} from 'rxjs/operators';
import * as _ from 'lodash';

@Component({
    selector: 'app-organisation-page',
    templateUrl: './organisation-page.component.html',
    styleUrls: ['./organisation-page.component.scss']
})
export class OrganisationPageComponent implements OnInit {
    public organisationId: string;
    public organisation: Organization;
    public associatedUsers: User[];
    public modalRef: BsModalRef;

    constructor(private activatedRoute: ActivatedRoute,
                private userResource: UserResource,
                private router: Router,
                private modalService: BsModalService,
                private organisationResource: OrganisationResource) {
    }

    ngOnInit() {
        this.organisationId = this.activatedRoute.snapshot.paramMap.get('id');
        this.fetchOrganisation();
        this.fetchAssociatedUsers();
    }

    public fetchOrganisation(): void {
        this.organisationResource.getOrganisation(this.organisationId).subscribe((organisation) => {
            this.organisation = organisation;
        });
    }

    public fetchAssociatedUsers(): void {
        const params = new HttpParams().append('by_organizations[]', this.organisationId);
        this.userResource.getUsersList(params).subscribe((usersResponse: UsersResponse) => {
            this.associatedUsers = usersResponse.users;
        });
    }

    public goToUser(userId): void {
        this.router.navigateByUrl(`users/${userId}`);
    }

    public openEditModal(): void {
        this.modalRef = this.modalService.show(OrganisationEditComponent, {
            initialState: {
                organisation: _.cloneDeep(this.organisation),
                action: 'edit'
            }
        });

        this.modalService.onHide
                .pipe(take(1))
                .subscribe(() => this.fetchOrganisation());
    }
}
