import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';


@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss']
})
export class AlertComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public data: AlertData,
                private dialogRef: MatDialogRef<AlertComponent>,
                private matDialog: MatDialog) {
    }

    onClose(): void {
        this.matDialog.closeAll();
    }

}

export class AlertData {
    title: string;
    message: string;
}
