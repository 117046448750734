<div class="card mb-4">
	<div class="card-header">
		<i class="fa fa-align-justify"></i> Incidents

	</div>
	<div class="card-body">

		<app-table [rows]="rows"
				   [startValues]="startValues"
				   [filterFields]="filterFields"
				   [columns]="columns"
				   [selectionEnabled]="false"
				   [tableSettings]="tableSettings"
				   (fetchPage)="fetchPage($event)">
		</app-table>
		<ng-template #titleColumn
					 ngx-datatable-cell-template
					 let-row="row"
					 let-value="value">
			<img src="../../../../assets/img/incidents-icons/{{ incidentsTypes[row.type].icon }}"
				 class="incident-icon"
				 alt=""/>
			{{ value}}
		</ng-template>
		<ng-template #statusColumn
                     ngx-datatable-cell-template
                     let-row="row"
                     let-value="value">
            <c-badge color="warning"
                     [ngClass]="{'badge-warning':value==='active','badge-light':value==='closed'}">{{value}}</c-badge>
        </ng-template>
		<ng-template #severityColumn
					 ngx-datatable-cell-template
					 let-row="row"
					 let-value="value">
			<div class="row align-items-center">
				<div class="col-auto">

					<div class="state-indicator"
						 [ngClass]="[incidentColor[value]]"></div>
				</div>
				<div class="col p-0">
					{{value}}
				</div>
			</div>
		</ng-template>
		<ng-template #additionalColumn
					 ngx-datatable-cell-template
					 let-row="row">
			<p *ngIf="row.payload">
				 <span [ngSwitch]="row.type"
					   class="font-weight-normal">
						<ng-container *ngSwitchCase="'high_flow_alert'">
							Flow: {{ row.payload.water_flow | liters}}
							<span class="l-font">l/min</span>
						</ng-container>
						<ng-container *ngSwitchCase="'large_usage_alert'">
						   Volume: {{ row.payload.volume | liters}}
							<span class="l-font">l</span>
						</ng-container>
						<ng-container *ngSwitchCase="'long_usage_alert'">
							Duration: {{ formatDuration(row.payload.duration)}} min
						</ng-container>
				</span>
			</p>
		</ng-template>
		<ng-template #actionsColumn
					 ngx-datatable-cell-template
					 let-row="row"
					 let-value="value">
			<a routerLink="/incidents/{{row.id}}"
			   class="btn btn-secondary btn-md px-5 ml-auto">Open</a>
		</ng-template>


	</div>
</div>
