<div class="card mb-4">
    <div class="card-header">
        <i class="fa fa-shield"></i> {{tabTitle}}
    </div>
    <div class="card-body" *ngIf="things && things.length && things[0].type === 'sonic_wifi'; else otherDevices">
        <div class="card mb-2"
            *ngFor="let hub of hubs">
            <div class="card-body p-2 clearfix">
                <c-row class="align-items-center">
                    <c-col lg="auto">
                        <i class="hl-icon hl-icon-bridge text-white bg-primary"></i>
                    </c-col>
                    <c-col>
                        <div class="h5 text-primary mb-0 mt-2">{{hub.name || 'Sonic WiFi'}}</div>
                        <div class="text-secondary text-uppercase font-weight-light font-xs">{{hub.id}}</div>
                    </c-col>
                </c-row>
            </div>
            <a class="card-footer px-3 py-2 pointer"
                [routerLink]="['/things/wifi',things[0].id]">
                <span class="font-weight-bold font-xs btn-block text-secondary pointer">View More
                    <i class="fa fa-angle-right float-end font-lg"></i></span>
            </a>
        </div>
    </div>
    <ng-template #otherDevices>
        <div class="card-body">
            <div class="card mb-2"
                *ngFor="let hub of hubs">
                <div class="card-body p-2 clearfix">
                    <c-row class="align-items-center">
                        <c-col lg="auto">
                            <i class="hl-icon hl-icon-bridge text-white bg-primary"></i>
                        </c-col>
                        <c-col>
                            <div class="h5 text-primary mb-0 mt-2">{{hub.name || 'Bridge'}}</div>
                            <div class="text-secondary text-uppercase font-weight-light font-xs">{{hub.id}}</div>
                        </c-col>
                    </c-row>
                </div>
                    <a class="card-footer px-3 py-2 pointer"
                        [routerLink]="['/hubs',hub.id]">
                        <span class="font-weight-bold font-xs btn-block text-secondary pointer">View More
                            <i class="fa fa-angle-right float-end font-lg"></i></span>
                    </a>
            </div>
            <div class="card mb-2"
                *ngFor="let thing of things">
                <div class="card-body p-2 clearfix">
                    <c-row class="align-items-center">
                        <c-col lg="auto">
                            <i class="hl-icon {{typeIcon(thing.type)}} text-white bg-primary"></i>
                        </c-col>
                        <c-col>
                            <div [ngSwitch]="thing.state">
                                <c-badge *ngSwitchCase="'open'"
                                        color="success">Open
                                </c-badge>
                                <c-badge *ngSwitchCase="'closed'"
                                        color="danger">Closed
                                </c-badge>
                                <c-badge *ngSwitchDefault
                                        color="default">{{thing.state}}
                                </c-badge>
                            </div>
                            <div class="h5 text-primary mb-0 mt-2">{{thing.name}}</div>
                            <div class="text-secondary text-uppercase font-weight-bold font-xs">{{thing.type === 'siryn' ? 'tail' : thing.type}}</div>
                            <div class="text-secondary text-uppercase font-weight-light font-xs">{{thing.id}}</div>
                        </c-col>
                    </c-row>
                </div>
                <a class="card-footer px-3 py-2 pointer"
                    [routerLink]="['/things',thing.id]">
                    <span class="font-weight-bold font-xs btn-block text-secondary pointer">View More
                        <i class="fa fa-angle-right float-end font-lg"></i></span>
                </a>
            </div>
        </div>
    </ng-template>
</div>
