import { Favourite } from './../../../providers/resources/dto/favourit-data';
import {Component, OnInit, ChangeDetectionStrategy} from '@angular/core';
import {Property} from '../../../_internal/property';
import {PropertyResource} from '../../../providers/resources/property.resource';

@Component({
    selector: 'app-hero-dashboard-page',
    templateUrl: './dashboard-page.component.html',
    styleUrls: ['./dashboard-page.component.scss'],
})
export class HeroDashboardPageComponent implements OnInit {
    public properties: Property[];

    constructor(private propertyResource: PropertyResource) {
    }

    ngOnInit() {
        this.fetchProperties();
    }

    public fetchProperties(): void {
        this.propertyResource.getPropertiesMap().subscribe(response => {
            this.properties = response.properties;
        });
    }
    public getFavourites(location: string): Favourite[] {
        const localStorageItem = JSON.parse(localStorage.getItem(location));
        return localStorageItem === null ? [] : localStorageItem.data;
    }
}
