import {environment} from './../../../../environments/environment';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class PasswordResource {
    private readonly url: string = `${environment.apiGatewayUrl}/iot-core-public/passwords`;

    constructor(private http: HttpClient) {
    }

    public requestPasswordReset(email: string): Observable<string> {
        const url = `${this.url}/reset_password`;
        return this.http.post<string>(url, {email});
    }
}
