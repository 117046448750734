import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {Property} from '../../../../_internal/property';
import 'leaflet';
import 'leaflet.markercluster';

const L = window['L'];


@Component({
    selector: 'app-properties-map',
    templateUrl: './properties-map.component.html',
    styleUrls: ['./properties-map.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PropertiesMapComponent implements AfterViewInit {
    @Input() properties: Property[];
    @ViewChild('mapDiv') mapContainer;

    public latitude = 52.913946;
    public longitude = -3.95022;
    private map: L.Map;
    // @ts-ignore
    private markersLayer = new L.MarkerClusterGroup();
    private tilesUrl = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
    private mapOptions: L.MapOptions = {
        zoomControl: false,
        minZoom: 2, // 11,
        maxZoom: 18,
    };

    constructor(private router: Router,
                private cdRef: ChangeDetectorRef) {
    }

    public ngAfterViewInit(): void {
        this.initMap();
        this.processProperties();
    }

    public goToProperty(id): void {
        this.router.navigate([`properties/${id}`]);
    }

    private initMap() {

        this.map = new L.Map(this.mapContainer.nativeElement, this.mapOptions)
                .setView([this.latitude, this.longitude], 11);
        L.tileLayer(this.tilesUrl, {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
            maxZoom: 19
        }).addTo(this.map);

        this.markersLayer.addTo(this.map);

        this.map.on('click', () => {
            this.cdRef.detectChanges();
        });

        this.cdRef.detectChanges();
    }

    private processProperties(): void {
        this.map.invalidateSize();

        const customMarkerIcon = L.icon({
            iconUrl: 'assets/img/brand/map-marker.svg',
            iconSize: [32, 32],
            popupAnchor: [0, -20],
            className: 'marker-in'
        });
        const markers = [];
        const markersPositions = [];

        this.properties.forEach((property) => {
            const markerItem = L.marker([property.lat, property.lng], {icon: customMarkerIcon});

            markerItem.on('click', () => this.goToProperty(property.id));
            markerItem.bindTooltip(`<b>${property.name}</b><br/>${property.city} ${property.country}`);

            markers.push(markerItem);
            this.markersLayer.addLayer(markerItem);
            markersPositions.push(markerItem.getLatLng());
        });

        const bounds = new L.LatLngBounds(markersPositions);
        this.map.fitBounds(bounds.pad(0.5));
        this.cdRef.detectChanges();
    }

}
