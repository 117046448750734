<div class="modal-content">
    <div class="modal-header">
        <h4 class="modal-title">Change blocked state of multiple devices</h4>
        <button type="button" class="close" aria-label="Close" (click)="close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="formGroup">

        <p>Select the type of test that should be unblocked.</p>
        <div class="row mb-3">
            <div class="col-lg-12">
                <label>Test type</label>
                    <div class="form-group">
                        <select class="form-control"
                                id="test-type"
                                type="text"
                                formControlName="testType">
                            <option *ngFor="let status of testTypes"
                                    [value]="status">{{status}}</option>
                        </select>
                    </div>
            </div>
        </div>
        <p> {{describtion}}</p>
        <div class="row mb-3">
            <div class="form-group">
                <label>Serial numbers</label>
                <textarea class="form-control"
                          rows="4"
                          type="text"
                          id="serial-numbers"
                          formControlName="serialNumbers"></textarea>
            </div>
        </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="close()">Cancel</button>
        <button type="button" class="btn btn-danger" (click)="confirm()" [disabled]="!this.formGroup.valid">Accept</button>
    </div>
</div>
