import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LegionellaChecksListComponent} from './legionella-checks-list.component';
import {DataTableModule} from '../../../common/components/data-table/data-table.module';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';

const components = [
    LegionellaChecksListComponent,
];

@NgModule({
    imports: [
        CommonModule,
        DataTableModule,
        BsDatepickerModule.forRoot(),
        BsDatepickerModule,
    ],
    exports: components,
    declarations: components,
    entryComponents: components,
    providers: [],
})
export class LegionellaChecksListModule { }
