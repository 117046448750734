export const propertyFilterFields = [
    {
        type: 'text',
        name: 'search',
        label: 'Name'
    },
    {
        type: 'text',
        name: 'city',
        label: 'City'
    },
    {
        type: 'text',
        name: 'country',
        label: 'Country'
    },
    {
        type: 'text',
        name: 'postcode',
        label: 'Postcode'
    },
];
