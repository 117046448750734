<c-row *ngIf="dashboardAmounts">
	<c-col sm="3">
		<c-widget-stat-c
				[title]="'Things'"
				class="mb-4 pointer"
				color="info"
				routerLink="/things"
				inverse
				[value]="dashboardAmounts.things"
		>
		</c-widget-stat-c>
	</c-col>
	<c-col sm="3">
		<c-widget-stat-c
				[title]="'Properties'"
				class="mb-4 pointer"
				color="primary"
				routerLink="/properties"
				inverse
				[value]="dashboardAmounts.properties"
		>
		</c-widget-stat-c>

	</c-col>

	<c-col sm="3">
		<c-widget-stat-c
				[title]="'Resolved Incidents'"
				class="mb-4 pointer"
				color="success"
				routerLink="/incidents/state/resolved"
				inverse
				[value]="dashboardAmounts.resolved_incidents"
		>
		</c-widget-stat-c>
	</c-col>
	<c-col sm="3">
		<c-widget-stat-c
				[title]="'Active Incidents'"
				class="mb-4 pointer"
				color="danger"
				routerLink="/incidents/state/active"
				inverse
				[value]="dashboardAmounts.active_incidents"
		>
		</c-widget-stat-c>
	</c-col>
</c-row>
