<div class="modal-content">
    <div class="modal-header">
        <h4 class="modal-title">Confirm change of valve state</h4>
    </div>
        <div class="modal-body">
					<label for="company">Current state of the valve: <b>{{ this.initialState }}</b></label>
                    <div *ngIf="thing.state === this.initialState; else noServer">Do you want to change the state of the valve?</div>
                    <ng-template #noServer><div>Are you sure you want to change state from <b>{{ this.initialState }}</b> to <b>{{ thing.state }}</b>.</div></ng-template>
                    <br>
                    <div class="form-group">
                        <label for="company">State</label>
                        <select class="form-control" [(ngModel)]="thing.state">
                            <option *ngFor="let state of thingStates">
                                {{state}}
                            </option>
                        </select>
                    </div>
				</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-secondary" (click)="onCancel()">Close</button>
		<button type="button" class="btn btn-primary" (click)="onConfirm()">Save changes</button>
	</div>
</div>

