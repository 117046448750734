import {TableDisplayedColumn} from './../../../../common/components/data-table/table-displayed-column';
import * as moment from 'moment';
import * as _ from 'lodash';

export class EventsDisplayFactory {
    public static build(applianceCategories): TableDisplayedColumn[] {
        return [
            { key: 'property_name', name: 'Property' },
            {
                key: 'predicted_appliance_category_probability', name: 'Prediction probability', formatter: (arr: number[]) => arr !== null ? parseFloat(_.first(arr).toFixed(2)) * 100 + '%' : ''
            },
            {
                key: 'predicted_appliance_category_ids',
                name: 'Predicted categories',
                formatter: (applianceCategoryIds) => {
                    const categories = _.map(applianceCategoryIds, (appId: string) => {
                        const icon = _.get(_.find(applianceCategories, { id: parseInt(appId, 10) }), 'icon_url');
                        return `<i class="hl-icon hl-icon-${icon}"></i>`;
                    });
                    return categories.toString();
                }
            },
            {
                key: 'appliance_category_ids',
                name: 'Categories',
                formatter: (applianceCategoryIds) => {
                    const categories = _.map(applianceCategoryIds, (appId) => {
                        const icon = _.get(_.find(applianceCategories, { id: appId }), 'icon_url');
                        return `<i class="hl-icon hl-icon-${icon}"></i>`;
                    });
                    return categories.toString();
                }
            },
            {
                key: 'complex', name: 'Complex', formatter: (complex) => `<i class="fa fa-${complex ? 'check' : 'times-circle'}"></i>`
            },
            {
                key: 'event_start', name: 'UTC start time', formatter: (timestamp) => {
                    const date = new Date(parseInt(timestamp, 10));
                    return moment.utc(date).format('HH:mm:ss');
                }
            },
            {
                key: 'event_duration',
                name: 'Duration',
                formatter: (duration) => {
                    const time = moment.utc().format('YYYY-MM-DD');
                    return moment.utc(time).add(duration, 'ms').format('HH:mm:ss');
                }
            },
            {
                key: 'water_consumed',
                name: 'Water consumed',
                formatter: (consumed) => (consumed / 1000).toFixed(2).toString() + ' L'
            }
        ];
    }
}
