export const usersFilterFields = [
    {
        type: 'text',
        name: 'by_names',
        label: 'Search'
    },
    {
        name: 'active',
        label: 'Active',
        multiple: false,
        options: [
            {
                value: 'true',
                label: 'Active'
            },
            {
                value: 'false',
                label: 'Inactive'
            },
        ]
    },
];
