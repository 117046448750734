import {AuthenticationService} from './authenthication.service';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

@Injectable()
export class AuthGuardService implements CanActivate {

    constructor(public authService: AuthenticationService,
                public router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): Observable<boolean> {
        return this.authService.getAuthStateStream().pipe(
            tap(response => {
                if (!response) {
                    this.authService.redirectUrl = routerState.url;
                    this.router.navigate(['login']);
                }
            })
        );
    }
}
