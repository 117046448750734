import { AfterViewInit, Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import {OptionalHttpParams} from '../../../common/api/CrudAware';
import {JsonDialogComponent} from '../../../common/components/dialogs/json-modal/json-dialog.component';
import { BurningTestResult, BurningTestResultResponse } from '../../providers/resources/dto/burning-mode';
import { ThingService } from '../../providers/services/thing.service';
import { PressureReport } from '../../_internal/pressure-test';
import { tableSettings } from '../../_internal/table-settings';
import { TableComponent } from '../table/table.component';
import * as _ from 'lodash';

@Component({
  selector: 'app-burning-mode-data',
  templateUrl: './burning-mode-data.component.html',
  styleUrls: ['./burning-mode-data.component.scss']
})
export class BurningModeDataComponent implements AfterViewInit {

    @Input() serialNumber: string;
    @ViewChild(TableComponent) table: TableComponent;
    @ViewChild('dateColumn') dateColumn: TemplateRef<any>;
    @ViewChild('actionsColumn') actionsColumn: TemplateRef<any>;
    @ViewChild('resultsPassedColumn') resultsPassedColumn: TemplateRef<any>;
    public tableSettings = _.cloneDeep(tableSettings);
    public columns = [];
    public rows: BurningTestResult[];
    constructor(private thingService: ThingService,
                 private modalService: BsModalService) { }
    ngAfterViewInit(): void {
        this.columns = [
            {prop: 'serial_no', name: 'Serial no', flexGrow: 1, sortable: false, cellTemplate: this.table.codeColumn},
            {cellTemplate: this.dateColumn, prop: 'created_at', name: 'Created at', flexGrow: 1, sortable: false},
            {prop: 'test_duration_s', name: 'Test duration', flexGrow: 1, sortable: false},
            {
              cellTemplate: this.resultsPassedColumn,
              prop: 'test_result',
              name: 'Test passed?',
              flexGrow: 1,
              sortable: false
          },
            {
                cellTemplate: this.actionsColumn, name: 'Actions', flexGrow: 1, sortable: false
            }
        ];
    }

    public fetchPage(params?: OptionalHttpParams): void {
        this.thingService.getBurningReportsData(this.serialNumber).subscribe(
            (response: BurningTestResultResponse) => {
                this.table.setPageParams(response);
                this.rows = response?.data;
                this.table.isLoading = false;
            }
        );
      }

      public reportPassed(result): boolean {
        return result === 'passed';
      }

      public openFullReportModal(report: BurningTestResult) {
        this.modalService.show(JsonDialogComponent, {
            initialState: {
                content: report,
                title: `Generated at: ${report.created_at} Serial no: ${report.serial_no}`,
            },
            class: 'modal-xl'
        });
    }
}
