import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {PropertyService} from '../../providers/services/property.service';
import {NotificationSettings} from '../../_internal/notification-settings';
import * as _ from 'lodash';
import {BehaviorSubject, Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {NotificationSettingsEditComponent} from '../notification-settings-edit/notification-settings-edit.component';

@Component({
    selector: 'app-notification-settings',
    templateUrl: './notification-settings.component.html',
    styleUrls: ['./notification-settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationSettingsComponent implements OnInit {

    @Input() propertyId: string;

    public modalRef: BsModalRef;

    public boolProperties = [
        {
            name: 'Radio disconnection',
            key: 'radio_disconnection'
        },
        {
            name: 'Pressure test skipped',
            key: 'pressure_test_skipped'
        },
        {
            name: 'Pressure test failed',
            key: 'pressure_test_failed'
        },
        {
            name: 'Low battery',
            key: 'low_battery_level'
        },
        {
            key: 'backup_battery_usage',
            name: 'Mains Power Disconnected'
        },
        {
            name: 'Low temperature',
            key: 'low_water_temperature'
        },
        {
            name: 'Sonic sensor status',
            key: 'health_check_failed'
        },
        {
            name: 'Valve position',
            key: 'device_handle_moved'
        },
        {
            name: 'Handle couldn\'t be moved due to low batteries',
            key: 'battery_too_low_to_move_handle'
        },
        {
            name: 'Cloud (wifi) disconnection',
            key: 'cloud_disconnection'
        },
        {
            name: 'Legionella check',
            key: 'legionella_risk'
        }
    ];

    private notificationSettingsSubject: BehaviorSubject<NotificationSettings> = new BehaviorSubject(null);


    constructor(private cdRef: ChangeDetectorRef,
                private modalService: BsModalService,
                private propertyService: PropertyService) {
    }

    ngOnInit() {


        this.fetchNotificationSettings();
    }

    public getNotificationSettingsStream(): Observable<NotificationSettings> {
        return this.notificationSettingsSubject.asObservable();
    }

    public openEditModal(): void {
        this.modalRef = this.modalService.show(NotificationSettingsEditComponent, {
            initialState: {
                propertyId: this.propertyId,
                boolProperties: this.boolProperties,
                notificationSettings: this.notificationSettingsSubject.getValue()
            }
        });
        this.modalService.onHide
                .pipe(take(1))
                .subscribe(() => this.fetchNotificationSettings());
    }

    private fetchNotificationSettings(): void {
        this.propertyService.getNotificationSettings(this.propertyId)
                .subscribe((settings) => {
                    this.notificationSettingsSubject.next(_.cloneDeep(settings));
                    this.cdRef.detectChanges();
                });
    }


}
