import {Injectable} from '@angular/core';
import Pusher from 'pusher-js';
import {environment} from '../../../../environments/environment';
import {Subscription, timer} from 'rxjs';
import {ToastrService} from 'ngx-toastr';


@Injectable({
    providedIn: 'root'
})
export class PusherService {

    public channel;
    public pusher: Pusher = new Pusher(environment.pusherKey, {
        cluster: 'eu',
        encrypted: true,
    });
    private unsubscribeSub: Subscription;
    private pusherTimeout = 5 * 60 * 1000; // 5 minutes
    private propertyId: string;

    constructor(private toastrService: ToastrService) {
        // TODO: should be reworked to be more angular way
        document.addEventListener('visibilitychange', () => {
            if (!this.propertyId) {
                return;
            }
            if (document.hidden) {
                this.unsubscribe(this.propertyId);
            } else {
                this.subscribe(this.propertyId);
            }
        });
    }

    public subscribe(propertyId: string): void {

        this.propertyId = propertyId;
        if (this.unsubscribeSub) {
            this.unsubscribeSub.unsubscribe();
        }
        this.unsubscribeSub = timer(this.pusherTimeout)
            .subscribe(() => this.handlePusherTimeout());
        this.channel = this.pusher.subscribe('properties@' + propertyId);
        console.log('Pusher was connected');
    }

    public unsubscribe(propertyId: string): void {
        if (this.channel) {
            this.channel.unbind();
            this.channel = null;
        }
        this.pusher.unsubscribe('properties@' + propertyId);
        console.log('Pusher was disconnected');
    }

    public init(propertyId: string) {
        this.subscribe(propertyId);
        return this.channel;
    }

    public unBindEvent(eventName: string): void {
        this.channel.unbind(eventName);
    }

    private handlePusherTimeout() {
        this.unsubscribe(this.propertyId);
        this.toastrService.warning('Pusher was disconnected, please refresh to re-connect.', 'Warning', {
            closeButton: true
        });
    }
}
