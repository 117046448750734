import {EventService} from './../../providers/services/event.service';
import {ApplianceCategory} from './../../_internal/appliance_category';
import {Component, OnInit} from '@angular/core';
import {Event, EventOverrides} from '../../_internal/event';
import {BsModalRef} from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../../src/environments/environment';

@Component({
    selector: 'app-event-override-modal',
    templateUrl: './event-override-modal.component.html',
    styleUrls: ['./event-override-modal.component.scss']
})
export class EventOverrideModalComponent implements OnInit {
    public event: Event;
    public overrides: EventOverrides;
    public applianceCategories: ApplianceCategory[];
    public errorMsg = '';

    constructor(private eventService: EventService,
                private bsModalRef: BsModalRef,
                private toastrService: ToastrService) {
    }

    public onSave() {
        if (this.overrides.confidence) {
            this.override();
        } else {
            this.errorMsg = 'Pick a value!';
        }
    }

    public override() {
        this.eventService.overrideEvent(this.event.device_id, this.event.event_start, this.overrides)
            .subscribe(
                event => {
                    this.event.overrides = event.overrides;
                    this.toastrService.success('Settings have been saved successfully.', 'Overwrite event.', environment.toastSettings);
                    this.bsModalRef.hide();
                },
                error => {
                    this.toastrService.error( 'An error has ocured while saving data. Error code: ' + error.status + 'Error message' + error.message, 'Overwrite event.', environment.toastSettings);
                }
            );
    }

    public onClose() {
        this.bsModalRef.hide();
    }

    ngOnInit() {
        this.overrides = _.clone(this.event.overrides);
    }

}
