import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
import { UserLogResource } from '../resources/user-logs.resource';
import { UserLogsResponse, UserLogsParams } from '../resources/dto/user-logs.response';

@Injectable()
export class UserLogService {

    constructor(private userLogresource: UserLogResource) { }

    public getLogs(params: UserLogsParams): Observable<UserLogsResponse> {
        return this.userLogresource.getLogs(params);
    }
}
