<div class="card mb-4">
	<div class="card-header">
		<i class="fa fa-align-justify"></i> Add to group
	</div>
	<div class="card-body">
		<div class="row">
			<div class="col-lg-12">
				<form (search)="search()">
					<div class="row align-items-center">
						<div class="col pr-0">
							<c-input-group>
									<span cInputGroupText>
                            <i cIcon
							   class="fa fa-search"></i>
									</span>
								<input class="form-control search"
									   type="search"
									   cFormControl
									   placeholder="Search"
									   [formControl]="devicesSearchControl"
									   #devicesSearchInput
								/>
							</c-input-group>

						</div>
						<div class="col-auto">
							<button class="btn btn-secondary"
									type="reset"
									(click)="resetSearch()"
									[disabled]="!this.devicesSearchControl.value">
								Reset
							</button>
						</div>
					</div>
				</form>
				<table class="table table-striped"
					   *ngIf="getThings()">
					<thead>
					<tr>
						<th>Id</th>
						<th>Name</th>
						<th>Type</th>
					</tr>
					</thead>
					<tbody>
					<tr *ngFor="let thing of getThings() | async">
						<td>{{thing.id}}</td>
						<td>{{thing.name}}</td>
						<td>{{thing.type}}</td>
						<td>
							<div class="btn btn-primary"
								 (click)="onAdd(thing.id)">Add
							</div>
						</td>
					</tr>
					<tr *ngFor="let hub of getHubs() | async">
						<td>{{hub.id}}</td>
						<td>{{hub.name}}</td>
						<td>{{hub.type}}</td>
						<td>
							<div class="btn btn-primary"
								 (click)="onAdd(hub.id)">Add
							</div>
						</td>
					</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</div>
