import {EventService} from './services/event.service';
import {ManufacturingResource} from './resources/manufacturing.resource';
import {PusherService} from './services/pusher.service';
import {ThingService} from './services/thing.service';
import {HistoricDataService} from './services/historic-data.service';
import {ThingResource} from './resources/thing.resource';
import {UserResource} from './resources/user.resource';
import {PropertyResource} from './resources/property.resource';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {RoleResource} from './resources/role.resource';
import {OrganisationResource} from './resources/organisation.resource';
import {UserService} from './services/user.service';
import {AuthenticationService} from './services/authenthication.service';
import {AuthGuardService} from './services/auth-guard.service';
import {PasswordResource} from './resources/password.resource';
import {CircleOfTrustResource} from './resources/circle-of-trust.resource';
import {CircleOfTrustService} from './services/circle-of-trust.service';
import {AuthInterceptorService} from '../interceptors/auth-interceptor.service';
import {FirmwareService} from './services/firmware-service';
import {FirmwareResource} from './resources/firmware-resource';
import {ApplianceCategoryResource} from './resources/appliance-category.resource';
import {ApplianceCategoryService} from './services/appliance-category.service';
import {HubResource} from './resources/hub.resource';
import {HubService} from './services/hub.service';
import {SpaceService} from './services/space.service';
import {SpaceResource} from './resources/space.resource';
import {ApplianceResource} from './resources/appliance.resource';
import {ApplianceService} from './services/appliance.service';
import {EventResource} from './resources/event.resource';
import {JobResource} from './resources/job.resource';
import {JobService} from './services/job.service';
import {UserLogService} from './services/user-log.service';
import {UserLogResource} from './resources/user-logs.resource';
import {RoleGuardService} from './services/role-guard.service';
import {LifeCycleEventService} from './services/life-cycle-event.service';
import {LifeCycleEventResource} from './resources/life-cycle-event.resource';
import {BatteryResource} from './resources/battery.resource';
import {BatteryService} from './services/battery.service';
import {RemoteRestartsResource} from './resources/remote-restarts.resource';
import {RemoteRestartsService} from './services/remote-restarts.service';
import {ShadowService} from './services/shadow.service';
import {ShadowResource} from './resources/shadow.resource';
import {CommonResource} from './resources/common.resource';
import {MonitoringResource} from './resources/monitoring-resource';
import {MonitoringService} from './services/monitoring-service';
import {FirmwareChannelResource} from './resources/firmware-channel.resource';
import {FirmwareChannelService} from './services/firmware-channel.service';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        HttpClientModule
    ],
    providers: [
        PropertyResource,
        UserResource,
        ThingResource,
        HistoricDataService,
        ThingService,
        PusherService,
        RoleResource,
        OrganisationResource,
        UserService,
        AuthenticationService,
        AuthGuardService,
        RoleGuardService,
        PasswordResource,
        FirmwareResource,
        FirmwareService,
        ApplianceCategoryResource,
        ApplianceCategoryService,
        CircleOfTrustResource,
        CircleOfTrustService,
        SpaceService,
        SpaceResource,
        HubResource,
        HubService,
        ApplianceResource,
        ApplianceService,
        EventResource,
        EventService,
        ManufacturingResource,
        JobResource,
        JobService,
        UserLogService,
        UserLogResource,
        LifeCycleEventService,
        LifeCycleEventResource,
        BatteryResource,
        BatteryService,
        RemoteRestartsResource,
        RemoteRestartsService,
        ShadowService,
        ShadowResource,
        CommonResource,
        MonitoringResource,
        MonitoringService,
        FirmwareChannelResource,
        FirmwareChannelService,
        {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true},
    ]
})
export class HeroProvidersModule {
}
