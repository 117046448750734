<div class="row">
  <div class="col-lg-3">

    <div class="card text-white bg-info">
      <div class="card-body">
        <div class="h1 text-muted text-right mb-1">
          <i class="icon-drop"></i>
        </div>
        <div class="h4 mb-0">{{getWaterUsage()}}</div>
        <small class="text-muted text-uppercase font-weight-bold">Usage</small>
      </div>
    </div>

  </div>

  <div class="col-lg-3">
    <div class="card text-white bg-success">
      <div class="card-body">
        <div class="h1 text-muted text-right mb-1">
          <i class="icon-speedometer"></i>
        </div>
        <div class="h4 mb-0">{{getDuration()}}</div>
        <small class="text-muted text-uppercase font-weight-bold">Duration</small>
      </div>
    </div>
  </div>

  <div class="col-lg-3">
    <div class="card text-white bg-warning">
      <div class="card-body">
        <div class="h1 text-muted text-right mb-1">
          <i class="icon-star"></i>
        </div>
        <div class="h4 mb-0">{{getPredictionProbability()}}</div>
        <small class="text-muted text-uppercase font-weight-bold">Prediction probability</small>
      </div>
    </div>
  </div>

  <div class="col-lg-3">
    <div class="card text-white bg-danger">
      <div class="card-body">
        <div class="h1 text-muted text-right mb-1">
          <i class="icon-layers"></i>
        </div>
        <div class="h4 mb-0"> {{event.complex ? 'Yes' : 'No'}} </div>
        <small class="text-muted text-uppercase font-weight-bold">Complexity</small>
      </div>
    </div>
  </div>

</div>
