<!--<c-header class="mb-4 d-print-none" position="sticky">-->
<ng-container>
	<c-container [fluid]="true">
		<button
				toggle="visible"
				cHeaderToggler
				[cSidebarToggle]="sidebarId"
				class="ps-1"
		>
			<svg cIcon
				 name="cilMenu"
				 size="lg"
			></svg>
		</button>
		<c-header-nav class="ms-3">
			<ng-container *ngTemplateOutlet="userDropdown"></ng-container>
		</c-header-nav>

	</c-container>
	<c-header-divider></c-header-divider>
	<c-container [fluid]="true">
		<c-breadcrumb-router class="ms-2"></c-breadcrumb-router>
	</c-container>
</ng-container>
<!--</c-header>-->

<ng-template #userDropdown>
	<app-current-user-badge></app-current-user-badge>
</ng-template>

