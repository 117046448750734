import {HeroProvidersModule} from './providers/hero-providers.module';
import {HeroPagesModule} from './pages/hero-pages.module';
import {HeroComponentsModule} from './components/hero-components.module';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuthenticationService} from './providers/services/authenthication.service';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        HeroComponentsModule,
        HeroPagesModule,
        HeroProvidersModule,
    ]
})
export class HeroLabsModule {
    constructor(authService: AuthenticationService) {
        authService.checkToken();
    }
}
