<dt>
    Power source:
</dt>
<dd>
    <ng-container *ngIf="batteryFeed">
        <ng-container *ngIf="hasPowerSupply();  else battery;">
            Mains
        </ng-container>
        <ng-template #battery>
            Battery
        </ng-template>
    </ng-container>
    <ng-container [ngSwitch]="batteryLevel">
        <c-badge color="info" *ngSwitchCase="'external_power_supply'"><i class="fa fa-plug"></i></c-badge>
        <c-badge color="success" *ngSwitchCase="'high'"><i class="fa fa-battery-4"></i></c-badge>
        <c-badge color="warning" *ngSwitchCase="'mid'"><i class="fa fa-battery-1"></i></c-badge>
        <c-badge color="danger" *ngSwitchCase="'low'"><i class="fa fa-battery-0"></i></c-badge>
    </ng-container>
    <br>
</dd>
<ng-container *ngIf="batteryFeed">
    <dt>
        Battery stats:
    </dt>
    <dd>
        Current battery: {{ getOnlyBatteries() }} V<br/>
        Reported value: {{ getValueFromFeed('battery') }}<br>
        Average when motor on: {{ getValueFromFeed('average_battery_when_motor_on') }}<br/>
        Lowest when motor on: {{ getValueFromFeed('lowest_battery_when_motor_on') }}<br/>
    </dd>
</ng-container>
