import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OrganisationPagesRoutingModule} from './organisation-pages-routing.module';
import {OrganisationListComponent} from './organisation-list/organisation-list.component';
import {NgxPaginationModule} from 'ngx-pagination';
import {OrganisationPageComponent} from './organisation-page/organisation-page.component';
import {OrganisationEditComponent} from './organisation-edit/organisation-edit.component';
import {ModalModule} from 'ngx-bootstrap/modal';
import {FormsModule} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';

@NgModule({
    declarations: [
        OrganisationListComponent,
        OrganisationPageComponent,
        OrganisationEditComponent
    ],
    imports: [
        CommonModule,
        OrganisationPagesRoutingModule,
        ModalModule.forRoot(),
        NgxPaginationModule,
        FormsModule,
        MatIconModule,
        MatButtonModule,
    ],
    exports: [
        OrganisationListComponent,
        OrganisationPageComponent,
        NgxPaginationModule
    ]
})
export class OrganisationPagesModule {
}
