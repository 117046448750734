<div class="card mb-4">
	<div class="card-header">
		<i class="fa fa-align-justify"></i> Reboots triggered
		<button class="btn btn-secondary btn-sm pull-right"
				(click)="refresh()">
			Refresh
		</button>
	</div>
	<div class="card-body"
		 *ngIf="(resetsStream | async); else noData">
		<app-data-table [dataStream]="resetsStream"
						[displayedColumns]="displayedColumns"
						[settings]="{hasAction: true}">
			<ng-template let-triggeredReset>
				<button type="button"
						(click)="goToJob(triggeredReset.id)"
						class="btn btn-sm btn-primary"
						tooltip="Go To Job">
					Show details
				</button>
			</ng-template>
		</app-data-table>

		<div class="col-12 d-flex justify-content-center"
			 *ngIf="isLoading">
			<div class="spinner-border"
				 role="status">
				<span class="sr-only">Loading...</span>
			</div>
		</div>
	</div>
	<ng-template #noData>
		<div class="card-body">
			<div class="col-12 d-flex justify-content-center">
				<h5>No reboots</h5>
			</div>
		</div>
	</ng-template>
</div>
