import {Component, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {MembershipRole, PropertyMembership} from '../../../_internal/property-membership';
import {MembershipRequest} from '../../../_internal/membership.request';
import * as _ from 'lodash';
import {FormControl, Validators} from '@angular/forms';
import {MembershipService} from '../../../providers/services/membership.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../../../src/environments/environment';

@Component({
    selector: 'app-add-member',
    templateUrl: './add-member.component.html',
    styleUrls: ['./add-member.component.scss']
})
export class AddMemberComponent implements OnInit {
    public members: PropertyMembership[];
    public requests: MembershipRequest[];
    public occupiedEmails: string[];
    public incorrectInputMsg: string;
    public propertyId: string;
    public emailFormControl: FormControl = new FormControl('', [Validators.required, Validators.email]);
    public onClose: Subject<boolean>;
    public membershipRoles: MembershipRole[] = ['user', 'guest'];
    public selectedRole: MembershipRole = _.head(this.membershipRoles);

    constructor(private _bsModalRef: BsModalRef,
                private membershipService: MembershipService,
                private toastrService: ToastrService) {
    }

    public ngOnInit(): void {
        this.onClose = new Subject();
    }

    public onConfirm(): void {
        this.occupiedEmails = _.union(
            _.map(this.members, member => member.email),
            _.map(this.requests, request => request.email)
        );
        if (this.emailFormControl.valid) {
            if (!this.isEmailValid(this.emailFormControl.value)) {
                this.incorrectInputMsg = 'Provided email already belongs to a member';
            } else {
                const payload = {email: this.emailFormControl.value, membership_role: this.selectedRole};
                this.membershipService.addMember(this.propertyId, payload).subscribe(
                    response => {
                        this.onClose.next(true);
                        this._bsModalRef.hide();
                        this.toastrService.success('The user has been invited', 'Invitation.', environment.toastSettings);
                    },
                    err => this.toastrService.error( 'An error has ocured. Error code: ' + err.status + 'Error message' + err.message, 'Invitation.', environment.toastSettings)

                );
            }
        } else {
            this.incorrectInputMsg = 'Provided email is incorrect';
        }
    }

    public isEmailValid(email): boolean {
        return _.find(this.occupiedEmails, (occupiedEmail) => occupiedEmail === email) === undefined;
    }

    public onCancel(): void {
        this._bsModalRef.hide();
    }
}
