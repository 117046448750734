import {Component, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {Appliance} from '../../_internal/appliance';
import {TableDisplayedColumn} from '../../../common/components/data-table/table-displayed-column';
import {BsModalService} from 'ngx-bootstrap/modal';
import {ManageApplianceDialogComponent} from './manage-appliance-dialog/manage-appliance-dialog.component';
import {ApplianceService} from '../../providers/services/appliance.service';
import * as _ from 'lodash';
import {PropertyService} from '../../providers/services/property.service';

@Component({
    selector: 'app-appliance-list',
    templateUrl: './appliance-list.component.html',
    styleUrls: ['./appliance-list.component.scss']
})
export class ApplianceListComponent implements OnInit {
    @Input() spaceId: string;
    @Input() propertyId: string;
    @Input() pageSize: number;
    applianceStream: Observable<Appliance[]>;

    public displayedColumns: TableDisplayedColumn[] = [
        {key: 'id', name: 'ID'},
        {key: 'appliance_category_name', name: 'Category'},
        {key: 'space_name', name: 'Space'},
        {key: 'name', name: 'Name'},
        {key: 'model', name: 'Model'},
        {key: 'brand', name: 'Brand'},
    ];

    constructor(private modalService: BsModalService,
                private propertyService: PropertyService,
                private applianceService: ApplianceService) { }

    public openUpdateDialog(appliance: Appliance) {
        this.modalService.show(ManageApplianceDialogComponent, {
            initialState: {appliance},
        });
    }

    public openCreateDialog() {
        this.modalService.show(ManageApplianceDialogComponent, {
            initialState: {appliance: {space_id: this.spaceId}},
        });

        const subscription = this.modalService.onHide.subscribe((event) => {
            if (event === 'applianceCreated') {
                this.fetchAppliances();
                subscription.unsubscribe();
            }
        });
    }

    public removeAppliance(appliance: Appliance) {
        if (window.confirm(`Are you sure you want to remove ${appliance.appliance_category_name}`)) {
            this.applianceService.deleteAppliance(appliance).subscribe(res => this.fetchAppliances());
        }
    }

    public fetchAppliances(): void {
        if (!_.isNil(this.propertyId)) {
            this.applianceStream = this.propertyService.getAssociatedAppliances(this.propertyId);
        } else {
            this.applianceStream = this.applianceService.getList(
                _.merge({space_id: this.spaceId}, {page_size: this.pageSize})
            );
        }
    }

    ngOnInit() {
        this.fetchAppliances();
    }
}
