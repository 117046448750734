<div mat-dialog-title class="  d-flex">
	<h4 class="modal-title" > Select the new owner of this property. </h4>

	<button type="button"
			class="close"
			aria-label="Close"
			mat-icon-button
			mat-dialog-close>
		<mat-icon>close</mat-icon>
	</button>
</div>
<div class="modal-body" mat-dialog-content>
	<div class="row">
		<div class="col-lg-12">
			<div *ngIf="standardMembersList.length; else noUsers">
				<div class="card mb-2"
					 *ngFor="let member of standardMembersList">
					<div (click)="clickMethod(data.propertyId, member.id, member)"
						 mat-ripple
						 class="card-body p-1 clearfix pointer">

						<c-row>
							<c-col lg="auto">
								<i class="icons icon-user bg-light p-3 font-xl me-3 float-start"></i>
							</c-col>
							<c-col>
								<div class="h6 text-primary mb-0 mt-2">{{member.first_name}} {{member.last_name}}</div>
								<div class="text-secondary font-xs">{{member.email}}</div>
							</c-col>
						</c-row>
					</div>
				</div>
			</div>
			<ng-template class="conteiner"
						 center
						 #noUsers>There are no users who can become owners of this property.
			</ng-template>
		</div>
	</div>

</div>
<div class="modal-footer" mat-dialog-actions>
	<button type="button"
			class="btn btn-secondary"
			mat-dialog-close>Close
	</button>
</div>
