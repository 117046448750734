<div *ngIf="telemetry">
    <dl class="small-list">
        <dt>Probed at</dt>
        <dd>
            <app-multi-time [date]="telemetry.probed_at"
                            [propertyId]="propertyId"
                            [showLocal]="false"
                            [showProperty]="false"></app-multi-time>
            {{ timeAgo(telemetry.probed_at) }}
        </dd>
        <dt>Status</dt>
        <dd>
            {{ telemetry.status }}
        </dd>


        <dt>
            <i class="fa fa-thermometer-full"></i> Water temp
        </dt>
        <dd>
            <c-badge color="info">
                {{ telemetry.water_temp | number : '1.2-2' }} °C
            </c-badge>

        </dd>
        <dt>
            <i class="fa fa-thermometer-full"></i> Ambient temp
        </dt>
        <dd>
            <c-badge color="info">
                {{ telemetry.ambient_temp | number : '1.2-2' }} °C
            </c-badge>
        </dd>
        <dt>
            <i class="fa fa-tint"></i> Waterflow
        </dt>
        <dd>
            <c-badge color="info">
                {{ telemetry.water_flow / 1000 | number : '1.2-2' }}L/m
                <ng-container *ngIf="telemetry.water_flow >100"> - is flowing</ng-container>
            </c-badge>
        </dd>
        <dt>
            <i class="fa fa-tachometer"></i> Pressure
        </dt>

        <dd>
            <c-badge color="info">
                {{ telemetry.pressure / 1000 | number : '1.2-2' }} B
            </c-badge>
        </dd>

    </dl>


</div>
