<div class="modal-content">
	<div class="modal-header">
		<h4 class="modal-title"> Generates a report  </h4>
	</div>
	<div class="modal-body">
		<div class="row mb-3">
            <div [innerHTML]="message"></div>
		</div>
        <div class="col-12 d-flex justify-content-center" *ngIf="showSpinner">
            <div class="spinner-border text-success"
                role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
	</div>
	<div class="modal-footer">
		<button type="button"
				class="btn btn-secondary"
				(click)="onClose()">Close
		</button>
	</div>
</div>
