import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Hub} from '../../../_internal/hub';
import {HubResource} from '../../../providers/resources/hub.resource';
import {HubService} from '../../../providers/services/hub.service';
import {AvailableFirmwareListComponent} from '../../../components/firmware-trigger/list/available-firmware-list.component';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../../../src/environments/environment';
import {TabDirective} from 'ngx-bootstrap/tabs';
import { HubEditComponent } from '../hub-edit/hub-edit.component';
import * as _ from 'lodash';
import { first } from 'rxjs/operators';
@Component({
    selector: 'app-hub-page',
    templateUrl: './hub-page.component.html',
    styleUrls: ['./hub-page.component.scss']
})
export class HubPageComponent implements OnInit {
    public hubId: string;
    public hub: Hub;
    public activeTab = '';
    public modalRef: BsModalRef;

    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                private modalService: BsModalService,
                private hubService: HubService,
                private hubResource: HubResource,
                private toastrService: ToastrService) {
    }

    public fetchHub(): void {
        this.hubResource.get(this.hubId).subscribe(res => {
            this.hub = res.hub;
            this.activeTab = 'firmwares';
        });
    }


    public openUpdateDialog() {
        this.modalService.show(AvailableFirmwareListComponent, {
            initialState: {listType: 'Firmwares', hub: this.hub},
            class: 'firmware-update-list modal-xl',
        });
        const subscription = this.modalService.onHide.subscribe((event) => {
            if (event === 'success') {
                this.fetchHub();
                subscription.unsubscribe();
            }
        });
    }

    ngOnInit() {
        this.hubId = this.activatedRoute.snapshot.paramMap.get('id');
        this.fetchHub();
    }

    public deleteHub(): void {
        if (window.confirm(`Are you sure you want to delete ${this.hubId}?`)) {
            this.hubService.deleteHub(this.hubId).subscribe(

                res => {
                    this.toastrService.success('Settings have been saved successfully.', 'Settings.', environment.toastSettings);
                    this.router.navigateByUrl('/hubs');
                },
                error => {
                    this.toastrService.error( 'An error has ocured while saving data. Error code: ' + error.status + 'Error message' + error.message, 'Settings.', environment.toastSettings);
                }
                );
        }
    }

    public tabSelect($event: TabDirective): void {
        if ($event.id) {
            this.activeTab = $event.id;
        }
    }

    public openEditModal() {
        this.modalRef = this.modalService.show(HubEditComponent, {
            initialState: {
                hub: _.cloneDeep(this.hub)
            }
        });
        this.modalService.onHide
            .pipe(first())
            .subscribe(() => this.fetchHub());
    }
}
