import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {RemoteRestartResponse, RemoteRestartsResponse} from '../resources/dto/remote-restarts.response';
import {RemoteRestartsResource} from '../resources/remote-restarts.resource';

@Injectable()
export class RemoteRestartsService {

    constructor(private remoteRestartsResource: RemoteRestartsResource) {
    }


    public getRestarts(params = {}): Observable<RemoteRestartsResponse> {
        return this.remoteRestartsResource.getRestarts(params);
    }

    public triggerRestart(params = {}): Observable<RemoteRestartResponse> {
        return this.remoteRestartsResource.triggerRestart(params);
    }
}
