import { Component, OnInit } from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ManufacturingService} from '../../../../hero-labs/providers/services/manufacturing.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../../../src/environments/environment';
@Component({
  selector: 'app-device-import',
  templateUrl: './device-import.component.html',
  styleUrls: ['./device-import.component.scss']
})
export class DeviceImportComponent implements OnInit {

    public fileName= '';
    public message = 'Add the device data file you want to import by <strong>clicking the paperclip icon</strong>.';
    public file: File;
    constructor(private toastrService: ToastrService,
                private bsModalRef: BsModalRef,
                private manufacturingService: ManufacturingService) {
    }

    ngOnInit(): void {
        this.bsModalRef.setClass('confirmation-dialog');
    }

    public onFileSelected(event): void {
        this.file = event.target.files[0];
        this.fileName = this.file.name;
        this.message = 'Click confirm to <strong>import</strong> device data. You can also cancel it by clicking <strong>cancel</strong> button.';
    }

    public close(): void {
        this.bsModalRef.hide();
    }

    public confirm(): void {
        this.manufacturingService.postDeviceData(this.file).subscribe(
            () => this.toastrService.success('Device imported successfully', 'Device import', environment.toastSettings),
            error => this.toastrService.error( 'An error has ocured while importing the data. Error code: ' + error.status + 'Error message: ' + error.error, 'Device import', environment.toastSettings)
        );
        this.bsModalRef.hide();
    }

}
