import {Component, Input, OnInit} from '@angular/core';
import {User} from '../../../_internal/user';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {UserResource} from '../../../providers/resources/user.resource';
import {RoleResource} from '../../../providers/resources/role.resource';
import {Role} from '../../../_internal/role';
import * as _ from 'lodash';
import {OrganisationResource} from '../../../providers/resources/organisation.resource';
import {Organization} from '../../../_internal/organization';
import {HttpErrorResponse} from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../../../src/environments/environment';
import { UserService } from '../../../providers/services/user.service';

@Component({
    selector: 'app-user-edit',
    templateUrl: './user-edit.component.html',
    styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit {
    @Input() user: User;
    @Input() action: string;
    public roles: Role[];
    public organisations: Organization[];
    public userForm: FormGroup;
    public errorMessage: string;
    public apiError: string;
    public apiErrors: [];
    private minFirstNameLength = 1;
    private minLastNameLength = 1;
    private minPhoneLength = 6;
    private minPasswordLength = 6;

    constructor(private _bsModalRef: BsModalRef,
                private roleResource: RoleResource,
                private organisationResource: OrganisationResource,
                private userResource: UserResource,
                private toastrService: ToastrService,
                private userService: UserService) {
    }

    public ngOnInit(): void {
        this.initFormGroup();
        this.fetchRoles();
        this.fetchOrganisations();
    }

    public initFormGroup(): void {
        this.userForm = new FormGroup({
            first_name: new FormControl(_.get(this.user, 'first_name'), [Validators.required, Validators.minLength(this.minFirstNameLength)]),
            last_name: new FormControl(_.get(this.user, 'last_name'), [Validators.required, Validators.minLength(this.minLastNameLength)]),
            phone: new FormControl(_.get(this.user, 'phone'), [Validators.required, Validators.minLength(this.minPhoneLength)]),
            email: new FormControl(_.get(this.user, 'email'), [Validators.required, Validators.email]),
            password: new FormControl(_.get(this.user, 'password'), [Validators.required, Validators.minLength(this.minPasswordLength)]),
            active: new FormControl(_.get(this.user, 'active')),
            roles: new FormControl({value: _.get(this.user, 'roles'), disabled: !this.userService.isRoot()}),
            organisations: new FormControl(_.get(this.user, 'organizations')),
        });
        this.userForm.valueChanges.subscribe((values) => {
            if (this.user) {
                this.user.first_name = values.first_name;
                this.user.last_name = values.last_name;
                this.user.phone = values.phone;
                this.user.active = values.active;
                this.user.email = values.email;
                this.user.roles = values.roles;
                this.user.organizations = values.organisations;
                this.user.role_ids = _.map(values.roles, 'id');
                this.user.organization_ids = _.map(values.organisations, 'id');

                if (this.action === 'create') {
                    this.user.password = values.password;
                }
            } else {
                this.user = {
                    first_name: values.first_name,
                    last_name: values.last_name,
                    phone: values.phone,
                    active: values.active,
                    email: values.email,
                    roles: values.roles,
                    organizations: values.organizations,
                    role_ids: _.map(values.roles, 'id'),
                    organization_ids: _.map(values.organisations, 'id'),
                };
            }
        });
    }

    public onConfirm(): void {
        this.resetErrors();

        switch (this.action) {
            case 'create':
                if (this.userForm.invalid) {
                    this.errorMessage = 'Please fill up the form';
                } else {
                    this.userResource.createUser(this.user).subscribe(
                        response => {
                            this.toastrService.success('Settings have been saved successfully.', 'Settings.', environment.toastSettings);
                            this._bsModalRef.hide();
                        },
                        error => {
                            this.toastrService.error( 'An error has ocured while loading page data. Error code: ' + error.status + 'Error message' + error.message, 'Loading page data.', environment.toastSettings);
                            this.handleError(error);
                        }
                    );
                }
                break;
            case 'update':
                this.userResource.updateUser(this.user).subscribe(
                    response => {
                        this.toastrService.success('Settings have been saved successfully.', 'Settings.', environment.toastSettings);
                        this._bsModalRef.hide();
                    },
                    error => {
                        this.toastrService.error( 'An error has ocured while loading page data. Error code: ' + error.status + 'Error message' + error.message, 'Loading page data.', environment.toastSettings);
                        this.handleError(error);
                    }
                );
                break;
        }
    }

    public onCancel(): void {
        this._bsModalRef.hide();
    }

    public fetchRoles(): void {
        this.roleResource.getRoleList().subscribe(response => {
            this.roles = response;
        });
    }

    public fetchOrganisations(): void {
        this.organisationResource.getOrganisationList().subscribe(response => {
            this.organisations = response;
        });
    }

    public hasErrors(controlName: string): boolean {
        return this.apiErrors && _.get(this.apiErrors, controlName);
    }

    private handleError(error: HttpErrorResponse): void {
        this.apiError = _.get(error, 'error.error');
        this.apiErrors = _.get(error, 'error.errors');
    }

    private resetErrors(): void {
        this.apiErrors = [];
        this.apiError = '';
        this.errorMessage = '';
    }
}
