import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {tap} from 'rxjs/operators';
import {UserService} from './user.service';
import {ActivatedRouteSnapshot, Router} from '@angular/router';
import * as _ from 'lodash';

@Injectable()
export class RoleGuardService {

    constructor(private userService: UserService, private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot): boolean{
        const roles = _.get(route, 'data.role');
        for (const role of roles) {
            if(this.userService.hasRole(role)) {
                return true;
            }
        }
        return false;
    }
}
