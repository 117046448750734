import {Injectable} from '@angular/core';
import {PropertyResource} from '../resources/property.resource';
import {PropertyCreate} from '../../_internal/property.create';
import {Observable} from 'rxjs';
import {PropertiesResponse, PropertyResponse} from '../resources/dto/property.response';
import {AppliancesResponse} from '../resources/dto/appliance.response';
import {Appliance} from '../../_internal/appliance';
import {map} from 'rxjs/operators';
import {PropertySettings} from '../../_internal/property.settings';
import {NotificationSettings} from '../../_internal/notification-settings';
import {Property} from '../../_internal/property';
import {Hub} from '../../_internal/hub';
import * as _ from 'lodash';
import { IncidentDetailsResponse } from '../resources/dto/incidents.response';
import {HttpParams} from '@angular/common/http';
import {OptionalHttpParams} from '../../../common/api/CrudAware';
import {Pageable} from '../../_internal/Pageable';
import {Incident} from '../../_internal/incident';

@Injectable({
    providedIn: 'root'
})

export class PropertyService {
    constructor(private propertyResource: PropertyResource) {
    }

    public getProperty(id): Observable<PropertyResponse> {
        return this.propertyResource.getProperty(id);
    }

    public getPropertiesList(params = {}): Observable<PropertiesResponse> {
        return this.propertyResource.getPropertiesList(params);
    }

    public getIncidentsList(propertyId: string, params?: HttpParams | OptionalHttpParams): Observable<Pageable<Incident>> {
        return this.propertyResource.getIncidentsList(propertyId, params);
    }

    public createProperty(propertyData: PropertyCreate): Observable<PropertyResponse> {
        return this.propertyResource.createProperty(propertyData);
    }

    public updateProperty(propertyId: string, propertyPayload): Observable<PropertyResponse> {
        return this.propertyResource.updateProperty(propertyId, propertyPayload);
    }

    public resolveIncident( incidentId: number): Observable<IncidentDetailsResponse> {
        return this.propertyResource.resolveIncident(incidentId);
    }

    public getAssociatedAppliances(propertyId: string): Observable<Appliance[]> {
        return this.propertyResource.getAssociatedAppliances(propertyId)
            .pipe(map((res: AppliancesResponse) => res.appliances));
    }

    public getSettings(propertyId: string): Observable<PropertySettings> {
        return this.propertyResource.getSettings(propertyId);
    }

    public getNotificationSettings(propertyId: string): Observable<NotificationSettings> {
        return this.propertyResource.getNotificationSettings(propertyId);
    }

    public updateNotificationSettings(propertyId: string, payload: NotificationSettings): Observable<NotificationSettings> {
        return this.propertyResource.updateNotificationSettings(propertyId, payload);
    }

    public getAllHubsFromProperty(property: Property): Hub[] {
        return _.flatMapDeep(property.spaces, (s) => s.hubs);
    }
}
