import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import * as moment from 'moment-timezone';
import {Moment} from 'moment-timezone';
import {PropertyResource} from '../../providers/resources/property.resource';

@Component({
    selector: 'app-multi-time',
    templateUrl: './multi-time.component.html',
    styleUrls: ['./multi-time.component.scss']
})
export class MultiTimeComponent implements OnInit, OnChanges {

    @Input() propertyId: string;
    @Input() date: Date | Moment | string;
    @Input() showLocal = true;
    @Input() showUtc = true;
    @Input() showProperty = true;
    @Input() dateFormat = 'YYYY-MM-DD HH:mm:ss';
    @Input() inputFormat;

    public displayDate: Moment;
    private timezone = 'Europe/London';


    constructor(private propertyResource: PropertyResource) {
    }

    ngOnInit() {
        this.displayDate = moment(this.date, this.inputFormat);
        if (this.propertyId) {
            this.propertyResource.getSettings(this.propertyId).subscribe(response => {
                if (response.timezone) {
                    this.timezone = response.timezone;
                }
            });
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.date) {
            this.date = changes.date.currentValue;
            this.displayDate = moment(this.date, this.inputFormat);
        }
    }

    public getPropertyTime(): Moment {
        return this.displayDate.clone().tz(this.timezone);
    }
}
