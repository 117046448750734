<div class="modal-content">
	<div class="modal-header">
		<h4 class="modal-title"> Edit organisation </h4>

		<button type="button"
				class="close"
				aria-label="Close"
				mat-icon-button
				(click)="onCancel()">
			<mat-icon>close</mat-icon>
		</button>
	</div>
	<div class="modal-body" *ngIf="organisation">
		<div class="row">
			<div class="col-lg-6">

				<div class="form-group">
					<label>Name</label>
					<input type="text" class="form-control" [(ngModel)]="organisation.name">
				</div>

			</div>
			<div class="col-lg-6">

				<div class="form-group">
					<label><input type="checkbox" [(ngModel)]="organisation.active"> Active </label>
				</div>

			</div>
		</div>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-secondary" (click)="onCancel()">Close</button>
		<button type="button" class="btn btn-primary" (click)="onConfirm()">Save changes</button>
	</div>
</div>
