import {HeroPagesRoutes} from './hero-labs/pages/hero-pages.routes';
import {NgModule} from '@angular/core';
// Import Containers
import {DefaultLayoutComponent} from './containers';
import {P404Component} from './views/error/404.component';
import {LoginComponent} from './views/login/login.component';
import {AuthGuardService} from './hero-labs/providers/services/auth-guard.service';
import {ResetPasswordComponent} from './views/reset-password/reset-password.component';
import {RouterModule, Routes} from '@angular/router';

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
    },
    {
        path: 'login',
        component: LoginComponent,
        data: {
            title: 'Login',
        },
    },
    {
        path: 'reset-password',
        component: ResetPasswordComponent,
        data: {
            title: 'Password reset',
        },
    },
    {
        path: '',
        canActivate: [AuthGuardService],
        component: DefaultLayoutComponent,
        data: {
            title: 'Home'
        },
        children: HeroPagesRoutes

    },
    {path: '**', component: P404Component}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        anchorScrolling: 'enabled'
    })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
