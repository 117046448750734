import {EventsResponse, LastEvaluatedKey} from './../../../providers/resources/dto/event.response';
import {TableDisplayedColumn} from './../../../../common/components/data-table/table-displayed-column';
import {ApplianceCategoryService} from './../../../providers/services/appliance-category.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {EventService} from './../../../providers/services/event.service';
import {Component, OnInit} from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import {ActivatedRoute, Router} from '@angular/router';
import {ApplianceCategory} from '../../../_internal/appliance_category';
import {Event} from '../../../_internal/event';
import {EventsDisplayFactory} from './events-display-factory';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {DataTableFilter} from '../../../../common/components/data-table/data-table-filter.pipe';

@Component({
    selector: 'app-event-list',
    templateUrl: './event-list.component.html',
    styleUrls: ['./event-list.component.scss']
})
export class EventListComponent implements OnInit {

    public static readonly maxEventLengthInMs = 150 * 60 * 1000; // 2,5 h
    @BlockUI() blockUI: NgBlockUI;
    public eventStart: string;
    public eventEnd: string;
    public startHour: string;
    public endHour: string;
    public date: string;
    public notLabelledAt: string;
    public deviceId = '';
    public lastEvaluatedKey: LastEvaluatedKey;
    public displayedColumns: TableDisplayedColumn[];

    public applianceCategories: ApplianceCategory[];
    public datepickerInitialValue: Date;

    public eventsFilter: DataTableFilter = {key: 'water_consumed', comparisionType: '>', value: 50};
    private eventStream = new BehaviorSubject([]);

    constructor(private eventService: EventService,
                private router: Router,
                private route: ActivatedRoute,
                private applianceCategoryService: ApplianceCategoryService) {
    }

    public onDatePick(date): void {
        this.date = moment(date).utc().format('YYYY-MM-DD');

        if (this.startHour === undefined) {
            this.startHour = moment.utc().subtract(1, 'hours').format('HH:mm');
            this.endHour = moment.utc().format('HH:mm');
        }

        this.onRangeChange();
    }

    public onRangeChange(): void {
        this.blockUI.start();

        this.startHour = this.startHour !== '' ? this.startHour : moment().format('HH:mm');
        this.endHour = this.endHour !== '' ? this.endHour : moment().add(1, 'hour').format('HH:mm');

        this.updateEventStream(this.date,
            moment(`${this.date} ${this.startHour}`).valueOf().toString(),
            moment(`${this.date} ${this.endHour}`).valueOf().toString()
        );
    }

    public onFilterLabelChange(notLabelledAt: string): void {
        this.notLabelledAt = notLabelledAt;
        this.manipulateQueryParams();
    }

    public updateEventStream(day, start, end): void {
        this.eventStart = start;
        this.eventEnd = end;
        this.manipulateQueryParams();

        const stream: Observable<EventsResponse> = this.deviceId === '' ? this.eventService.getEvents(day, start, end, this.notLabelledAt) :
            this.eventService.getEventsByDeviceId(day, start, end, this.notLabelledAt, this.deviceId);

        stream.subscribe(res => {
            this.eventStream.next(
                this.lastEvaluatedKey !== null ? _.uniqBy(this.eventStream.value.concat(res.events), 'event_start') : res.events
            );
            this.lastEvaluatedKey = res.last_evaluated_key;
            this.blockUI.stop();
        });
    }

    public manipulateQueryParams() {
        this.router.navigate(['.'],
            {
                relativeTo: this.route, queryParams: this.getFilters()
            });
    }

    public getEventStream(): Observable<Event[]> {
        return this.eventStream.asObservable();
    }

    public loadMore(): void {
        this.updateEventStream(this.date, this.lastEvaluatedKey.event_start, this.eventEnd);
    }

    public goTo(paths) {
        this.router.navigate(paths);
    }

    public goToEvent(eventStart, deviceId, index, dataSet) {
        const filterHistory = JSON.stringify(this.getFilters());

        const nextEvents = _.reverse(_.slice(dataSet, 0, index));
        const queryParams = _.map(nextEvents, (e: Event) => JSON.stringify({id: e.device_id, start: e.event_start}));
        this.router.navigate(['events', eventStart, deviceId], {
            queryParams: {
                nextEvents: queryParams,
                filters: filterHistory
            }
        });
    }

    public getFilters() {
        return {
            startHour: this.startHour,
            endHour: this.endHour,
            deviceId: this.deviceId,
            date: this.date,
            notLabelledAt: this.notLabelledAt
        };
    }

    public fetchApplianceCategories() {
        this.applianceCategoryService.getMany().subscribe(
            (res) => {
                this.applianceCategories = res;
                this.displayedColumns = EventsDisplayFactory.build(this.applianceCategories);
            }
        );
    }

    public canViewEventDetails(event: Event): boolean {
        return (+event.event_end - +event.event_start) < EventListComponent.maxEventLengthInMs;
    }

    ngOnInit() {
        this.fetchApplianceCategories();
        this.route.queryParams.subscribe(params => {
            this.startHour = params['startHour'];
            this.endHour = params['endHour'];
            this.deviceId = params['deviceId'] !== undefined ? params['deviceId'] : '';
            this.date = params['date'] !== undefined ? params['date'] : moment(new Date()).format('YYYY-MM-DD');
            this.notLabelledAt = params['notLabelledAt'] !== undefined ? params['notLabelledAt'] : 'everything';
            this.datepickerInitialValue = new Date(this.date);
        });
    }

}
