import {Injectable} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {OptionalHttpParams} from '../../../common/api/CrudAware';
import {Observable} from 'rxjs';
import { SubscriptionResource } from '../resources/subscription.resource';
import { SubscriptionData } from '../resources/dto/subscription.response';

@Injectable({
    providedIn: 'root'
})
export class SubscriptionService {

    constructor(private subscriptionResource: SubscriptionResource) {
    }

    public getSubscriptionData(id: string): Observable<SubscriptionData> {
        return this.subscriptionResource.getSubscriptionData(id);
    }

    public createSubscription(params: HttpParams | OptionalHttpParams): Observable<SubscriptionData> {
        return this.subscriptionResource.createSubscription(params);
    }

    public updateSubscription(id: string, params: HttpParams | OptionalHttpParams): Observable<SubscriptionData> {
        return  this.subscriptionResource.updateSubscription(id, params);
    }
}
