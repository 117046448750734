import { Component, Input, OnInit } from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {FormBuilder} from '@angular/forms';

@Component({
  selector: 'app-stats-page-columns',
  templateUrl: './stats-page-columns.component.html',
  styleUrls: ['./stats-page-columns.component.scss']
})
export class StatsPageColumnsComponent implements OnInit {

    @Input() public columnsToggle: {
        [key: string]: boolean;
      };
    formGroup = this.formBuilder.group({
        Name: true,
        'Cloud connection': true,
        Valve: true,
        'Valve on/off changes': true,
        'Valve all changes': true,
        Events: true,
        Incidents: true,
        'Hub events': true,
        'Thing events': true,
        'Offline time': true,
        'Online time': true,
        'Power source': true,
      });
  constructor( private modalService: BsModalService,
                private bsModalRef: BsModalRef,
                private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.formGroup.get('Name').setValue(this.columnsToggle['Name']);
    this.formGroup.get('Cloud connection').setValue(this.columnsToggle['Cloud connection']);
    this.formGroup.get('Cloud connection').setValue(this.columnsToggle['Cloud connection']);
    this.formGroup.get('Valve').setValue(this.columnsToggle['Valve']);
    this.formGroup.get('Valve on/off changes').setValue(this.columnsToggle['Valve on/off changes']);
    this.formGroup.get('Valve all changes').setValue(this.columnsToggle['Valve all changes']);
    this.formGroup.get('Events').setValue(this.columnsToggle['Events']);
    this.formGroup.get('Incidents').setValue(this.columnsToggle['Incidents']);
    this.formGroup.get('Hub events').setValue(this.columnsToggle['Hub events']);
    this.formGroup.get('Thing events').setValue(this.columnsToggle['Thing events']);
    this.formGroup.get('Offline time').setValue(this.columnsToggle['Offline time']);
    this.formGroup.get('Power source').setValue(this.columnsToggle['Power source']);

  }

  public close(): void {
    this.modalService.setDismissReason('close');
    this.bsModalRef.hide();
}

    public save(): void {
        this.modalService.setDismissReason(this.formGroup.value);
        this.bsModalRef.hide();
    }
}
