import { Injectable } from '@angular/core';
import { SwapResponse } from '../../_internal/swap-data';
import { SwapResource } from '../resources/swap.resource';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SwapService {

  constructor(private swapResource: SwapResource) { }

  public thingSwaps(thingId: string): Observable<SwapResponse> {
    return this.swapResource.thingSwaps(thingId);
}

public hubSwaps(hubID: string): Observable<SwapResponse> {
    return this.swapResource.hubSwaps(hubID);
}

}
