import {IncidentListPageComponent} from './incident-list/incident-list-page.component';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {IncidentDetailsComponent} from './incident-details/incident-details.component';


const routes: Routes = [
    {
        path: '',
        children: [
            {
                path: '',
                component: IncidentListPageComponent,
                data: {
                    title: 'Incidents list',
                },
            },
            {
                path: 'state/:state',
                component: IncidentListPageComponent,
                data: {
                    title: 'Incidents list by state',
                },
            },
            {
                path: 'status/:status',
                component: IncidentListPageComponent,
                data: {
                    title: 'Incidents list by status',
                },
            },
            {
                path: ':id',
                component: IncidentDetailsComponent,
                data: {
                    title: 'Incident',
                },
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class IncidentPagesRoutingModule {
}
