import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {SpaceResponse} from '../resources/dto/space.response';
import {SpaceResource} from '../resources/space.resource';
import {Space} from '../../_internal/space';

@Injectable()
export class SpaceService {

    constructor(private spaceResource: SpaceResource) {
    }

    public getSpace(spaceId: string): Observable<SpaceResponse> {
        return this.spaceResource.getSpace(spaceId);
    }

    public createSpace(propertyId: string, spaceName: string): Observable<SpaceResponse> {
        return this.spaceResource.createSpace(propertyId, spaceName);
    }

    public deleteSpace(spaceId: string): Observable<SpaceResponse> {
        return this.spaceResource.deleteSpace(spaceId);
    }

    public updateSpace(space: Space): Observable<SpaceResponse> {
        return this.spaceResource.updateSpace(space);
    }
}
