<div class="bg-light min-vh-100 d-flex flex-row align-items-center">
	<c-container>
		<div class="row">
			<div class="col-md-12 mx-auto">
				<div class="card-group">
					<div class="card p-4 flex-conteiner">
						<div class="card-body">
							<form [formGroup]="loginForm"
								  *ngIf="loginForm">
								<h1>Login</h1>
								<p class="text-muted">Sign In to your account</p>
								<div role="alert"
									 class="alert alert-danger"
									 *ngIf="error && error !== ''">
									{{error}}
								</div>

								<c-input-group class="mb-3">
									<span cInputGroupText>
										<svg cIcon
											 name="cilUser"></svg>
									</span>
									<input autoComplete="email"
										   cFormControl
										   placeholder="E-mail"
										   type="email"
										   formControlName="email"
										   (keyup.enter)="passwordField.focus()"
										   required
									/>
								</c-input-group>


								<c-input-group class="mb-4">
                  <span cInputGroupText>
                    <svg cIcon
						 name="cilLockLocked"></svg>
                  </span>
									<input type="password"
										   #passwordField
										   (keyup.enter)="onSubmit()"
										   class="form-control"
										   cFormControl
										   placeholder="Password"
										   formControlName="password"
										   required>
								</c-input-group>
								<div class="row">
									<div class="col-6">
										<button type="button"
												(click)="onSubmit()"
												class="btn btn-primary px-4">
											Login
										</button>
									</div>
									<div class="col-6 text-right">
										<button type="button"
												class="btn btn-link px-0"
												(click)="goToReset()">Forgot
											password?
										</button>
									</div>
								</div>
							</form>
						</div>
					</div>
					<div class="card text-white py-5 d-md-down-none flex-conteiner">
						<div class="card-body text-center flex-center">
							<div>
								<img src="assets/img/brand/logo-black.svg"
									 alt="">
							</div>

						</div>
					</div>
				</div>
			</div>
		</div>
	</c-container>
		<div class="row mt-auto">
			<div class="col-auto ml-auto">
				<span class="app-version">ver-{{version}}</span>
			</div>
		</div>
</div>
