import {EChartsOption} from 'echarts';

export const lineChartDefaultOptions: EChartsOption = {
    tooltip: {
        trigger: 'axis',
        position: (pt) => [pt[0] + 20, '20%']
    },
    toolbox: {
        feature: {
            dataZoom: {
                yAxisIndex: 'none'
            },
            restore: {},
            saveAsImage: {}
        }
    },
    dataZoom: [
        {
            type: 'inside',
        },
        {}
    ],
    yAxis: {
        min: 'dataMin',
    },
    animation: true,
};
