import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserResource} from '../../../providers/resources/user.resource';
import {User} from '../../../_internal/user';
import {PropertyResource} from '../../../providers/resources/property.resource';
import {Property} from '../../../_internal/property';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {UserEditComponent} from '../user-edit/user-edit.component';
import {take} from 'rxjs/operators';
import * as  _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../../../src/environments/environment';
import { SubscriptionData } from '../../../providers/resources/dto/subscription.response';
import { SubscriptionEditComponent } from '../subscription-edit/subscription-edit.component';
import { SubscriptionService } from '../../..//providers/services/subscription.service';

@Component({
    selector: 'app-user-page',
    templateUrl: './user-page.component.html',
    styleUrls: ['./user-page.component.scss']
})
export class UserPageComponent implements OnInit {
    public userId: string;
    public user: User;
    public properties: Property[];
    public modalRef: BsModalRef;
    public keys = Object.keys;
    public subscriptionData: SubscriptionData;

    constructor(private activatedRoute: ActivatedRoute,
                private propertyResource: PropertyResource,
                private modalService: BsModalService,
                private router: Router,
                private userResource: UserResource,
                private toastrService: ToastrService,
                private subscriptionService: SubscriptionService
                ) {
    }

    ngOnInit() {
        this.userId = this.activatedRoute.snapshot.paramMap.get('id');
        this.fetchUser();
        this.fetchProperties();
        this.getSubscriptionData();
    }

    public getSubscriptionData(): void{
        this.subscriptionService.getSubscriptionData(this.userId).subscribe(data => this.subscriptionData = data[0]);

    }

    public fetchUser(): void {
        this.userResource.getUser(this.userId).subscribe(response => {
            this.user = response;
        }, () => this.router.navigateByUrl('/users'));
    }

    public fetchProperties(): void {
        this.propertyResource.getPropertiesByUserId(this.userId).subscribe(response => {
            this.properties = response;
        });
    }

    public goToUser(userId) {
        this.router.navigate(['users', userId]);
    }

    public modalStyle(event): string {
        switch (event) {
            case 'update':
                return 'alert-primary';
            case 'create':
                return 'alert-success';
            default:
                return 'alert-secondary';
        }
    }

    public openEditModal(): void {
        this.modalRef = this.modalService.show(UserEditComponent, {
            initialState: {
                action: 'update',
                user: _.cloneDeep(this.user)
            }
        });

        this.modalService.onHide
            .pipe(take(1))
            .subscribe(() => this.fetchUser());
    }

    public deleteUser(): void {
        if (window.confirm(`Are you sure you want to delete ${this.user.full_name}?`)) {
            this.userResource.deleteUser(this.userId).subscribe(
                response => {
                    this.router.navigateByUrl('/users');
                    this.toastrService.success('User has been deleted.', 'User.', environment.toastSettings);
                },
                err => this.toastrService.error( 'An error has ocured while deleting a user. Error code: ' + err.status + 'Error message' + err.message, 'Loading page data.', environment.toastSettings)
            );
        }
    }

    public openSubscriptionModal(): void {
        this.modalRef = this.modalService.show(SubscriptionEditComponent, {
            initialState: {
                userId: this.userId,
                subscriptionData: this.subscriptionData
            }
        });
        const subscription = this.modalService.onHide.subscribe((event) => {
            if (event === 'success') {
                this.getSubscriptionData();
            }
        });
    }
}
