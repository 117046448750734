import {EventPageComponent} from './event-page/event-page.component';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {EventListComponent} from './event-list/event-list.component';


const routes: Routes = [
    {
        path: '',
        children: [
            {
                path: '',
                component: EventListComponent,
                data: {
                    title: 'Events list',
                },
            },
            {
                path: ':event_start/:device_id',
                component: EventPageComponent,
                data: {
                    title: 'Event',
                },
            },
        ]
    },

];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class EventPagesRoutingModule {
}
