import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {SpaceService} from '../../../providers/services/space.service';
import {Space} from '../../../_internal/space';
import {AddSpaceComponent} from '../../../components/spaces-list/add-space/add-space.component';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-list',
    templateUrl: './space-page.component.html',
    styleUrls: ['./space-page.component.scss']
})
export class SpacePageComponent implements OnInit {

    public spaceId: string;
    public space: Space;
    public propertyId: string;
    public readonly pageSize = 999;
    public modalRef: BsModalRef;

    constructor(private modalService: BsModalService,
                private route: ActivatedRoute,
                private spaceService: SpaceService) {
    }

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.spaceId = params.spaceId;
            this.propertyId = params.propertyId;
            this.fetchSpace();
        });
    }

    public openEditDialog(space: Space): void {
        this.modalRef = this.modalService.show(AddSpaceComponent, {
            initialState: {
                space,
                propertyId: this.propertyId
            }
        });
    }

    private fetchSpace() {
        this.spaceService.getSpace(this.spaceId)
            .subscribe((response) => this.space = response.space);
    }
}
