<div class="modal-content">
    <div class="modal-header">
        {{title}}
        <div class="button-position">
            <button *ngIf="rounded; else roundedValues"
                    type="button"
                    class="btn btn-sm btn-secondary btn-sm ml-2"
                    (click)="roundToggle()">
                Full values
            </button>
            <ng-template #roundedValues>
                <button
                    type="button"
                    class="btn btn-sm btn-secondary btn-sm ml-2"
                    (click)="roundToggle()">
                    Rounded values
                </button>
            </ng-template>
            <button type="button"
                    class="close"
                    aria-label="Close"
                    mat-icon-button
                    (click)="close()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
    <div class="modal-body">
        <div class="flex-container">
            <table class="table table-bordered table-striped table-responsive result-table w-100 d-block">
                <thead class="thead-dark">
                <tr>
                    <th>#</th>
                    <th *ngFor="let header of headersRow" scope="col">{{header}}</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let record of records; let index=index">
                    <td>{{index}}</td>
                    <td *ngFor="let item of record ">
                        <ng-container *ngIf="rounded;else full">
                            {{item | slice:0:6}}
                        </ng-container>
                        <ng-template #full>
                            {{item | slice:0:12}}
                        </ng-template>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="close()">Cancel</button>
    </div>
</div>
