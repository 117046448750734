import {AfterViewInit, ChangeDetectorRef, Component, Input, TemplateRef, ViewChild} from '@angular/core';
import {provisionFilterFields} from '../../pages/manufacturing/manufacturing-config';
import * as _ from 'lodash';
import {ProvisionReport} from '../../_internal/provision-report';
import {Observable} from 'rxjs';
import {TableComponent} from '../table/table.component';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {JsonDialogComponent} from '../../../common/components/dialogs/json-modal/json-dialog.component';
import {OptionalHttpParams} from '../../../common/api/CrudAware';
import {ProvisionReportResponse} from '../../providers/resources/dto/provision-report.response';
import {tableSettings} from '../../_internal/table-settings';
import {InformationDialogComponent} from '../../../common/components/dialogs/information-dialog/information-dialog.component';
import {ManufacturingService} from '../../providers/services/manufacturing.service';

@Component({
    selector: 'app-provision-reports-table',
    templateUrl: './provision-reports-table.component.html',
    styleUrls: ['./provision-reports-table.component.scss'],
})
export class ProvisionReportsTableComponent implements AfterViewInit {
    @ViewChild(TableComponent) table: TableComponent;
    @ViewChild('dateColumn') dateColumn: TemplateRef<any>;
    @ViewChild('resultsFailedColumn') resultsFailedColumn: TemplateRef<any>;
    @ViewChild('resultsPassedColumn') resultsPassedColumn: TemplateRef<any>;
    @ViewChild('actionsColumn') actionsColumn: TemplateRef<any>;
    @Input() filterFields = provisionFilterFields;
    @Input() showDeviceLink = true;
    public tableSettings = _.cloneDeep(tableSettings);
    public rows: ProvisionReport[];
    public columns = [];
    public modalRef: BsModalRef;

    constructor(private manufacturingService: ManufacturingService,
                private cdRef: ChangeDetectorRef,
                private modalService: BsModalService) {
    }

    @Input()
    public getData: (params?: OptionalHttpParams) => Observable<ProvisionReportResponse> = (params?: OptionalHttpParams) => this.manufacturingService.getProvisionReports(params);

    public ngAfterViewInit(): void {
        this.columns = [
            {prop: 'serial_no', name: 'Serial no', flexGrow: 1, sortable: false, cellTemplate: this.table.codeColumn},
            {cellTemplate: this.dateColumn, prop: 'created_at', name: 'Created at', flexGrow: 1, sortable: false},
            {
                cellTemplate: this.resultsFailedColumn,
                prop: 'result',
                name: 'Failed units',
                flexGrow: 1.5,
                sortable: false
            },
            {
                cellTemplate: this.resultsPassedColumn,
                prop: 'result',
                name: 'Test passed?',
                flexGrow: 1,
                sortable: false
            },
            {
                cellTemplate: this.actionsColumn, name: 'Actions', flexGrow: 2, sortable: false
            }
        ];

    }


    public openFullReportModal(report: ProvisionReport) {
        this.modalService.show(JsonDialogComponent, {
            initialState: {
                content: report.result,
                title: `Generated at: ${report.created_at} Serial no: ${report.serial_no}`,
            },
            class: 'modal-xl'
        });
    }

    public openTestDetailsModal(report: ProvisionReport) {
        this.modalService.show(JsonDialogComponent, {
            initialState: {
                content: JSON.parse(report.test_details),
                serial: report.serial_no,
                title: `Test details for Serial no: ${report.serial_no}`,
                nestedJson: true
            },
            class: 'modal-xl'
        });
    }

    public failedUnits(result): string[] {
        const failed = [];
        for (const test in result) {
            if (result[test] !== 'ok') {
                failed.push(test);
            }
        }

        return failed;
    }

    public reportPassed(result): boolean {
        return this.failedUnits(result).length === 0;
    }


    public fetchPage(params?: OptionalHttpParams): void {
        _.unset(params, 'order_by');
        _.unset(params, 'order_strategy');
        this.getData(params)
            .subscribe((response: ProvisionReportResponse) => {
                this.table.setPageParams(response);
                this.rows = response.data;
                this.table.isLoading = false;
                this.cdRef.detectChanges();
            });

    }

    public manufacturingFeedbackModal( feedback: string) {
        this.modalRef = this.modalService.show(InformationDialogComponent, {
            initialState: {
                modalTitle: 'Feedback',
                modalContent: feedback,
            }
        });
    }
}
