import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ConfirmationDialogComponent} from '../../../common/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import {ValueStatusButtonComponent} from '../../pages/things/value-status-button/value-status-button.component';
import {Thing} from '../../_internal/thing';
import {environment} from '../../../../environments/environment';
import {finalize, take} from 'rxjs/operators';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ThingService} from '../../providers/services/thing.service';
import {ToastrService} from 'ngx-toastr';
import {PusherService} from '../../providers/services/pusher.service';
import {PusherStateChangeMessage} from '../../_internal/pusher.state.change.message';
import {BlockUIService} from 'ng-block-ui';
import * as _ from 'lodash';
import { UserService } from '../../providers/services/user.service';

@Component({
    selector: 'app-thing-actions',
    templateUrl: './thing-actions.component.html',
    styleUrls: ['./thing-actions.component.scss']
})
export class ThingActionsComponent implements OnInit, OnDestroy {

    @Input() thing: Thing;
    @Input() isSonicWifi = false;
    public thingId: string;
    public loadingState = false;
    public modalRef: BsModalRef;
    public pusherChannel;


    constructor(private modalService: BsModalService,
                private thingService: ThingService,
                private toastrService: ToastrService,
                private pusher: PusherService,
                private blockUI: BlockUIService,
                public userService: UserService) {
    }

    ngOnInit(): void {
        this.thingId = this.thing.id;
    }

    ngOnDestroy() {
        this.closePusher();
    }

    public triggerPressureTest() {
        this.modalService.show(ConfirmationDialogComponent, {
            initialState: {
                message: `Are you sure you want to trigger pressure test?`,
                title: 'Trigger pressure test',
                button: 'Trigger'
            }
        });
        const subscription = this.modalService.onHide.subscribe((reason) => {
            if (reason === 'action-confirmed') {
                this.thingService.triggerPressureTests(this.thing.id).subscribe(
                    res => {
                        this.toastrService.success('Pressure test was triggered.', 'Settings.', environment.toastSettings);
                    },
                    error => {
                        this.toastrService.error('An error has ocured while triggering pressure test. Error code: ' + error.status + 'Message' + error.message, 'Settings.', environment.toastSettings);
                    }
                );
            }
            subscription.unsubscribe();
        });
    }

    public openValveStatusModal(): void {
        this.modalRef = this.modalService.show(ValueStatusButtonComponent, {
            initialState: {
                thing: _.cloneDeep(this.thing),
                state: _.clone(this.thing.state)
            }
        });
        this.modalRef.content.onStateChange.subscribe(result => {
            this.loadingState = true;
        });
        this.modalService.onHide
            .pipe(take(1))
            .subscribe(() => this.fetchThing(false));
    }

    public initPusher(): void {
        this.pusherChannel = this.pusher.init(this.thing.property_id);
        this.pusherChannel.bind(environment.stateChangePusherEvent, (message: PusherStateChangeMessage) => {
            if (message.message.device_id === this.thingId) {
                this.thing.state = message.message.state;
                this.loadingState = false;
            }
        });
    }

    public closePusher(): void {
        this.pusher.unsubscribe(this.thing.property_id);
    }

    public openChronograf() {
        const domain = environment.production ? 'ai' : 'dev';
        // eslint-disable-next-line max-len
        const url = `https://chronograf.watergate.${domain}/sources/10000/chronograf/data-explorer?query=SELECT%20%2A%20FROM%20%22telegraf-telemetry%22.%22autogen%22.%22mqtt_consumer%22%20WHERE%20time%20%3E%20%3AdashboardTime%3A%20AND%20time%20%3C%20%3AupperDashboardTime%3A%20AND%20%22device_id%22%3D%27${this.thingId}%27%20LIMIT%202000`;
        this.openNewTab(url);
    }

    public openAWS() {
        const url = `https://eu-west-2.console.aws.amazon.com/iot/home?region=eu-west-2#/thing/${this.thingId}`;
        this.openNewTab(url);
    }

    public fetchThing(pusher = true): void {
        this.blockUI.start('loader', 'Loading...');
        this.thingService.getThing(this.thingId)
            .pipe(finalize(() => this.blockUI.stop('loader')))
            .subscribe(response => {
                this.thing = response.thing;
                if (pusher) {
                    this.initPusher();
                }
            });
    }

    private openNewTab(url: string) {
        window.open(url);
    }
}
