import {CircleOfTrust} from '../../../_internal/circle-of-trust';
import {EmergencyContactFormInput} from '../../../_internal/emergencyContactFormInput';
import {EmergencyContactsCreate} from '../../../_internal/emergency-contacts-create';

export class EmergencyContactFactory {
    private constructor() {
    }

    public static build(params: CircleOfTrust, formInput: EmergencyContactFormInput): EmergencyContactsCreate {
        return {
            property_id: params.propertyId,
            contact_type: params.contact_type,
            email: formInput.email,
            first_name: formInput.firstName,
            id: params.id,
            last_name: formInput.lastName,
            phone: formInput.phone,
        };
    }
}
