import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {HubListComponent} from './hub-list/hub-list.component';
import {HubPageComponent} from './hub-page/hub-page.component';

const routes: Routes = [
    {
        path: '',
        children: [
            {
                path: '',
                component: HubListComponent,
                data: {
                    title: 'Hubs list',
                },
            },
            {
                path: ':id',
                component: HubPageComponent,
                data: {
                    title: 'Hub',
                },
            }
        ]
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class HubPagesRoutingModule {
}
