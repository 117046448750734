<div class="modal-content">
    <div class="modal-header">
        <h4 class="modal-title"> Edit hub </h4>
        <button type="button" class="close" aria-label="Close" (click)="onCancel()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" *ngIf="firmwareSettings">
        <form [formGroup]="formGroup">
            <div class="row align-items-center">
                <div class="col-lg-12">
                    <label for="name">Name</label>
                    <input type="text"
                           id="name"
                           formControlName="name"
                           class="form-control">
                </div>
                <div class="col-lg-6">
                    <label for="firmware_channel_id">Firmware channel</label>
                    <select class="form-control"
                            id="firmware_channel_id"
                            formControlName="firmware_channel_id">
                        <option *ngFor="let firmwareChannel of firmwareChannels"
                                [value]="firmwareChannel.id">
                            {{firmwareChannel.name}}
                        </option>
                    </select>
                </div>
                <div class="col-lg-6">
                    <label for="mass_firmware_upgrade_disabled">Group updates blocked</label>
                    <input type="checkbox"
                           id="mass_firmware_upgrade_disabled"
                           class="checkbox-input"
                           formControlName="mass_firmware_upgrade_disabled">
                </div>
            </div>
        </form>


        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="onCancel()">Close</button>
            <button type="button" class="btn btn-primary" (click)="onConfirm()">Save changes</button>
        </div>
    </div>
