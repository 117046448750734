<div class="modal-content">
    <div class="modal-header">
        <h4 class="modal-title">Edit device</h4>
        <button type="button" class="close" aria-label="Close" (click)="onCancel()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" *ngIf="device">
        <form [formGroup]="formGroup">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <label for="name">Hardware revision</label>
                    <input type="number" id="hardware_revision" formControlName="hardware_revision" class="form-control">
                </div>
                <div class="col-lg-6">
                    <label for="is_golden_sample">Golden sample?</label>
                    <input type="checkbox" id="is_golden_sample" class="checkbox-input"
                        formControlName="is_golden_sample">
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="onCancel()">Close</button>
        <button type="button" class="btn btn-primary" (click)="onConfirm()">Save changes</button>
    </div>
</div>