<div *ngIf="event && applianceCategories">
    <div class="row">

        <div class="col-lg-8">

            <div class="card mb-4">
                <div class="card-body">
                    <div *ngIf="event.event_duration <= eventTooLongThresholdInMs; else eventToLong"
                         class="chart-container">
                        <div echarts [options]="telemetryChartOptions"></div>

                        <div echarts [options]="pressureChartOptions"></div>

                    </div>
                    <ng-template #eventToLong>
                        <div class="hidden-chart">Chart hidden due to too long event (longer than 24h).</div>
                    </ng-template>
                </div>
            </div>
        </div>
        <div class="col-lg-4">
            <div class="card mb-4">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-6">
                            <dl class="dl-horizontal">

                                <dt>Recognition UTC time:</dt>
                                <dd>
                                    {{utcStart}} - {{utcEnd}}
                                </dd>

                                <dt>Predicted categories:</dt>
                                <dd>
										<span *ngFor="let category_id of event.predicted_appliance_category_ids"
                                              tooltip="{{getCategory(category_id)?.name}}">
										    <i class="hl-icon hl-icon-{{getCategory(category_id)?.icon_url}}"> </i>
                                        </span>
                                </dd>

                                <dt>Current categories:</dt>
                                <dd>
                      <span *ngFor="let category_id of event.appliance_category_ids"
                            tooltip="{{getCategory(category_id).name}}">
                        <i class="hl-icon hl-icon-{{getCategory(category_id).icon_url}}"> </i>
                      </span>
                                </dd>

                                <dt>User labeled appliances:</dt>
                                <dd>
                                    <ng-container *ngIf="event.appliance_ids.length>0; else noAppliances">
							<span *ngFor="let applianceId of event.appliance_ids"
                                  tooltip="{{getAppliance(applianceId).name}}">
                        <i class="hl-icon hl-icon-{{getAppliance(applianceId).icon_url}}"> </i>
                      </span>
                                    </ng-container>
                                    <ng-template #noAppliances>
                                        <span>-</span>
                                    </ng-template>
                                </dd>

                                <dt>Prediction probabilities</dt>
                                <dd>
                                    <div *ngFor="let propability of mapProbabilities(); let propabilityIndex = index">
                                        <span class="hl-icon  hl-icon-{{probabilitiesTypes[propabilityIndex]}}"></span>
                                        <span>{{propability}}</span>
                                    </div>
                                </dd>
                            </dl>

                            <div class="btn-toolbar text-center well">
                                <button type="button"
                                    (click)="openRawJsonModal()"
                                    class="btn btn-secondary btn-sm">
                                        Raw JSON
                                </button>
                                <button type="button"
                                        (click)="openOverrideModal()"
                                        class="btn btn-sm btn-danger">
                                        Overwrite event
                                </button>
                            </div>

                        </div>
                        <div class="col-lg-6">
                            <div class="card mb-4"
                                 *ngIf="event.overrides.confidence">
                                <div class="card-header">
                                    Overrides
                                    <div class="float-end"
                                         (click)="removeOverrides()">
                                        <button class="btn btn-sm btn-danger">
                                            <i class="fa fa-trash"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <dl class="dl-horizontal">
                                        <dt>Complex:</dt>
                                        <dd>{{event.overrides?.complex ? 'Yes' : 'No'}}</dd>

                                        <dt>Children count:</dt>
                                        <dd>{{event.overrides?.children_count}}</dd>

                                        <dt>Categories(Staff labeled):</dt>
                                        <dd>
                        <span *ngFor="let category_id of event.overrides?.appliance_category_ids"
                              tooltip="{{getCategory(category_id).name}}">
                          <i class="hl-icon hl-icon-{{getCategory(category_id).icon_url}}"> </i>
                        </span>
                                        </dd>

                                        <dt>Confidence</dt>
                                        <dd>
                                            <rating [(ngModel)]="event.overrides.confidence"
                                                    [max]="5"
                                                    [readonly]="true"></rating>
                                        </dd>
                                    </dl>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


        </div>
    </div>
</div>
