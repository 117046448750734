<div class="row">
    <div class="col-lg-4">
        <div class="card mb-4"
             *ngIf="property">
            <div class="card-header">
                <i class="fa fa-align-justify"></i> {{property.name}}
                <div class="float-end buttons-row">
                    <app-favourites-button
                        location="properties"
                        [rowID]="property.id"
                        [rowName]="property.name"
                        [rowAddress]="property.city">
                    </app-favourites-button>
                    <span class="btn-toolbar text-center well float-end">
						<button cButton
                                class="btn btn-secondary btn-sm"
                                color="secondary"

                                (click)="openPropertyEditModal()">
							Edit
							<i class="fa icons icon-pencil"></i>
						</button>
						<button cButton
                                class="btn btn-secondary btn-sm"
                                color="danger"
                                *appHasRoles="'root'"
                                (click)="deleteProperty();">
							Delete
							<i class="fa fa-trash-o"></i>
						</button>
					</span>
                </div>

            </div>
            <div class="card-body">
                <dl class="dl-horizontal">

                    <dt>Address:</dt>
                    <dd>
                        {{property.address_1}} {{property.address_2}} {{property.address_3}}
                    </dd>

                    <dt>City:</dt>
                    <dd>
                        {{property.city}}
                    </dd>

                    <dt>Postcode:</dt>
                    <dd>
                        {{property.postcode}}
                    </dd>

                    <dt>Country:</dt>
                    <dd>
                        {{property.country}}
                    </dd>

                </dl>
            </div>
        </div>

        <div class="card mb-4"
             *ngIf="settings && property">
            <div class="card-header">
                <i class="fa fa-align-justify"></i> {{property.name}} settings
                <c-badge color="info"
                         *ngIf="settings.dont_disturb_mode">DND is ON
                </c-badge>
                <div class="float-end">
					<span class="btn-toolbar text-center well">
                    <button class="btn btn btn-secondary btn-sm"
                            (click)="openSettingsEditModal()">
                        Edit settings
                        <i class="fa icons icon-pencil"></i>
                    </button>
					<button class="btn btn-sm btn-danger"
                            (click)="openFakeIncidentstModal()">
						Fake incident
						<i class="fa icons icon-pencil"></i>
					</button>
				</span>
                </div>

            </div>
            <div class="card-body">
                <dl class="dl-horizontal">

                    <dt>Water cost per litre:</dt>
                    <dd>
                        {{settings.water_cost_per_litre}}
                    </dd>

                    <dt>Water daily target usage:</dt>
                    <dd>
                        {{settings.target_daily_usage_litres ? settings.target_daily_usage_litres : 'Not set'}}
                    </dd>

                    <dt>Currency:</dt>
                    <dd>
                        {{settings.currency}}
                    </dd>

                    <dt>Adults count:</dt>
                    <dd>
                        {{settings.adults_count}}
                    </dd>
                    <dt>Children count:</dt>
                    <dd>
                        {{settings.children_count}}
                    </dd>
                    <dt>Pets:</dt>
                    <dd>
                        {{settings.pets ? 'Yes' : 'No'}}
                    </dd>
                    <dt>Auto shut off:</dt>
                    <dd>
                        {{settings.auto_shut_off ? 'Yes' : 'No'}}
                    </dd>

                    <dt>Pressure Tests enabled</dt>
                    <dd>
                        {{settings.pressure_tests_enabled ? 'Yes' : 'No'}}
                    </dd>

                    <dt>Pressure Tests schedule</dt>
                    <dd>
                        {{settings.pressure_tests_schedule}}
                    </dd>

                    <dt>Appliance Usage Logging</dt>
                    <dd>
                        {{settings.appliance_logging ? 'Yes' : 'No'}}
                    </dd>
                    <dt>Do not disturb mode</dt>
                    <dd>
                        {{settings.dont_disturb_mode ? 'Enabled' : 'Disabled'}}
                    </dd>
                    <dt>Timezone</dt>
                    <dd>
                        {{settings.timezone}}
                    </dd>
                    <dt>
                        Time
                    </dt>
                    <dd>
                        <app-multi-time [propertyId]="propertyId"
                                        [showLocal]="false"
                                        [showProperty]="false"></app-multi-time>
                    </dd>
                    <dt>
                        Local API
                    </dt>
                    <dd>
                        {{settings.local_api_enabled ? 'Enabled' : 'Disabled'}}
                        <ng-container *ngIf="settings.local_api_enabled">
                            <br/>
                            Username: {{ settings.local_api_username}}<br/>
                            Password: {{ settings.local_api_password}}
                        </ng-container>
                    </dd>

                </dl>
            </div>
        </div>
    </div>
    <div class="col-lg-4">
        <app-thing-list [tabTitle]="'Associated things'"
                        [property]="property"
                        *ngIf="property">
        </app-thing-list>
        <app-incident-list [propertyId]="propertyId"
                           [tabTitle]="'Property incidents'">
        </app-incident-list>
        <app-notification-settings [propertyId]="propertyId"></app-notification-settings>
        <ng-container *ngIf="property">
            <app-spaces-list [spaces]="property.spaces"
                             [propertyId]="propertyId"
                             [tabTitle]="'Spaces'">
            </app-spaces-list>
        </ng-container>
    </div>
    <div class="col-lg-4">
        <app-membership-list [propertyId]="propertyId"></app-membership-list>
        <app-logs-list [resourceId]="propertyId"></app-logs-list>
        <app-circle-of-trust-list [propertyId]="propertyId"></app-circle-of-trust-list>
    </div>
</div>
