import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MonitoringResponse } from './dto/monitoring-response';

@Injectable({
    providedIn: 'root'
})
export class MonitoringResource {
    private readonly url = `${environment.apiGatewayUrl}/iot-core/admin`;

    constructor(private http: HttpClient) {

    }
    public getMonitoringData(serialNumbers: string, event_start: string, event_end: string): Observable<MonitoringResponse> {
        const url = `${this.url}/watchdog/?${serialNumbers}start_ts=${event_start}&end_ts=${event_end}`;
        return this.http.get<MonitoringResponse>(url);
    }

    public getDevicesData(): Observable<string> {
        const url = `${environment.apiGatewayUrl}/watchdog/iot-core/admin/device_report`;
        return this.http.get(url, {responseType: 'text'});
    }

    public geWaterReportData(propertyIDs: string, from: string, to: string): Observable<MonitoringResponse>{
        const url = `${this.url}/water_usage_report?${propertyIDs}from=${from}&to=${to}`;
        return this.http.get<MonitoringResponse>(url);
    }

    public geWaterReportCSVData(propertyIDs: string, from: string, to: string): Observable<string> {

        const url = `${environment.apiGatewayUrl}/iot-core/admin/water_usage_report/download?${propertyIDs}from=${from}&to=${to}`;
        return this.http.get(url, {responseType: 'text'});
    }
}
