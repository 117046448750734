import {Injectable} from '@angular/core';
import {CrudAware, OptionalHttpParams} from '../../../common/api/CrudAware';
import {Observable} from 'rxjs';
import {HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {ApplianceCategoryPayload, ApplianceCategoryResponse} from '../resources/dto/appliance-category';
import {ApplianceCategoryResource} from '../resources/appliance-category.resource';
import {ApplianceCategory} from '../../_internal/appliance_category';

@Injectable()
export class ApplianceCategoryService implements CrudAware<ApplianceCategory> {
    constructor(private readonly resource: ApplianceCategoryResource) {
    }

    create(payload: ApplianceCategoryPayload, params?: HttpParams | OptionalHttpParams): Observable<ApplianceCategory> {
        return this.resource.create({appliance_category: payload}, params);
    }

    delete(id: string | number, params?: HttpParams | OptionalHttpParams): Observable<void> {
        return this.resource.delete(id, params);
    }

    getMany(params?: HttpParams | OptionalHttpParams): Observable<ApplianceCategory[]> {
        return this.resource.getMany(params)
            .pipe(map((res: ApplianceCategoryResponse) => res.appliance_categories));
    }

    get(id: string | number, params?: HttpParams | OptionalHttpParams): Observable<ApplianceCategory> {
        return this.resource.get(id, params);
    }

    update(id: string | number,
           payload: ApplianceCategoryPayload,
           params?: HttpParams | OptionalHttpParams): Observable<ApplianceCategory> {
        return this.resource.update(id, payload, params);
    }
}
