import {AfterViewInit, ChangeDetectorRef, Component, Input, TemplateRef, ViewChild} from '@angular/core';
import {CalibrationResult} from '../../_internal/calibration-result';
import {OptionalHttpParams} from '../../../common/api/CrudAware';
import * as _ from 'lodash';
import {CalibrationResponse} from '../../providers/resources/dto/calibration.response';
import {TableComponent} from '../table/table.component';
import {calibrationFilterFields} from '../../pages/manufacturing/manufacturing-config';
import {Observable} from 'rxjs';
import {tableSettings} from '../../_internal/table-settings';
import {InformationDialogComponent} from '../../../common/components/dialogs/information-dialog/information-dialog.component';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ManufacturingService} from '../../providers/services/manufacturing.service';
import {CsvDialogComponent} from '../../../common/components/dialogs/csv-dialog/csv-dialog.component';

@Component({
    selector: 'app-calibration-results-table',
    templateUrl: './calibration-results-table.component.html',
    styleUrls: ['./calibration-results-table.component.scss'],
})
export class CalibrationResultsTableComponent implements AfterViewInit {

    @Input() filterFields = calibrationFilterFields;
    @Input() showDeviceLink = true;
    @ViewChild(TableComponent) table: TableComponent;
    @ViewChild('dateColumn') dateColumn: TemplateRef<any>;
    @ViewChild('resultsPassedColumn') resultsPassedColumn: TemplateRef<any>;
    @ViewChild('actionsColumn') actionsColumn: TemplateRef<any>;
    public tableSettings = _.cloneDeep(tableSettings);
    public rows: CalibrationResult[];
    public columns = [];
    public modalRef: BsModalRef;

    constructor(private manufacturingService: ManufacturingService,
                private cdRef: ChangeDetectorRef,
                private modalService: BsModalService) {
    }
    @Input()
    public getData: (params?: OptionalHttpParams) => Observable<CalibrationResponse> = (params?: OptionalHttpParams) => this.manufacturingService.getCalibrationResults(params);

    public ngAfterViewInit(): void {
        this.columns = [
            {prop: 'serial_no', name: 'Serial no', flexGrow: 2, sortable: false, cellTemplate: this.table.codeColumn},
            {cellTemplate: this.dateColumn, prop: 'created_at', name: 'Created at', flexGrow: 2, sortable: false},
            {prop: 'result.socket_no', name: 'Socket number', flexGrow: 1, sortable: false},
            {
                cellTemplate: this.resultsPassedColumn,
                prop: 'result.result',
                name: 'Test passed?',
                flexGrow: 1,
                sortable: false
            },
            {
                cellTemplate: this.actionsColumn, name: 'Actions', flexGrow: 3, sortable: false
            }
        ];

    }

    public downloadCsv(result: CalibrationResult) {
        const link = document.createElement('a');
        link.download = `calibration_${result.serial_no}.csv`;
        link.href = 'data:application/csv;charset=utf-8,' + result.content;
        link.click();
        link.remove();
    }

    public showCsv(result: CalibrationResult) {
        this.modalService.show(CsvDialogComponent, {
            initialState: {
                csvData: result.content,
                title: `Calibration data. Socket number - ${result.result.socket_no}`,
            },
            class: 'modal-xxl'
        });
    }

    public fetchPage(params?: OptionalHttpParams): void {
        _.unset(params, 'order_by');
        _.unset(params, 'order_strategy');
        this.getData(params)
            .subscribe((response: CalibrationResponse) => {
                this.table.setPageParams(response);
                this.rows = response.data;
                this.table.isLoading = false;
                this.cdRef.detectChanges();
            });
    }

    public manufacturingFeedbackModal( feedback: string) {
        this.modalRef = this.modalService.show(InformationDialogComponent, {
            initialState: {
                modalTitle: 'Feedback',
                modalContent: feedback,
            }
        });
    }

    public reportPassed(result): boolean {
        return result === 'passed';
    }

}
