<div class="modal-content">
    <div class="modal-header">
        <h4 class="modal-title">Columns</h4>
    </div>
    <div class="modal-body">
        <form class="sliders" [formGroup]="formGroup" ngNativeValidate>
            <mat-slide-toggle formControlName="Name">Name</mat-slide-toggle>
            <mat-slide-toggle formControlName="Cloud connection">Cloud connection</mat-slide-toggle>
            <mat-slide-toggle formControlName="Valve">Valve</mat-slide-toggle>
            <mat-slide-toggle formControlName="Valve on/off changes">Valve on/off changes</mat-slide-toggle>
            <mat-slide-toggle formControlName="Valve all changes">Valve all changes</mat-slide-toggle>
            <mat-slide-toggle formControlName="Events">Events</mat-slide-toggle>
            <mat-slide-toggle formControlName="Incidents">Incidents</mat-slide-toggle>
            <mat-slide-toggle formControlName="Hub events">Hub events</mat-slide-toggle>
            <mat-slide-toggle formControlName="Thing events">Thing events</mat-slide-toggle>
            <mat-slide-toggle formControlName="Offline time">Offline time</mat-slide-toggle>
            <mat-slide-toggle formControlName="Power source">Power source</mat-slide-toggle>
          </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="close()">Cancel</button>
        <button type="button" class="btn btn-secondary" (click)="save()">Save</button>

    </div>
</div>
