import {Component, Input} from '@angular/core';
import {SonicTelemetryMessage} from '../../_internal/telemetry';
import * as moment from 'moment/moment';

@Component({
    selector: 'app-thing-telemetry-stats',
    templateUrl: 'thing-telemetry-stats-component.component.html'
})

export class ThingTelemetryStatsComponent {


    @Input() telemetry: SonicTelemetryMessage;
    @Input() propertyId: string;

    public timeAgo(date: number): string {
        return moment.utc(date).fromNow();
    }
}
