import {ApplianceCategory} from './../../../_internal/appliance_category';
import {ApplianceCategoryService} from './../../../providers/services/appliance-category.service';
import {EventService} from './../../../providers/services/event.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Component, OnInit} from '@angular/core';
import {Event} from '../../../_internal/event';
import * as _ from 'lodash';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {PropertyService} from '../../../providers/services/property.service';
import {Appliance} from '../../../_internal/appliance';

@Component({
    selector: 'app-event-page',
    templateUrl: './event-page.component.html',
    styleUrls: ['./event-page.component.scss']
})
export class EventPageComponent implements OnInit {
    @BlockUI() blockUI: NgBlockUI;
    eventStart: string;
    eventEnd: string;
    deviceId: string;
    event: Event;
    filters;
    nextEvent: { id; start };
    nextEvents: { id; start }[];
    applianceCategories: ApplianceCategory[];
    appliances: Appliance[];

    constructor(private route: ActivatedRoute,
                private propertyService: PropertyService,
                private router: Router,
                private applianceCategoryService: ApplianceCategoryService,
                private eventService: EventService) {
    }

    public fetchEvent() {
        this.blockUI.start();
        this.event = null;
        this.eventService.getEvent(this.deviceId, this.eventStart).subscribe((res: Event) => {
            this.event = res;
            this.fetchAppliances();
            this.blockUI.stop();
            this.eventEnd = this.event.event_end;
        });
    }

    public goToEventList() {
        const params = this.filters !== null ? this.filters : {};
        this.router.navigate(['events'], {queryParams: params});
    }

    public goToNextEvent() {
        this.nextEvents = _.slice(this.nextEvents, 1);
        const params = _.map(this.nextEvents, (e) => JSON.stringify({id: e.id, start: e.start}));
        this.router.navigate(['events', this.nextEvent.start, this.nextEvent.id], {
            queryParams: {
                nextEvents: params,
                filters: this.filters !== null ? JSON.stringify(this.filters) : '{}'
            }
        });
    }

    public goToDevice(deviceId: string) {
        this.router.navigate(['things', deviceId]);
    }

    public fetchApplianceCategories() {
        this.applianceCategoryService.getMany().subscribe((res) => this.applianceCategories = res);
    }

    ngOnInit() {
        this.fetchApplianceCategories();
        this.route.params.subscribe((params) => {
            this.eventStart = params['event_start'];
            this.deviceId = params['device_id'];
            this.fetchEvent();
        });
        this.route.queryParams.subscribe((queryParams) => {
            this.filters = queryParams['filters'] !== undefined ? JSON.parse(queryParams['filters']) : null;
            this.nextEvents = typeof queryParams['nextEvents'] === 'string' ? [JSON.parse(queryParams['nextEvents'])] :
                    _.map(queryParams['nextEvents'], (s) => JSON.parse(s)) || [];
            if (this.nextEvents.length) {
                this.nextEvent = _.first(this.nextEvents);
            }
        });
    }

    private fetchAppliances() {
        this.propertyService.getAssociatedAppliances(this.event.property_id)
                .subscribe(response => this.appliances = response);
    }
}
