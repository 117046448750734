import {Injectable} from '@angular/core';
import {User} from '../../_internal/user';
import * as _ from 'lodash';
import {Role} from '../../_internal/role';
import { UserResource } from '../resources/user.resource';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { OptionalHttpParams } from '../../../common/api/CrudAware';import { UsersResponse } from '../resources/dto/users.response';

@Injectable({providedIn: 'root'})
export class UserService {
    public user: User;

    private _userId: string;

    private _token: string;

    constructor(private readonly userResource: UserResource) {
    }

    get token(): string {
        return this._token;
    }

    set token(value: string) {
        this._token = value;
    }

    // eslint-disable-next-line @typescript-eslint/member-ordering
    get userId(): string {
        return this._userId;
    }

    set userId(value: string) {
        this._userId = value;
    }

    public isRoot(): boolean {
        return !_.isUndefined(_.find(this.user.roles, (role: Role) => role.name === 'root'));
    }

    public hasRole(roleName: string): boolean {
        return !_.isUndefined(_.find(this.user.roles, (role: Role) => role.name === roleName));
    }

    public isFirmwareManager(): boolean {
        return this.hasRole('firmware_manager');
    }

    public isFirmwareMaster(): boolean {
        return this.hasRole('firmware_master');
    }

    public getUsersList(params: HttpParams | OptionalHttpParams): Observable<UsersResponse> {
        return this.userResource.getUsersList(params);
    }
}
