<div class="modal-content">
    <div class="modal-header">
        <h4 class="modal-title">Import device data</h4>
    </div>
    <div class="modal-body">
        <p [innerHTML]="message"></p>
    </div>
    <div class="buttons">
        <button
        class="btn btn-secondary btn-sm"
        (click)="getDeviceData('deregister')">Export data and transfer certificates</button>
        <button
        class="btn btn-secondary btn-sm"
        (click)="getDeviceData('export')">Export data</button>
    </div>
    <br>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="close()">Cancel</button>
    </div>
</div>
