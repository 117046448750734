import { Component } from '@angular/core';

@Component({
  selector: 'app-pressure-test-results',
  templateUrl: './pressure-test-results.component.html',
  styleUrls: ['./pressure-test-results.component.scss']
})
export class PressureTestResultsComponent  {

  constructor() { }


}
