import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {RemoteRestartResponse, RemoteRestartsResponse} from './dto/remote-restarts.response';

@Injectable()
export class RemoteRestartsResource {

    private readonly url: string = `${environment.apiGatewayUrl}/iot-core/admin/remote_restarts`;

    constructor(private http: HttpClient) {
    }

    public getRestarts(params = {}): Observable<RemoteRestartsResponse> {
        return this.http.get<RemoteRestartsResponse>(this.url, {params});
    }

    public triggerRestart(params = {}): Observable<RemoteRestartResponse> {
        return this.http.post<RemoteRestartResponse>(this.url, params);
    }
}
