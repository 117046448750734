import {IncidentDetailsResponse, IncidentsResponse} from './dto/incidents.response';
import {Observable} from 'rxjs';
import {environment} from './../../../../environments/environment';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {IncidentLogReponse} from './dto/IncidentLogReponse';
import {OptionalHttpParams} from '../../../common/api/CrudAware';
import { fakeIncidentsPayload } from './dto/incidents.response';
import { CustomMessage } from './dto/custom-message';

@Injectable({
    providedIn: 'root'
})
export class IncidentResource {
    private readonly URL: string = `${environment.apiGatewayUrl}/iot-core/admin/incidents`;
    private readonly messagesURL: string = `${environment.apiGatewayUrl}/planner`;


    constructor(private http: HttpClient) {
    }

    public getIncidentsList(params: OptionalHttpParams = {}): Observable<IncidentsResponse> {
        return this.http.get<IncidentsResponse>(this.URL, {params});
    }

    public getIncident(id: string): Observable<IncidentDetailsResponse> {
        return this.http.get<IncidentDetailsResponse>(`${this.URL}/${id}`);
    }

    public findById(incidentId: string): Observable<IncidentLogReponse> {
        return this.http.get<IncidentLogReponse>(`${this.URL}/${incidentId}/logs`);
    }

    public sendFakeIncident(params: fakeIncidentsPayload): Observable<IncidentsResponse> {
        return this.http.post<IncidentsResponse>(this.URL, params);
    }

    public sendCustomSMS(params: CustomMessage): Observable<CustomMessage> {
        return this.http.put<CustomMessage>(`${this.messagesURL}/messages/sms`, params);
    }
    public sendCustomPush(params: CustomMessage): Observable<CustomMessage> {
        return this.http.put<CustomMessage>(`${this.messagesURL}/messages/push`, params);
    }
}
