import {Thing} from '../../../_internal/thing';
import {ThingResource} from '../../../providers/resources/thing.resource';
import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, TemplateRef, ViewChild} from '@angular/core';
import * as _ from 'lodash';
import {ThingsResponse} from '../../../providers/resources/dto/thing.response';
import {TableComponent} from '../../../components/table/table.component';
import {tableSettings} from '../../../_internal/table-settings';
import {OptionalHttpParams} from '../../../../common/api/CrudAware';
import {thingsFilterFields} from './filter-fields';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../../environments/environment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DevicesReportComponent } from '../../../components/devices-report/devices-report.component';
import { ValveStatesService } from '../../../providers/services/valve-states.service';

@Component({
    selector: 'app-things-list',
    templateUrl: './thing-list-page.component.html',
    styleUrls: ['./thing-list-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ThingListPageComponent implements AfterViewInit {

    @ViewChild(TableComponent) table: TableComponent;
    @ViewChild('dateColumn') dateColumn: TemplateRef<any>;
    @ViewChild('stateColumn') stateColumn: TemplateRef<any>;
    @ViewChild('batteryColumn') batteryColumn: TemplateRef<any>;
    @ViewChild('actionsColumn') actionsColumn: TemplateRef<any>;
    @ViewChild('mainTable', {static: true}) mainTable: TableComponent;
    @ViewChild('cloudConnectionColumn') cloudConnectionColumn: TemplateRef<any>;
    @ViewChild('radioConnectionColumn') radioConnectionColumn: TemplateRef<any>;

    public filterFields = thingsFilterFields;
    public tableSettings = _.cloneDeep(tableSettings);
    public rows: Thing[];
    public columns = [];

    constructor(private thingResource: ThingResource,
                private router: Router,
                private toastrService: ToastrService,
                private cdRef: ChangeDetectorRef,
                private valveStatesService: ValveStatesService,
                private modalService: BsModalService) {
    }


    public ngAfterViewInit(): void {

        this.columns = [
            {prop: 'name', name: 'Name', flexGrow: 1.0, sortable: false},
            {prop: 'internal_name', name: 'Internal name', flexGrow: 1.1, sortable: false},
            {
                cellTemplate: this.cloudConnectionColumn,
                prop: 'cloud_connection',
                name: 'Cloud',
                flexGrow: 0.5,
                sortable: false
            },
            {
                cellTemplate: this.radioConnectionColumn,
                prop: 'radio_connection',
                name: 'Radio',
                flexGrow: 0.5,
                sortable: false
            },
            {prop: 'version', name: 'Version', flexGrow: 0.7, sortable: false},
            {cellTemplate: this.stateColumn, prop: 'state', name: 'State', flexGrow: 0.8, sortable: false},
            {cellTemplate: this.batteryColumn, prop: 'battery_level', name: 'Power source', flexGrow: 0.4, sortable: false},
            {prop: 'serial_no', name: 'Serial No', flexGrow: 1, sortable: false, cellTemplate: this.table.codeColumn},
            {
                cellTemplate: this.actionsColumn,
                name: '',
                flexGrow: 1.2,
                sortable: false
            },
        ];

    }

    public fetchPage(params?: OptionalHttpParams): void {
        this.thingResource.getThingsList(params)
                .subscribe((response: ThingsResponse) => {
                    this.table.setPageParams(response);
                    this.rows = response.things;
                    this.table.isLoading = false;
                    this.cdRef.detectChanges();
                });
    }

    public getRouterUrl(thing: Thing): string{
        switch(thing.type){
            case 'sonic_wifi':
                return `/things/wifi/${thing.id}`;
            case 'siryn':
                return `/things/tile/${thing.id}`;
            case 'smart_valve':
            default:
                return `/things/${thing.id}`;
        }
    }

    public triggerDevicesReportDownload(): void {
        this.modalService.show(DevicesReportComponent);
    }

    public returnValveStateBadge(thing: Thing) {
        return this.valveStatesService.returnValveState(thing, true);
    }

    public isStateDanger(state: string): boolean{
        return this.valveStatesService.stateDanger(state);
    }
    public isStateSucces(state: string): boolean{
        return this.valveStatesService.stateSucces(state);
    }

    public isStateSecondary(state: string): boolean{
        return this.valveStatesService.stateSecondary(state);
    }
}
