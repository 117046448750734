import {Component, EventEmitter, OnDestroy, OnInit} from '@angular/core';
import {ThingGroupService} from '../../../providers/services/thing-group.service';
import {ThingGroup} from '../../../_internal/thing-group';
import {Subscription} from 'rxjs';
import {UserService} from '../../../providers/services/user.service';
import {Location} from '@angular/common';
import {MatDialog} from '@angular/material/dialog';
import {AlertComponent} from '../../../../common/components/dialogs/alert/alert.component';

@Component({
    selector: 'app-thing-group-list',
    templateUrl: './thing-group-list.component.html',
    styleUrls: ['./thing-group-list.component.scss']
})
export class ThingGroupListComponent implements OnInit, OnDestroy {
    thingGroupList: ThingGroup[];
    thingGroupSubscription: Subscription;
    switchThingGroupEvent: EventEmitter<string> = new EventEmitter<string>();

    constructor(private thingGroupService: ThingGroupService,
                private userService: UserService,
                private location: Location,
                private dialog: MatDialog) {
    }

    public focusThingGroup(groupId: string) {
        this.switchThingGroupEvent.emit(groupId);
    }

    ngOnInit() {
        if (!this.checkAccess()) {
            return;
        }
        this.fetchThingGroups();
    }

    ngOnDestroy() {
        if (this.thingGroupSubscription) {
            this.thingGroupSubscription.unsubscribe();
        }
    }

    private fetchThingGroups(): void {
        this.thingGroupSubscription = this.thingGroupService.getList().subscribe((data) => {
            this.thingGroupList = data.groups;
        });
    }

    private checkAccess(): boolean {
        if (this.userService.isRoot()) {
            return true;
        }
        setTimeout(() => {
            if (this.dialog.openDialogs && this.dialog.openDialogs.length) {
                return;
            }
            this.dialog.open(AlertComponent, {
                closeOnNavigation: false,
                data: {
                    title: 'Access denied!',
                    message: 'Not enough permissions'
                }
            });
        }, 1);
        this.location.back();

    }

}
