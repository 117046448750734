import { ThingService } from './../../../providers/services/thing.service';
import {Subject} from 'rxjs';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Component, Input, OnInit} from '@angular/core';
import {Thing} from '../../../_internal/thing';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../../../src/environments/environment';

@Component({
  selector: 'app-value-status-button',
  templateUrl: './value-status-button.component.html',
  styleUrls: ['./value-status-button.component.scss']
})
export class ValueStatusButtonComponent implements OnInit {
    @Input() thing: Thing;
    @Input() state: string;
    public thingStates: string[] = ['open', 'closed', 'dead'];
    public onStateChange: Subject<boolean>;
    public initialState: string;
    constructor(private _bsModalRef: BsModalRef,
                private thingService: ThingService,
                private toastrService: ToastrService) {
    }

    ngOnInit() {
        this.onStateChange = new Subject();
        this.initialState = this.state;
    }

    public onConfirm(): void {
        if (this.state !== this.thing.state) {
            this.onStateChange.next(true);
        }
        const payload = {state: this.thing.state};
        this.thingService.updateValveState(this.thing.id, payload).subscribe(
            response => {
                this.toastrService.success('Changing valve state.', 'Valve state.', environment.toastSettings);
                this._bsModalRef.hide();
            },
            error => {
                this.toastrService.error( 'An error has ocured while changing valve state. Error code: ' + error.status + 'Error message' + error.message, 'Valve state.', environment.toastSettings);
            }
        );
    }

    public onCancel(): void {
        this._bsModalRef.hide();
    }

}
