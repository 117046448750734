import {Component, Input, OnInit} from '@angular/core';
import {Organization} from '../../../_internal/organization';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {OrganisationResource} from '../../../providers/resources/organisation.resource';
import {Subject} from 'rxjs';

@Component({
    selector: 'app-organisation-edit',
    templateUrl: './organisation-edit.component.html',
    styleUrls: ['./organisation-edit.component.scss']
})
export class OrganisationEditComponent implements OnInit {
    @Input() organisation: Organization;
    @Input() action: string;
    public onClose: Subject<boolean>;

    constructor(private _bsModalRef: BsModalRef,
                private organisationResource: OrganisationResource) {
    }

    ngOnInit() {
        this.onClose = new Subject();
    }

    public onConfirm(): void {
        if (this.action === 'create') {
            this.organisationResource.createOrganisation(this.organisation)
                    .subscribe((response) => {
                        this.onClose.next(true);
                        this._bsModalRef.hide();
                    });
        } else {
            this.organisationResource.updateOrganisation(this.organisation.id, this.organisation)
                    .subscribe((response) => {
                        this._bsModalRef.hide();
                    });
        }
        this._bsModalRef.hide();
    }

    public onCancel(): void {
        this._bsModalRef.hide();
    }

}
