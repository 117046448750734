import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { EChartsOption } from 'echarts';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { lineChartDefaultOptions } from '../../../../common/chart-defaults';
import { PressureTestMeasurement } from '../../../../hero-labs/_internal/pressure-test';
import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
  selector: 'app-pressure-test-graph',
  templateUrl: './pressure-test-graph.component.html',
  styleUrls: ['./pressure-test-graph.component.scss']
})
export class PressureTestGraphComponent {

    @Input() data: PressureTestMeasurement[];
    @Input() modalTitle: string;
    public chartOptions: EChartsOption;
    private echartsInstance: any;

    constructor(private bsModalRef: BsModalRef) { }

    public getChartSettings(values: PressureTestMeasurement[]): EChartsOption {
        const chartData = _.cloneDeep(lineChartDefaultOptions);
        return _.merge(chartData, {
            xAxis: {
                data: this.mapLabels(values),
                axisTick: {
                    interval: 0
                }
            },
            yAxis: {
                axisLabel: {
                    fontSize: 10,
                    width: '30px'
                }
            },
            toolbox: false,
            dataZoom: false,
            series: [
                {
                    name: 'Pressure',
                    type: 'line',
                    data: this.mapValues(values),
                    areaStyle: {},
                }
            ],
        });
    }

    public close(): void {
        this.bsModalRef.hide();
    }

    public onChartInit($event: any) {
        this.echartsInstance = $event;
    }

    private mapValues(values: PressureTestMeasurement[]): number[] {
        return values.map((val) => val.pressure);
    }

    private mapLabels(values: PressureTestMeasurement[]): string[] {
        return values.map((val) => moment.utc(val.probed_at).format('HH:mm:ss'));
    }
}
