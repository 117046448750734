<c-dropdown alignment="center"
			variant="nav-item">
	<button cButton
			color=""
			[caret]="false"
			cDropdownToggle
			class="py-0">
		<i class="fa fa-user-circle"></i>
		{{currentUser.full_name}}
	</button>
	<ul cDropdownMenu
		class="pt-0 pr-5 w-auto">
		<li (click)="signOut()">
			<a class="dropdown-item pointer">
				<i class="fa fa-sign-out"></i>
				Logout
			</a>
		</li>
		<li (click)="resetPassword()">
			<a class="dropdown-item pointer">
				<i class="fa fa-edit"></i>
				Reset password
			</a>
		</li>
		<li (click)="goToCurrentUser()">
			<a class="dropdown-item pointer">
				<i class="fa fa-user"></i>
				Your profile
			</a>
		</li>
	</ul>
</c-dropdown>

<ng-template #modal>
	<div class="modal-header">
		<h4 class="modal-title pull-left">Password has been reset successfully!</h4>
	</div>
	<div class="modal-body">
		Check your mailbox to see further instructions
	</div>
</ng-template>
