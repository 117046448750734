import {Observable} from 'rxjs';
import {UsersResponse} from './dto/users.response';
import {environment} from './../../../../environments/environment';
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {User} from '../../_internal/user';
import {LoginResponse} from './dto/login.response';
import {RefreshTokenResponse, ValidateTokenResponse} from './dto/token.response';
import {OptionalHttpParams} from '../../../common/api/CrudAware';

@Injectable({
    providedIn: 'root'
})
export class UserResource {
    private readonly url: string = `${environment.apiGatewayUrl}/user-service/admin/users`;
    private readonly authUrl: string = `${environment.apiGatewayUrl}/user-service/auth`;

    constructor(private http: HttpClient) {
    }

    public getUsersList(params: HttpParams | OptionalHttpParams): Observable<UsersResponse> {
        return this.http.get<UsersResponse>(this.url, {params});
    }

    public getUser(userId: string): Observable<User> {
        return this.http.get<User>(`${this.url}/${userId}`);
    }

    public updateUser(user: User): Observable<User> {
        return this.http.put<User>(`${this.url}/${user.id}`, {user});
    }

    public createUser(user: User): Observable<User> {
        return this.http.post<User>(this.url, {user});
    }

    public deleteUser(userId: string): Observable<User> {
        return this.http.delete<User>(`${this.url}/${userId}`);
    }

    public signOutUser(): Observable<any> {
        return this.http.delete(`${this.authUrl}/sign_out`);
    }

    public signInUser(email: string, password: string): Observable<LoginResponse> {
        return this.http.post<LoginResponse>(`${this.authUrl}/sign_in`, {email, password});
    }

    public refreshUserToken(): Observable<RefreshTokenResponse> {
        return this.http.put<RefreshTokenResponse>(`${this.authUrl}/refresh_token`, null);
    }
    public validateUserToken(): Observable<ValidateTokenResponse> {
        return this.http.get<ValidateTokenResponse>(`${this.authUrl}/validate_token`);
    }


}
