import {Component} from '@angular/core';
import {BlockUIService} from 'ng-block-ui';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ThingEvent} from '../../../_internal/thing.event';
import {Appliance} from '../../../_internal/appliance';
import {copyArrayItem, moveItemInArray} from '@angular/cdk/drag-drop';
import {ThingService} from '../../../providers/services/thing.service';
import * as moment from 'moment';

@Component({
    selector: 'app-manage-event-category-dialog',
    templateUrl: 'manage-event-category-dialog.html',
    styleUrls: ['./manage-event-category-dialog.scss']
})

export class ManageEventCategoryDialogComponent {
    public appliances: Appliance[];
    public event: ThingEvent;
    public actionPerformed: boolean;
    public eventAppliances: Appliance[] = [];

    constructor(private blockUI: BlockUIService,
                private modalService: BsModalService,
                private thingService: ThingService,
                private bsModalRef: BsModalRef) {
    }

    public onDragEnd(event) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            copyArrayItem(event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex);
        }
    }

    public saveChanges() {
        const start = moment.utc(this.event.event_start).valueOf().toString();
        const ids = this.eventAppliances.map(appliance => appliance.id);
        this.thingService.updateAppliancesInEvent(this.event.device_id, start, ids).subscribe(
            (res) => {
                this.actionPerformed = true;
                this.close();
            }
        );
    }

    public removeApplianceFromEvent(appliance: Appliance, index) {
        this.eventAppliances.splice(index, 1);
    }

    public close() {
        this.bsModalRef.hide();
    }
}
