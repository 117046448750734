import {NgModule} from '@angular/core';
import {CommonModule, DatePipe, JsonPipe} from '@angular/common';
import {DataTableComponentComponent} from './data-table.component';
import {DataTableFilterPipe} from './data-table-filter.pipe';
import {BadgeModule} from '@coreui/angular';

@NgModule({
    declarations: [
        DataTableComponentComponent,
        DataTableFilterPipe
    ],
    exports: [DataTableComponentComponent],
    imports: [
        CommonModule,
        BadgeModule
    ],
    providers: [
        JsonPipe,
        DatePipe
    ]
})
export class DataTableModule {
}
