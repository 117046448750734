import { Observable } from 'rxjs';
import { environment } from './../../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Organization } from '../../_internal/organization';

@Injectable({
    providedIn: 'root'
})
export class OrganisationResource {
    private readonly url: string = `${environment.apiGatewayUrl}/user-service/admin/organizations`;

    constructor(private http: HttpClient) {
    }

    public getOrganisationList(): Observable<Organization[]> {
        const url = `${this.url}`;
        return this.http.get<Organization[]>(url);
    }

    public getOrganisation(organisationId): Observable<Organization> {
        const url = `${this.url}/${organisationId}`;
        return this.http.get<Organization>(url);
    }

    public createOrganisation(payload): Observable<Organization> {
        const url = `${this.url}`;
        return this.http.post<Organization>(url, { organization: payload });
    }

    public updateOrganisation(organisationId: string, payload): Observable<Organization> {
        const url = `${this.url}/${organisationId}`;
        return this.http.put<Organization>(url, { organization: payload });
    }

    public deleteOrganisation(organisationId: string): Observable<Organization> {
        const url = `${this.url}/${organisationId}`;
        return this.http.delete<Organization>(url);
    }
}
