<div class="card mb-4">
	<div class="card-header">
		<i class="fa fa-align-justify"></i> Users

		<div class="float-end">
			<button class="btn btn-secondary btn-sm"
					(click)="openCreateModal()">Create user
			</button>
		</div>
	</div>
	<div class="card-body">

		<app-table [rows]="rows"
				   *ngIf="showTable"
				   [filterFields]="filterFields"
				   [selectionEnabled]="false"
				   [columns]="columns"
				   [defaultSorts]="[{prop:'created_at', dir:'desc'}]"
				   [tableSettings]="tableSettings"
				   (fetchPage)="fetchPage($event)"
				   pageName="users">
		</app-table>
		<ng-template #rolesColumn
					 ngx-datatable-cell-template
					 let-row="row"
					 let-value="value">
			<c-badge [color]="role.name==='admin'?'warning':'secondary'"
					 class="me-2"
					 *ngFor="let role of value">
				{{role.name}}
			</c-badge>
		</ng-template>
		<ng-template #activeColumn
					 ngx-datatable-cell-template
					 let-row="row"
					 let-value="value">
			<c-badge color="success"
					 *ngIf="value">Active
			</c-badge>
			<c-badge color="danger"
					 *ngIf="!value">Inactive
			</c-badge>

		</ng-template>
		<ng-template #actionsColumn
					 ngx-datatable-cell-template
					 let-row="row"
					 let-value="value">
			<a routerLink="/users/{{row.id}}"
			   class="btn btn-secondary px-5 ml-auto">Open</a>
			<app-favourites-button location="users"
								   [rowID]="row.id"
								   [rowName]="row.first_name +' '+ row.last_name"
								   [rowAddress]="row.email"></app-favourites-button>
		</ng-template>
	</div>
</div>
