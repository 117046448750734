<div class="row">
    <div class="col-lg-12">
        <h1>Device {{serialNo}}</h1>
    </div>
    <div class="col-lg-6">
        <div class="card mb-4" *ngIf="device">
            <div class="card-header">
                <i class="fa fa-align-justify"></i> Details
                <span class="btn-toolbar text-center well float-end">
                    <button
                        class="btn btn-secondary btn-sm"
                        (click)="exportDevice()">Export device</button>
                    <button
                        class="btn btn-secondary btn-sm"
                        (click)="openEditModal()">Edit <i class="fa icons icon-pencil"></i></button>
                </span>
            </div>
            <div class="card-body">
                <dl>
                    <dt>Serial no:</dt>
                    <dd>
                        <pre>{{device.serial_no}}</pre>
                    </dd>
                    <dt>Device type:</dt>
                    <dd>{{device.device_type === 'siryn' ? 'tail' : device.device_type}}</dd>
                    <dt>Group type:</dt>
                    <dd>{{device.group_type}}</dd>
                    <dt>Hardware revision:</dt>
                    <dd>{{device.hardware_revision}}</dd>
                    <dt>Notes:</dt>
                    <dd>{{device.notes}}</dd>
                    <dt>Created at:</dt>
                    <dd>{{device.created_at | date: 'medium' : 'UTC'}}</dd>
                    <dt>Updated at:</dt>
                    <dd>{{device.updated_at | date: 'medium': 'UTC'}}</dd>
                    <dt>Is golden sample?:</dt>
                    <dd>
                        <c-badge
                            [color]="device.is_golden_sample ?'success':'danger'">{{device.is_golden_sample ? 'Yes' : 'No'}}</c-badge>
                    </dd>
                </dl>
            </div>
        </div>
    </div>


    <div class="col-lg-6">
        <div class="card mb-4" *ngIf="device">
            <div class="card-header">
                <i class="fa fa-align-justify"></i> Manufactoring details
            </div>
            <div class="card-body" *ngIf="manufactoringData">
                <dl>

                    <dt>Provisioned:
                        <button type="button" class="btn btn-secondary btn-sm ml-2"
                                [disabled]="device.is_golden_sample"
                                (click)="openDeviceModal('provisioned')">Change
                            to {{!manufactoringData.provisioned}}</button>
                    </dt>
                    <dd>{{manufactoringData.provisioned}} </dd>
                    <dt>Provisioning blocked:
                        <button type="button"
                                [disabled]="device.is_golden_sample ||!(manufactoringData.provisioning_blocked && lastProvisionReport)"
                                class="btn btn-secondary btn-sm ml-2"
                                (click)="unblockModal('provisioning')">Unblock
                        </button>
                    </dt>
                    <dd>{{manufactoringData.provisioning_blocked}}</dd>
                    <div *ngIf="manufactoringData.provisioned">
                        <dt>Provisioned at:</dt>
                        <dd>{{manufactoringData.calibrated_at | date: 'medium' : 'UTC'}}</dd>
                    </div>
                    <dt>Calibrated:
                        <button type="button" class="btn btn-secondary btn-sm ml-2"
                                [disabled]="device.is_golden_sample"
                                (click)="openDeviceModal('calibrated')">Change
                            to {{!this.manufactoringData.calibrated}}</button>
                    </dt>
                    <dd>{{manufactoringData.calibrated}}</dd>
                    <dt>Calibration blocked:
                        <button type="button"
                                [disabled]="device.is_golden_sample || !(manufactoringData.calibration_blocked && lastCalibrationResult)"
                                class="btn btn-secondary btn-sm ml-2"
                                (click)="unblockModal('calibration')">Unblock
                        </button>
                    </dt>
                    <dd>{{manufactoringData.calibration_blocked}}</dd>
                    <div *ngIf="manufactoringData.calibrated">
                        <dt>Calibration at:</dt>
                        <dd>{{manufactoringData.calibrated_at | date: 'medium' : 'UTC'}}</dd>
                    </div>
                    <dt>Pressure tested:
                        <button type="button" class="btn btn-secondary btn-sm ml-2"
                                [disabled]="device.is_golden_sample"
                                (click)="openDeviceModal('pressure_tested')">Change
                            to {{!this.manufactoringData.pressure_tested}}</button>
                    </dt>
                    <dd>{{manufactoringData.pressure_tested}}</dd>
                    <dt>Pressure tested blocked:
                        <button type="button"
                                [disabled]="device.is_golden_sample || !(manufactoringData.pressure_test_blocked && lastPressureTest)"
                                class="btn btn-secondary btn-sm ml-2"
                                (click)="unblockModal('pressure_test')">Unblock
                        </button>
                    </dt>
                    <dd>{{manufactoringData.pressure_test_blocked}}</dd>
                    <div *ngIf="manufactoringData.pressure_tested">
                        <dt>Pressure tested at at:</dt>
                        <dd>{{manufactoringData.pressure_tested_at | date: 'medium' : 'UTC'}}</dd>
                    </div>
                </dl>
            </div>
        </div>
    </div>


</div>
<ng-container *ngIf="serialNo">
    <div id="provision">
        <app-provision-reports-table [filterFields]="null"
                                     [showDeviceLink]="false"
                                     [getData]="getProvisionReports()"></app-provision-reports-table>
    </div>
    <div id="calibration">
        <app-calibration-results-table [filterFields]="null"
                                       [showDeviceLink]="false"
                                       [getData]="getCalibrationResults()"></app-calibration-results-table>
    </div>
    <div id="pressure-tests">
        <app-pressure-test-table
            [showDeviceLink]="false"
            [getData]="getPressureTests()"
            [showSearchBar]=false></app-pressure-test-table>
    </div>
    <div id="provision">
        <app-burning-mode-data
            [serialNumber]="serialNo"></app-burning-mode-data>
    </div>
</ng-container>

