<div class="modal-content">
    <div class="modal-header">
        <h4 class="modal-title">Import device data</h4>
        <button type="button" class="close" aria-label="Close" (click)="close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p [innerHTML]="message"></p>
    </div>
    <input type="file" class="file-input"
    (change)="onFileSelected($event)" #fileUpload>

    <div class="file-upload">
    {{fileName || "No file uploaded yet."}}
        <button mat-mini-fab color="secondary" class="upload-btn"
        (click)="fileUpload.click()">
            <mat-icon>attach_file</mat-icon>
        </button>
    </div>
    <br>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="close()">Cancel</button>
        <button type="button" class="btn btn-danger" (click)="confirm()">Import</button>
    </div>
</div>
