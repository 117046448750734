import {Component, Injector, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FirmwareService} from '../../../providers/services/firmware-service';
import {OptionalHttpParams} from '../../../../common/api/CrudAware';
import {Observable} from 'rxjs';
import {Firmware, FirmwareUpgrade} from '../../../providers/resources/dto/firmware.response';
import {Thing} from '../../../_internal/thing';
import {finalize, map} from 'rxjs/operators';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {TableDisplayedColumn} from '../../../../common/components/data-table/table-displayed-column';
import {Hub} from '../../../_internal/hub';
import * as _ from 'lodash';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {environment} from '../../../../../../src/environments/environment';
import { FirmwareDialogComponent } from '../../../../common/components/dialogs/firmware-dialog/firmware-dialog.component';

@Component({
    selector: 'app-available-firmware',
    templateUrl: 'available-firmware-list.component.html'
})

export class AvailableFirmwareListComponent implements OnInit, OnChanges {
    @Input() thing: Thing;
    @Input() hub: Hub;
    @Input() group: string;
    @Input() firmwareTypes: string[];
    @Input() listType: 'Firmwares' | 'Requested Firmwares';

    public firmwaresStream: Observable<Firmware[] | FirmwareUpgrade[]>;
    public isRequestedList = false;

    public isLoading = false;
    public displayedColumnsFirmwares: TableDisplayedColumn[] = [
        {key: 'id', name: 'Firmware ID', autoFormat: 'code'},
        {key: 'type', name: 'Type'},
        {key: 'version', name: 'Version'},
        {key: 'firmware_channels', name: 'Channels'},
        {key: 'description', name: 'Description'},
        {key: 'file_name', name: 'File Name'},
    ];

    public displayedRequestColumns: TableDisplayedColumn[] = [
        {key: 'id', name: 'ID', autoFormat: 'code'},
        {key: 'device_id', name: 'Device ID'},
        {key: 'type', name: 'Type'},
        {key: 'status', name: 'Status'},
        {key: 'token', name: 'Token'},
        {key: 'inserted_at', name: 'Released At', autoFormat: 'date'},
        {key: 'token_expires_at', name: 'Expires At', autoFormat: 'dateUTC'},
    ];

    private requestParams: OptionalHttpParams;
    private bsModalRef: BsModalRef;

    constructor(private readonly firmwareService: FirmwareService,
                private modalService: BsModalService,
                private router: Router,
                private injector: Injector,
                private toastrService: ToastrService) {
    }

    ngOnInit() {
        this.setParams();
        this.updateFirmwareStream(this.requestParams);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!_.get(changes.thing, 'firstChange')) {
            this.updateFirmwareStream(this.requestParams);
        }
    }

    goToJob(id) {
        this.router.navigate(['jobs', id]);
    }

    public update(firmware: Firmware) {
        this.modalService.show(FirmwareDialogComponent, {
            initialState: {
                message: 'Are you sure you want to trigger <strong>' + firmware.file_name + '</strong> firmware update for this device?',
            }
        });

        const subscription = this.modalService.onHide.subscribe((reason) => {
            if (reason === 'ota' || reason === 'factory') {
                const deviceId = _.isNil(this.thing) ? this.hub.id : this.thing.id;
                this.firmwareService.triggerUpgrade(deviceId, firmware.id, reason)
                    .pipe(finalize(() => {
                        this.isLoading = false;
                        subscription.unsubscribe();
                    }))
                    .subscribe(
                        response => {
                            this.modalService.setDismissReason('success');
                            this.toastrService.success('Settings have been saved successfully.', 'Settings.', environment.toastSettings);
                            this.bsModalRef.hide();
                        },
                        error => {
                            this.toastrService.error( 'An error has ocured while saving data. Error code: ' + error.status + 'Error message' + error.message, 'Settings.', environment.toastSettings);
                        }
                    );
            } else {
                subscription.unsubscribe();
            }
        });
    }

    public close() {
        this.bsModalRef.hide();
    }

    public setDeviceParams(): { thing_id?: string; hub_id?: string; group_id?: string } {
        if (this.group) {
            return {group_id: this.group};
        } else {
            return {};
        }
    }

    public setDeviceTypeParams() {
        return _.isNil(this.group) ? {device_type: _.isNil(this.thing) ? this.hub.type : this.thing.type} : {};
    }

    public redirectToJOb(data) {
        this.router.navigate(['jobs', data]);
    }

    private updateFirmwareStream(params?: OptionalHttpParams): void {
        this.isLoading = true;
        const deviceId = _.isNil(this.thing) ? this.hub.id : this.thing.id;
        params = _.set(params, 'limit', 100);
        this.firmwaresStream = this.getListStream(deviceId, params)
            .pipe(map((items) => {
                if (!this.firmwareTypes) {
                    return items;
                }
                return _.filter(items, (item) => _.includes(this.firmwareTypes, _.get(item, 'type'))) as (Firmware[] | FirmwareUpgrade[]);
            }))
            .pipe(finalize(() => {
                this.isLoading = false;
}));
    }

    private getListStream(deviceId: string, params?: OptionalHttpParams): Observable<Firmware[] | FirmwareUpgrade[]> {
        return this.listType === 'Firmwares' ?
            this.firmwareService.getMany(params).pipe(map((response) => response.firmwares)) :
            this.firmwareService.getFirmwareUpgrades(deviceId, params).pipe(map((response) => response.items));
    }

    private setParams() {
        this.isRequestedList = this.listType !== 'Firmwares';
        this.requestParams = !this.isRequestedList ?
            this.setDeviceTypeParams() : this.setDeviceParams();

        if (!this.isRequestedList) {
            this.bsModalRef = this.injector.get(BsModalRef);
        }
    }
}
