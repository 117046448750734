import {Component, Input} from '@angular/core';
import {BatteryFeed} from '../../_internal/thing';
import * as _ from 'lodash';

@Component({
    selector: 'app-battery-feed',
    templateUrl: './battery-feed.component.html',
    styleUrls: ['./battery-feed.component.scss']
})
export class BatteryFeedComponent {

    @Input() batteryLevel: string;
    @Input() batteryFeed: BatteryFeed;

    // eslint-disable-next-line no-bitwise
    public powerSupplyBit = 1 << 15;

    public getValueFromFeed(key: string) {
        const value = _.get(this.batteryFeed, key, null);
        switch (value) {
            case 0:
                return 'reported as 0';
            case 65535:
                return 'not measured';
            case null:
                return 'not reported';
        }
        return `${value / 1000} V`;
    }

    public hasPowerSupply(): number {
        // eslint-disable-next-line no-bitwise
        return _.get(this.batteryFeed, 'battery', null) & this.powerSupplyBit;
    }

    public getOnlyBatteries(): number {
        // eslint-disable-next-line no-bitwise
        return (_.get(this.batteryFeed, 'battery', null) & (~this.powerSupplyBit)) / 1000;
    }
}
