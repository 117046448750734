import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from '@angular/core';
import {NotificationSettings} from '../../_internal/notification-settings';
import {PropertyService} from '../../providers/services/property.service';
import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-notification-settings-edit',
    templateUrl: './notification-settings-edit.component.html',
    styleUrls: ['./notification-settings-edit.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationSettingsEditComponent {


    @Input() notificationSettings: NotificationSettings;
    @Input() propertyId: string;
    @Input() boolProperties: { name: string; key: string }[];

    constructor(private cdRef: ChangeDetectorRef,
                private bsModalRef: BsModalRef,
                private propertyService: PropertyService) {
    }

    public hideModal(): void {
        this.bsModalRef.hide();
    }

    public onConfirm(): void {
        this.updateNotificationSettings();
    }

    private updateNotificationSettings(): void {
        this.propertyService.updateNotificationSettings(this.propertyId, this.notificationSettings)
                .subscribe((res) => {
                    this.cdRef.detectChanges();
                    this.hideModal();
                });
    }
}
