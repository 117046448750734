import {Component, TemplateRef, ViewChild} from '@angular/core';
import {UserService} from '../../providers/services/user.service';
import {User} from '../../_internal/user';
import {AuthenticationService} from '../../providers/services/authenthication.service';
import {PasswordResource} from '../../providers/resources/password.resource';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {Router} from '@angular/router';

@Component({
    selector: 'app-current-user-badge',
    templateUrl: './current-user-badge.component.html',
    styleUrls: ['./current-user-badge.component.scss']
})
export class CurrentUserBadgeComponent {

    @ViewChild('modal') modal: TemplateRef<any>;
    public currentUser: User;
    public modalRef: BsModalRef;

    constructor(private userService: UserService,
                private passwordResource: PasswordResource,
                private modalService: BsModalService,
                private router: Router,
                private authService: AuthenticationService) {
        this.currentUser = this.userService.user;
    }

    public signOut(): void {
        this.authService.signOut();
    }

    public resetPassword(): void {
        this.passwordResource.requestPasswordReset(this.userService.user.email).subscribe(
            (_response) => {
                this.modalRef = this.modalService.show(this.modal);
                setTimeout(() => {
                    this.modalRef.hide();
                }, 2000);
            }
        );
    }

    public goToCurrentUser(): void {
        this.router.navigateByUrl(`users/${this.userService.userId}`);
    }

}
