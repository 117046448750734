<div class="card mb-4"
     xmlns="http://www.w3.org/1999/html">
    <div class="card-header">
        <i class="fa fa-bell"></i> Notification settings
        <div class="float-end">
            <button class="btn btn btn-secondary btn-sm"
                    (click)="openEditModal()">
                Edit settings
                <i class="fa icons icon-pencil"></i>
            </button>
        </div>

    </div>
    <div class="card-body">
        <table class="table table-striped"
               *ngIf="getNotificationSettingsStream() | async as notificationSettings">
            <tbody>
            <tr *ngFor="let prop of boolProperties">
                <td>{{prop.name}}:</td>
                <td>
                    <span
                        [ngClass]="{'text-success': notificationSettings[prop.key],'text-warning':!notificationSettings[prop.key]}">{{notificationSettings[prop.key] ? 'On' : 'Off' }}</span>
                </td>
            </tr>
            <tr>
                <td>High volume threshold:</td>
                <td>
                    {{notificationSettings.high_volume_threshold_litres}} l
                </td>
            </tr>
            <tr>
                <td>Long flow delay:</td>
                <td>
                    {{notificationSettings.long_flow_notification_delay_mins}} min
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</div>
