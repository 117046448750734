import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {PropertyMembership} from '../../../_internal/property-membership';
import * as _ from 'lodash';
import {MembershipService} from '../../../providers/services/membership.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import { first } from 'rxjs/operators';
import { ConfirmationDialogComponent } from '../../../../common/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { environment } from '../../../../../../src/environments/environment';

@Component({
    selector: 'app-change-owner',
    templateUrl: './change-owner.component.html',
    styleUrls: ['./change-owner.component.scss'],

})
export class ChangeOwnerComponent implements OnInit {
    public members: PropertyMembership[];
    public standardMembersList: PropertyMembership[];


  constructor(@Inject(MAT_DIALOG_DATA) public data: {members: PropertyMembership[]; propertyId: string},
              private membershipService: MembershipService,
              private toastrService: ToastrService,
              private modalService: BsModalService ) { }

  ngOnInit() {
    this.members = this.data.members;
      this.standardMembersList = _.filter(this.members, ['ownership', false]);
  }


  public clickMethod(propertyId: string, membershipId: string, memberData): void {
    this.modalService.show(ConfirmationDialogComponent, {
        initialState: {
            message: `Are you sure to give ownership of the property to ${memberData.first_name} ${memberData.last_name}?`,
            title: 'Confirm change',
            button: 'Save'
        }
    });
    this.modalService.onHide
            .pipe(first())
            .subscribe((reason) => {
                if (reason === 'action-confirmed') {
                  this.changeOwner(propertyId, membershipId);
                }
            });
  }

  public changeOwner(propertyId: string, memberipId: string): void {
    this.membershipService.changeOwner(propertyId, memberipId).subscribe({
      next : () => this.toastrService.success('The change of owner for a given property has been completed successfully.', 'Owner change.', environment.toastSettings),
      error: (err) => this.toastrService.error( 'The change of owner for a given property has failed. Error code: ' + err.status + 'Error message' + err.message, 'Owner change.', environment.toastSettings)
    });
  }


}
