import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {RemoteRestartsService} from '../../providers/services/remote-restarts.service';
import {BsModalService} from 'ngx-bootstrap/modal';
import {ConfirmationDialogComponent} from '../../../common/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import {AlertComponent} from '../../../common/components/dialogs/alert/alert.component';
import * as _ from 'lodash';
import {MatDialog} from '@angular/material/dialog';
import {take} from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../../src/environments/environment';

@Component({
    selector: 'app-trigger-reset-button',
    templateUrl: './trigger-reset-button.component.html',
    styleUrls: ['./trigger-reset-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TriggerResetButtonComponent {

    @Input() hubId: string;
    @Input() thingId: string;
    @Input() groupId: string;
    @Input() sm = true;

    constructor(private remoteRestartsService: RemoteRestartsService,
                private modalService: BsModalService,
                private dialog: MatDialog,
                private toastrService: ToastrService) {
    }


    public beforeTriggerReset(): void {
        this.modalService.show(ConfirmationDialogComponent, {
            initialState: {
                message: `Are you sure you want to trigger reboot?`,
                title: 'Confirm reboot',
                button: 'Trigger'
            }
        });
        this.modalService.onHide
                .pipe(take(1))
                .subscribe((reason) => {
                    if (reason === 'action-confirmed') {
                        this.triggerReset();
                    }
                });
    }

    public triggerReset(): void {
        this.remoteRestartsService.triggerRestart({
            hub_id: this.hubId,
            thing_id: this.thingId,
            group_id: this.groupId,
        })
                .subscribe((response) => {
                            if (response.remote_restart) {
                                this.toastrService.success('Reboot triggered.', 'Success.', environment.toastSettings);
                            }
                        },
                        (error) => this.toastrService.error( 'An error has ocured while saving data. Error code: ' + error.status + 'Message' + error.message, 'Settings.', environment.toastSettings)
                );
    }

    private showMessage(title: string, message: string): void {
        this.dialog.open(AlertComponent, {
            closeOnNavigation: false,
            data: {
                message,
                title,
            }
        });
    }
}
