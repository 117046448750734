<div class="card">
  <div class="card-header">
      <i class="fa fa-align-justify"></i> {{title}}
  </div>
  <div class="card-body">
    <div class="row">
        <div class="col-lg-4 position-relative">
          <div class="calendar-box">
            <div class="form-group">
              <div class="btn-group mb-2" role="group">
                <button class="btn btn-primary" (click)="daterangepicker.toggle()" [attr.aria-expanded]="daterangepicker.isOpen" type="button">Date Range Picker</button>
              </div>
              <input type="text"
              class="form-control"
              formControlName="dateRange"
              #daterangepicker="bsDaterangepicker"
              bsDaterangepicker
              id="DateOfBirth" placeholder="Date Range" (bsValueChange)="dateRangeCreated($event)"
              [bsConfig]="{ containerClass: 'theme-blue', rangeInputFormat : 'MMMM Do YYYY', dateInputFormat: 'MMMM Do YYYY'  }">
          </div>
                <bs-datepicker-inline [bsValue]="datepickerInitialValue"
                                      (bsValueChange)="onDatePick($event)"
                                      [bsConfig]="{containerClass: 'theme-blue'}"></bs-datepicker-inline>
                                    </div>
                                  </div>
                                      <div class="col-lg-8" *ngIf="legionellaData | async; else noEvents">

                                        <app-data-table [dataStream]="legionellaData"
                                                        [displayedColumns]="displayedColumns">
                                        </app-data-table>
                                        <div class="col-12 d-flex justify-content-center" *ngIf="isLoading">
                                            <div class="spinner-border" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                    </div>
                            <ng-template #noEvents>
                                <div class="card-body">
                                    <div class="col-12 d-flex justify-content-center">
                                        <h5>No Events yet</h5>
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                      </div>
                    </div>
