<div class="card mb-4">
	<div class="card-header">
		<i class="fa fa-users"></i> Circle of trust
		<button class="btn btn btn-secondary btn-sm float-end"
				(click)="addContact()">Add
		</button>
	</div>
	<div class="card-body">
		<ng-container *ngIf="emergencyContacts">
			<div class="card mb-2"
				 *ngFor="let member of emergencyContacts">
				<div class="card-body p-1 clearfix">
					<i class="icons icon-user bg-light p-3 font-xl me-3 float-left"></i>
					<div class="h6 text-primary mb-0 mt-2">{{member.first_name}} {{member.last_name}}</div>
					<div class="text-muted font-xs">{{member.email}}</div>
					<div class="buttons float-end">
						<i class="fa icons icon-trash ml-2 float-end cursor pointer"
						   (click)="deleteContact(member)"> </i>
						<i class="fa icons icon-pencil ml-2 float-end"
						   [ngStyle]="{'cursor': 'pointer'}"
						   (click)="openEditDialog(member)"></i></div>
				</div>
			</div>
		</ng-container>
		<p *ngIf="!emergencyContacts">
			No emergency contacts assigned to property.
		</p>


	</div>
</div>
