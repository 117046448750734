<div class="modal-content">
	<div class="modal-header">
		<h4 class="modal-title"
			*ngIf="action === 'update'"> Edit user </h4>
		<h4 class="modal-title"
			*ngIf="action === 'create'"> Create user </h4>
		<button type="button"
				class="close"
				aria-label="Close"
				mat-icon-button
				(click)="onCancel()">
			<mat-icon>close</mat-icon>
		</button>
	</div>
	<div class="modal-body"
		 *ngIf="user || action==='create'">
		<ng-container *ngIf="userForm">

			<form [formGroup]="userForm">

				<div class="row">
					<div class="col-lg-6">

						<div class="form-group">
							<label>Email</label>
							<input type="text"
								   [ngClass]="{'is-invalid': hasErrors('email')}"
								   class="form-control"
								   formControlName="email">
							<div class="errors"
								 *ngIf="apiErrors">
								<p *ngFor="let error of apiErrors['email']"
								   class="alert alert-danger">
									{{ error }}
								</p>
							</div>
						</div>
					</div>
					<div class="col-lg-6">
						<div class="form-group">
							<label>Phone</label>
							<input type="text"
								   [ngClass]="{'is-invalid': hasErrors('phone')}"
								   class="form-control"
								   formControlName="phone">
							<div class="errors"
								 *ngIf="apiErrors">
								<p *ngFor="let error of apiErrors['phone']"
								   class="alert alert-danger">
									{{ error }}
								</p>
							</div>
						</div>
					</div>
					<div class="col-lg-6">
						<div class="form-group">
							<label>First name</label>
							<input type="text"
								   class="form-control"
								   [ngClass]="{'is-invalid': hasErrors('first_name')}"
								   formControlName="first_name">
							<div class="errors"
								 *ngIf="apiErrors">
								<p *ngFor="let error of apiErrors['first_name']"
								   class="alert alert-danger">
									{{ error }}
								</p>
							</div>
						</div>
					</div>
					<div class="col-lg-6">
						<div class="form-group">
							<label>Last name</label>
							<input type="text"
								   [ngClass]="{'is-invalid': hasErrors('last_name')}"
								   class="form-control"
								   formControlName="last_name">
							<div class="errors"
								 *ngIf="apiErrors">
								<p *ngFor="let error of apiErrors['last_name']"
								   class="alert alert-danger">
									{{ error }}
								</p>
							</div>
						</div>
					</div>
					<div class="col-lg-6">
						<div class="form-group"
							 *ngIf="action === 'create'">
							<label>Password</label>
							<input type="text"
								   [ngClass]="{'is-invalid': hasErrors('password')}"
								   class="form-control"
								   formControlName="password">
							<div class="errors"
								 *ngIf="apiErrors">
								<p *ngFor="let error of apiErrors['password']"
								   class="alert alert-danger">
									{{ error }}
								</p>
							</div>
						</div>
					</div>

					<div class="col-lg-6">

						<div class="form-group">
							<label>Roles</label>
							<ng-select
									[items]="roles"
									[multiple]="true"
									[closeOnSelect]="false"
									bindLabel="name"
									placeholder="Select roles"
									formControlName="roles">
							</ng-select>
						</div>

					</div>
					<div class="col-lg-6">

						<div class="form-group">
							<label><input type="checkbox"
										  formControlName="active"> Active </label>
						</div>
					</div>

					<div class="col-lg-6">
						<div class="form-group">
							<label>Organisations</label>
							<ng-select
									[items]="organisations"
									[multiple]="true"
									[closeOnSelect]="false"
									bindLabel="name"
									placeholder="Select organisations"
									formControlName="organisations">
							</ng-select>
						</div>

					</div>
					<div class="col-12">
						<p *ngIf="errorMessage"
						   class="alert alert-danger">
							{{errorMessage}}
						</p>
						<p *ngIf="apiError"
						   class="alert alert-danger">
							{{apiError}}
						</p>
					</div>
				</div>
			</form>
		</ng-container>

	</div>
	<div class="modal-footer">
		<button type="button"
				class="btn btn-secondary"
				(click)="onCancel()">Close
		</button>
		<button type="button"
				class="btn btn-primary"
				(click)="onConfirm()">Save changes
		</button>
	</div>
</div>
