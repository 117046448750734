import {ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {TableDisplayedColumn} from '../../../common/components/data-table/table-displayed-column';
import {Router} from '@angular/router';
import {RemoteRestartsService} from '../../providers/services/remote-restarts.service';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {RemoteRestart} from '../../_internal/remote-restart';
import * as _ from 'lodash';

@Component({
    selector: 'app-trigger-resets-list',
    templateUrl: './trigger-resets-list.component.html',
    styleUrls: ['./trigger-resets-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TriggerResetsListComponent implements OnInit, OnChanges {

    @Input() hubId: string;
    @Input() thingId: string;
    @Input() groupId: string;

    public isLoading = false;
    public displayedColumns: TableDisplayedColumn[] = [
        {key: 'id', name: 'Reset ID'},
        {key: 'state', name: 'State'},
        {key: 'inserted_at', name: 'Inserted at', autoFormat: 'date'},
        {key: 'updated_at', name: 'Updated at', autoFormat: 'date'},
    ];

    public resetsStream: Observable<RemoteRestart[]>;

    constructor(private router: Router,
                private remoteRestartsService: RemoteRestartsService) {
    }

    ngOnInit() {
        this.updateStream();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!_.get(changes.thing, 'firstChange')) {
            this.updateStream();
        }
    }

    public goToJob(id): void {
        this.router.navigate(['jobs', id]);
    }

    public refresh(): void {
        this.updateStream();
    }

    private updateStream(): void {
        const params = {};
        if (this.hubId) {
            _.set(params, 'hub_id', this.hubId);
        } else if (this.groupId) {
            _.set(params, 'group_id', this.groupId);
        } else if (this.thingId) {
            _.set(params, 'thing_id', this.thingId);
        }
        this.resetsStream = this.remoteRestartsService.getRestarts(params)
            .pipe(
                map(response => response.remote_restarts),
                map((data) => _.orderBy(data,['updated_at'],['desc']))

            );
    }
}
