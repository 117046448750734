import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {ColumnMode, DatatableComponent, TableColumn} from '@swimlane/ngx-datatable';
import {HttpParams} from '@angular/common/http';
import * as _ from 'lodash';
import {OptionalHttpParams} from '../../../common/api/CrudAware';
import {PaginatedResponse} from '../../_internal/pagination';
import {FilterField, FilterOption, TableSettings} from '../../_internal/table-settings';
import { Router } from '@angular/router';

@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableComponent implements OnInit {

    @Input() externalSorting = true;
    @Input() columns: TableColumn[];
    @Input() rows: any[];
    @Input() selectionEnabled = true;
    @Input() tableSettings: TableSettings;
    @Input() rowHeight: number | 'auto';
    @Input() defaultSorts: any[];
    @Input() filterFields: FilterField[];
    @Input() startValues: FilterOption[];
    @Input() pageName: string;
    @Input() scrollToTop = true;
    @Input() sorts: any[];
    @Input() showLoader = true;
    @Output() fetchPage = new EventEmitter<HttpParams | OptionalHttpParams>();
    @Output() groupUpdate = new EventEmitter<HttpParams | OptionalHttpParams>();
    @Output() selected = new EventEmitter<any>();
    @ViewChild('codeColumn') codeColumn: TemplateRef<any>;
    @ViewChild('dateColumn') dateColumn: TemplateRef<any>;
    @ViewChild('yesNoColumn') yesNoColumn: TemplateRef<any>;
    @ViewChild('serianNumberColumn') serianNumberColumn: TemplateRef<any>;
    @ViewChild('table', { static: true }) table: DatatableComponent;

    public ColumnMode = ColumnMode;
    public offset = 0;
    public isLoading: boolean;
    private filterValues = {};

    constructor(private router: Router,) {

    }

    public ngOnInit(): void {
        const startPage = Number(window.location.hash.substr(1));
        if (startPage) {
            _.set(this.tableSettings, 'page', startPage);
        }
        if (_.size(this.startValues) === 0) {
            if (this.defaultSorts) {
                _.set(this.tableSettings, 'order_strategy', _.get(this.defaultSorts, '[0].dir'));
                _.set(this.tableSettings, 'order_by', _.get(this.defaultSorts, '[0].prop'));
            }
            this.fetchNewPage();
        }
    }

    public onSort(event: any): void {
        if (!this.externalSorting) {
            return;
        }
        const sort = event.sorts[0];
        _.set(this.tableSettings, 'order_strategy', sort.dir);
        _.set(this.tableSettings, 'order_by', sort.prop);

        this.fetchNewPage();
    }

    public onRowSelect({selected}): void {
        this.selected.emit(selected[0]);
    }

    public setPage(pageInfo): void {
        _.set(this.tableSettings, 'page', pageInfo.offset + 1);
        if(this.scrollToTop){
            window.location.hash = this.tableSettings.page.toString();
        }
        this.fetchNewPage();
    }

    public onSearch(params: OptionalHttpParams): void {
        this.filterValues = params;
        _.set(this.tableSettings, 'page', 1);
        this.fetchNewPage();
    }

    public setPageParams(result: PaginatedResponse): void {
        _.set(this.tableSettings, 'page', +result.page_number);
        _.set(this.tableSettings, 'page_size', +result.page_size);
        _.set(this.tableSettings, 'total_elements', +result.total_entries);
        _.set(this.tableSettings, 'total_pages', +result.total_pages);
    }

    public toNum(value: string | number): number {
        return value as number;
    }

    public refresh(showLoader = true): void {
        this.showLoader = showLoader;
        this.fetchNewPage();
    }

    private fetchNewPage(): void {
        if (this.showLoader) {
            this.isLoading = true;
        }

        const params = this.filterValues;
        _.set(params, 'page', this.tableSettings.page);
        _.set(params, 'page_size', this.tableSettings.page_size);
        if (this.tableSettings.order_by && this.tableSettings.order_strategy) {
            _.set(params, 'order_by', this.tableSettings.order_by);
            _.set(params, 'order_strategy', this.tableSettings.order_strategy);
        }
        this.fetchPage.emit(params);
        this.showLoader = true;
    }
}
