<div class="card mb-4">
	<div class="card-header">
		<i class="fa fa-align-justify"></i> Properties
		<div class="float-end buttons-flex">
			<button class="btn btn-secondary btn-sm"
					(click)="openCreateModal()">Create property
			</button>
            <button class="btn btn-secondary btn-sm"
                    (click)="openPropertyInvite()">Mass invite
            </button>
		</div>
	</div>

	<div class="card-body">
		<app-table [rows]="rows"
				   [filterFields]="filterFields"
				   [columns]="columns"
				   [tableSettings]="tableSettings"
				   [selectionEnabled]="false"
				   (fetchPage)="fetchPage($event)"
                   pageName="properties">
		</app-table>
		<ng-template #addressColumn
					 ngx-datatable-cell-template
					 let-row="row"
					 let-value="value">
			{{row.address_1}} {{row.address_2}} {{row.address_3}}
		</ng-template>
		<ng-template #systemColumn
					 ngx-datatable-cell-template
					 let-row="row"
					 let-value="value">
			{{ printSystemStatus(row)}}
		</ng-template>
		<ng-template #actionsColumn
					 ngx-datatable-cell-template
					 let-row="row"
					 let-value="value">
			<a routerLink="/properties/{{row.id}}"
			   class="btn btn-secondary btn-md px-5 ml-auto">Open</a>

               <app-favourites-button location="properties" [rowID]="row.id" [rowName]="row.name" [rowAddress]="row.city"></app-favourites-button>
		</ng-template>
	</div>

</div>
