import { ThingResponse, ThingsResponse } from './../resources/dto/thing.response';
import { ThingEventsResponse } from './../resources/dto/thing-event.response';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {TotalUsage} from '../../_internal/total.usage';
import {ThingResource} from '../resources/thing.resource';
import {ThingEvent} from '../../_internal/thing.event';
import {map} from 'rxjs/operators';
import {PressureTest} from '../../_internal/pressure-test';
import {ApplianceCategory} from '../../_internal/appliance_category';
import { Thing } from '../../_internal/thing';
import * as _ from 'lodash';
import { ThingUpdateData } from '../../_internal/thing-update.response';
import { PressureTestsResponse } from '../resources/dto/pressure-tests.response';
import { HttpParams } from '@angular/common/http';
import {OptionalHttpParams} from '../../../common/api/CrudAware';
import { HourlyStatistics } from '../resources/dto/hourly-statistics';
import { AggregatedDailyData } from '../resources/dto/aggregated-daily-data';
import {Moment} from 'moment-timezone';
import { BurningTestResultResponse } from '../resources/dto/burning-mode';
import { AdvancedValveRaport } from '../resources/dto/advanced-valve-raport';
import { FirmwareSettings } from '../resources/dto/firmware-device-settings.response';

@Injectable()
export class ThingService {

    constructor(private thingResource: ThingResource) {
    }

    public getThingsList(params: OptionalHttpParams = {}): Observable<ThingsResponse> {
        return this.thingResource.getThingsList(params);
    }

    public getThing(thingId: string): Observable<ThingResponse> {
        return this.thingResource.getThing(thingId);
    }

    public totalUsage(thingId: string, dateFrom: Moment, dateTo: Moment, applianceType: string = ''): Observable<TotalUsage> {
        const queryParams = this.craftRangeQueryParams(dateFrom, dateTo, applianceType);
        return this.thingResource.getTotalUsage(thingId, queryParams);
    }

    public craftRangeQueryParams(dateFrom: Moment, dateTo: Moment, applianceType: string): string {
        let query = `?range_from=${dateFrom.valueOf()}&range_to=${dateTo.valueOf()}`;

        if (applianceType !== '') {
            query += `&appliance_type=${applianceType}`;
        }
        return query;
    }

    public getThingEvents(thingId: string, from: string, to: string): Observable<ThingEventsResponse> {
        return this.thingResource.getEvents(thingId, from, to);
    }

    public getDailyUsage(thingId: string, utcStart: string, utcEnd: string): Observable<AggregatedDailyData> {
        return this.thingResource.getDailyUsage(thingId, utcStart, utcEnd);
    }

    public getApplianceCategories(thingId: string): Observable<ApplianceCategory[]> {
        return this.thingResource.getApplianceCategories(thingId)
            .pipe(map((res) => res.appliance_categories));
    }

    public getPressureTests(thingId: string): Observable<PressureTest[]> {
        return this.thingResource.getPressureTests(thingId)
            .pipe(map((res) => res.pressure_tests));
    }

    public getPressureTestsWithResponseData(thingId: string, params?: HttpParams | OptionalHttpParams): Observable<PressureTestsResponse>{
        return this.thingResource.getPressureTestsPaginated(thingId, params);
    }

    public updateAppliancesInEvent(thingId: string, eventStart: string, applianceIds: string[]): Observable<ThingEvent> {
        return this.thingResource.updateAppliancesInEvent(thingId, eventStart, applianceIds)
            .pipe(map((res) => res.event));
    }

    public triggerPressureTests(thingId: string): Observable<PressureTest> {
        return this.thingResource.triggerPressureTests(thingId)
            .pipe(map((res) => res.pressure_test));
    }

    public updateValveState(thingId: string, payload: {state: string}): Observable<ThingResponse> {
        return this.thingResource.updateValveState(thingId, payload);
    }

    public updateThing(thingId: string, payload: ThingUpdateData): Observable<ThingResponse> {
        return this.thingResource.updateThing(thingId, payload);
    }

    public getHourlyStatistics(thingId: string, payload: any): Observable<HourlyStatistics[]> {
        return this.thingResource.getHourlyStatistics(thingId, payload);
    }

    public getBurningReportsData(serialNo: string): Observable<BurningTestResultResponse> {
        return this.thingResource.getBurningReportsData(serialNo);
    }

    public getAdvancedValveRaportData(thingId: string, from: number, to: number): Observable<AdvancedValveRaport[]> {
        return this.thingResource.getAdvancedValveRaportData(thingId,from,to);
    }

    public getFirmwareSettings(thingId: string): Observable<FirmwareSettings> {
        const headers = {Accept: 'application/vnd.wtg.device.v1+json'};
        return this.thingResource.getFirmwareSettings(thingId, headers);
    }

    public updateFirmwareSettings(thingId: string, payload: FirmwareSettings): Observable<FirmwareSettings> {
        const headers = {
            'Content-Type': 'application/vnd.wtg.device-update.v1+json',
            Accept: 'application/vnd.wtg.device.v1+json'
        };
        return this.thingResource.updateFirmwareSettings(thingId, payload, headers);
    }
}
