<div *ngIf="telemetry">
    <dl class="small-list">
        <dt>Probed at</dt>
        <dd>
            <app-multi-time [date]="telemetry.probed_at"
                            [propertyId]="propertyId"
                            [showLocal]="false"
                            [showProperty]="false"></app-multi-time>
            {{ timeAgo(telemetry.probed_at) }}
        </dd>
        <dt>Status</dt>
        <dd>
            {{ telemetry.status }}
        </dd>

        <dt>
            <i class="fa fa-thermometer-full"></i> Ambient temp
        </dt>
        <dd>
            <c-badge color="info">
                {{ telemetry.temperature | number : '1.1-1' }} °C
            </c-badge>
        </dd>
        <dt>
            <i class="fa fa-tint"></i> Humidity
        </dt>
        <dd>
            <c-badge color="info">
                {{ telemetry.humidity | number : '1.0-0' }}%
            </c-badge>
        </dd>
        <dt>
            <i class="fa fa-exclamation-triangle"></i> Leak
        </dt>

        <dd>
            <c-badge color="info">
                {{ telemetry.leak_detected }}
            </c-badge>
        </dd>

        <dt>
            <i class="fa fa-battery"></i> Battery 1
        </dt>

        <dd>
            <c-badge color="info">
                {{ telemetry.first_battery_voltage }}
            </c-badge>
        </dd>
        <dt>
            <i class="fa fa-battery"></i> Battery 2
        </dt>

        <dd>
            <c-badge color="info">
                {{ telemetry.second_battery_voltage }}
            </c-badge>
        </dd>

    </dl>


</div>
