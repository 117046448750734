import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    TemplateRef,
    ViewChild
} from '@angular/core';
import {ShadowService} from '../../providers/services/shadow.service';
import {OptionalHttpParams} from '../../../common/api/CrudAware';
import {DeviceShadowLog, ShadowLogResponse} from '../../_internal/shadow';
import * as _ from 'lodash';
import {tableSettings} from 'app/hero-labs/_internal/table-settings';
import {TableComponent} from '../table/table.component';

@Component({
    selector: 'app-shadow-logs',
    templateUrl: './shadow-logs.component.html',
    styleUrls: ['./shadow-logs.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShadowLogsComponent implements AfterViewInit {

    @Input() hubId: string;
    @Input() thingId: string;
    @Input() sonicWifi = false;
    @Input() valveStatus = false;

    @ViewChild(TableComponent) table: TableComponent;
    @ViewChild('dateColumn') dateColumn: TemplateRef<any>;
    @ViewChild('messageColumn') messageColumn: TemplateRef<any>;
    public graphMode = false;
    public parameterName: string;
    public tableSettings = _.cloneDeep(tableSettings);
    public rows: DeviceShadowLog[];
    public columns = [];
    public modeButtonName = 'Show graph';

    constructor(private cdRef: ChangeDetectorRef,
                private shadowService: ShadowService) {
    }

    public ngAfterViewInit(): void {
        this.columns = [
            {
                cellTemplate: this.messageColumn,
                prop: 'original_message',
                name: 'Message',
                flexGrow: 0.6,
                sortable: false
            },
            {cellTemplate: this.dateColumn, prop: 'received_at', name: 'Received at', flexGrow: 0.2, sortable: false}
        ];
    }

    public toggleMode() {
        this.graphMode = !this.graphMode;
        this.modeButtonName = this.graphMode ? 'Show raw data' : 'Show graph';
        this.cdRef.detectChanges();
    }

    public fetchPage(params?: OptionalHttpParams): void {
        if (this.hubId) {
            _.set(params, 'hub_id', this.hubId);
        }

        if (this.thingId) {
            _.set(params, 'thing_id', this.thingId);
        }

        // Temporary fix until backend handles ordering and filtering
        _.unset(params, 'order_by');
        _.unset(params, 'order_strategy');

        this.shadowService.getLogs(params)
                .subscribe((response: ShadowLogResponse) => {
                    this.table.setPageParams(response);
                    this.rows = response.device_shadow_logs;
                    if(this.rows){
                        this.parameterName = this.rows[0].thing_id ? 'rssi' : 'wifi_rssi';
                    }
                    this.table.isLoading = false;
                    this.cdRef.detectChanges();
                });

    }

}
