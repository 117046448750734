
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {LifeCycleEventService} from '../../providers/services/life-cycle-event.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import {EChartsOption} from 'echarts';
import {lineChartDefaultOptions} from '../../../common/chart-defaults';
import { DeviceShadowLog } from '../../_internal/shadow';

@Component({
  selector: 'app-valve-status-timeline',
  templateUrl: './valve-status-timeline.component.html',
  styleUrls: ['./valve-status-timeline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ValveStatusTimelineComponent implements OnChanges {

  @Input() events: DeviceShadowLog[];
  @Input() hubId: string;
  @Input() thingId: string;
  @Input() sonicConnection: boolean | string;
  @Input() bridgeConnection: boolean;
  @Input() sonicWifi = false;

  public chartsOption: EChartsOption;


  public chartLabels = [];
  public responseSizeReachedMax = false;
  public noDataMessage = false;
  private maxChartTimeSpanMs = 3600 * 24 * 1000 * 30; // 30 days

  constructor(private lifeCycleEventService: LifeCycleEventService,
              private cdRef: ChangeDetectorRef) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(moment.utc().diff(this.events[0].received_at, 'days') === 0){
        const currentState = _.cloneDeep(_.first(this.events));
        currentState.received_at = moment.utc().format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
        this.events.unshift(currentState);
    }
    this.updateChart();
}



    public mapLabels(probes: DeviceShadowLog[]): string[] {
        return probes.map((val) => val.received_at);
        }


    public mapValues(probes: DeviceShadowLog[], offset = 0): any[] {
        return probes.map((val) => {
        switch(val.original_message.state) {
            case 'open':
                return {
                    value: [val.received_at , 2 + offset ],
                    itemStyle: {color: 'red'},
                    object: val
                };
            case 'closing':
                return {
                    value: [val.received_at, 1 + offset ],
                    itemStyle: {color:  'red'},
                    object: val
                };
            case 'unknown':
                return {
                    value: [val.received_at, 0 + offset],
                    itemStyle: {color: 'red'},
                    object: val
                };
            case 'opening':
                return {
                    value: [val.received_at, -1 + offset],
                    itemStyle: {color: 'red'},
                    object: val
                };
            case 'closed':
                return {
                    value: [val.received_at, -2 + offset],
                    itemStyle: {color: 'red'},
                    object: val
                };
        }
        });
    }

    private returnLabelName(labelNumber) {
        switch (labelNumber){
            case -2:
                return 'Closed';
            case -1:
                return 'Opening';
            case 0:
                return 'Unknown';
            case 1:
                return 'Closing';
            case 2:
                return 'Open';
        }
    }

    private updateChart(): void {
        this.chartsOption = _.cloneDeep(lineChartDefaultOptions);
        const series = [];
        const yLabels = [];

        series.push({
                    name: 'Valve status',
                    type: 'line',
                    step: '1',
                    data: this.mapValues(this.events),
                    lineStyle: {
                        width: 1,
                        color: 'red'
                    }
        });



        _.merge(this.chartsOption,
                {
                title: {
                    text: 'Valve status',
                },
                useUTC: true,
                xAxis: {
                    type: 'time',
                    data: this.mapLabels(this.events),
                },
                yAxis: [{
                    min: -2,
                    max: 2,
                    minInterval: 1,
                    show: true,
                    axisLabel: {
                        formatter: (value) => this.returnLabelName(value),
                      }
                    },
                    {
                    type: 'category',
                    position: 'left',
                    }
                ],
                legend: {
                    data: ['Valve state']
                },
                tooltip: {
                    position: (pt, params) => [pt[0] - 100, pt[1] - 100],
                    formatter: (params) => {
                        const valveData = params[0].data.object;
                        return `<div>Date: ${moment.utc(valveData.received_at).format('YYYY-MM-DD HH:mm:ss')}</div> <strong>Valve: ${valveData.original_message.state} </strong>`;
                    },

                },
                dataZoom: [
                    {
                        filterMode: 'none',
                        startValue: moment().subtract(30, 'days').valueOf(),
                        type: 'inside',
                        maxValueSpan: this.maxChartTimeSpanMs
                    },
                    {
                        dataBackground: {
                            lineStyle: {
                                width: 0
                            },
                            areaStyle: {
                                opacity: 0
                            }
                        },
                        selectedDataBackground: {
                            lineStyle: {
                                width: 0
                            },
                            areaStyle: {
                                opacity: 0
                            }
                        },
                        filterMode: 'none',
                        maxValueSpan: this.maxChartTimeSpanMs
                    }
                ],
                series,
            });
        }
}

