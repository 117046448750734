import {Injectable} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import { LegionellaResource } from '../resources/legionella.resource';
import { LegionellaCheckResonse } from '../resources/dto/legionella.response';

@Injectable({
  providedIn: 'root'
})
export class LegionellaService {

  constructor(private legionellaResource: LegionellaResource) {

  }
  public legionellaTemperatureChecks(thingID: string): Observable<LegionellaCheckResonse> {
    return this.legionellaResource.legionellaTemperatureChecks(thingID);
}

public legionellaStagnationChecks(thingID: string): Observable<LegionellaCheckResonse> {
    return this.legionellaResource.legionellaStagnationChecks(thingID);
}

}


