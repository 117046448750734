import {Component, Input, OnInit} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {Thing} from '../../_internal/thing';

@Component({
    selector: 'app-chronograf-button',
    templateUrl: './chronograf-button.component.html',
    styleUrls: ['./chronograf-button.component.scss']
})
export class ChronografButtonComponent implements OnInit {

    @Input() thing: Thing;
    @Input() mode = 'default';

    constructor() {
    }

    ngOnInit(): void {
    }

    public openChronograf() {
        const domain = environment.production ? 'com' : 'net';
        const chronografBase = 'https://chronograf.hero-labs';
        const path = 'sources/10000/chronograf/data-explorer?query=';
        let query = '';
        switch (this.mode) {
            case 'battery':
                // eslint-disable-next-line max-len
                query = `SELECT%20time%2Cfirst_battery_voltage%2Csecond_battery_voltage%20FROM%20"telegraf-telemetry"."autogen"."mqtt_consumer"%20WHERE%20time%20>%20%3AdashboardTime%3A%20AND%20time%20<%20%3AupperDashboardTime%3A%20AND%20"device_id"%3D%27${this.thing.id}%27%20LIMIT%202000#`;
                break;
            case 'default':
            default:
                // eslint-disable-next-line max-len
                query = `SELECT%20%2A%20FROM%20%22telegraf-telemetry%22.%22autogen%22.%22mqtt_consumer%22%20WHERE%20time%20%3E%20%3AdashboardTime%3A%20AND%20time%20%3C%20%3AupperDashboardTime%3A%20AND%20%22device_id%22%3D%27${this.thing.id}%27%20LIMIT%202000`;
                break;
        }
        const url = `${chronografBase}.${domain}/${path}${query}`;

        this.openNewTab(url);
    }

    private openNewTab(url: string) {
        window.open(url);
    }
}
