import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {CalibrationResult} from '../../../_internal/calibration-result';
import {OptionalHttpParams} from '../../../../common/api/CrudAware';
import {Device} from '../../../_internal/device';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {DeviceModalComponent} from '../../../components/device-modal/device-modal.component';
import {first, take, tap} from 'rxjs/operators';
import {ManufacturingFeedback} from '../../../_internal/manufacturing-response';
import {ManufacturingService} from '../../..//providers/services/manufacturing.service';
import {ConfirmationDialogComponent} from '../../../../common/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import {ProvisionReport} from '../../../_internal/provision-report';
import {DevicePressureTestResult} from '../../../providers/resources/dto/pressure-test-response';
import * as _ from 'lodash';
import {ToastrService} from 'ngx-toastr';
import {emojisplosion} from 'emojisplosion';
import {timer} from 'rxjs';
import {ExportDeviceComponent} from '../../../../common/components/dialogs/export-device/export-device.component';
import {InformationDialogComponent} from '../../../../common/components/dialogs/information-dialog/information-dialog.component';
import {environment} from '../../../../../../src/environments/environment';
import {DeviceEditPageComponent} from '../device-edit/device-edit-page.component';

@Component({
    selector: 'app-device',
    templateUrl: './device.component.html',
    styleUrls: ['./device.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceComponent implements OnInit {

    public serialNo: string;
    public device: Device;
    public modalRef: BsModalRef;
    public manufactoringData;
    public lastProvisionReport: ProvisionReport;
    public lastCalibrationResult: CalibrationResult;
    public lastPressureTest: DevicePressureTestResult;

    constructor(private cdRef: ChangeDetectorRef,
                private manufacturingService: ManufacturingService,
                private route: ActivatedRoute,
                private modalService: BsModalService,
                private toastrService: ToastrService) {
    }

    ngOnInit(): void {
        this.route.params
            .subscribe((params) => {
                this.serialNo = params['serialNo'];
                this.fetchData();
            });
    }

    public getCalibrationResults() {
        return (params?: OptionalHttpParams) => this.manufacturingService.getCalibrationResultsBySerial(this.serialNo, params)
            .pipe(tap((data) => {
                if (data.total_entries === 0) {
                    return;
                }
                this.lastCalibrationResult = _.head(data.data);
            }));
    }

    public getProvisionReports() {
        return (params?: OptionalHttpParams) => this.manufacturingService.getProvisionReportsBySerial(this.serialNo, params)
            .pipe(tap((data) => {
                if (data.total_entries === 0) {
                    return;
                }
                this.lastProvisionReport = _.head(data.data);
            }));
    }

    public getPressureTests() {
        return (params?: OptionalHttpParams) => this.manufacturingService.getPressureTestReportsBySerial(this.serialNo, params)
            .pipe(tap((data) => {
                if (data.total_entries === 0) {
                    return;
                }
                this.lastPressureTest = _.head(data.data);
            }));
    }

    public openDeviceModal(statusType: string): void {
        this.modalRef = this.modalService.show(DeviceModalComponent, {
            initialState: {
                serialNo: this.serialNo,
                statusValue: this.manufactoringData[statusType],
                statusType
            }
        });
        this.modalService.onHide
            .pipe(first())
            .subscribe(() => {
                this.fetchManufactoring();
                this.cdRef.detectChanges();
            });
    }

    public unblockModal(statusType: string): void {
        this.modalService.show(ConfirmationDialogComponent, {
            initialState: {
                message: `Are you sure you want to unblock?`,
                title: 'Confirm unblocking',
                button: 'Unblock'
            }
        });
        this.modalService.onHide
            .pipe(first())
            .subscribe((reason) => {
            if (reason === 'action-confirmed') {
                switch (statusType) {
                    case 'provisioning':
                        this.sendProvisioningFeedback();
                        break;
                    case 'calibration':
                        this.sendCalibrationFeedback();
                        break;
                    case 'pressure_test':
                        this.sendPressureTestFeedback();
                        break;
                }
            }
        });
    }

    public exportDevice(): void{
        this.modalService.show(ExportDeviceComponent, {
            initialState: {
                serialNo: this.serialNo,
            },
        });

        const subscription = this.modalService.onHide
        .subscribe((reason) => {
            if (reason !== 'certificates-transfer') {
                subscription.unsubscribe();
                return;
            }
            this.modalService.show(InformationDialogComponent, {
                initialState: {
                    modalTitle: `Certificates transfer`,
                    modalContent: 'Certificates transfered. You can import device data to using import button in device list page.',
                }
            });
            subscription.unsubscribe();
        });
    }

    public openEditModal(): void {
        this.modalRef = this.modalService.show(DeviceEditPageComponent, {
            initialState: {
                device: _.cloneDeep(this.device)
            }
        });
        this.modalService.onHide
            .pipe(take(1))
            .subscribe(() => this.fetchData());
    }

    private sendPressureTestFeedback() {
        const params = this.getFeedbackParams();
        this.manufacturingService.updatePressureTest(this.serialNo, this.lastPressureTest.id, params)
            .subscribe(
                () => {
                    this.toastrService.success('Settings have been saved successfully', 'Settings.', environment.toastSettings);
                    this.fetchData();
                },
                error => {
                    this.toastrService.error( 'An error has ocured while saving data. Error code: ' + error.status + 'Error message' + error.message, 'Settings.', environment.toastSettings);
                }
            );
    }

    private sendCalibrationFeedback() {
        const params = this.getFeedbackParams();
        this.manufacturingService.updateCalibrationResult(this.serialNo, this.lastCalibrationResult.id, params)
        .subscribe(
            () => {
                this.toastrService.success('Settings have been saved successfully.', 'Settings.',environment.toastSettings);
                this.fetchData();
            },
            error => {
                this.toastrService.error( 'An error has ocured while saving data. Error code: ' + error.status + 'Error message' + error.message, 'Settings.', environment.toastSettings);
            }
        );
    }

    private sendProvisioningFeedback() {
        const params = this.getFeedbackParams();
        this.manufacturingService.updateProvisionReport(this.serialNo, this.lastProvisionReport.id, params)
        .subscribe(
            () => {
                this.toastrService.success('Settings have been saved successfully.', 'Settings.', environment.toastSettings);
                this.fetchData();
            },
            error => {
                this.toastrService.error( 'An error has ocured while saving data. Error code: ' + error.status + 'Error message' + error.message, 'Settings.', environment.toastSettings);
            }
        );
    }

    private getFeedbackParams(): { manufacturing_feedback: ManufacturingFeedback } {
        return {manufacturing_feedback: {description: 'Generic unblock from admin'}};
    }

    private fetchManufactoring(): void {
        this.manufacturingService.getDeviceManufacturingReport(this.serialNo)
            .subscribe((response) => {
                this.manufactoringData = response;
                this.cdRef.detectChanges();
            });
    }

    private fetchData() {
        this.fetchDevice();
        this.fetchManufactoring();
    }

    private fetchDevice(): void {
        this.manufacturingService.getDevice(this.serialNo)
            .subscribe((response) => {
                this.device = response;
                if (this.device.is_golden_sample) {

                    this.showFireworks();
                }
                this.cdRef.detectChanges();
            });
    }

    private showFireworks() {
        timer(1000)
            .subscribe(() => {
                emojisplosion({
                    emojiCount: 20,
                    position: () => ({
                        x: innerWidth / 2,
                        y: innerHeight / 2,
                    }),
                    physics: {
                        initialVelocities: {
                            rotation: {min: -10, max: 10},
                            x: {min: -30, max: 30},
                            y: {min: -30, max: 30},
                        }
                    }
                });
            });
    }
}
