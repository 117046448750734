import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {EChartsOption} from 'echarts';
import {lineChartDefaultOptions} from '../../../../../common/chart-defaults';
import * as moment from 'moment';
import * as _ from 'lodash';
import { HourlyStatistics } from '../../../../providers/resources/dto/hourly-statistics';

@Component({
  selector: 'app-pressure-chart',
  templateUrl: './pressure-chart.component.html',
  styleUrls: ['./pressure-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class PressureChartComponent implements OnInit, OnChanges {

    @Input() deviceStatistics: HourlyStatistics[];
    @Input() dateFormat: string;
    public chartOptions: EChartsOption;
    constructor() { }

    ngOnInit(): void {
        this.updateChart();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.updateChart();
    }

    private updateChart(): void {
        this.chartOptions = _.cloneDeep(lineChartDefaultOptions);

        _.merge(this.chartOptions,
            {
                title: {
                  text: 'Pressure [bar]'
                },
                useUTC: true,
                tooltip: {
                  trigger: 'axis'
                },
                legend: {
                  data: ['Max pressure', 'Min presure', 'Average pressure']
                },
                xAxis: {
                  type: 'category',
                  boundaryGap: false,
                  data: this.deviceStatistics.map(a => moment.unix(a.agr_date).format(this.dateFormat)),
                },
                yAxis: {
                  type: 'value',
                  name: 'bar',
                  min: 0,
                },
                series: [
                  {
                    name: 'Max pressure',
                    type: 'line',
                    color: 'red',
                    data: this.deviceStatistics.map(a => _.round(a.pressure_max/1000, 2)),
                  },
                  {
                    name: 'Min presure',
                    type: 'line',
                    color: 'blue',
                    data: this.deviceStatistics.map(a => _.round(a.pressure_min/1000, 2)),
                  },
                  {
                    name: 'Average pressure',
                    type: 'line',
                    color: 'orange',
                    data:this.deviceStatistics.map(a => _.round(a.pressure_average, 0)/1000),
                  },
                ]
              }
        );
        }
}
