import {UserListPageComponent} from './user-list/user-list-page.component';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {UserPageComponent} from './user-page/user-page.component';


const routes: Routes = [
    {
        path: '',
        children: [
            {
                path: '',
                component: UserListPageComponent,
                data: {
                    title: 'Users list',
                },
            },
            {
                path: ':id',
                component: UserPageComponent,
                data: {
                    title: 'User',
                },
            },
        ]
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class UserPagesRoutingModule {
}
