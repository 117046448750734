import {Injectable} from '@angular/core';
import {CrudAware, OptionalHttpParams} from '../../../common/api/CrudAware';
import {Observable} from 'rxjs';
import {HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {HubResponse, HubsResponse} from '../resources/dto/hub.response';
import {Hub, HubUpdateData} from '../../_internal/hub';
import {HubResource} from '../resources/hub.resource';
import {ThingsResponse} from '../resources/dto/thing.response';
import {Thing} from '../../_internal/thing';
import {FirmwareSettings} from '../resources/dto/firmware-device-settings.response';

@Injectable()
export class HubService implements CrudAware<HubResponse> {
    constructor(private readonly hubResource: HubResource) {
    }

    getMany(params?: HttpParams | OptionalHttpParams): Observable<Hub[]> {
        return this.hubResource.getMany(params)
            .pipe(map((res: HubsResponse) => res.hubs));
    }

    public getAssociatedThings(hubId: string): Observable<Thing[]> {
        return this.hubResource.getAssociatedThings(hubId)
            .pipe(map((res: ThingsResponse) => res.things));
    }

    public deleteHub(hubId: string): Observable<Hub> {
        return this.hubResource.deleteHub(hubId)
            .pipe(map((res: HubResponse) => res.hub));
    }

    public updateHub(hubId: string, payload: HubUpdateData): Observable<HubResponse> {
        return this.hubResource.updateHub(hubId, payload);
    }

    public getFirmwareSettings(thingId: string): Observable<FirmwareSettings> {
        const headers = {Accept: 'application/vnd.wtg.device.v1+json'};
        return this.hubResource.getFirmwareSettings(thingId, headers);
    }

    public updateFirmwareSettings(thingId: string, payload: FirmwareSettings): Observable<FirmwareSettings> {
        const headers = {
            'Content-Type': 'application/vnd.wtg.device-update.v1+json',
            Accept: 'application/vnd.wtg.device.v1+json'
        };
        return this.hubResource.updateFirmwareSettings(thingId, payload, headers);
    }
}
