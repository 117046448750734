import {PressureTestStatuses} from './pressure-test-statuses';
import {PressureTest, PressureTestMeasurement} from './../../_internal/pressure-test';
import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, TemplateRef, ViewChild} from '@angular/core';
import {Thing} from '../../_internal/thing';
import * as _ from 'lodash';
import {TableComponent} from '../table/table.component';
import {tableSettings} from '../../_internal/table-settings';
import { ThingService } from '../../providers/services/thing.service';
import {OptionalHttpParams} from '../../../common/api/CrudAware';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PressureTestGraphComponent } from '../../../common/components/dialogs/pressure-test-graph/pressure-test-graph.component';

@Component({
    selector: 'app-pressure-test-list',
    templateUrl: './pressure-test-list.component.html',
    styleUrls: ['./pressure-test-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PressureTestListComponent implements AfterViewInit{
    @ViewChild(TableComponent) table: TableComponent;
    @ViewChild('state') state: TemplateRef<any>;
    @ViewChild('status') status: TemplateRef<any>;
    @ViewChild('average') average: TemplateRef<any>;
    @ViewChild('resultsPassedColumn') resultsPassedColumn: TemplateRef<any>;
    @ViewChild('tendency') tendency: TemplateRef<any>;
    @ViewChild('created_at') created_at: TemplateRef<any>;
    @ViewChild('started_at') started_at: TemplateRef<any>;
    @ViewChild('finished_at') finished_at: TemplateRef<any>;
    @ViewChild('actionsColumn') actionsColumn: TemplateRef<any>;

    @Input() thing: Thing;
    public tableSettings = _.cloneDeep(tableSettings);
    public columns = [];
    public rows: PressureTest[];
    public modalRef: BsModalRef;

    constructor(private thingService: ThingService,
                private cdRef: ChangeDetectorRef,
                private modalService: BsModalService) {
    }

    roundNumber(value, toFixed = 2) {
        return typeof value === 'number' ? value.toFixed(toFixed) : '';
    }

    determineStateClassName(state: string): string {
        switch (state) {
            case 'requested':
                return 'secondary';
                break;
            case 'preparing':
                return 'primary';
                break;
            case 'in_progress':
                return 'primary';
                break;
            case 'opening':
                return 'primary';
                break;
            case 'closing':
                return 'primary';
                break;
            case 'finished':
                return 'success';
                break;
            case 'skipped':
                return 'warning';
                break;
            case 'error':
                return 'warning';
                break;
        }
    }

    ngAfterViewInit(): void {
        this.columns = [
            {prop: 'id', name: 'Id', flexGrow: 2, sortable: false, cellTemplate: this.table.codeColumn},
            {
                cellTemplate: this.state,
                prop: 'state',
                name: 'State',
                flexGrow: 1,
                sortable: false
            },
            {
                cellTemplate: this.status,
                prop: 'status',
                name: 'Status',
                flexGrow: 2.5,
                sortable: false
            },
            {
                cellTemplate: this.average,
                prop: 'avg_pressure',
                name: 'Average',
                flexGrow: 1,
                sortable: false
            },
            {
                cellTemplate: this.resultsPassedColumn,
                prop: 'passed',
                name: 'Passed',
                flexGrow: 0.6,
                sortable: false,
            },
            {
                cellTemplate: this.tendency,
                prop: 'percentage_tendency',
                name: 'Tendency',
                flexGrow: 1,
                sortable: false
            },
            {
                cellTemplate: this.created_at,
                prop: 'created_at',
                name: 'Created at',
                flexGrow: 1.6,
                sortable: false,
            },
            {
                cellTemplate: this.started_at,
                prop: 'started_at',
                name: 'Started at',
                flexGrow: 1.6,
                sortable: false
            },
            {
                cellTemplate: this.finished_at,
                prop: 'finished_at',
                name: 'Finished at',
                flexGrow: 1.6,
                sortable: false
            },
            {
                cellTemplate: this.actionsColumn, name: 'Actions', flexGrow: 1, sortable: false
            }
        ];

    }

    public tendencyValue(value: string | number): boolean {
        return typeof value === 'number' ? true : false;
    }

    public tendencyData(tendency: number): string{
        if (typeof tendency === 'number') {
            return tendency >= 0 ? 'success' : 'danger';
        }
        return 'danger';
    }

    public returnStatus(value: string): string{
        return PressureTestStatuses[value];
    }

    public fetchPressureTests(params?: OptionalHttpParams): void {
        _.unset(params, 'order_by');
        _.unset(params, 'order_strategy');
        this.thingService.getPressureTestsWithResponseData(this.thing.id, params).subscribe(
            data => {
                this.rows = data.pressure_tests;
                this.table.setPageParams(data);
                this.table.isLoading = false;
                this.cdRef.detectChanges();
            }
        );
    }

    public openModal(pressureData): void {
        this.modalRef = this.modalService.show(PressureTestGraphComponent, {
            initialState: {
                modalTitle: 'Pressure test',
                data: pressureData.measurements,
            }
        });
    }
}
