import {Component, Input, OnInit} from '@angular/core';
import {CircleOfTrust} from '../../_internal/circle-of-trust';
import {CircleOfTrustService} from '../../providers/services/circle-of-trust.service';
import {CircleOfTrustRequest} from '../../_internal/circle-of-trust.request';
import * as moment from 'moment';
import {HttpErrorResponse} from '@angular/common/http';
import {CircleOfTrustResponse} from '../../providers/resources/dto/circle-of-trust.response';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {AddContactModalComponent} from './add-contact/add-contact-modal.component';
import {ConfirmationDialogComponent} from '../../../common/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import {first, take} from 'rxjs/operators';

@Component({
    selector: 'app-circle-of-trust-list',
    templateUrl: './circle-of-trust-list.component.html',
    styleUrls: ['./circle-of-trust-list.component.scss']
})
export class CircleOfTrustListComponent implements OnInit {

    @Input() propertyId: string;
    public emergencyContacts: CircleOfTrust[];
    public requests: CircleOfTrustRequest[];
    public modalRef: BsModalRef;

    constructor(private modalService: BsModalService,
                private circleOfTrustService: CircleOfTrustService) {
    }

    ngOnInit() {
        this.fetchContacts();
    }

    public fetchContacts() {
        this.circleOfTrustService.getPropertyCircleOfTrust(this.propertyId).subscribe((response: CircleOfTrustResponse) => {
                this.emergencyContacts = response.emergency_contacts;
                this.requests = response.requests;
            },
            (err) => this.handleError(err)
        );
    }

    public dateAgo(date): string {
        return moment.utc(date, 'YYYY-MM-DD hh:mm:ss').fromNow();
    }

    public addContact(): void {
        this.modalRef = this.modalService.show(AddContactModalComponent, {
            initialState: {
                contacts: this.emergencyContacts,
                propertyId: this.propertyId
            }
        });
        this.modalService.onHide
            .pipe(take(1))
            .subscribe(() => this.fetchContacts());
    }

    public deleteContact(item: CircleOfTrust): void {
        this.modalService.show(ConfirmationDialogComponent, {
            initialState: {
                message: `Are you sure you want to delete <strong>${item.first_name} ${item.last_name}</strong> from the Circle of trust ?`,
                title: 'Confirm Circle of trust Delete',
                button: 'Delete'
            }
        });
        this.modalService.onHide
            .pipe(first())
            .subscribe((reason) => {
            if (reason === 'action-confirmed') {
                this.circleOfTrustService.deleteEmergencyContact(this.propertyId, item.id)
                    .subscribe(() => {
                        this.fetchContacts();
                    });
            }
        });
    }

    public openEditDialog(member: CircleOfTrust): void {
        this.modalRef = this.modalService.show(AddContactModalComponent, {
            initialState: {
                contact: member,
                contacts: this.emergencyContacts,
                propertyId: this.propertyId
            }
        });

        this.modalService.onHide
            .pipe(take(1))
            .subscribe(() => this.fetchContacts());
    }

    private handleError(err: HttpErrorResponse): void {
        console.error('An error accured: ', err);
    }
}
