import {FavouritesButtonComponent} from './favourites-button/favourites-button.component';
import {CollapseModule} from 'ngx-bootstrap/collapse';
import {NgSelectModule} from '@ng-select/ng-select';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {NgModule} from '@angular/core';
import {ThingListComponent} from './thing-list/thing-list.component';
import {IncidentListComponent} from './incident-list/incident-list.component';
import {ThingTelemetryComponent} from './thing-telemetry/thing-telemetry.component';
import {MembershipListComponent} from './membership-list/membership-list.component';
import {AddMemberComponent} from './membership-list/add-member/add-member.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PropertyListComponent} from './property-list/property-list.component';
import {CurrentUserBadgeComponent} from './current-user-badge/current-user-badge.component';
import {HeroPropertyScoreComponent} from './hero-property-score/hero-property-score.component';
import {RatingModule} from 'ngx-bootstrap/rating';
import {HeroScoreComponent} from './hero-score/hero-score.component';
import {HeroUserScoreComponent} from './hero-user-score/hero-user-score.component';
import {NgxPaginationModule} from 'ngx-pagination';
import {CircleOfTrustListComponent} from './circle-of-trust-list/circle-of-trust-list.component';
import {PressureTestListComponent} from './pressure-test-list/pressure-test-list.component';
import {DataTableModule} from '../../common/components/data-table/data-table.module';
import {AddContactModalComponent} from './circle-of-trust-list/add-contact/add-contact-modal.component';
import {SpacesListComponent} from './spaces-list/spaces-list.component';
import {AddSpaceComponent} from './spaces-list/add-space/add-space.component';
import {ApplianceListComponent} from './appliance-list/appliance-list.component';
import {ManageApplianceDialogComponent} from './appliance-list/manage-appliance-dialog/manage-appliance-dialog.component';
import {RouterModule} from '@angular/router';
import {EventDetailsComponent} from './event-details/event-details.component';
import {EventWidgetsComponent} from './event-widgets/event-widgets.component';
import {EventOverrideModalComponent} from './event-override-modal/event-override-modal.component';
import {ApplianceUsageLogListComponent} from './appliance-usage-log-list/appliance-usage-log-list.component';
import {LogsListComponent} from './logs-list/logs-list.component';
import {TableComponent} from './table/table.component';
import {SearchFormComponent} from './search-form/search-form.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {PipesModule} from '../pipes/pipes.module';
import {IncidentListItemComponent} from './incident-list-item/incident-list-item.component';
import {ConnectivityTimelineComponent} from './connectivity-timeline/connectivity-timeline.component';
import {BatteryTimelineComponent} from './battery-timeline/battery-timeline.component';
import {ProvisioningCountsComponent} from './provisioning-counts/provisioning-counts.component';
import {TriggerResetsListComponent} from './trigger-resets-list/trigger-resets-list.component';
import {TriggerResetButtonComponent} from './trigger-reset-button/trigger-reset-button.component';
import {ThingCachedTelemetryComponent} from './thing-cached-telemetry/thing-cached-telemetry.component';
import {ShadowLogsComponent} from './shadow-logs/shadow-logs.component';
import {MultiTimeComponent} from './multi-time/multi-time.component';
import {MomentModule} from 'ngx-moment';
import {NotificationSettingsComponent} from './notification-settings/notification-settings.component';
import {NotificationSettingsEditComponent} from './notification-settings-edit/notification-settings-edit.component';
import {ChangeOwnerComponent} from './membership-list/change-owner/change-owner.component';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {ModalModule} from 'ngx-bootstrap/modal';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatRippleModule} from '@angular/material/core';
import {MatDialogModule} from '@angular/material/dialog';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {NgChartsModule} from 'ng2-charts';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {AlertModule, BadgeModule, ButtonGroupModule, ButtonModule, CardModule, DropdownModule, GridModule} from '@coreui/angular';
import {ThingTelemetryStatsComponent} from './thing-telemetry-stats/thing-telemetry-stats.component';
import {NgxEchartsModule} from 'ngx-echarts';
import {CalibrationResultsTableComponent} from './calibration-results-table/calibration-results-table.component';
import {ProvisionReportsTableComponent} from './provision-reports-table/provision-reports-table.component';
import {DeviceModalComponent} from './device-modal/device-modal.component';
import {FakeEventsComponent} from './fake-events/fake-events.component';
import {PressureTestTableComponent} from './pressure-test-table/pressure-test-table.component';
import {SwapDataComponent} from './swap-data/swap-data.component';
import {ThingActionsComponent} from './thing-actions/thing-actions.component';
import {ValveStatusTimelineComponent} from './valve-status-timeline/valve-status-timeline.component';
import {ConnectivityStrengthComponent} from './connectivity-strength/connectivity-strength.component';
import {BatteryFeedComponent} from './battery-feed/battery-feed.component';
import {DevicesReportComponent} from './devices-report/devices-report.component';
import {ChronografButtonComponent} from './chronograf-button/chronograf-button.component';
import { BurningModeDataComponent } from './burning-mode-data/burning-mode-data.component';
import { ValveRaportDataComponent } from './valve-raport-data/valve-raport-data.component';
import { TileTelemetryComponent } from './tile-telemetry/tile-telemetry.component';

const components = [
    ThingListComponent,
    IncidentListComponent,
    ThingTelemetryComponent,
    MembershipListComponent,
    AddMemberComponent,
    PropertyListComponent,
    CurrentUserBadgeComponent,
    HeroPropertyScoreComponent,
    HeroScoreComponent,
    HeroUserScoreComponent,
    CircleOfTrustListComponent,
    PressureTestListComponent,
    AddContactModalComponent,
    SpacesListComponent,
    AddSpaceComponent,
    ApplianceListComponent,
    ManageApplianceDialogComponent,
    EventDetailsComponent,
    EventWidgetsComponent,
    EventOverrideModalComponent,
    ApplianceUsageLogListComponent,
    LogsListComponent,
    TableComponent,
    SearchFormComponent,
    IncidentListItemComponent,
    ConnectivityTimelineComponent,
    BatteryTimelineComponent,
    ProvisioningCountsComponent,
    TriggerResetsListComponent,
    TriggerResetButtonComponent,
    ThingCachedTelemetryComponent,
    ShadowLogsComponent,
    MultiTimeComponent,
    NotificationSettingsComponent,
    NotificationSettingsEditComponent,
    FavouritesButtonComponent,
    ChangeOwnerComponent,
    ThingTelemetryStatsComponent,
    DeviceModalComponent,
    FakeEventsComponent,
    SwapDataComponent,
    ThingActionsComponent,
    BurningModeDataComponent,
    ValveRaportDataComponent
];

@NgModule({
    declarations: [
        ...components,
        MultiTimeComponent,
        CalibrationResultsTableComponent,
        ProvisionReportsTableComponent,
        PressureTestTableComponent,
        SwapDataComponent,
        ThingActionsComponent,
        ValveStatusTimelineComponent,
        ConnectivityStrengthComponent,
        BatteryFeedComponent,
        DevicesReportComponent,
        ChronografButtonComponent,
        TileTelemetryComponent,
        BurningModeDataComponent,
        ValveRaportDataComponent,
    ],
    imports: [
        CommonModule,
        TabsModule.forRoot(),
        BsDropdownModule.forRoot(),
        FormsModule,
        ReactiveFormsModule,
        PopoverModule.forRoot(),
        CollapseModule.forRoot(),
        RouterModule,
        ModalModule.forRoot(),
        TooltipModule.forRoot(),
        NgxPaginationModule,
        DataTableModule,
        NgSelectModule,
        RatingModule.forRoot(),
        MatFormFieldModule,
        MatSelectModule,
        MatButtonModule,
        MatIconModule,
        MatInputModule,
        MatDatepickerModule,
        MatRippleModule,
        PipesModule,
        MomentModule,
        MatMenuModule,
        MatDialogModule,
        NgxDatatableModule,
        NgChartsModule,
        DropdownModule,
        ButtonModule,
        GridModule,
        CardModule,
        AlertModule,
        ButtonGroupModule,
        BadgeModule,
        NgxEchartsModule,
    ],
    exports: [
        ...components,
        CalibrationResultsTableComponent,
        ProvisionReportsTableComponent,
        PressureTestTableComponent,
        BatteryFeedComponent,
        TileTelemetryComponent
    ]
})
export class HeroComponentsModule {
}
