<div *ngIf="group">
	<div class="row">
		<div class="col-lg-4">
			<h2>Group: <b>{{group}}</b></h2>
		</div>
		<div class="col-lg-8">
			<button class="btn btn-secondary float-end"
					(click)="addDeviceToTheGroup()">Add device to the group
			</button>
			<button class="btn btn-warning float-end me-2"
					*ngIf="this.userService.isFirmwareMaster() || this.userService.isRoot()"
					(click)="openUpdateDialog()">
				<i class="fa fa-download"
				   [ngStyle]="{'cursor': 'pointer'}"
				   aria-hidden="true"
				   tooltip="Updates">
				</i> Update firmware
			</button>
			<app-trigger-reset-button [groupId]="group"
									  *ngIf="group"
									  class="float-end me-2"
									  [sm]="false"></app-trigger-reset-button>
		</div>
	</div>

	<table class="table table-striped"
		   *ngIf="groupDeviceList">
		<thead>
		<tr>
			<th>No.</th>
			<th>Id</th>
			<th>Device</th>
			<th>Type</th>
			<th>Name</th>
			<th>Serial no</th>
			<th>Action</th>
		</tr>
		</thead>
		<tbody>
		<tr *ngFor="let device of groupDeviceList; index as index">
			<td>{{index + 1}}</td>
			<td>{{device.id}}</td>
			<td>{{device.device}}</td>
			<td>{{device.type}}</td>
			<td>{{device.name}}</td>
			<td>{{device.serial_no}}</td>
			<td >
				<span class="btn-toolbar text-center well test">
				<button type="button"
						(click)="goToDevice(device)"
						class="btn btn-sm btn-secondary">
					<i class="fa fa-arrow-right"></i>
				</button>
				<button type="button"
						(click)="removeDeviceFromTheGroup(device)"
						class="btn btn-sm btn-danger">
					<i class="fa fa-trash"></i>
				</button>
				</span>
			</td>
		</tr>
		</tbody>

	</table>

	<div class="text-center">
		<button *ngIf="nextToken"
				(click)="fetchThingList()"
				class="btn btn-sm btn-primary"> Load more
		</button>
	</div>

	<tabset>
		<tab>
			<ng-template tabHeading>
				Firmwares
			</ng-template>
			<div class="row">
				<div class="col-12">
					<app-available-firmware *ngIf="showFirmwares"
											[group]="group"
											[listType]="'Requested Firmwares'">
					</app-available-firmware>
				</div>
			</div>
		</tab>

		<tab>
			<ng-template tabHeading>
				Logs
			</ng-template>
			<div class="row">
				<div class="col-12">
					<app-logs-list *ngIf="showFirmwares"
								   [resourceId]="group"></app-logs-list>
				</div>
			</div>
		</tab>
		<tab>
			<ng-template tabHeading>
				Remote resets
			</ng-template>
			<div class="row">
				<div class="col-12">
					<app-trigger-resets-list [groupId]="group"></app-trigger-resets-list>
				</div>
			</div>
		</tab>
	</tabset>

</div>
