import { Component, OnInit } from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-firmware-dialog',
  templateUrl: './firmware-dialog.component.html',
  styleUrls: ['./firmware-dialog.component.scss']
})
export class FirmwareDialogComponent implements OnInit {

    public message = 'Are you sure you want to confirm this action ?';

    constructor(private modalService: BsModalService,
                private bsModalRef: BsModalRef) {
    }

    ngOnInit() {
        this.bsModalRef.setClass('confirmation-dialog');
    }

    public close(): void {
        this.bsModalRef.hide();
    }

    public confirm(dissmisReason: string): void {
        if(confirm('Are you sure to trigger the update')) {
            this.modalService.setDismissReason(dissmisReason);
            this.bsModalRef.hide();
        }
    }
}
