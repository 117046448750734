import {
    AfterViewInit,
    Component,
    Input,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { SwapService } from '../../providers/services/swap.service';
import { OptionalHttpParams } from '../../../common/api/CrudAware';
import { SwapResponse, SwapData } from '../../_internal/swap-data';
import { TableComponent } from '../table/table.component';
import { ChangeDetectorRef } from '@angular/core';
import { tableSettings } from 'app/hero-labs/_internal/table-settings';
import * as _ from 'lodash';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-swap-data',
    templateUrl: './swap-data.component.html',
    styleUrls: ['./swap-data.component.scss'],
})
export class SwapDataComponent implements AfterViewInit {
    @Input() hubId: string;
    @Input() thingId: string;
    @ViewChild(TableComponent) table: TableComponent;
    @ViewChild('dateColumn') dateColumn: TemplateRef<any>;
    @ViewChild('messageColumn') messageColumn: TemplateRef<any>;

    public swapData: Observable<SwapResponse>;
    public tableSettings = _.cloneDeep(tableSettings);
    public rows: SwapData[];
    public columns = [];

    constructor(
        private swapService: SwapService,
        private cdRef: ChangeDetectorRef
    ) {}

    ngAfterViewInit(): void {
        this.columns = [
            {
                prop: 'id',
                name: 'ID',
                flexGrow: 1.2,
                sortable: false,
            },
            {
                prop: 'new_serial_no',
                name: 'New serial number',
                flexGrow: 0.6,
                sortable: false,
            },
            {
                prop: 'old_serial_no',
                name: 'Old serial number',
                flexGrow: 0.6,
                sortable: false,
            },
            {
                cellTemplate: this.dateColumn,
                prop: 'created_at',
                name: 'Date',
                flexGrow: 0.7,
                sortable: false,
            },
        ];
    }

    public fetchPage(params?: OptionalHttpParams): void {
        if (this.hubId) {
            this.swapData = this.swapService.hubSwaps(this.hubId);
        } else if (this.thingId) {
            this.swapData = this.swapService.thingSwaps(this.thingId);
        }
        this.swapData.subscribe((response: SwapResponse) => {
            this.table.setPageParams({
                page_number: 1,
                page_size: response.swaps.length,
                total_entries: response.swaps.length,
                total_pages: 1,
            });
            this.rows = response.swaps;
            this.table.isLoading = false;
            this.cdRef.detectChanges();
        });
    }
}
