import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {TableDisplayedColumn} from '../../../common/components/data-table/table-displayed-column';
import {forkJoin, Observable} from 'rxjs';
import * as moment from 'moment';
import * as _ from 'lodash';
import {LegionellaService} from '../../providers/services/legionella.service';
import {finalize, map} from 'rxjs/operators';

@Component({
    selector: 'app-legionella-checks-list',
    templateUrl: './legionella-checks-list.component.html',
    styleUrls: ['./legionella-checks-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LegionellaChecksListComponent implements OnInit {
    @Input() thing: string;
    @Input() title = 'Legionella checks';
    public datepickerInitialValue = new Date();
    public startDate: number;
    public endDate: number;
    public isLoading = true;
    public legionellaData: Observable<any>;

    public displayedColumns: TableDisplayedColumn[] = [
        {
            key: 'requested_at', name: 'Requested At', formatter: (dateTime) => `UTC: ${moment.utc(dateTime).format('MMM DD HH:mm:ss')}`
        },
        {
            key: 'responded_at', name: 'Responded At', formatter: (dateTime) => `${moment.utc(dateTime).format('MMM DD HH:mm:ss')}`
        },
        {
            key: 'result.has_risk', name: 'Has risk', formatter: (boolean) => boolean
        },
        {
            key: 'result.lambda',
            name: 'Lambda',
            formatter: (string) => string
        },
    ];

    constructor(
                private legionellaService: LegionellaService, ) {
    }

    ngOnInit() {
        this.startDate = moment().startOf('day').utc().valueOf();
        this.endDate = moment().endOf('day').utc().valueOf();
    }

    public onDatePick(dateString: string): void {
        const propertyDate = moment(dateString).utcOffset(0, true);
        this.startDate = propertyDate.clone().startOf('day').valueOf();
        this.endDate = propertyDate.clone().endOf('day').valueOf();
        this.updateLegionellaData();
    }

    public dateRangeCreated($event: object): void {
        this.startDate = moment($event[0]).utcOffset(0, true).startOf('day').valueOf();
        this.endDate = moment($event[1]).utcOffset(0, true).endOf('day').valueOf();
        this.updateLegionellaData();
    }

    public updateLegionellaData(): void {
        this.legionellaData = forkJoin(
            [(this.legionellaService.legionellaStagnationChecks(this.thing)),
            (this.legionellaService.legionellaTemperatureChecks(this.thing))])
            .pipe(finalize(() => this.isLoading = false),
            map((response) => response[0].entries.concat(response[1].entries)),
            map(row => row.filter(value => (moment(value.requested_at.valueOf()).isBetween(this.startDate, this.endDate)))),
            map(res => _.sortBy(res, ['requested_at']).reverse()),
            map(value => _.forEach(value, ((data) => {
            if(data.result['lambda'] === undefined){
                    data.result['lambda'] = 'legionella-stagnation';
                }
}))
            )
        );
    }
  }
