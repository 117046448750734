import {Property} from './../../../_internal/property';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    TemplateRef,
    ViewChild
} from '@angular/core';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {PropertyResource} from '../../../providers/resources/property.resource';
import {PropertiesResponse} from '../../../providers/resources/dto/property.response';
import * as _ from 'lodash';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {PropertyEditPageComponent} from '../property-edit/property-edit-page.component';
import {take} from 'rxjs/operators';
import {tableSettings} from 'app/hero-labs/_internal/table-settings';
import {TableComponent} from '../../../components/table/table.component';
import {propertyFilterFields} from './filter-fields';
import {OptionalHttpParams} from '../../../../common/api/CrudAware';
import { Router } from '@angular/router';

@Component({
    selector: 'app-property-list-page',
    templateUrl: './property-list-page.component.html',
    styleUrls: ['./property-list-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PropertyListPageComponent implements AfterViewInit {
    @BlockUI() blockUI: NgBlockUI;
    @ViewChild(TableComponent) table: TableComponent;
    @ViewChild('addressColumn') addressColumn: TemplateRef<any>;
    @ViewChild('actionsColumn') actionsColumn: TemplateRef<any>;
    @ViewChild('systemColumn') systemColumn: TemplateRef<any>;
    public modalRef: BsModalRef;
    public filterFields = propertyFilterFields;
    public tableSettings = _.cloneDeep(tableSettings);
    public rows: Property[];
    public columns = [];

    constructor(private propertyResource: PropertyResource,
                private modalService: BsModalService,
                private cdRef: ChangeDetectorRef,
                private router: Router) {
    }


    public ngAfterViewInit(): void {
        this.columns = [
            {prop: 'id', name: 'ID', flexGrow: 2, sortable: false, cellTemplate: this.table.codeColumn},
            {prop: 'name', name: 'Name', flexGrow: 1, sortable: false},
            {cellTemplate: this.addressColumn, prop: 'address', name: 'Address', flexGrow: 2, sortable: false},
            {prop: 'city', name: 'City', flexGrow: 0.9, sortable: false},
            {prop: 'country', name: 'Country', flexGrow: 0.9, sortable: false},
            {name: 'Has hubs?', flexGrow: 0.5, sortable: false, cellTemplate: this.systemColumn},
            {
                cellTemplate: this.actionsColumn, name: 'Actions', flexGrow: 1.5, sortable: false
            }
        ];

    }


    public fetchPage(params?: OptionalHttpParams): void {
        params = _.omit(params, ['order_by', 'order_strategy']);
        this.propertyResource.getPropertiesList(params).subscribe(
                (response: PropertiesResponse) => {
                    this.table.setPageParams(response);
                    this.rows = response.properties;
                    this.table.isLoading = false;
                    this.cdRef.detectChanges();
                }
        );
    }


    public openCreateModal(): void {
        this.modalRef = this.modalService.show(PropertyEditPageComponent, {
            initialState: {
                property: null,
                action: 'create'
            }
        });
        this.modalService.onHide
                .pipe(take(1))
                .subscribe(() => this.table.refresh());
    }

    public openPropertyInvite(): void {
        this.router.navigate([`properties/invite`]);
    }
    public printSystemStatus(row: Property): string {
        const hubsNumber = _.size(_.flatMapDeep(row.spaces, (s) => s.hubs));
        return hubsNumber > 0 ? `System has ${hubsNumber} hub${hubsNumber > 1 ? 's' : ''}` : '';
    }
}
