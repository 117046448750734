import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {JobListComponent} from './job-list/job-list.component';
import {JobPageComponent} from './job-page/job-page.component';

const routes: Routes = [
    {
        path: '',
        children: [
            {
                path: '',
                component: JobListComponent,
                data: {
                    title: 'Jobs list',
                },
            },
            {
                path: ':id',
                component: JobPageComponent,
                data: {
                    title: 'Job',
                },
            },
        ]
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [RouterModule]
})
export class JobPagesRoutingModule { }
