import {AfterViewInit, ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import * as _ from 'lodash';
import {tableSettings} from '../../../_internal/table-settings';
import {OptionalHttpParams} from '../../../../common/api/CrudAware';
import {Device, DevicesResponse, DevicesStatistics} from '../../../_internal/device';
import {TableComponent} from '../../../components/table/table.component';
import {HeroConfig} from '../../../hero-config';
import {deviceFilter, paramsFilterFields} from '../manufacturing-config';
import {Observable} from 'rxjs';
import * as moment from 'moment';
import {ManufacturingService} from '../../../providers/services/manufacturing.service';
import {FormControl, FormGroup} from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DeviceImportComponent } from '../../../../common/components/dialogs/import-device/device-import.component';
import {ManufacturingStatesChangeComponent} from '../../../../common/components/dialogs/manufacturing-states-change/manufacturing-states-change.component';
import {ManufacturingBlockedStatusChangeComponent} from '../../../../common/components/dialogs/manufacturing-blocked-status-change/manufacturing-blocked-status-change.component';

@Component({
    selector: 'app-devices',
    templateUrl: './devices.component.html',
    styleUrls: ['./devices.component.scss'],
})
export class DevicesComponent implements AfterViewInit, OnInit {

    @ViewChild(TableComponent) table: TableComponent;
    @ViewChild('actionsColumn') actionsColumn: TemplateRef<any>;

    public filterFields = paramsFilterFields;
    public deviceFilter = deviceFilter;
    public tableSettings = _.cloneDeep(tableSettings);
    public rows: Device[];
    public columns = [];
    public statisticsData: Observable<DevicesStatistics>;
    public startDate: string;
    public endDate: string;
    public dateFormGroup: FormGroup;

    constructor(private cdRef: ChangeDetectorRef,
                private manufacturingService: ManufacturingService,
                private modalService: BsModalService,) {
    }

    ngOnInit(): void {
        this.dateFormGroup = new FormGroup({
            dateRange: new FormControl()
        });
    }

    public ngAfterViewInit(): void {

        this.columns = [
            {prop: 'serial_no', name: 'Serial no', flexGrow: 1, sortable: false, cellTemplate: this.table.codeColumn},
            {prop: 'device_type', name: 'Type', flexGrow: 1, sortable: false},
            {prop: 'group_type', name: 'Group', flexGrow: 1, sortable: false},
            {prop: 'is_golden_sample', cellTemplate: this.table.yesNoColumn, name: 'Is golden?', flexGrow: 1, sortable: false},
            {cellTemplate: this.table.dateColumn, prop: 'updated_at', name: 'Updated at', flexGrow: 1, sortable: false},

            {
                cellTemplate: this.actionsColumn, name: 'Actions', flexGrow: 1, sortable: false
            }
        ];

    }

    public fetchDevice(params: OptionalHttpParams) {
        const serialNo = _.get(params, 'serial_no');
        if (!serialNo) {
            this.table.refresh();
            return;
        }
        this.manufacturingService.getDevice(serialNo.toString())
            .subscribe((device) => {
                this.table.setPageParams({
                    page_number: 1,
                    page_size: HeroConfig.itemsPerPage,
                    total_entries: 1,
                    total_pages: 1
                });
                this.rows = [device];
                this.table.isLoading = false;
                this.cdRef.detectChanges();
            }, () => {
                this.table.setPageParams({
                    page_number: 1,
                    page_size: HeroConfig.itemsPerPage,
                    total_entries: 0,
                    total_pages: 1
                });
                this.rows = [];
                this.table.isLoading = false;
                this.cdRef.detectChanges();
            });
    }

    public fetchStatistics(startingDate: string, endingDate: string) {
        const parems = {};
        parems['from'] = startingDate;
        parems['to'] = endingDate;
        this.statisticsData = this.manufacturingService.getStats(parems);
    }

    public fetchPage(params?: OptionalHttpParams): void {
        _.unset(params, 'order_by');
        _.unset(params, 'order_strategy');
        this.manufacturingService.getDevices(params)
            .subscribe((response: DevicesResponse) => {
                this.table.setPageParams(response);
                this.rows = _.map(response.data, (a) => {
                    if(a.device_type === 'siryn'){
                        a.device_type = 'tail';
                    }
                    return a;
                  });
                this.table.isLoading = false;
                this.cdRef.detectChanges();
                if (this.rows.length > 0) {
                    this.dataRangeInitialValue(this.rows[0].created_at, this.rows[this.rows.length - 1].created_at,);
                }
            });
    }

    public postDeviceData(): void{
        this.modalService.show(DeviceImportComponent);
    }

    public changeDevicesStatus(): void{
        this.modalService.show(ManufacturingStatesChangeComponent);
    }

    public changeDevicesBlockedStatus(): void{
        this.modalService.show(ManufacturingBlockedStatusChangeComponent);
    }

    public dataRangeInitialValue(startDate: string, endDate: string) {
        const lastDate = (moment(startDate).toDate());
        const firstDate = (moment(endDate).toDate());
        this.dateFormGroup.setValue({
            dateRange: [
                firstDate,
                lastDate
            ]
        });
    }

    public dateRangeCreated($event: object): void {
        this.startDate = moment($event[0]).format('YYYY-MM-DDTHH:mm:ss') + 'Z';
        this.endDate = moment($event[1]).format('YYYY-MM-DDTHH:mm:ss') + 'Z';
        this.fetchStatistics(this.startDate, this.endDate);
    }

    public setTimeFrame(days: number): void {
        const toDate = moment();
        const fromDate = toDate.clone().subtract(days, 'days');
        this.dateFormGroup.setValue({
            dateRange: [
                fromDate.toDate(),
                toDate.toDate()
            ]
        });
    }
}
