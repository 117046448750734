import {TabsModule} from 'ngx-bootstrap/tabs';
import {ThingPageComponent} from './thing-page/thing-page.component';
import {ThingPagesRoutingModule} from './thing-pages-routing.module';
import {ThingListPageComponent} from './thing-list/thing-list-page.component';
import {HeroComponentsModule} from '../../components/hero-components.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ModalModule} from 'ngx-bootstrap/modal';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ThingEditPageComponent} from './thing-edit/thing-edit-page.component';
import {NgxPaginationModule} from 'ngx-pagination';
import {FirmwareTriggerModule} from '../../components/firmware-trigger/firmware-trigger.module';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {ThingEventListModule} from '../../components/thing-event-list/thing-event-list.module';
import {FiltersModule} from '../../../common/components/filters/filters.module';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {MomentModule} from 'ngx-moment';
import {ValueStatusButtonComponent} from './value-status-button/value-status-button.component';
import {BadgeModule, ButtonGroupModule, GridModule} from '@coreui/angular';
import { LegionellaChecksListModule } from '../../components/legionella-checks-list/legionella-checks-list.module';
import { SonicWifiPageComponent } from './sonic-wifi-page/sonic-wifi-page.component';
import { MatButtonModule } from '@angular/material/button';
import { HourlyDataModule } from '../../components/hourly-data/hourly-data.module';
import { TilePageComponent } from './tile-page/tile-page.component';

@NgModule({
    declarations: [
        ThingListPageComponent,
        ThingPageComponent,
        ThingEditPageComponent,
        ValueStatusButtonComponent,
        SonicWifiPageComponent,
        TilePageComponent,
    ],
    imports: [
        CommonModule,
        HeroComponentsModule,
        FormsModule,
        ModalModule.forRoot(),
        TabsModule.forRoot(),
        ThingPagesRoutingModule,
        NgxPaginationModule,
        FirmwareTriggerModule,
        TooltipModule,
        ThingEventListModule,
        FiltersModule,
        ReactiveFormsModule,
        NgxDatatableModule,
        MomentModule,
        LegionellaChecksListModule,
        HourlyDataModule,
        GridModule,
        BadgeModule,
        ButtonGroupModule,
        MatButtonModule,

    ],
    exports: [
        ThingListPageComponent,
        ThingPageComponent,
        NgxPaginationModule,
        SonicWifiPageComponent,
        TilePageComponent
    ]
})
export class ThingPagesModule {
}
