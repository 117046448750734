import {Injectable} from '@angular/core';
import {CrudAware, OptionalHttpParams} from '../../../common/api/CrudAware';
import {Observable} from 'rxjs';
import {HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Hub} from '../../_internal/hub';
import {HubResource} from '../resources/hub.resource';
import { MonitoringResponse } from '../resources/dto/monitoring-response';
import { MonitoringResource } from '../resources/monitoring-resource';

@Injectable()
export class MonitoringService {
    constructor(private monitoringResource: MonitoringResource) {
    }

    public getMonitoringData(serialNumbers: string, event_start: string, event_end: string): Observable<MonitoringResponse> {
        return this.monitoringResource.getMonitoringData(serialNumbers, event_start, event_end);
    }

    public getDevicesData(): Observable<string> {
        return this.monitoringResource.getDevicesData();
    }

    public geWaterReportData(propertyIDs: string, from: string, to: string): Observable<any> {
        return this.monitoringResource.geWaterReportData(propertyIDs, from, to);
    }

    public geWaterReportCSVData(propertyIDs: string, from: string, to: string): Observable<string> {
        return this.monitoringResource.geWaterReportCSVData(propertyIDs, from, to);
    }
}
