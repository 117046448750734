<button (click)="toggleMode()" class="btn btn-secondary card-content">{{modeButtonName}}</button>

<div *ngIf="graphMode; else rawData">
    <app-valve-status-timeline class="card-content" *ngIf="valveStatus" [events]="rows" ></app-valve-status-timeline>
    <app-connectivity-strength *ngIf="!sonicWifi" [events]="rows" [parameterName]="parameterName"></app-connectivity-strength>

    <app-table [rows]="rows"
           [scrollToTop]="false"
		   [columns]="[]"
		   rowHeight="auto"
		   [selectionEnabled]="false"
		   [tableSettings]="tableSettings"
		   (fetchPage)="fetchPage($event)">
    </app-table>
</div>

<ng-template #rawData>
    <app-table [rows]="rows"
            [columns]="columns"
            rowHeight="auto"
            [selectionEnabled]="false"
            [tableSettings]="tableSettings"
            (fetchPage)="fetchPage($event)">
    </app-table>
        <ng-template #dateColumn
                ngx-datatable-cell-template
                let-row="row"
                let-value="value">
        {{value | date: 'yyyy-MM-dd HH:mm:ss': 'UTC'}}
    </ng-template>

    <ng-template #messageColumn
                ngx-datatable-cell-template
                let-row="row"
                let-value="value">
        <pre>{{ value | json }}</pre>
    </ng-template>
</ng-template>
