import {HeroComponentsModule} from './../../components/hero-components.module';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EventPagesRoutingModule} from './event-pages-routing.module';
import {EventListComponent} from './event-list/event-list.component';
import {FormsModule} from '@angular/forms';
import {DataTableModule} from '../../../common/components/data-table/data-table.module';
import {EventPageComponent} from './event-page/event-page.component';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {ButtonGroupModule, ButtonModule} from '@coreui/angular';

@NgModule({
    declarations: [
        EventListComponent,
        EventPageComponent,
    ],
    imports: [
        CommonModule,
        DataTableModule,
        FormsModule,
        BsDatepickerModule.forRoot(),
        HeroComponentsModule,
        TooltipModule.forRoot(),
        EventPagesRoutingModule,
        ButtonModule,
        ButtonGroupModule,
    ]
})
export class EventPagesModule {
}
