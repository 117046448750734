import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { JobResource } from '../resources/job.resource';
import { JobsResponse, JobsParameters, JobExecutionsResponse, JobExecutionParameters, JobQueryParameters } from '../resources/dto/job.response';
import * as _ from 'lodash';
import {Job, JobDescription, JobExecution, JobExecutionDetails} from '../../_internal/job';

@Injectable()
export class JobService {

    constructor(private jobResource: JobResource) { }

    public getJobs(params: JobsParameters): Observable<JobsResponse> {
        return this.jobResource.getJobs(params)
            .pipe(map((res: JobsResponse) => {
                _.map(res.jobs, (job) => this.convertJobTImestamps(job));
                return res;
            }));
    }

    public getJobDescribe(id: string): Observable<JobDescription> {
        return this.jobResource.getJobDescription(id)
            .pipe(map((res: JobDescription) => {
                res = this.convertJobTImestamps(res);
                return res;
            }));
    }

    public getJobsExecutions(params: JobExecutionParameters): Observable<JobExecutionsResponse> {
        return this.jobResource.getJobExecutions(params);
    }

    public getJobsExecution(id: string, params: JobQueryParameters): Observable<JobExecutionDetails> {
        return this.jobResource.getJobExecution(id, params);
    }

    private convertJobTImestamps(job) {
        job.created_at = job.created_at * 1000;
        job.last_updated_at = job.last_updated_at * 1000;
        job.completed_at = job.completed_at * 1000;
        return job;
    }

}
