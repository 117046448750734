import {Component, Input, OnInit} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../../../src/environments/environment';
import { SubscriptionData } from '../../../providers/resources/dto/subscription.response';
import { SubscriptionService } from '../../..//providers/services/subscription.service';

@Component({
  selector: 'app-subscription-edit',
  templateUrl: './subscription-edit.component.html',
  styleUrls: ['./subscription-edit.component.scss']
})
export class SubscriptionEditComponent implements OnInit {

    @Input() subscriptionData: SubscriptionData;
    @Input() userId: string;
    public subscriptionForm: FormGroup;
    public subsctiptionTiers = ['Basic', 'Explorer'];
    public status = [true, false];
    public billingInterval = ['month', 'year'];
    public minDate = moment.utc().startOf('day');
    public toControl: FormControl;


    private modalMode: string;
    constructor(private bsModalRef: BsModalRef,
                private modalService: BsModalService,
                private subscriptionService: SubscriptionService,
                private toastrService: ToastrService) { }

    ngOnInit(): void {
        this.formSetup();
    }

    public onConfirm(): void {
        if(!this.subscriptionForm.valid){
            this.toastrService.error( 'Form is invalid. Please fill all inputs.' , 'Form.', environment.toastSettings);
            return;
        }

        switch(this.modalMode){
            case 'create':
                this.onConfirmCreate();
                break;
            case 'update':
                if(this.subscriptionData.stripe_subscription_id === '-1'){
                    this.onConfirmUpdate();
                    break;
                }
                this.toastrService.error( 'Cant edit real subscriptions', 'Subscription.', environment.toastSettings);
                break;
        }
    }

    public onCancel(): void {
        this.bsModalRef.hide();
    }

    public dataChange(): void {
        this.subscriptionForm.value.end_date = moment(this.subscriptionForm.value.end_date).utc().endOf('d').format('YYYY-MM-DDTHH:mm:ss') + 'Z';
    }

    private formSetup(): void {
        this.toControl = new FormControl('');
        this.subscriptionForm = new FormGroup({
            billing_interval: new FormControl(this.subscriptionData?.billing_interval || 'month', [Validators.required]),
            is_active: new FormControl(this.subscriptionData?.is_active ? true : false, [Validators.required]),
            key: new FormControl(this.subscriptionData?.key || '', [Validators.required]),
            name: new FormControl(this.subscriptionData?.name || '', [Validators.required]),
            start_date: new FormControl(this.subscriptionData?.start_date || moment().utc().format('YYYY-MM-DDTHH:mm:ss') + 'Z', [Validators.required]),
            end_date: new FormControl(this.subscriptionData?.end_date || 'null'),
            user_id: new FormControl(this.userId),
        });

        if(this.subscriptionData === undefined){
            this.modalMode = 'create';
            return;
        }
        this.subscriptionForm.addControl('stripe_subscription_id', new FormControl(this.subscriptionData.stripe_subscription_id));
        this.modalMode = 'update';
    }

    private onConfirmUpdate(): void {
        this.subscriptionService.updateSubscription(this.subscriptionData.id, this.subscriptionForm.value).subscribe(
            response => {
                this.toastrService.success('Settings have been saved successfully.', 'Settings.', environment.toastSettings);
                this.modalService.setDismissReason('success');
                this.bsModalRef.hide();
            },
            error => {
                this.toastrService.error( 'An error has ocured while saving data. Error code: ' + error.status + 'Error message' + error.message, 'Settings.', environment.toastSettings);
            }
        );
    }

    private onConfirmCreate(): void {
        this.subscriptionService.createSubscription(this.subscriptionForm.value).subscribe(
            response => {
                this.toastrService.success('Subscription created.', 'Subscription.', environment.toastSettings);
                this.modalService.setDismissReason('success');
                this.bsModalRef.hide();
            },
            error => {
                this.toastrService.error( 'An error has ocured while saving data. Error code: ' + error.status + 'Error message' + error.message, 'Settings.', environment.toastSettings);
            }
        );
    }
}
