import {HeroLabsModule} from './hero-labs/hero-labs.module';
import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {AppComponent} from './app.component';
import {DefaultLayoutComponent} from './containers';
import {P404Component} from './views/error/404.component';
import {P500Component} from './views/error/500.component';
import {LoginComponent} from './views/login/login.component';
import {RegisterComponent} from './views/register/register.component';
import {BlockUIModule} from 'ng-block-ui';
import {AppRoutingModule} from './app.routing';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HeroComponentsModule} from './hero-labs/components/hero-components.module';
import {ResetPasswordComponent} from './views/reset-password/reset-password.component';
import {NoopAnimationsModule} from '@angular/platform-browser/animations';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {MAT_DATE_LOCALE} from '@angular/material/core';
// import {MomentTimezonePickerModule} from 'moment-timezone-picker';
import {DirectivesModule} from './directives/directives.module';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {NgChartsModule} from 'ng2-charts';
import {CommonModule} from '@angular/common';
import {DefaultHeaderComponent} from './containers/default-header/default-header.component';
import {DefaultFooterComponent} from './containers/default-footer/default-footer.component';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {
    AvatarModule,
    BadgeModule,
    BreadcrumbModule,
    ButtonGroupModule,
    ButtonModule,
    CardModule,
    DropdownModule,
    FooterModule,
    FormModule,
    GridModule,
    HeaderModule,
    ListGroupModule,
    NavModule,
    ProgressModule,
    SharedModule,
    SidebarModule,
    UtilitiesModule
} from '@coreui/angular';
import {IconModule, IconSetService} from '@coreui/icons-angular';
import {NgxEchartsModule} from 'ngx-echarts';

@NgModule({
    imports: [
        AppRoutingModule,
        BrowserModule,
        PerfectScrollbarModule,
        BsDropdownModule.forRoot(),
        TooltipModule.forRoot(),
        BlockUIModule.forRoot(),
        TabsModule.forRoot(),
        NgChartsModule,
        ReactiveFormsModule,
        FormsModule,
        HeroLabsModule,
        HeroComponentsModule,
        NoopAnimationsModule,
        NgxDatatableModule,
        MatMomentDateModule,
        MatDatepickerModule,
        NavModule,
        DropdownModule,
        NgxEchartsModule.forRoot({
            echarts: () => import('echarts')
        }),
        // MomentTimezonePickerModule,
        DirectivesModule,
        CommonModule,
// CORE UI
        GridModule,
        AvatarModule,
        BreadcrumbModule,
        FooterModule,
        DropdownModule,
        IconModule,
        HeaderModule,
        SidebarModule,

        ButtonModule,
        FormModule,
        UtilitiesModule,
        ButtonGroupModule,
        SidebarModule,
        SharedModule,
        TabsModule,
        ListGroupModule,
        ProgressModule,
        BadgeModule,
        ListGroupModule,
        CardModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot(),
    ],
    providers: [
        IconSetService,
        MatDatepickerModule,
        MatMomentDateModule,
        {provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
    ],
    declarations: [
        AppComponent,
        DefaultHeaderComponent,
        DefaultFooterComponent,
        DefaultLayoutComponent,
        P404Component,
        P500Component,
        LoginComponent,
        RegisterComponent,
        ResetPasswordComponent
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
