import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import * as _ from 'lodash';
import {ToastrService} from 'ngx-toastr';
import { environment } from '../../../../../../../src/environments/environment';
import { Subscription, timer } from 'rxjs';
import {MembershipService} from '../../../../providers/services/membership.service';
import { MultiInvitePayload } from '../../../../providers/resources/dto/memberships.response';

@Component({
  selector: 'app-stepper-menu',
  templateUrl: './stepper-menu.component.html',
  styleUrls: ['./stepper-menu.component.scss']
})
export class StepperMenuComponent implements OnInit, OnDestroy {

    @ViewChild('userList', {static: false}) userList;
    @ViewChild('propertiesList', {static: false}) propertiesList;
    @ViewChild('stepper', {static: false}) stepper;
    public sendButtonBlocked = false;
    public showTable = true;
    public activeTab = 'users';
    private timerObservable: Subscription;
    constructor(
        private toastrService: ToastrService,
        private membershipService: MembershipService,
    ) {
    }
    ngOnDestroy(): void {
        if( this.timerObservable){
            this.timerObservable.unsubscribe();
        }
    }
    ngOnInit(): void {
        this.membershipService.massAddUsersList = [];
        this.membershipService.massAddPropertiesList = [];
    }

      public resetList(listType: string): void {
        switch (listType) {
            case 'users':
                this.membershipService.massAddUsersList = [];
                break;
            case 'properties':
                this.membershipService.massAddPropertiesList = [];
                break;
        }
    }

      public selectAll(listType: string): void {
        switch (listType) {
            case 'users':
                this.userList.selectPage();
                break;
            case 'properties':
                this.propertiesList.selectPage();
                break;
        }
    }

    public numberOfSelectedUsers(): number {
        return this.membershipService.massAddUsersList.length;
    }

    public numberOfSelectedProperties(): number {
        return this.membershipService.massAddPropertiesList.length;
    }

    public tableToggle(state: boolean): void {
        this.showTable = state;
    }

    public changeTable(tableName: string): void {
        this.activeTab = tableName;
    }
    public addUsers(payload: MultiInvitePayload[]): void{
        this.membershipService.multiInvite(payload).subscribe(
            (response) => {
                this.resetList('users');
                this.resetList('properties');
                this.showTable = true;
                this.activeTab = 'users';
                this.stepper.reset();
                this.toastrService.success('The users have been added.', 'Success.');
            },
            (error) => {
                this.buttonTimedBlockade();
                this.toastrService.error(
                    'An error has ocured, Error message' +
                    error.message,
                    'Error.',
                    environment.toastSettings
                );
            }
        );
    }

    public preparePayload(): void {
        const payload = [];
        for (const userId of this.membershipService.massAddUsersList) {
            for (const propertyId of this.membershipService.massAddPropertiesList) {
                payload.push({
                    membership: {
                        user_id: userId,
                        membership_role: 'user',
                    },
                    property_id: propertyId});
            }
        }
        this.addUsers(payload);
    }

    private buttonTimedBlockade(): void {
        this.sendButtonBlocked = true;
        this.timerObservable = timer(4000)
            .subscribe(() => this.sendButtonBlocked = false
        );
    }

}
