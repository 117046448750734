import {UserPagesRoutingModule} from './user-pages-routing.module';
import {UserListPageComponent} from './user-list/user-list-page.component';
import {HeroComponentsModule} from '../../components/hero-components.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ModalModule} from 'ngx-bootstrap/modal';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserPageComponent} from './user-page/user-page.component';
import {UserEditComponent} from './user-edit/user-edit.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {NgxPaginationModule} from 'ngx-pagination';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldControl, MatFormFieldModule} from '@angular/material/form-field';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {DirectivesModule} from '../../../directives/directives.module';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {BadgeModule, ButtonGroupModule} from '@coreui/angular';
import { SubscriptionEditComponent } from './subscription-edit/subscription-edit.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';

@NgModule({
    declarations: [
        UserListPageComponent,
        UserPageComponent,
        UserEditComponent,
        SubscriptionEditComponent
    ],
    imports: [
        CommonModule,
        HeroComponentsModule,
        FormsModule,
        NgxPaginationModule,
        ModalModule.forRoot(),
        ReactiveFormsModule,
        NgSelectModule,
        UserPagesRoutingModule,
        MatSelectModule,
        MatFormFieldModule,
        NgxDatatableModule,
        DirectivesModule,
        MatIconModule,
        MatButtonModule,
        BadgeModule,
        MatDatepickerModule,
        ButtonGroupModule,
        MatNativeDateModule,
        MatInputModule
    ],
    exports: [
        UserListPageComponent,
        UserPageComponent,
        NgxPaginationModule,
    ]
})
export class UserPagesModule {
}
