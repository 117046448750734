import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
    selector: 'app-calibration-results',
    templateUrl: './calibration-results.component.html',
    styleUrls: ['./calibration-results.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CalibrationResultsComponent {

    constructor() {
    }

}
