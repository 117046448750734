import { Observable } from 'rxjs';
import { environment } from './../../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JobsResponse, JobExecutionsResponse } from './dto/job.response';
import {JobDescription, JobExecution, JobExecutionDetails} from '../../_internal/job';

@Injectable({
    providedIn: 'root'
})
export class JobResource {
    private readonly url: string = `${environment.apiGatewayUrl}/iot-core/admin/jobs`;
    private readonly jobExecutionsUrl: string = `${environment.apiGatewayUrl}/iot-core/admin/job_executions`;

    constructor(private http: HttpClient) { }

    public getJobs(params = {}): Observable<JobsResponse> {
        return this.http.get<JobsResponse>(this.url, { params });
    }

    public getJobDescription(id: string): Observable<JobDescription> {
        return this.http.get<JobDescription>(`${this.url}/${id}`);
    }

    public getJobExecutions(params = {}): Observable<JobExecutionsResponse> {
        return this.http.get<JobExecutionsResponse>(this.jobExecutionsUrl, { params });
    }

    public getJobExecution(id: string, params = {}): Observable<JobExecutionDetails> {
        return this.http.get<JobExecutionDetails>(`${this.jobExecutionsUrl}/${id}`, { params });
    }
}
