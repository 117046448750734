<div class="card mb-4">
    <div class="card-header">
        <i class="fa fa-align-justify"></i> Valve advanced stats
    </div>
    <div class="card-body">
        <app-table [rows]="rows"
                   [columns]="columns"
                   [selectionEnabled]="false"
                   [tableSettings]="tableSettings"
                   (fetchPage)="fetchPage($event)">
        </app-table>
    </div>
</div>
