import {Favourite} from '../../../providers/resources/dto/favourit-data';
import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
    selector: 'app-favourite-page',
    templateUrl: './favourite-page.component.html',
    styleUrls: ['./favourite-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FavouritePageComponent {

    constructor() {
    }

    public getFavourites(location: string): Favourite[] {
        const localStorageItem = JSON.parse(localStorage.getItem(location));
        return localStorageItem == null ? [] : localStorageItem.data;
    }

    public removeFavourite(id: string, location: string): void {
        let favourites = this.getFavourites(location);
        favourites = favourites.filter((item) => item.id !== id);
        this.setLocalStorageFavourites(location, favourites);
    }

    private setLocalStorageFavourites(location: string, favourites: Favourite[]): void {
        localStorage.setItem(location, JSON.stringify({data: favourites}));
    }
}
